import { Instance, types, applySnapshot, flow, getEnv } from 'mobx-state-tree';
import { IStoresEnv } from '@core';
import { TakeFiveSummary } from '../domain/TakeFiveSummary';
import { formatDate_yyyymmdd } from '@utils';

export const TakeFiveSummaryStore = types
  .model({
    teams: types.array(types.string),
    selectedTeam: types.optional(types.string, ''),
    fromDate: types.optional(types.maybeNull(types.Date), new Date().setMonth(new Date().getMonth() - 1)),
    toDate: types.optional(types.maybeNull(types.Date), new Date()),
    takeFiveSummary: types.array(TakeFiveSummary),
    isLoading: types.optional(types.boolean, false)
  })
  .actions((self) => {
    const {
      api,  
    } = getEnv<IStoresEnv>(self);

    const mapData = (y) => ({
      technicianId: y.technicianId,
      technicianName: y.technicianName,
      totalCompletedTakeFive: y.totalCompletedTakeFive,
      startDate: self.fromDate,
      endDate: self.toDate,
    });

    const fetchData = flow(function* dateFetch() {
      self.isLoading = true;

      const apiUrl = `/api/TakeFiveSummary?technicianTeam=${self.selectedTeam}&startDate=${formatDate_yyyymmdd(self.fromDate)}&endDate=${formatDate_yyyymmdd(self.toDate)}`

      const result = yield api.get(apiUrl);
      const data = result.data || [];
      applySnapshot(self.takeFiveSummary, data.map((x) => mapData(x)));

      self.isLoading = false;
    });

    return {
      fetchFilters: flow(function* filtersFetch() {
        self.isLoading = true;

        const teams = yield api.get(`/api/teams/permitted`);
        applySnapshot(self.teams, teams.data || []);

        self.isLoading = false;
      }),
      selectTeam(team: string) {
        self.selectedTeam = team;
        fetchData();
      },
      selectDateRange(from: Date | null, to: Date | null) {
        self.fromDate = from;
        self.toDate = to;
        fetchData();
      },
      reloadData() {
        if(!! self.selectedTeam)
        {
          fetchData();
        }
      }
    };
  });

export type ITakeFiveSummaryStore = Instance<typeof TakeFiveSummaryStore>;

import Paper from '@material-ui/core/Paper';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import React from 'react';
import { UnallocatedLostTimeModel } from '../domain/UnallocatedLostTime';
import LostTimeRow from './LostTimeRow';
import SortableTableHead, { HeadCell } from '../../components/sortable-table/SortableTableHead';
import { observer } from 'mobx-react-lite';
import { SortOrder, sort } from '@utils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      overflow: 'auto',
      width: '100%',
      flex: 1
    }
  }));

interface Props {
  unallocatedLostTimeItems: UnallocatedLostTimeModel[];
}

const headCells: Array<HeadCell<UnallocatedLostTimeModel>> = [
  { id: 'date', label: 'Date' },
  { id: 'team', label: 'Technician Team' },
  { id: 'technicianName', label: 'Technician' },
  { id: 'startDate', label: 'Start Time' },
  { id: 'endDate', label: 'End Time' },
  { id: 'totalGpHours', label: 'Total GP Hours' },
  { id: 'allocatedHours', label: 'Allocated Hours' },
  { id: 'actions', label: '' }
];

export const LostTimeTable: React.FC<Props> = observer((props) => {
  const classes = useStyles();
  const { unallocatedLostTimeItems } = props;
  const [order, setOrder] = React.useState();
  const [orderBy, setOrderBy] = React.useState();

  const handleRequestSort = (newSortOrder: SortOrder, newOrderBy: keyof UnallocatedLostTimeModel) => {
    setOrder(newSortOrder);
    setOrderBy(newOrderBy);
  };

  return (
    <Paper className={classes.root}>
      <Table stickyHeader={true} size="small">
        <SortableTableHead<UnallocatedLostTimeModel>
          headCells={headCells}
          order={order}
          orderBy={orderBy}
          onSort={handleRequestSort}
        />
        <TableBody>
          {sort<UnallocatedLostTimeModel>(unallocatedLostTimeItems, order, orderBy).map((x, index) => (
            <LostTimeRow
              key={index}
              unallocatedLostTime={x}
            />
          ))}
        </TableBody>
      </Table>
    </Paper>
  );
});

import { IApiStore, EndpointCallParams, ApiResponse } from "api";

export const processEnquiries = async (
  api: IApiStore,
  params: EndpointCallParams<any>,
): Promise<ApiResponse<any>> => {
  const url = `/api/payrollAdjustments/process`;

  return await api.post(url, params);
};

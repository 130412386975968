import { createStyles, makeStyles, Theme  } from "@material-ui/core/styles";

const drawerWidth = 250;

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      backgroundColor: '#eef4f9',
      height: '100%',
      position: 'absolute',
      left: '0',
      width: '100%',
      overflow: 'hidden'
    },

    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap'
    },
    drawerOpen: {
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen
      })
    },
    drawerClose: {
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      }),
      overflowX: 'hidden',
      width: theme.spacing(7) + 1,
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(7) + 1
      }
    },
    content: {
      flexGrow: 1,
      paddingTop: theme.spacing(8),
      overflow: 'auto'
    },
    contentBody: {
      height: '100%',
      overflow: 'auto',
      padding: theme.spacing(2),
    }
  })
);

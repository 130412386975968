import { types, Instance } from 'mobx-state-tree';

export const EstimateStatus = types.model({
  id: types.optional(types.string, ''),
  status: types.optional(types.string, ''),
  isVisible: types.optional(types.boolean, true),
  defaultStatus: types.optional(types.boolean, false)
})
  .actions((self) => {
    return {
      setStatus: (status: string) => {
          self.status = status;
      },
      setIsVisible: (isVisible: boolean) => {
          self.isVisible = isVisible;
      },
      setDefaultStatus: (defaultStatus: boolean) => {
          self.defaultStatus = defaultStatus;
      }

    };
  })
  .views((self) => ({
      equals(status) {
          return self.status === status.status
            && self.isVisible === status.isVisible
            && self.defaultStatus === status.defaultStatus;
      }
    })
  );

export type EstimateStatusModel = Instance<typeof EstimateStatus>;

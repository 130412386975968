import { types, Instance } from 'mobx-state-tree';

export const EstimateEquipmentType = types.model({
  id: types.optional(types.string, ''),
  equipmentType: types.optional(types.string, '')
})
  .actions((self) => {
    return {
      setEquipmentType: (equipmentType: string) => {
          self.equipmentType = equipmentType;
      },

    };
  })
  .views((self) => ({
      equals(equipmentType) {
          return self.equipmentType === equipmentType.equipmentType
      }
    })
  );

export type EstimateEquipmentTypeModel = Instance<typeof EstimateEquipmentType>;

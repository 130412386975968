import { types, Instance } from 'mobx-state-tree';
import { EstimatePriority} from './Priority';

import { uuidv4 } from '@utils';


export const EstimatePriorities = types.model({
    priorities: types.optional(types.array(EstimatePriority), [])
})
  .actions((self) => {
    return {
      addNewLine: () => {
        const priority: any = {
            id: uuidv4()
        };
        
        self.priorities.push(priority);
      }
    };
  })
  .views((self) => ({
    equals(priorities) {
        return self.priorities.filter(
          (t) => !priorities.some( (x) => t.equals(x))).length === 0;
    }
  }));

export type EstimatePrioritiesModel = Instance<typeof EstimatePriorities>;

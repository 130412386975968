import React, { useState } from 'react';
import { observer } from 'mobx-react';

import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import { Button, CircularProgress } from '@material-ui/core';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { blueGrey, lightBlue } from '@material-ui/core/colors';

import { LocalFindModel } from '../domain/LocalFind';
import ConfirmationDialog from 'components/ConfirmationDialog';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      textDecoration: 'none'
    },
    label: {
      textTransform: 'none'
    },
    row: {
      '&:nth-child(even)': {
        backgroundColor: blueGrey[50]
      },
      '&:hover': {
        backgroundColor: lightBlue[100]
      }
    }
  }));

interface Props {
  localFind: LocalFindModel;
  onDelete: (localFind: LocalFindModel) => void;
}

export const LocalFindRow: React.FC<Props> = observer((props) => {
  const classes = useStyles();
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const { localFind, onDelete } = props;

  const handleDeleteClick = () => {
    setConfirmationOpen(true);
  };

  const handleConfirmationClose = (confirmed: boolean) => {
    setConfirmationOpen(false);
    if (confirmed) {
        onDelete(localFind);
    }
   };

  return (
    <TableRow className={classes.row}>
      <TableCell component="th" scope="row" align="left">
        {localFind.branchName}
      </TableCell>
      <TableCell align="left">{localFind.displayName}</TableCell>
      <TableCell align="left">{localFind.searchText}</TableCell>
      <TableCell align="right">
          <Button
            className={classes.label}
            color="primary"
            startIcon={localFind.isDeleting ? <CircularProgress size={14} /> : <DeleteOutlineIcon />}
            onClick={handleDeleteClick}
          >
            Delete
          </Button>
      </TableCell>
      <ConfirmationDialog
        open={confirmationOpen}
        onClose={handleConfirmationClose}
        title="Are you sure you want to delete this local find record?"
      />
    </TableRow>
  );
});

import { makeStyles } from '@material-ui/styles';

import { Theme, createStyles } from '@material-ui/core';

import { blue } from '@material-ui/core/colors';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    selected: {
      color: blue[500],
    }
  })
);
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import React from 'react';
import { grey, green, purple, red, blue, amber, cyan, teal, orange } from '@material-ui/core/colors';
import Typography from '@material-ui/core/Typography';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      backgroundColor: grey['800'],
      borderRadius: '15px',
      color: grey['50'],
      textAlign: 'left',
      paddingLeft: '5px',
      maxWidth: '120px',
      fontSize: 'inherit'
    },
    icon: {
      verticalAlign: 'text-top',
      marginRight: '5px'
    }
  }));

interface Props {
  status: TimeSheetStatus;
}

export enum TimeSheetStatus {
  NoData = 'No Data',
  New = 'New',
  Submitted = 'Submitted',
  Rejected = 'Rejected',
  Approved = 'Approved',
  Processed = 'Processed',
  Integrated = 'Integrated',
  Draft = 'Draft',
  Final = 'Final'
}

export default function TimeSheetStatusViewer(props: Props) {
  const getStatusColor = (value: TimeSheetStatus) => {
    switch (value) {
      case TimeSheetStatus.New:
        return green[400];
      case TimeSheetStatus.Submitted:
        return purple[400];
      case TimeSheetStatus.Rejected:
        return red[400];
      case TimeSheetStatus.Approved:
        return blue[400];
      case TimeSheetStatus.Processed:
          return amber[400];
      case TimeSheetStatus.Integrated:
        return cyan[400];
      case TimeSheetStatus.NoData:
        return teal[400];
      default:
        return orange[400];
    }
  };

  const classes = useStyles();
  const { status } = props;
  const statusColor = getStatusColor(status);

  return (
    <Typography noWrap={true} className={classes.wrapper} style={{ backgroundColor: statusColor }}>
      <FiberManualRecordIcon fontSize="inherit" className={classes.icon} />
      {status}
    </Typography>
  );
}

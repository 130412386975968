import { types, Instance } from 'mobx-state-tree';

export const EstimateTask = types.model({
  id: types.optional(types.string, ''),
  task: types.optional(types.string, ''),
  taskTime: types.maybe(types.number),
  estimateID: types.optional(types.string, '')
})
  .actions((self) => {
    return {
      setTask: (task: string) => {
          self.task = task;
      },
      setTaskTime: (taskTime: number) => {
          self.taskTime = taskTime;
      },
      setEstimateID: (estimateID: string) => {
          self.estimateID = estimateID;
      },

    };
  })
  .views((self) => ({
      equals(task) {
          return self.task === task.task
      }
    })
  );

export type EstimateTaskModel = Instance<typeof EstimateTask>;

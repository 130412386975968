import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import React from 'react';
import { Button } from '@material-ui/core';
import { OutstandingApprovalsModel } from 'outstanding-approvals/domain/OutstandingApprovals';
import RemoveRedEyeOutlinedIcon from '@material-ui/icons/RemoveRedEyeOutlined';
import { blueGrey, lightBlue } from '@material-ui/core/colors';
import { NavLink } from 'react-router-dom';
import { formatDate_yyyymmdd } from '@utils/dateTime';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      textDecoration: 'none'
    },
    label: {
      textTransform: 'none'
    },
    row: {
      '&:nth-child(even)': {
        backgroundColor: blueGrey[50]
      },
      '&:hover': {
        backgroundColor: lightBlue[100]
      }
    }
  }));

interface Props {
  approval: OutstandingApprovalsModel;
}

const MONTHS = ['Jan', 'Feb', 'Mar','Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

export default function ApprovalRow(props: Props) {
  const classes = useStyles();
  const { approval } = props;

  const formatDate = (date: Date) => {
    if (date == null) {
      return '';
    }

    return `${date.getDate()} ${MONTHS[date.getMonth()]} ${date.getFullYear()}`;
  };

  return (
    <TableRow key={approval.technicianId} className={classes.row}>
      <TableCell component="th" scope="row" align="left">
        {approval.technicianName}
      </TableCell>
      <TableCell align="left">
        {formatDate(approval.date)}
      </TableCell>
      <TableCell align="left">{approval.gpHours}</TableCell>
      <TableCell align="left">{approval.payrollHours}</TableCell>
      <TableCell align="right">
        <NavLink
          className={classes.button}
          to={`timesheet-details/${approval.technicianId}/${formatDate_yyyymmdd(approval.date)}`}>
          <Button
            className={classes.label}
            color="primary"
            startIcon={<RemoveRedEyeOutlinedIcon />}
          >
            View on Timesheet Details
          </Button>
        </NavLink>
      </TableCell>
    </TableRow>
  );
}

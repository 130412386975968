import { observer } from 'mobx-react';
import React from 'react';
import { Grid, Divider, Button, createStyles, makeStyles, Theme } from '@material-ui/core';
import { GpAdjustmentRow } from './GpAdjustmentRow';
import ControlPointOutlinedIcon from '@material-ui/icons/ControlPointOutlined';
import { blueGrey } from '@material-ui/core/colors';
import clsx from 'clsx';
import { GpAdjustmentModel } from 'timesheet-details/domain/gp-adjustments/GpAdjustment';
import { AllocationModel } from 'timesheet-details/domain/Allocations';
import { TimeSheetStatus } from 'components/TimeSheetStatusViewer';

interface Props {
    disabled?: boolean;
    adjustments: GpAdjustmentModel[];
    allocations: AllocationModel[];
    date: Date;
    maximumCallAdjustmentAllocations: number;
    onAdd: () => void;
    onDelete: (allocation: GpAdjustmentModel) => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        newRow: {
            paddingLeft: theme.spacing(1),
            paddingTop: theme.spacing(1),
        },
        row: {
            padding: theme.spacing(1)
        },
        even: {
            backgroundColor: blueGrey[50]
        }
    }));

export const GpAdjustmentsDetails: React.FC<Props> = observer((props) => {
    const classes = useStyles();
    const { adjustments, onAdd, onDelete, disabled, date, allocations, maximumCallAdjustmentAllocations } = props;
    
    const canAddAdjustments = maximumCallAdjustmentAllocations - (adjustments.filter(x=> x.tempStatus == TimeSheetStatus.Draft).length + allocations.filter(x=> x.tempStatus == TimeSheetStatus.Draft).length) > 0;

    return (
        <Grid container={true}>
            <Grid item={true} xs={12}>
                <Divider />
            </Grid>
            <Grid item={true} xs={12} className={classes.newRow}>
                <Button
                    disabled={disabled || !canAddAdjustments}
                    variant="outlined"
                    color="primary"
                    startIcon={<ControlPointOutlinedIcon />}
                    onClick={onAdd}
                >
                    ADD NEW LINE
                </Button>
            </Grid>
            { adjustments.map((adj, index) =>
                <Grid item={true} xs={12} className={clsx(index % 2 === 1 && classes.even, classes.row)}
                    key={`timeline${index}`}
                >
                    <GpAdjustmentRow
                        date={date}
                        disabled={disabled}
                        adjustment={adj}
                        onDelete={onDelete}
                    />
                </Grid>)
            }
        </Grid>
    );
});

import { types } from "mobx-state-tree";

export enum DataState {
    notLoaded = 'notLoaded',
    loading = 'loading',
    done = 'done',
    error = 'error'
}
export const DataStateEn = types.enumeration('DataState', [
    DataState.notLoaded, DataState.loading, DataState.error, DataState.done]);

import { observer } from 'mobx-react';
import React from 'react';
import { useStyles } from './details-component.style';
import { Tabs, Tab, Typography, Box, ExpansionPanelDetails, ExpansionPanelSummary, ExpansionPanel } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { DetailsTab } from './DetailsTab';
import { CustomerTab } from './CustomerTab';
import { LogsTab } from './LogsTab';
import { IServiceCallDetailModel } from './models/ServiceCallDetailModel';
import { ITravelRecordModel } from './models';
import { TakeFiveTab } from './TakeFiveTab';

interface Props {
  callDetails: IServiceCallDetailModel;
  showMarker: (lat: number, lng: number) => void;
  calcRoute: (travelRecord: ITravelRecordModel) => void;
  className?: string;
}

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={4}>{children}</Box>}
    </Typography>
  );
}

export const DetailsComponent: React.FC<Props> = observer((props) => {
  const classes = useStyles();
  const theme = useTheme();
  const { callDetails, showMarker, calcRoute } = props;

  const [value, setValue] = React.useState(3);
  const [expansion, setExpansion] = React.useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleExpansion = () => {
    setExpansion(!expansion);
  };

  return (
    <div className={classes.root}>
      <ExpansionPanel
        defaultExpanded
        expanded={expansion}
      >
        <ExpansionPanelSummary
          className={classes.expansion}
          expandIcon={<ExpandMoreIcon onClick={handleExpansion} />}
          aria-controls="panel1c-content"
          id="panel1c-header"
        >
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            variant="standard"
            aria-label="full width tabs example"
          >
            <Tab className={classes.tab} label="Details" {...a11yProps(0)} />
            <Tab className={classes.tab} label="Customer" {...a11yProps(1)} />
            <Tab className={classes.tab} label="Take Five" {...a11yProps(2)} />
            <Tab className={classes.tab} label="Logs" {...a11yProps(3)} />
          </Tabs>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className={classes.noPadding}>
          <TabPanel
            value={value}
            index={0}
            dir={theme.direction}
            className={classes.customTabPanel}
          >
            <DetailsTab
              detailsData={callDetails}
            />
          </TabPanel>
          <TabPanel value={value} index={1} dir={theme.direction}>
            <CustomerTab
              customerData={callDetails}
            />
          </TabPanel>
          <TabPanel value={value} index={2} dir={theme.direction}>
            <TakeFiveTab
              takeFiveData={callDetails} />
          </TabPanel>
          <TabPanel value={value} index={3} dir={theme.direction}>
            <LogsTab
              showMarker={showMarker}
              calcRoute={calcRoute}
              logsData={callDetails}
            />
          </TabPanel>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </div>
  );
});

import { makeStyles, createStyles, Theme } from "@material-ui/core";
import { blue, grey } from "@material-ui/core/colors";

export const useStyles = makeStyles((theme: Theme) =>
createStyles({
  tabName: {
    color: blue[500],
  },
  tabSection: {
    width: '620px',
    maxHeight: '670px',
    overflowY: 'auto',
    padding: '25px',
  }, 
  firstTabSection: {
    width: '500px',
    maxHeight: '260px',
    overflowY: 'auto',
    padding: '25px',
  }, 
  middleTabSection: {
    width: '500px',
    maxHeight: '195px',
    overflowY: 'auto',
    borderBottom: `1px solid ${grey[300]}`,
    borderTop: `1px solid ${grey[300]}`,
    padding: '25px',
  },
  bottomTabSection: {
    width: '500px',
    padding: '25px',
    overflowY: 'auto',
    maxHeight: '350px',
  },
  gridCellName: {
    fontWeight: 'bold',
  },
  gridRow: {
    paddingTop: '10px',
  },
  pointer: {
    cursor: 'pointer',
  },
  textArea: {
    width: '470px',
    overflowY: 'auto',
    maxHeight: '110px',
    minHeight: '110px'
  }
}));
import { inject, observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { STORE_KEYS } from '../@core/createStores';
import { IUnallocatedLostTimeStore } from './store/UnallocatedLostTimeStore';
import SingleSelect from '../components/SingleSelect';
import { Grid, Paper, makeStyles, createStyles, Theme, LinearProgress } from '@material-ui/core';
import { LostTimeTable } from './components/LostTimeTable';
import DateRangePicker from '../components/DateRangePicker';

interface Props {

}

type InjectedProps = {
  UnallocatedLostTimeStore: IUnallocatedLostTimeStore;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      height: '100%'
    },
    gridContainer: {
      height: 'calc(100% - 115px)',
      display: 'flex',
      flex: 1
    },
    filter: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      padding: theme.spacing(2)
    },
    progress: {
      width: '100%'
    }
  }));

const UnallocatedLostTimeComponent: React.FC<Props> = observer((props) => {
  const store = (props as InjectedProps).UnallocatedLostTimeStore;
  const classes = useStyles();

  useEffect(() => {
    store.fetchFilters();
  }, []);

  return (
    <Grid container={true}
      direction="column"
      wrap="nowrap"
      className={classes.wrapper}
    >
      <Grid item={true}>
        <Paper className={classes.filter}>
          <Grid container={true} spacing={2}>
            <Grid item={true} lg={2} md={3} xs={3}>
              <SingleSelect
                label="Lost Time Type"
                values={store.lostTimeTypes}
                selectedValue={store.selectedLostTimeType}
                onChanged={store.selectLostType}
                error={false}
              />
            </Grid>
            <Grid item={true} lg={2} md={3} xs={3}>
              <SingleSelect
                label="Technician Branch"
                values={store.branches}
                selectedValue={store.selectedBranch}
                onChanged={store.selectBranch}
                error={false}
              />
            </Grid>
            <Grid item={true} lg={4} md={5} xs={6}>
              <DateRangePicker fromDate={store.fromDate} toDate={store.toDate} onChanged={store.selectDateRange} />
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Grid item={true} className={classes.gridContainer}>
        {store.isLoading && <LinearProgress className={classes.progress} />}
        {!store.isLoading && <LostTimeTable unallocatedLostTimeItems={store.unallocatedLostTimeItems} />}
      </Grid>
    </Grid>
  );
});

export const UnallocatedLostTime = inject(STORE_KEYS.UnallocatedLostTimeStore)(UnallocatedLostTimeComponent);

import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { useStyles } from './call-details.style';
import { Grid } from '@material-ui/core';
import { DetailsComponent } from './DetailsComponent';
import ReactDOM from 'react-dom';
import { IServiceCallDetailModel } from './models/ServiceCallDetailModel';
import { ITravelRecordModel } from './models';

interface Props {
  callDetails: IServiceCallDetailModel;
  onMessage: (message: string) => void;
  satelliteView: boolean;
}

export const MapComponent: React.FC<Props> = observer((props) => {
  const classes = useStyles();
  const [mapInstance, setMapInstance] = useState(null);
  useEffect(() => {
    setMapInstance(initializeMap());
  }, []);
  useEffect(() => {
    if (mapInstance !== null) {
      initMapType(mapInstance);
    }
  }, [props.satelliteView]);
  const initMapType = (map: any) => {
    if (props.satelliteView) {
      map.setMapTypeId(google.maps.MapTypeId.SATELLITE)
    } else {
      map.setMapTypeId(google.maps.MapTypeId.ROADMAP)
    }
  }
  const initializeMap = () => {
    const { callDetails } = props;
    const directionsRenderer = new google.maps.DirectionsRenderer();
    const directionsService = new google.maps.DirectionsService();

    // const selectedData = callDetails.getupdateLogs.map(t => ({
    //   latitude: t.statusUpdateLocationLatitude,
    //   longitude: t.statusUpdateLocationLongitude
    // }));
    // const filtereDate: { latitude, longitude }[] = uniqWith(selectedData, isEqual);
    // const locations = filtereDate.map((log) =>
    //   new google.maps.LatLng(log.latitude, log.longitude));
    const locations = callDetails.getupdateLogs;

    const map = new google.maps.Map(document.getElementById('map'),
      {
        mapTypeControl: false
      }
    );
    initMapType(map);
    const allMarkers = new Array();

    directionsRenderer.setMap(map);
    let centerControlDiv = document.createElement('div');
    centerControlDiv.style.padding = '10px';

    function calcRoute(t: ITravelRecordModel) {
      const { callDetails } = props;

      callDetails.getTravelRecords.forEach(t => {
        const start = new google.maps.LatLng(t.startLocationLatitude, t.startLocationLongitude);
        const end = new google.maps.LatLng(t.endLocationLatitude, t.endLocationLongitude);

        // var start = 'chicago, il';
        // var end = 'st louis, mo';
        const request = {
          origin: start,
          destination: end,
          // Note that JavaScript allows us to access the constant
          // using square brackets and a string value as its
          // "property."
          travelMode: google.maps.TravelMode['DRIVING']
        };
        directionsService.route(request, function (response, status) {
          if (status == 'OK') {
            directionsRenderer.setDirections(response);
            if (allMarkers[0]) {
              map.setZoom(18);
              map.setCenter(allMarkers[0].getPosition());

              map.fitBounds(bounds);
            }
          } else {
            props.onMessage(`Route render: ${status}`);
          }
        });

      });
    }

    const showSingleMarker = (lat: number, lng: number) => {
      var el = allMarkers.find(t => t.position.lat() === lat && t.position.lng() === lng)
      if (el) {
        map.setZoom(15);
        map.panTo(el.getPosition());
      }
    }

    calcRoute({} as ITravelRecordModel);
    const tabInfoComp = <DetailsComponent showMarker={showSingleMarker} calcRoute={calcRoute} callDetails={callDetails} />;

    ReactDOM.render(tabInfoComp, centerControlDiv)

    map.controls[google.maps.ControlPosition.LEFT].push(centerControlDiv);

    let bounds = new google.maps.LatLngBounds();
    locations.forEach((location, index) => {
      let marker = new google.maps.Marker({
        position: new google.maps.LatLng(location.statusUpdateLocationLatitude, location.statusUpdateLocationLongitude),
        title: `${index + 1}`,
        map: map
      });

      // bounds.extend(marker.position);
      // marker.setMap(map);
      marker.addListener('click', function () {
        map.setZoom(15);
        map.panTo(marker.getPosition());
      });
      bounds.extend(marker.position);
      allMarkers.push(marker);
    });

    map.fitBounds(bounds);
    return map;
  }

  return (
    <Grid item={true} className={classes.gridContainer}>
      <div id="map" className={classes.map} />
    </Grid>
  );
});

import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { TextField } from '@material-ui/core';

interface Props {
  label: string;
  defaultValue?: string;
  onChanged?: (value: string) => void;
}

function InputTextField(props: Props) {
  const { onChanged, label, defaultValue } = props;
  const [input, setInput] = useState('');

  const handleChange = event => {
    const newData = event.target.value as string;

    setInput(newData);
  };

  const handleBlur = event => {
    const newData = event.target.value as string;

    setInput(newData);

    if (onChanged && defaultValue !== input)
      onChanged(input);
  };


  useEffect(
    () => {
      setInput(defaultValue || input);
    },
    [defaultValue]
  );

  return (
    <TextField
      onChange={handleChange}
      onBlur={handleBlur}
      id="outlined-basic"
      label={label}
      value={input}
      variant="outlined"
      margin='dense'
      fullWidth
    />
  );
}

export default observer(InputTextField);

import Paper from '@material-ui/core/Paper';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import React from 'react';
import { TimesheetSummaryModel } from 'timesheet-summary/domain/TimesheetSummary';
import SummaryRow from './SummaryRow';
import SortableTableHead, { HeadCell } from 'components/sortable-table/SortableTableHead';
import { SortOrder, sort } from '@utils/sort';
import { observer } from 'mobx-react-lite';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      overflowX: 'auto',
      width: '100%',
      flex: 1
    }
  }));

interface Props {
  summaries: TimesheetSummaryModel[];
}

const headCells: Array<HeadCell<TimesheetSummaryModel>> = [
  { id: 'technicianName', label: 'Technician ID' },
  { id: 'status', label: 'Status' },
  { id: 'gpHours', label: 'GP Hours' },
  { id: 'payrollHours', label: 'Payroll Hours' },
  { id: 'actions', label: '' }
];

export const SummaryTable: React.FC<Props> = observer((props) => {
  const classes = useStyles();
  const { summaries } = props;

  const [order, setOrder] = React.useState();
  const [orderBy, setOrderBy] = React.useState();

  const handleRequestSort = (newSortOrder: SortOrder, newOrderBy: keyof TimesheetSummaryModel) => {
    setOrder(newSortOrder);
    setOrderBy(newOrderBy);
  };

  return (
    <Paper className={classes.root}>
      <Table stickyHeader={true} size="small">
        <SortableTableHead<TimesheetSummaryModel>
          headCells={headCells}
          order={order}
          orderBy={orderBy}
          onSort={handleRequestSort}
        />
        <TableBody>
          {sort<TimesheetSummaryModel>(summaries, order, orderBy).map((summary) => (
            <SummaryRow
              key={summary.technicianId}
              summary={summary}
            />
          ))}
        </TableBody>
      </Table>
    </Paper>
  );
});

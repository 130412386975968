import { inject, observer } from 'mobx-react';
import React, {useEffect} from 'react';
import { IEquipmentTypeStore } from './store/EquipmentTypeConfigStore';
import { EstimateEquipmentTypes } from './EquipmentTypes';
import { STORE_KEYS } from '@core';


interface Props {
  EquipmentTypeStore: IEquipmentTypeStore;
}

const EquipmentTypeComponent: React.FC<Props> = observer((props) => {
  const store = props.EquipmentTypeStore;
  const { equipmentTypes, hasChanges, save, delete: handleDelete } = store;

  useEffect(() => {
    store.fetchData();
    return function cleanup() {
    };
  }, []);

  return (
    <EstimateEquipmentTypes EstimateEquipmentTypeModel={equipmentTypes} 
    equipmentTypes={equipmentTypes.equipmentTypes} 
    hasChanges={hasChanges(equipmentTypes.equipmentTypes)}
    onSave={save}
    onDelete={handleDelete}
    />
  );
});

export const EquipmentTypeConfig = inject(STORE_KEYS.EquipmentTypeConfigStore)(EquipmentTypeComponent);

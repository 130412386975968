import { makeStyles, Theme, createStyles } from "@material-ui/core";
import { grey } from '@material-ui/core/colors';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    rowCells: {
      '& td': {
        '&:not(:nth-child(2))': {
          fontWeight: 'bold',
        },
        '&:not(:first-child)': {
          borderTop: `1px solid ${grey[300]}`,
          borderBottom: `1px solid ${grey[300]}`,
        },
        borderBottom: 'none',
        paddingBottom: '25px',
        fontSize: '12px',
      }
    },
    lastTotalRow: {
      '& td': {
        borderBottom: 'none !important',
      }
    }
  }));
import { types } from "mobx-state-tree";

export enum UserRoles {
    SettingsAdmin = 'SettingsAdmin',
    PayrollAdmin = 'PayrollAdmin',
    TimesheetCreator = 'TimesheetCreator',
    TimesheetEditor = 'TimesheetEditor',
    TimesheetApprover = 'TimesheetApprover',
    PayrollEnquiry = 'PayrollEnquiry',
    TakeFiveReader = 'TakeFiveReader',
    EstimateEditor = 'EstimateEditor'
}

export const UserRolesEn = types.enumeration('UserRoles', [
  UserRoles.SettingsAdmin, UserRoles.PayrollAdmin, UserRoles.TimesheetCreator,
  UserRoles.TimesheetEditor, UserRoles.TimesheetApprover,
  UserRoles.PayrollEnquiry, UserRoles.TakeFiveReader, UserRoles.EstimateEditor]);
import { STORE_KEYS } from "@core";
import { inject, observer } from "mobx-react";
import React from 'react';
import { Button, Grid, makeStyles, Theme, createStyles, Paper, LinearProgress} from '@material-ui/core';
import { CommonJobsSummaryTable } from "./components/CommonJobsSummaryTable";
import { ICommonJobsSummaryStore } from "./store/CommonJobsSummaryStore";
import { NavLink } from 'react-router-dom';


interface Props {
  // Add props if needed to pass data or functions from EstimatingTool 
}

type InjectedProps = {
  CommonJobsSummaryStore: ICommonJobsSummaryStore;
};


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      height: '85%'
    },
    gridContainer: {
      height: 'calc(85% - 115px)',
      display: 'flex',
      flex: 1
    },
    filter: {
      display: "flex",
      alignItems: "center", // Vertically centers children
      padding: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    select: {
      marginRight: theme.spacing(2), // Adds some space between the dropdown and the button
    },
    progress: {
      width: '100%',
      marginTop: theme.spacing(2)
    },
    button: {
      'justify-content': 'flex-start'
    },
    link: {
      textDecoration: 'none',
      color: theme.palette.primary.main
    }
  }));

const CommonJobsSummaryComponent: React.FC<Props> = observer((props: Props & InjectedProps) => {
  const store = (props as InjectedProps).CommonJobsSummaryStore;
  const classes = useStyles();

  return (
    <div>
      <Paper className={classes.filter}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={10} container justifyContent="flex-end">
            <NavLink to="/commonJobs/new" className={classes.link}>
              <Button variant="contained" color="primary">
                Create New Common Job
              </Button>
            </NavLink>
          </Grid>
        </Grid>
      </Paper>
      <Paper className={classes.filter}>
        <Grid item={true} className={classes.gridContainer}>
          {store.isLoading && <LinearProgress className={classes.progress} />}
          {!store.isLoading && <CommonJobsSummaryTable CommonJobsSummary={store.CommonJobsSummary} />}
        </Grid>
      </Paper>
    </div>
  );
});

export const CommonJobsSummary = inject(STORE_KEYS.CommonJobsSummaryStore)(CommonJobsSummaryComponent);



import { STORE_KEYS } from "@core";
import { inject, observer } from "mobx-react";
import { ITakeFiveSummaryStore } from "./store/TakeFiveSummaryStore";
import { Grid, LinearProgress, Paper, Theme, createStyles, makeStyles } from "@material-ui/core";
import React, { useEffect } from 'react';
import DateRangePicker from "components/DateRangePicker";
import { TakeFiveSummaryTable } from "./components/TakeFiveSummaryTable";
import SingleSelect from "components/SingleSelect";

interface Props {

}

type InjectedProps = {
    TakeFiveSummaryStore: ITakeFiveSummaryStore;
  };

  const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      height: '100%'
    },
    gridContainer: {
      height: 'calc(100% - 115px)',
      display: 'flex',
      flex: 1
    },
    filter: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      padding: theme.spacing(2)
    },
    progress: {
      width: '100%'
    }
  }));

const TakeFiveSummaryComponent: React.FC<Props> = observer((props) => {
    const store = (props as InjectedProps).TakeFiveSummaryStore;
    const classes = useStyles();

    useEffect(() => {
      store.fetchFilters();
      store.reloadData();
    }, []);

    
  return (
    <Grid container={true}
      direction="column"
      wrap="nowrap"
      className={classes.wrapper}
    >
      <Grid item={true}>
        <Paper className={classes.filter}>
          <Grid container={true} spacing={2}>
            <Grid item={true} lg={2} md={3} xs={6}>
              <SingleSelect
                label="Team ID"
                values={store.teams}
                selectedValue={store.selectedTeam}
                onChanged={store.selectTeam}
                error={false}
              />
            </Grid>
            <Grid item={true} lg={4} md={5} xs={6}>
              <DateRangePicker fromDate={store.fromDate} toDate={store.toDate} onChanged={store.selectDateRange} />
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Grid item={true} className={classes.gridContainer}>
        {store.isLoading && <LinearProgress className={classes.progress} />}
        {!store.isLoading && <TakeFiveSummaryTable takeFiveSummary={store.takeFiveSummary} />}
      </Grid>
    </Grid>
  );
});


export const TakeFiveSummary = inject(STORE_KEYS.TakeFiveSummaryStore)(TakeFiveSummaryComponent);

import { types, Instance } from 'mobx-state-tree';

export const UserImport = types
  .model({
    error: types.optional(types.string, ''),
    isImporting: types.optional(types.boolean, false),
    email: types.optional(types.string, '')
  })
  .actions((self) => {
    return {
      setUserEmail(email) {
        self.email = email;
        self.error = '';
      },
      setError(error) {
        self.error = error;
      },
      setIsImporting(isImporting) {
        self.isImporting = isImporting;
      }
    };
  });

export type UserImportModel = Instance<typeof UserImport>;

import React from 'react';
import { observer } from 'mobx-react';
import ArrowDropDownOutlinedIcon from '@material-ui/icons/ArrowDropDownOutlined';
import { Grid, makeStyles, Theme, createStyles, Typography, TableSortLabel } from '@material-ui/core';
import { SortOrder, getSortOrder } from '@utils/sort';
import { UserConfigurationModel } from '../domain/UserConfiguration';

interface Props {
  onSort: (newSortOrder: SortOrder, newOrderBy: keyof UserConfigurationModel) => void;
  order: SortOrder;
  orderBy: keyof UserConfigurationModel;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
        margin: theme.spacing(1)
      },
    userLabel: {
        paddingLeft: theme.spacing(5)
    }
}));

export const UsersListHeader: React.FC<Props> = observer((props) => {
  const classes = useStyles();
  const { order, orderBy, onSort } = props;

  const handleUserIdSort = () => {
    onSort(getSortOrder(props.order), 'userId');
  };

  const handleUserNameSort = () => {
    onSort(getSortOrder(props.order), 'name');
  };

  return (
    <Grid container item xs={10} className={classes.root}>
        <Grid item xs={3} className={classes.userLabel}>
            <TableSortLabel
              style={{fontWeight: 'bold'}}
              active={orderBy === 'name' && !!order}
              direction={order}
              IconComponent={ArrowDropDownOutlinedIcon}
              onClick={handleUserNameSort}
            >
                User Name
            </TableSortLabel>
        </Grid>
        <Grid item xs={3}>
            <TableSortLabel
              style={{fontWeight: 'bold'}}
              active={orderBy === 'userId' && !!order}
              direction={order}
              onClick={handleUserIdSort}
              IconComponent={ArrowDropDownOutlinedIcon}
            >
                User ID
            </TableSortLabel>
        </Grid>
        <Grid item xs={2}>
            <Typography  style={{fontWeight: 'bold'}} variant="body2" display="block" gutterBottom>
                Assigned teams
            </Typography>
        </Grid>
     </Grid>
  );
});

import { inject, observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { STORE_KEYS } from '@core';
import { IOutstandingApprovalsStore } from './store/OutstandingApprovalsStore';
import SingleSelect from 'components/SingleSelect';
import { ApprovalsTable } from './components/ApprovalsTable';
import { Grid, Paper, makeStyles, createStyles, Theme, LinearProgress } from '@material-ui/core';

interface Props {

}

type InjectedProps = {
  OutstandingApprovalsStore: IOutstandingApprovalsStore;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      height: '100%'
    },
    gridContainer: {
      height: 'calc(100% - 115px)',
      display: 'flex',
      flex: 1
    },
    filter: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      padding: theme.spacing(2)
    },
    progress: {
      width: '100%'
    }
  }));

const OutstandingApprovalsComponent: React.FC<Props> = observer((props) => {
  const store = (props as InjectedProps).OutstandingApprovalsStore;
  const classes = useStyles();

  useEffect(() => {
    store.fetchTeam();
    store.fetchData();
  }, []);

  return (
    <Grid container={true}
      direction="column"
      wrap="nowrap"
      className={classes.wrapper}
    >
      <Grid item={true}>
        <Paper className={classes.filter}>
          <Grid container={true} spacing={2}>
            <Grid item={true} lg={2} md={3} xs={6}>
              <SingleSelect
                label="Team ID"
                values={store.teams}
                selectedValue={store.selectedTeam}
                onChanged={store.selectTeam}
                error={false}
              />
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Grid item={true} className={classes.gridContainer}>
        {store.isLoading && <LinearProgress className={classes.progress} />}
        {!store.isLoading && <ApprovalsTable approvals={store.filteredOutstandingApprovals} />}
      </Grid>
    </Grid>
  );
});

export const OutstandingApprovals = inject(STORE_KEYS.OutstandingApprovalsStore)(OutstandingApprovalsComponent);

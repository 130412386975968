import { startOfToday, addMinutes, getHours, getMinutes, format } from 'date-fns';
import { toTwoDecimalNumber } from './decimal';

export const formatDate = (date: Date) => {
    if (date == null) {
      return '';
    }

    return format(date,"EEEE dd MMM yyyy");
};

export const formatTime = (date: Date) => {
  if (date == null) {
    return '';
  }

  const hours = date.getHours() < 10 ? `0${date.getHours()}` : `${date.getHours()}`;
  const minutes = date.getMinutes() < 10 ? `0${date.getMinutes()}` : `${date.getMinutes()}`;

  return `${hours}:${minutes}`;
};

export const formatHours = (hours) => {
  const today = startOfToday();
  const result = addMinutes(today, hours * 60);

  return result;
};

export const formatDate_yyyymmdd = (date: Date | null) => {
  if (!date) {
    return '';
  }
  const yyyy = date.getFullYear();
  const month = date.getMonth() + 1;
  const mm = month < 10 ? `0${month}` : `${month}`;
  const dd = date.getDate() < 10 ? `0${date.getDate()}` : `${date.getDate()}`;

  return `${yyyy}-${mm}-${dd}`;
};


export const formatDate_ddmmyyyy = (date: Date | null) => {
  if (!date) {
    return '';
  }
  const yyyy = date.getFullYear();
  const month = date.getMonth() + 1;
  const mm = month < 10 ? `0${month}` : `${month}`;
  const dd = date.getDate() < 10 ? `0${date.getDate()}` : `${date.getDate()}`;

  return `${dd}-${mm}-${yyyy}`;
};

export const getHoursInTwoDecimalNumberFormat = (date: Date) => {
  return toTwoDecimalNumber(getHours(date) + getMinutes(date) / 60);
};

export const millisecondsToHours = (milliseconds: number) => {
  return milliseconds / 3600000;
};

export const millisecondsToSeconds = (milliseconds: number) => {
  return milliseconds / 1000;
};

export const DATE_FORMAT = 'yyyy-MM-dd\'T\'HH:mm';

export const getDateTime = (dateStr: string) => {
  const date = new Date(dateStr);
  date.setSeconds(0);
  date.setMilliseconds(0);

  return date;
};

import { types, Instance } from 'mobx-state-tree';

export const RequestsSummary = types
    .model({
      id: types.boolean,
      priority: types.string,
      nsc: types.string,
      EstimateNr: types.string,
      DebtorId: types.string,
      CustomerName: types.string,
      Location: types.string,
      Branch: types.string,
      Requestor: types.string,
      EquipmentID: types.string,
      Make: types.string,
      Model: types.string,
      Description: types.string,
      Status: types.string,
      Owner: types.string,
      selected: types.optional(types.boolean, false),
    })
    .actions((self) => ({
      selectItem () {
        self.selected = !self.selected;
      },
    }));

export type RequestsSummaryModel = Instance<typeof RequestsSummary>;



import { inject, observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { IPayrollAdjustmentEnquiryeStore } from './store';
import { Grid, Paper, Switch, FormControlLabel, LinearProgress } from '@material-ui/core';
import { PayrollEnquiryTable } from './components/PayrollEnquiryTable';
import DateRangePicker from '../components/DateRangePicker';
import { useStyles } from './payroll.style';
import TeamsMultipleSelect from 'components/TeamsMultipleSelect';
import { PAYROLL_INJECTION_KEY } from '@shared/store/AppStore';
import { ActionButtons } from '@shared/components/ActionButtons';

interface Props {
  teams: string[];
  selectedTeams: string[];
}

interface InjectedProps extends Props {
  payrollStore: IPayrollAdjustmentEnquiryeStore;
};

const PayrollAdjustmentEnquiryComponent: React.FC<InjectedProps> = observer((props) => {
  const store = (props as InjectedProps).payrollStore;
  const classes = useStyles();

  useEffect(() => {
    store.fetchEnquiries();
    store.fetchData();
  }, []);

  const handleTeamsSelect = (items: string[]) => {
    store.selectTeams(items);
  };

  const toggleChecked = event => {
    store.toggleChecked(event.target.checked);
  };

  const onExport = () => {
    store.exportEnquiries();
  };

  const onProcess = () => {
    store.processEnquiries();
  };

  return (
    <Grid container={true}
      direction="column"
      wrap="nowrap"
      className={classes.wrapper}
    >
      <Grid item={true}>
        <Paper className={classes.filter}>
          <Grid container={true} spacing={2}>
            <Grid item xs={2}>
              <TeamsMultipleSelect
                teams={store.teams}
                selectedTeams={store.selectedTeams}
                onSelectedTeamsChanged={handleTeamsSelect}
              />
            </Grid>
            <Grid item={true} lg={4} md={5} xs={4}>
              <DateRangePicker disabled={!store.processed} fromDate={store.fromDate} toDate={store.toDate} onChanged={store.selectDateRange} />
            </Grid>
            <Grid item={true} xs={6} className={classes.toggle}>
              <FormControlLabel
                control={
                  <Switch
                    checked={store.processed}
                    onChange={toggleChecked}
                    color="primary"
                  />
                }
                label={store.processed ? "Processed" : "Unprocessed"}
              />
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Grid item={true} className={classes.gridContainer}>
        {store.isLoading && <LinearProgress className={classes.progress} />}
        {!store.isLoading && 
          <>
            <PayrollEnquiryTable
              enquiryGroups={store.filteredEnquiryGroups}
              amountOfRows={store.amountOfRows}
              amountOfSelectedRows={store.amountOfSelectedRows}
            />
            <ActionButtons 
              leftBtnTest='EXPORT'
              rightBtnTest='PROCESS'
              leftAction={onExport}
              rigthAction={onProcess}
              hideRightBtn={store.processed}
              disabled={store.amountOfSelectedRows === 0}
            />
          </>
        }
      </Grid>
    </Grid>
  );
});

export const PayrollAdjustmentEnquiry = inject(PAYROLL_INJECTION_KEY)(PayrollAdjustmentEnquiryComponent);

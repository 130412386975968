import { ApiCallParams, ApiResponse } from 'api';
import { IGraphApiStore, IApiStore } from 'api';

const msgrapgApi = 'https://graph.microsoft.com/v1.0';
export const getUserProfile = async (
  api: IGraphApiStore,
  params: ApiCallParams<null>,
): Promise<ApiResponse<any>> => {
  const url = `${msgrapgApi}/me`;
  params.isAbsoluteUrl = true;
  return await api.get(url, params);
};

export const getUserConfigurations = async (
  api: IApiStore,
  params: ApiCallParams<null>,
  id: string
): Promise<ApiResponse<any>> => {
  const url = `/api/UserConfigurations/${id}`;
  return (await api.get(url, params));
};
export const getUserPhoto = async (
  api: IApiStore,
  params: ApiCallParams<null>,
): Promise<ApiResponse<any>> => {
  const url = `${msgrapgApi}/me/photo/$value`;
  params.isAbsoluteUrl = true;
  
  return await api.getFile(url, params);
};
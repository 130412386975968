import { observer } from 'mobx-react';
import React from 'react';
import { Grid, makeStyles, Theme, createStyles, FormControlLabel,
    Checkbox, InputLabel, Select, FormControl,
    MenuItem } from '@material-ui/core';
import { UserTeamDetails } from './UserTeamDetails';
import { UserConfigurationModel } from '../domain/UserConfiguration';
import { AddUserTeam } from './AddUserTeam';
import { DefaultPages } from '../domain/DefaultPages';

interface Props {
    user: UserConfigurationModel;
    teams: string[]
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    row: {
        marginLeft: theme.spacing(6)
    },
    label: {
        textTransform: 'capitalize'
    },
    formControl: {
        marginBottom: theme.spacing(1),
        marginTop: theme.spacing(1),
        width: 220
    }
}));

export const UserConfigurationDetails: React.FC<Props> = observer((props) => {
    const classes = useStyles();
    const { user, teams } = props;

    const inputLabel = React.useRef<HTMLLabelElement>(null);
    const [labelWidth, setLabelWidth] = React.useState(0);
    React.useEffect(() => {
      setLabelWidth(inputLabel.current!.offsetWidth);
    }, []);

    const handlePayrollAdminChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        user.setPayrollAdmin(event.target.checked);
    };
    
    const handleTakeFiveReaderChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        user.setTakeFiveReader(event.target.checked);
    };

    const handleEstimateEditorChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        user.setEstimateEditor(event.target.checked);
    };

    const handleSettingsAdminChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        user.setSettingsAdmin(event.target.checked);
    };

    const handleDefaultPageChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        user.setDefaultPage(event.target.value as string);
      };

    const defaultPages = [DefaultPages.Daily, DefaultPages.OutstandingApprovals,
    DefaultPages.PayEnquiry, DefaultPages.PayrollAdjustments, DefaultPages.UserSetup, DefaultPages.EstimatingTool];

    return (
        <Grid container direction="row">
            <Grid container item direction="row" xs={11} className={classes.row}>
                <Grid item xs={2}>
                    <FormControlLabel
                        control={
                        <Checkbox
                            checked={user.isSettingsAdmin}
                            onChange={handleSettingsAdminChange}
                            value="checkedSettingsAdmin"
                            color="primary"
                        />
                        }
                        label="Settings Admin"
                    />
                </Grid>
                <Grid item xs={2}>
                    <FormControlLabel
                        control={
                        <Checkbox
                            checked={user.isPayrollAdmin}
                            onChange={handlePayrollAdminChange}
                            value="checkedPayrollAdmin"
                            color="primary"
                        />
                        }
                        label="Payroll Admin"
                    />
                </Grid>
                <Grid item xs={2}>
                    <FormControlLabel
                        control={
                        <Checkbox
                            checked={user.isTakeFiveReader}
                            onChange={handleTakeFiveReaderChange}
                            value="checkedTakeFiveReader"
                            color="primary"
                        />
                        }
                        label="Take Five Reader"
                    />
                </Grid>
                <Grid item xs={2}>
                    <FormControlLabel
                        control={
                        <Checkbox
                            checked={user.isEstimateEditor}
                            onChange={handleEstimateEditorChange}
                            value="checkedEstimateEditor"
                            color="primary"
                        />
                        }
                        label="Estimate Editor"
                    />
                </Grid>
                <Grid item xs={2}>
                    <FormControl variant="outlined" className={classes.formControl}>
                        <InputLabel ref={inputLabel} id="default-page-select-outlined-label">
                            Default pages
                        </InputLabel>
                        <Select
                            labelId="default-page-select-outlined-label"
                            id="default-page-select-outlined"
                            value={user.defaultPage}
                            margin="dense"
                            onChange={handleDefaultPageChange}
                            labelWidth={labelWidth}
                        >
                            {defaultPages.map((p) =>
                                <MenuItem key={`defaulPage_u${user.userId}_${p}`} value={p}>{p}</MenuItem>)
                            }
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            <AddUserTeam user={props.user} teams={teams} />
            { props.user.assignedTeams.map((team, index) =>
                <UserTeamDetails
                    key={`user_${user.userId}_team_${index}`}
                    team={team}
                    onDelete={props.user.deleteTeam}
                />)
            }
        </Grid>
  );
});

import { observer } from 'mobx-react';
import React from 'react';
import { Grid, makeStyles, TextField, FormControlLabel, Checkbox } from '@material-ui/core';
import { HoursRow } from '../HoursRow';
import { DetailsHeader } from '../DetailsHeader';
import { ServiceCallDetailsPopup } from '../ServiceCallDetailsPopup';
import { TimeSheetTimePicker } from '../TimeSheetTimePicker';
import { TimeSheetLineModel } from 'timesheet-details/domain/timesheet-lines/TimeSheetLine';
import EnumSelector from 'components/EnumSelector';
import SingleSelect from 'components/SingleSelect';

interface Props {
    disabled?: boolean;
    timeSheetLineTypes: Map<string, string>,
    lostTimeTypes: string[];
    timeline: TimeSheetLineModel;
    disableType: boolean,
    date: Date,
    validate: (timeLine: TimeSheetLineModel) => any;
    getAllocTime: (timeLine: TimeSheetLineModel) => number;
    onDelete: (allocation: TimeSheetLineModel) => void;
}

export const TimesheetLineRow: React.FC<Props> = observer((props) => {
    const { timeline, validate, onDelete, getAllocTime, lostTimeTypes, disabled, timeSheetLineTypes, disableType, date } = props;
    const allocTime = getAllocTime(timeline);
    timeline.setAllocationTime(allocTime);
    
    const useStyles = makeStyles({
        root: {
            padding: '8px 0px 0px 0px'
        },
      });
    
    const classes = useStyles();
    
    const handleDelete = () => {
        onDelete(timeline);
    };

    const onCallOutChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        timeline.setCallOut(checked);
    };

    const handleServiceCallNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        
        const { value } = event.target;
        console.log('call number change');
        console.log(value, date);
        timeline.setServiceCallNumber(value, date);
    };

    const { invalidTotalGP, invalidTotalPayH, startHigherThanEndError, lostTimeReasonMissingError, serviceCallMissingError, startTimeError, endTimeError, gpHoursError, payHoursError } = timeline.validate();
    const { overlapError } = validate(timeline);

    const errors = [invalidTotalGP, invalidTotalPayH, startHigherThanEndError, overlapError, lostTimeReasonMissingError, serviceCallMissingError, startTimeError, endTimeError, gpHoursError, payHoursError]
        .filter((e) => !!e);
        
    return (
    <>
        <DetailsHeader
            canDelete={timeline.canDelete && !disabled}
            status={timeline.status}
            onDeleteClick={handleDelete}    
            errors={errors}
            hasErrors={errors.length > 0}
        />
        <Grid container direction="row" justify="space-between" alignItems="center" >
            <Grid item xs={6}>
                <Grid container spacing={1} direction="row">
                    <Grid item xs={7}>
                        <Grid container spacing={1} direction="row">
                            <Grid item xs={4}>
                                <EnumSelector
                                    label="Type"
                                    disabled={timeline.isReadOnly || disabled || disableType}
                                    value={timeline.type}
                                    options={timeSheetLineTypes}
                                    onChange={timeline.setType}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    fullWidth={true}
                                    disabled={!timeline.isServiceCallRequired || timeline.isReadOnly || disabled}
                                    label="Service Call"
                                    margin="dense"
                                    variant="outlined"
                                    error={serviceCallMissingError}
                                    value={timeline.serviceCallNumber}
                                    onChange={handleServiceCallNumberChange}
                                    inputProps={{ maxLength: 9 }}
                                />
                            </Grid>
                            <Grid item xs={5}>
                                <SingleSelect
                                    disabled={!timeline.isLostTypeReasonRequired || timeline.isReadOnly || disabled}
                                    label="Reason"
                                    values={lostTimeTypes}
                                    selectedValue={timeline.lostTimeReason}
                                    onChanged={timeline.setLostTimeReason}
                                    error={lostTimeReasonMissingError}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={5}>
                        <Grid container spacing={1} direction="row">
                            <Grid item xs={8}>
                                <Grid container spacing={1} direction="row">
                                    <Grid item xs={6}>
                                        <TimeSheetTimePicker
                                            label="Start"
                                            disabled={timeline.isReadOnly || disabled}
                                            dateTime={timeline.startDateTime}
                                            onChange={timeline.setStartTime}
                                            error={startHigherThanEndError || overlapError || startTimeError}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TimeSheetTimePicker
                                            label="End"
                                            disabled={timeline.isReadOnly || disabled}
                                            dateTime={timeline.endDateTime}
                                            onChange={timeline.setEndTime}
                                            error={startHigherThanEndError || overlapError || endTimeError}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={4}>
                                <FormControlLabel
                                    control={
                                    <Checkbox
                                        disabled={timeline.isReadOnly || disabled}
                                        checked={timeline.callOut}
                                        value="callOut"
                                        color="primary"
                                    />
                                    }
                                    label="Call Out"
                                    onChange={onCallOutChange}
                                    classes={classes}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={6}>
                <HoursRow
                    model={timeline.hours}
                    disabled={timeline.isReadOnly || disabled}
                    disableOvertimeFields={!timeline.isOvertimeAllowed}
                />
            </Grid>
        </Grid>
        <Grid container alignItems="center">
            <ServiceCallDetailsPopup
                details={timeline.serviceCallDetails}
                notes={timeline.notes}
            />
        </Grid>
    </>
  );
});

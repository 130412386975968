import React from 'react';
import { observer } from 'mobx-react';
import { Grid } from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

interface Props {
  fromDate: Date | null;
  toDate: Date | null;
  onChanged: (fromDate: Date | null, toDate: Date | null) => void;
  disabled?: boolean;
}

function DateRangePicker(props: Props) {
  const { fromDate, toDate, onChanged, disabled } = props;

  const handleFromChange = (from: Date) => {
    if (!from || !isNaN(from.getDay())) {
      onChanged(from, toDate);
    }
  };

  const handleToChange = (to: Date) => {
    if (!to || !isNaN(to.getDay())) {
      onChanged(fromDate, to);
    }
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Grid container={true} spacing={1}>
        <Grid item={true} xs={6}>
          <KeyboardDatePicker
            disabled={disabled}
            fullWidth={true}
            margin="dense"
            label="Start Date"
            value={fromDate}
            format="dd/MM/yyyy"
            maxDate={toDate}
            onChange={handleFromChange}
            inputVariant="outlined"
          />
        </Grid>
        <Grid item={true} xs={6}>
          <KeyboardDatePicker
            disabled={disabled}
            fullWidth={true}
            margin="dense"
            label="End Date"
            value={toDate}
            format="dd/MM/yyyy"
            minDate={fromDate}
            onChange={handleToChange}
            inputVariant="outlined"
          />
        </Grid>
      </Grid>
    </MuiPickersUtilsProvider>
  );
}

export default observer(DateRangePicker);

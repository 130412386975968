import { Button, TableCell, TableRow, Theme, createStyles, makeStyles } from '@material-ui/core';
import { blueGrey, lightBlue } from '@material-ui/core/colors';
import React from 'react';
import { NavLink } from 'react-router-dom';
import RemoveRedEyeOutlinedIcon from '@material-ui/icons/RemoveRedEyeOutlined';
import { RequestDetailsModel } from '../domain/RequestDetails';
import AssignmentIndOutlined from '@material-ui/icons/AssignmentIndOutlined';
import { IRequestStore } from '../store/RequestStore';
import { IAppStore } from '@shared/store';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      textDecoration: 'none'
    },
    label: {
      textTransform: 'none'
    },
    row: {
      '&:nth-child(even)': {
        backgroundColor: blueGrey[50]
      },
      '&:hover': {
        backgroundColor: lightBlue[100]
      }
    }
  }));

  interface Props {
    EstimateRequestDetails: RequestDetailsModel;
    RequestStore: IRequestStore;
    AppStore: IAppStore;
  }

export default function RequestsSummaryRow(props: Props) {
  const classes = useStyles();
  const store = props.RequestStore;
  const appStore = props.AppStore;
  const { EstimateRequestDetails } = props;

  const handleAssignClick = () => {
    store.assignOwner(EstimateRequestDetails.ID);
  };

  return (
    <>
      <TableRow className={classes.row}>
        <TableCell component="th" scope="row" align="left">
          {EstimateRequestDetails.Priority}
        </TableCell>
        <TableCell component="th" scope="row" align="left">
          {EstimateRequestDetails.DebtorID}
        </TableCell>
        <TableCell component="th" scope="row" align="left">
          {EstimateRequestDetails.CustomerName}
        </TableCell>
        <TableCell align="left">
          {EstimateRequestDetails.Location}
        </TableCell>
        <TableCell align="left">
          {EstimateRequestDetails.Branch}
        </TableCell>
        <TableCell align="left">
          {EstimateRequestDetails.EquipmentID}
        </TableCell>
        <TableCell align="left">
          {EstimateRequestDetails.Make}
        </TableCell>
        <TableCell align="left">
          {EstimateRequestDetails.Model}
        </TableCell>
        <TableCell align="left">
          {EstimateRequestDetails.JobDescription}
        </TableCell>
        <TableCell align="left">
          {EstimateRequestDetails.Status}
        </TableCell>
        <TableCell align="left">
          <NavLink  
          className={classes.button}
          to={`/requests/${EstimateRequestDetails.ID}`}>
            <Button
              className={classes.label}
              color="primary"
              startIcon={<RemoveRedEyeOutlinedIcon />}
            >
              View Details
            </Button>
          </NavLink>
        </TableCell>
        <TableCell align="left">

          <Button
            color="primary"
            className={classes.label}
            startIcon={<AssignmentIndOutlined />} 
            onClick={handleAssignClick}
            disabled={EstimateRequestDetails.Status === 'Draft' || !appStore.user?.isEstimateEditor === true}
          >
            Assign to me
          </Button>
        </TableCell>
      </TableRow>



    </>
  );
}
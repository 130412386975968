import { inject, observer } from "mobx-react";
import { IPayPeriodSubmissionStore } from "./store";
import React, { useEffect } from "react";
import { STORE_KEYS } from "@core";
import { Grid, LinearProgress, Theme, createStyles, makeStyles } from "@material-ui/core";
import { TechnicianTeamsTable } from "./component/TechnicianTeamsTable";

interface Props {
  }
  
type InjectedProps = {
  PayPeriodSubmissionStore: IPayPeriodSubmissionStore;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      height: '100%'
    },
    gridContainer: {
      height: 'calc(100% - 115px)',
      display: 'flex',
      flex: 1
    },
    filter: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      padding: theme.spacing(2)
    },
    progress: {
      width: '100%'
    }
  }));

const PayPeriodSubmissionComponent: React.FC<Props> = observer((props) => {
    const store = (props as InjectedProps).PayPeriodSubmissionStore;
    const classes = useStyles();

    useEffect(() => {
      store.fetchData();
    }, []);

    const integrate = (technicianTeam) => {
      store.onSubmit(technicianTeam);
    };

    return (
      <Grid container={true}
        direction="column"
        wrap="nowrap"
        className={classes.wrapper}
      >
        {store.isLoading && <LinearProgress className={classes.progress} />}
        <Grid item={true} className={classes.gridContainer}>
          {!store.isLoading && <TechnicianTeamsTable 
          teams={store.teams}
          integrate={integrate}/>}
        </Grid>
      </Grid>
    );
  });
  
  export const PayPeriodSubmission = inject(STORE_KEYS.PayPeriodSubmissionStore)(PayPeriodSubmissionComponent);
  
import { observer } from 'mobx-react';
import React, { useState } from 'react';
import clsx from 'clsx';
import { Grid, IconButton, Typography, Button, makeStyles, Theme, createStyles,
    ExpansionPanelDetails, ExpansionPanel, ExpansionPanelSummary, CircularProgress } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import CloudDownloadOutlinedIcon from '@material-ui/icons/CloudDownloadOutlined';
import ArchiveOutlinedIcon from '@material-ui/icons/ArchiveOutlined';
import { UserConfigurationModel } from '../domain/UserConfiguration';
import { UserConfigurationDetails } from './UserConfigurationDetails';
import { blueGrey, yellow } from '@material-ui/core/colors';
import ConfirmationDialog from 'components/ConfirmationDialog';

interface Props {
    index: number;
    teams: string[];
    user: UserConfigurationModel;
    hasChanges: boolean;
    onArchiveUser: (user: UserConfigurationModel) => void;
    onSaveUser: (user: UserConfigurationModel) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    label: {
        textTransform: 'capitalize'
    },
    highlight: {
        backgroundColor: '#f2f5fa'
    },
    root: {
        margin: '0 !important'
    },
    summary: {
        '& div:first-child': {
            margin: '0 !important'
        },
        'minHeight': 'auto !important',
        'padding': '0 !important'
    },
    details: {
        padding: '0 !important'
    },
    disabled: {
        'pointer-events': 'none',
        backgroundColor: blueGrey[50]
    },
    userEmailLabel: {
        paddingLeft: theme.spacing(1)
    },
    teamsLabel: {
        paddingLeft: theme.spacing(1)
    },
    changes: {
        'border-style': 'solid',
        'border-color': yellow[600]
    },
    truncate: {
        'width': '100%',
        'text-overflow': 'ellipsis',
        'overflow': 'hidden'
    }
}));

export const UserConfigurationListItem: React.FC<Props> = observer((props) => {
  const classes = useStyles();
  const { user, teams, hasChanges } = props;

  const [isOpen, setIsOpen] = useState(false);
  const [confirmationOpen, setConfirmationOpen] = useState(false);

  const handleExpandCollapseClick = () => {
    setIsOpen(!isOpen);
  };

  const handleConfirmationClose = (confirmed: boolean) => {
    setConfirmationOpen(false);
    if (confirmed) {
        props.onArchiveUser(user);
    }
   };

  const handleArchiveClick = () => {
    setConfirmationOpen(true);
  };

  const handleSaveClick = () => {
    props.onSaveUser(user);
  };

  return (
    <ExpansionPanel square expanded={isOpen} className={clsx(classes.root, hasChanges && classes.changes)}>
        <ExpansionPanelSummary className={clsx({
            [classes.highlight]: props.index % 2 === 1
            }, classes.summary)}
        >
            <Grid container item xs={10}>
                <Grid container direction="row" alignItems="center" item xs={3}>
                    <IconButton
                        onClick={handleExpandCollapseClick}
                        aria-expanded={isOpen}
                    >
                        {isOpen ? <ExpandLessIcon/> : <ExpandMoreIcon />}
                    </IconButton>
                    <Typography variant="subtitle2" gutterBottom>
                        {user.name}
                    </Typography>
                </Grid>
                <Grid className={classes.userEmailLabel}
                    item xs={3} container alignItems="center" >
                    <Typography variant="subtitle2" className={classes.truncate} gutterBottom>
                        {user.userId}
                    </Typography>
                </Grid>
                <Grid className={classes.teamsLabel} item xs={2} container alignItems="center">
                    <Typography variant="subtitle2" gutterBottom>
                        {user.teamsCount}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container
                item
                xs={2}
                justify="flex-end"
                alignItems="center"
            >
                <Grid item xs={6}>
                    <Button
                        color="primary"
                        disabled={!hasChanges}
                        className={classes.label}
                        startIcon={user.isSaving ? <CircularProgress size={14} /> : <CloudDownloadOutlinedIcon />}
                        onClick={handleSaveClick}
                    >
                        Save
                    </Button>
                </Grid>
                <Grid item xs={6}>
                    <Button
                        color="primary"
                        className={classes.label}
                        startIcon={<ArchiveOutlinedIcon />}
                        onClick={handleArchiveClick}
                    >
                        Archive
                    </Button>
                </Grid>
            </Grid>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className={clsx(user.isSaving && classes.disabled, classes.details)}>
            <UserConfigurationDetails user={user} teams={teams} />
        </ExpansionPanelDetails>
        <ConfirmationDialog
                open={confirmationOpen}
                onClose={handleConfirmationClose}
                title="Are you sure you want to archive this user?"
        />
    </ExpansionPanel>
  );
});

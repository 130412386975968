import { inject, observer } from 'mobx-react';
import React, {useEffect} from 'react';
import { IBranchStore } from './store/BranchConfigStore';
import { EstimateBranches } from './Branches';
import { STORE_KEYS } from '@core';


interface Props {
  BranchStore: IBranchStore;
}

const BranchComponent: React.FC<Props> = observer((props) => {
  const store = props.BranchStore;
  const { branches, hasChanges, save, delete: handleDelete } = store;

  useEffect(() => {
    store.fetchData();
    return function cleanup() {
    };
  }, []);

  return (
    <EstimateBranches EstimateBranchModel={branches} 
    branches={branches.branches} 
    hasChanges={hasChanges(branches.branches)}
    onSave={save}
    onDelete={handleDelete}
    />
  );
});

export const BranchConfig = inject(STORE_KEYS.BranchConfigStore)(BranchComponent);

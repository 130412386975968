import { types, Instance } from 'mobx-state-tree';
import { TakeFiveLineItemAnswer } from './TakeFiveLineItemAnswers';

export const TakeFiveLineItem = types 
.model({
    id: types.optional(types.string, ''),
    date:  types.optional(types.string, ''),
    time:  types.optional(types.string, ''),
    customer:  types.optional(types.string, ''),
    address: types.optional(types.string, ''),
    serviceCallID: types.optional(types.string, ''),
    equipmentID: types.optional(types.string, ''),
    manufacturer: types.optional(types.string, ''),
    model: types.optional(types.string, ''),
    answers: types.array(TakeFiveLineItemAnswer)
})

export type TakeFiveLineItemModel = Instance<typeof TakeFiveLineItem>;

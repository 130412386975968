import { makeStyles, createStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
createStyles({
  container: {
    display: 'flex',
    padding: '5px',
   },
   icon: {
    display: 'inherit',
    marginLeft: '-4px',
    marginRight: '8px'
   }
}));

import { Instance, types } from 'mobx-state-tree';

export const UpdateLogModel = types
  .model({
    id: types.optional(types.string, ''),
    gpId: types.optional(types.string, ''),
    technician: types.optional(types.string, ''),
    status: types.optional(types.number, 0),
    statusUpdateLocationLatitude: types.maybeNull(types.number),
    statusUpdateLocationLongitude: types.maybeNull(types.number),
    statusUpdateTime: types.maybeNull(types.string),
    serviceAppointmentId: types.optional(types.string, ''),
  });

export type IUpdateLogModel = Instance<typeof UpdateLogModel>;

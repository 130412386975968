import { types, Instance } from 'mobx-state-tree';
import { PayrollAdjustmentEnquiry } from './PayrollAdjustmentEnquiry';
import { isSameDay, parseISO, isAfter, isBefore } from 'date-fns';

export const PayrollEnquiryGroup = types
  .model({
    team: types.optional(types.string, ''),
    enquiries: types.optional(types.array(PayrollAdjustmentEnquiry), []),
    fromDate: types.optional(types.Date, 0),
    toDate: types.optional(types.Date, 0),
    processed: types.optional(types.boolean, false),
    allSelected: types.optional(types.boolean, false),
  })
  .views((self) => ({
    get filteredEnquiries() {
      if (!self.processed) {
        return self.enquiries.filter(item => !item.processed);
      }

      const processedEnquiries = self.enquiries.filter(item => item.processed);

      return processedEnquiries.filter((eq) => eq.processDate && (isSameDay(self.fromDate, parseISO(eq.processDate)) ||
        isAfter(parseISO(eq.processDate), self.fromDate) && isBefore(parseISO(eq.processDate), self.toDate)));
    },
    get amountOfSelectedItems() {
      return self.enquiries.filter(item => item.selected).length;
    }
  }))
  .actions((self) => {
    return {
      setDateRange(from: Date, to: Date) {
        self.fromDate = from;
        self.toDate = to;
      },
      toggleChecked(value: boolean) {
        self.processed = value;
      },
      selectAllItems() {
        self.allSelected = !self.allSelected;
        self.filteredEnquiries.forEach(item => item.selected = self.allSelected);
      }
    };
  });

export type IPayrollEnquiryGroup = Instance<typeof PayrollEnquiryGroup>;

import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { TextField, Button, makeStyles, createStyles, Theme, Checkbox, Tooltip, Typography } from '@material-ui/core';
import { EstimateBranchModel } from './domain/Branch';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import ConfirmationDialog from 'components/ConfirmationDialog';

interface Props {
  branch: EstimateBranchModel
  onDeleteClick?: (branch: EstimateBranchModel) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    branchesWrapper: {
      display: 'flex',
      gap: '2rem'
    },
    label: {
      textTransform: 'capitalize'
    }
  }));

export const EstimateBranch: React.FC<Props> = observer((props) => {

  const { branch, onDeleteClick } = props;
  const classes = useStyles();
  const [confirmationOpen, setConfirmationOpen] = useState(false);


  const handleDeleteClick = () => {
    setConfirmationOpen(true);
  };

  const handleConfirmationClose = (confirmed: boolean) => {
    setConfirmationOpen(false);
    if (onDeleteClick && confirmed) {
      onDeleteClick(branch);
    }
  };

  const handleBranchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    branch.setBranch(event.target.value);
  };
  const handleOfficeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    branch.setOffice(event.target.value);
  };
  const handleSetViewAllChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    branch.setViewAll(event.target.checked);
  };
  const handleThresholdChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    branch.setThreshold(Number(event.target.value));
  };
  const handleRetailSendToChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    branch.setRetailSendTo(event.target.value);
  };
  const handleRentalAccessSendToChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    branch.setRentalAccessSendTo(event.target.value);
  };
  const handleRentalForkliftSendToChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    branch.setRentalForkliftSendTo(event.target.value);
  };
  const handleSentFromChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    branch.setSentFrom(event.target.value);
  };

  return (
    <div className={classes.branchesWrapper}>
      <TextField
        fullWidth={true}
        label="Branch"
        margin="dense"
        variant="outlined"
        value={branch.branch}
        onChange={handleBranchChange}
      />
      <TextField
        fullWidth={true}
        label="Office Location"
        margin="dense"
        variant="outlined"
        value={branch.officeLocation}
        onChange={handleOfficeChange}
      />
      <TextField
        fullWidth={true}
        label="Threshold [$]"
        margin="dense"
        variant="outlined"
        value={branch.threshold}
        onChange={handleThresholdChange}
      />
      <TextField
        fullWidth={true}
        label="Sent From"
        margin="dense"
        variant="outlined"
        value={branch.sentFrom}
        onChange={handleSentFromChange}
      />
      <TextField
        fullWidth={true}
        label="Retail Send To"
        margin="dense"
        variant="outlined"
        value={branch.retailSendTo}
        onChange={handleRetailSendToChange}
      />
      <TextField
        fullWidth={true}
        label="Rental Access Send To"
        margin="dense"
        variant="outlined"
        value={branch.rentalAccessSendTo}
        onChange={handleRentalAccessSendToChange}
      />     
      <TextField
        fullWidth={true}
        label="Rental Forklift Send To"
        margin="dense"
        variant="outlined"
        value={branch.rentalForkliftSendTo}
        onChange={handleRentalForkliftSendToChange}
      />
      <Tooltip title={<Typography variant="body1">See Requests For All Branches</Typography>}>
      <Checkbox     
        name="checkbox"
        checked={branch.viewAll}
        onChange={handleSetViewAllChange}
        color="primary"
      />
  </Tooltip>
      <Button
        color="primary"
        className={classes.label}
        startIcon={<DeleteOutlineIcon />}
        onClick={handleDeleteClick}
      >
        Delete
      </Button>
      <ConfirmationDialog
        open={confirmationOpen}
        onClose={handleConfirmationClose}
        declineButtonTitle="No"
        confirmButtonTitle="Yes"
        title="Are you sure you want to delete this item?"
      />
    </div>
  );
});
import React from 'react';
import { observer } from "mobx-react";
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Paper, Table, TableBody } from '@material-ui/core';
import SortableTableHead, { HeadCell } from 'components/sortable-table/SortableTableHead';
import { SortOrder, sort } from '@utils';
import RequestsSummaryRow from './RequestsSummaryRow';
import { RequestDetailsModel } from '../domain/RequestDetails';
import { IRequestStore } from '../store/RequestStore';
import { IAppStore } from '@shared/store';



const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      overflow: 'auto',
      width: '100%',
      flex: 1
    },
    saveBtn: {
      textAlign: 'right'
    },
    label: {
      textTransform: 'capitalize'
    }
  }));

interface Props {
  requestsStore: IRequestStore;
  appStore: IAppStore;
}

const headCells: Array<HeadCell<RequestDetailsModel>> = [
  { id: 'Priority', label: 'Priority' },
  { id: 'DebtorID', label: 'Debtor Id' },
  { id: 'CustomerName', label: 'Customer' },
  { id: 'Location', label: 'Location' },
  { id: 'Branch', label: 'Branch' },
  { id: 'EquipmentID', label: 'Equipment Id' },
  { id: 'Make', label: 'Make' },
  { id: 'Model', label: 'Model' },
  { id: 'JobDescription', label: 'Description' },
  { id: 'Status', label: 'Status' },
  { id: 'Details', label: 'Details' },
  { id: 'Actions', label: 'Actions' }

];

export const RequestsSummaryTable: React.FC<Props> = observer((props) => {
  const classes = useStyles();
  const { requestsStore, appStore } = props;
  const [order, setOrder] = React.useState<SortOrder>('asc');
  const [orderBy, setOrderBy] = React.useState<keyof RequestDetailsModel>('CustomerName');

  const handleRequestSort = (newSortOrder: SortOrder, newOrderBy: keyof RequestDetailsModel) => {
    setOrder(newSortOrder);
    setOrderBy(newOrderBy);
  };

  return (
    <Paper className={classes.root}>
      <Table stickyHeader={true} size="small">
        <SortableTableHead<RequestDetailsModel>
          headCells={headCells}
          order={order}
          orderBy={orderBy}
          onSort={handleRequestSort}
        />
        <TableBody>
          {sort<RequestDetailsModel>(requestsStore.requestsSummary, order, orderBy).map((x, index) => ( 
          <RequestsSummaryRow 
              key={index}
              EstimateRequestDetails={x}
              RequestStore={props.requestsStore} 
              AppStore={appStore}         />
          ))}
        </TableBody>
      </Table>
    </Paper>
  );
});

import { Instance, types } from 'mobx-state-tree';
import { TakeFiveDetailsModel } from './TakeFiveDetailsModel';

export const TakeFiveModel = types
  .model({
    date: types.optional(types.string, ''),
    time: types.optional(types.string, ''),
    answers: types.optional(types.array(TakeFiveDetailsModel), []),
  });

export type ITakeFiveModel = Instance<typeof TakeFiveModel>;

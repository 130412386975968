import { inject, observer } from 'mobx-react';
import React, {useEffect} from 'react';
import { IAreaStore } from './store/AreaConfigStore';
import { EstimateAreas } from './Areas';
import { STORE_KEYS } from '@core';


interface Props {
  AreaConfigStore: IAreaStore;
}

const AreaComponent: React.FC<Props> = observer((props) => {
  const store = props.AreaConfigStore;
  const { areas, hasChanges, save, delete: handleDelete } = store;

  useEffect(() => {
    store.fetchData();
    return function cleanup() {
    };
  }, []);

  return (
    <EstimateAreas EstimateAreaModel={areas} 
    areas={areas.areas} 
    hasChanges={hasChanges(areas.areas)}
    onSave={save}
    onDelete={handleDelete}
    />
  );
});

export const AreaConfig = inject(STORE_KEYS.AreaConfigStore)(AreaComponent);

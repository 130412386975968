import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import LocalFindForm from './LocalFindForm';
import { LocalFindModel } from '../domain/LocalFind';
import { CircularProgress } from '@material-ui/core';

interface Props {
    title: string;
    branches: string[];
    status: string;
    open: boolean;
    validateLocalFind: (localFind: LocalFindModel) => any;
    onClose: () => void;
    onOk: (localFind: LocalFindModel) => any;
}

export const LocalFindAddDialog: React.FC<Props> = observer((props) => {
  const { open, onClose, onOk, title, status, branches, validateLocalFind } = props;
  const [localFind, setLocalFind] = useState({} as LocalFindModel);

  useEffect(() => {
    if (status === 'None') {
      setLocalFind({} as LocalFindModel);
    }
  }, [status] );

  const handleCancelClick = () => {
    setLocalFind({} as LocalFindModel);
    onClose();
  };

  const handleOkClick = () => {
    onOk(localFind);
  };

  const handleLocalFindChange = (newLocalFind: LocalFindModel) => {
    setLocalFind(newLocalFind);
  };

  const { branchName: name, displayName } = localFind;
  const validationResult = validateLocalFind(localFind);
  const isValid = !!name && !!displayName && !validationResult.duplicateError;

  return (
      <Dialog
        open={open}
        keepMounted={false}
        onClose={handleCancelClick}
      >
        <DialogTitle>
            {title}
        </DialogTitle>
        <DialogContent dividers>
          <LocalFindForm
            disabled={status === 'Saving'}
            duplicateError={validationResult.duplicateError}
            onChange={handleLocalFindChange}
            branches={branches}
            localFind={localFind}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCancelClick}
            color="primary"
            disabled={status === 'Saving'}
          >
            Cancel
          </Button>
          <Button
            onClick={handleOkClick}
            disabled={!isValid}
            color="primary"
          >
            { status === 'Saving' ? <CircularProgress size={14} /> : 'Ok' }
          </Button>
        </DialogActions>
      </Dialog>
  );
});

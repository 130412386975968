import { pluralize, DATE_FORMAT} from '@utils';
import { types, Instance } from 'mobx-state-tree';
import { AdjustmentType } from './AdjustmentType';
import { TimeSheetStatus } from 'components/TimeSheetStatusViewer';
import { PayrollAdjustment, PayrollAdjustmentModel } from './PayrollAdjustment';
import { getTotalHours } from '../TimeSheetHours';
import { format } from 'date-fns';
import { LeaveType } from './LeaveType';

interface AllowanceTotals {
  mealAllowance: number;
  phOnCall: number;
  onCall: number;
}

export const PayrollAdjustments = types.model({
  adjustments: types.optional(types.array(PayrollAdjustment), [])
})
  .actions((self) => {
    const tuneDateForTimeLine = (date: Date, hours: number) => {
      date.setHours(hours);
      date.setMinutes(0);

      return format(date, DATE_FORMAT);
    };

    return {
      addAdjustment: (date: Date) => {
        const adj = {
          status: TimeSheetStatus.New,
          type: AdjustmentType.Time,
          leaveType: LeaveType.Empty,
          startTime: tuneDateForTimeLine(date, 8),
          endTime: tuneDateForTimeLine(date, 16),
          hours: {},
          serviceCallDetails: {}
        };
        self.adjustments.push(adj);
        self.adjustments[self.adjustments.length - 1].recalculateTotals();
      },
      deleteAdjustment: (adj: PayrollAdjustmentModel) => {
        self.adjustments.remove(adj);
      },
    };
  })
  .views((self) => {
    const isValidAdjustmentReason = () => {
      return self.adjustments.length === 0 || self.adjustments.every((t) => {
        return t.isAdjustmentReasonValid
      });
    };

    const isValidTimeRange = () => {
      return self.adjustments.length === 0 || self.adjustments.every((t) => {
        return t.isValidTimeRange
      });
    };

    const isValidLeaveType = () => {
      return self.adjustments.length === 0 || self.adjustments.every((t) => {
        return t.isValidLeaveType
      });
    };

    const hasChangesInternal = (originalAdjustment) => {
      const adjustment = self.adjustments.find((l) => l.id === originalAdjustment.id);
      if (!adjustment) {
        return true;
      }

      const isEqual = originalAdjustment.equals(adjustment);
      return !isEqual;
    };

    const isValid = (hasMealAllowance: boolean, hasPhOnCallAllowance: boolean, hasOnCall: boolean) => {

      const totals = self.adjustments.reduce<AllowanceTotals>( (accumulator, adj) => {
        switch (adj.type) {
          case AdjustmentType.MealAllowance:
            accumulator.mealAllowance += adj.amount;
            break;
          case AdjustmentType.OnCallAllowance:
            accumulator.onCall += adj.amount;
            break;
          case AdjustmentType.PHOnCallAllowance:
            accumulator.phOnCall += adj.amount;
        }
        return accumulator;
      },
      { mealAllowance: hasMealAllowance ? 1 : 0, phOnCall: hasPhOnCallAllowance ? 1 : 0, onCall: hasOnCall ? 1 : 0 });

      return totals.mealAllowance >= 0 && totals.mealAllowance <= 1 &&
        totals.phOnCall >= 0 && totals.phOnCall <= 1 &&
        totals.onCall >= 0 && totals.onCall <= 1;
    };

    return {
      get title() {
        const adjustmentCount = self.adjustments.length;
        return `Payroll Adjustments ( ${adjustmentCount} ${pluralize('event', 's', adjustmentCount)})`;
      },
      get totalHours() {
        return getTotalHours(self.adjustments.map((t) => t.hours));
      },
      hasChanges(originalData) {
        return originalData.adjustments.length !== self.adjustments.length
          || originalData.adjustments.some(hasChangesInternal);
      },
      isValid,
      isValidAdjustmentReason,
      isValidTimeRange,
      isValidLeaveType,
      validate(hasMealAllowance: boolean, hasPhOnCallAllowance: boolean, hasOnCall: boolean) {
        return {
          totalsValidationResult: !isValid(hasMealAllowance, hasPhOnCallAllowance, hasOnCall)
            ? 'Payroll adjustment of the same Allowance cannot be used twice.'
            : ''
        };
      },
      validateReason() {
        return {
          adjustmentReasonValidation: !isValidAdjustmentReason()
            ? 'Some payroll adjustment lines are missing a reason.'
            : ''
        };
      }
    };
  });

export type PayrollAdjustmentsModel = Instance<typeof PayrollAdjustments>;

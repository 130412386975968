import { types, Instance } from 'mobx-state-tree';
import { AdjustmentType } from './AdjustmentType';
import { TimeSheetHours } from '../TimeSheetHours';
import { ServiceCallDetails, setServiceCall } from '../ServiceCallDetails';
import { TimeSheetStatus } from 'components/TimeSheetStatusViewer';

export const GpAdjustment = types.model({
  id: types.optional(types.string, ''),
  status: types.enumeration('TimeSheetStatus', [
    TimeSheetStatus.Approved,
    TimeSheetStatus.New,
    TimeSheetStatus.Processed,
    TimeSheetStatus.Rejected,
    TimeSheetStatus.Submitted,
    TimeSheetStatus.Integrated,
    TimeSheetStatus.NoData,
    TimeSheetStatus.Draft,
    TimeSheetStatus.Final
  ]),
  tempStatus: types.enumeration('TimeSheetStatus', [
                TimeSheetStatus.Draft,
                TimeSheetStatus.Final
              ]),
  type: types.enumeration('AdjustmentType', [
    AdjustmentType.Travel,
    AdjustmentType.ServiceCall
  ]),
  reason: types.optional(types.string, ''),
  hours: TimeSheetHours,
  serviceCallId: types.optional(types.string, ''),
  serviceCallNumber: types.optional(types.string, ''),
  serviceCallDetails: types.maybeNull(ServiceCallDetails),
  serviceCallError: types.optional(types.string, ''),
  notes: types.optional(types.string, ''),
})
  .actions((self) => {
    const recalculateTotals = () => {
      const totalGPHours = self.hours.normalGP + self.hours.overtimeGP +
                           self.hours.doubleTimeGP + self.hours.overtimeDoubleTimeGP;
  
      self.hours.setTotalGP(totalGPHours);
    };

    return {
      setType: (value: AdjustmentType) => {
        self.type = value;
      },
      setReason: (value: string) => {
        self.reason = value;
      },
      setNormalGP: (hours: number) => {
        self.hours.setNormalGP(hours);
        recalculateTotals();
      },
      setOvertimeGP: (hours: number) => {
        self.hours.setOvertimeGP(hours);
        recalculateTotals();
      },
      setDoubleTimeGP: (hours: number) => {
        self.hours.setDoubleTimeGP(hours);
        recalculateTotals();
      },
      setOvertimeDoubleTimeGP: (hours: number) => {
        self.hours.setOvertimeDoubleTimeGP(hours);
        recalculateTotals();
      },
      setServiceCallNumber: (serviceCallId, date) => {
        setServiceCall(self, serviceCallId, date, 2);
      }
    };
  })
  .views((self) => {

    const isValidServiceCall = () => {
      return  self.serviceCallNumber && !self.serviceCallError && self.serviceCallNumber !== ""
    }
    
    return {
      get isValid() {
        return isValidServiceCall();
      },
      get isServiceCallValid() {
        return isValidServiceCall();
      },
      get isReadOnly() {
        return self.status === TimeSheetStatus.Submitted || self.status === TimeSheetStatus.Approved ||
        self.status === TimeSheetStatus.Integrated;
      },
      get canDelete() {
        return self.status === TimeSheetStatus.New || self.status === TimeSheetStatus.Rejected;
      },
      equals(adjustment) {
        return self.id === adjustment.id
        && self.status === adjustment.status
        && self.type === adjustment.type
        && self.serviceCallNumber === adjustment.serviceCallNumber
        && self.reason === adjustment.reason
        && self.hours.equals(adjustment.hours)
        && self.notes === adjustment.notes;
      },
      validate() {
        return {
          serviceCallMissingError: !isValidServiceCall() ? 'Valid service call must be entered' : ''
        } 
      }
    };
  });

  export type GpAdjustmentModel = Instance<typeof GpAdjustment>;

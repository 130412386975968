import { Instance, types, getEnv, flow, applySnapshot } from 'mobx-state-tree';
import { isEmpty } from 'lodash';
import { IStoresEnv, INotificationEnv } from '@core';
import { ApiResponse } from 'api';
import { getCallDetails } from 'call-details/endpoints';
import { ServiceCallDetailModel } from 'call-details/models/ServiceCallDetailModel';

export const CallDetailsStore = types
  .model({
    serviceCallNumber: types.optional(types.string, ''),
    appointmentNumber: types.optional(types.string, ''),
    callDetails: types.optional(ServiceCallDetailModel, {}),
    isLoading: types.optional(types.boolean, false),
    satelliteView: types.optional(types.boolean, false),
  })
  .views((self) => ({
    get isDataFilled() {
      return !isEmpty(self.appointmentNumber) && !isEmpty(self.serviceCallNumber)
    },
  }))
  .actions(self => {
    const { api } = getEnv<IStoresEnv>(self);
    const { snackMessenger
    } = getEnv<INotificationEnv>(self);

    const resetStore = () => {
      applySnapshot(self.callDetails, {});
    };

    return ({
      resetStore,
      loadCallDetails: flow(function* () {
        const serviceCallNumber = self.serviceCallNumber;
        const appointmentNumber = self.appointmentNumber;
        self.isLoading = true;
        const response: ApiResponse<any> =
          yield getCallDetails(
            api,
            {
              payload: { serviceCallNumber, appointmentNumber },
              errorHandler: 'Failed to load call details.',
            },
          );
        if (response.status === 204) {
          self.isLoading = false;
          resetStore();
        }
        console.log(response.data)
        if (response.success && response.data) {
          self.isLoading = false;
          applySnapshot(self.callDetails, response.data);
        }

        console.log(self.callDetails)
        return response.success;
      }),
      setCallDetails(value: string) {
        self.serviceCallNumber = value;
      },
      setSatelliteView(value: boolean) {
        self.satelliteView = value;
      },
      setAppointmentNumber(value: string) {
        self.appointmentNumber = value;
      },
      addMessage(message: string) {
        snackMessenger.addSnackMessage({ message });
      }
    });
  });

export type ICallDetailsStore = Instance<typeof CallDetailsStore>;

import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import React from 'react';
import { IPayEnquiry } from '../domain/PayEnquiry';
import { formatDate } from '@utils';
import { Checkbox } from '@material-ui/core';
import { parseISO } from 'date-fns';
import { observer } from 'mobx-react';
import { useStyles } from './row.style';

interface Props {
  enquiry: IPayEnquiry;
  technician: string;
  technicianId: string;
  onSelect: AnyFunction;
  selected: boolean;
  index: number;
  beforeV2: boolean
}

export const EnquiryRowItem: React.FC<Props> = observer((props) => {
  const classes = useStyles();
  const { enquiry, index, onSelect, selected, technician, beforeV2 } = props;

  return (
    <TableRow
      onClick={() => onSelect()}
      className={classes.row}
    >
      <TableCell
        padding="checkbox"
        align="right"
        className={classes.cell}
        size='small'
      >
        {index == 0 &&
          <Checkbox
            checked={selected}
            color="primary"
            inputProps={{ 'aria-labelledby': 'labelId' }}
          />
        }

      </TableCell>
      <TableCell
        align="left"
        className={classes.cell}
        size='medium'
      >
        {index == 0 && technician}
      </TableCell>
      <TableCell className={classes.cell} align="left" size="medium"> {enquiry.processedDate && formatDate(parseISO(enquiry.processedDate))} </TableCell>
      <TableCell className={classes.cell} align="left"> {enquiry.norm} </TableCell>
      {beforeV2 && <TableCell className={classes.cell} align="left"> {enquiry.th} </TableCell> }
      {beforeV2 && <TableCell className={classes.cell} align="left"> {enquiry.doub} </TableCell>}
      {beforeV2 && <TableCell className={classes.cell} align="left"> {enquiry.dh} </TableCell>}
      <TableCell className={classes.cell} align="left"> {enquiry.sl} </TableCell>
      <TableCell className={classes.cell} align="left"> {enquiry.al} </TableCell>
      <TableCell className={classes.cell} align="left"> {enquiry.lsl} </TableCell>
      <TableCell className={classes.cell} align="left"> {enquiry.publicHolidays} </TableCell>
      <TableCell className={classes.cell} align="left"> {enquiry.carersLeave} </TableCell>
      <TableCell className={classes.cell} align="left"> {enquiry.leaveWoPay} </TableCell>
      <TableCell className={classes.cell} align="left"> {enquiry.workersComp} </TableCell>
      <TableCell className={classes.cell} align="left"> {enquiry.mealAllowance} </TableCell>
      <TableCell className={classes.cell} align="left"> {enquiry.callOutAllowance} </TableCell>
      <TableCell className={classes.cell} align="left"> {enquiry.phCallOutAllowance} </TableCell>
      <TableCell className={classes.cell} align="left"> {enquiry.fdo} </TableCell>
      <TableCell className={classes.cell} align="left"> {enquiry.mdo} </TableCell>
      <TableCell className={classes.cell} align="left"> {enquiry.anniversaryLeave} </TableCell>
      {!beforeV2 && <TableCell className={classes.cell} align="left"> {enquiry.extendedLWOP} </TableCell> }
      {!beforeV2 && <TableCell className={classes.cell} align="left"> {enquiry.parentalLeave} </TableCell> }
      {!beforeV2 && <TableCell className={classes.cell} align="left"> {enquiry.juryDuty} </TableCell> }
      {!beforeV2 && <TableCell className={classes.cell} align="left"> {enquiry.armyReserves} </TableCell> }
      {!beforeV2 && <TableCell className={classes.cell} align="left"> {enquiry.compassionateLeave} </TableCell> }
      <TableCell className={classes.cell} align="left"> {enquiry.totalAmount} </TableCell> 
    </TableRow>
  );
});

import { makeStyles, createStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
createStyles({
  wrapper: {
    height: '100%'
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '20px 0'
  },
  gridContainer: {
    height: '100%',
    flex: 1
  },
  filter: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2)
  },
  action: {
    backgroundColor: 'white',
  },
  progress: {
    width: '100%'
  },
  icon: {
    marginRight: '10px'
  }
}));
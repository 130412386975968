import { observer } from 'mobx-react';
import React from 'react';
import { Grid, Typography, Divider, makeStyles, Theme, createStyles } from '@material-ui/core';
import { TakeFiveLineItemAnswerModel } from 'take-five-details/domain/TakeFiveLineItemAnswers';

interface Props {
    answer: TakeFiveLineItemAnswerModel
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    answersContainer: {
        marginLeft: '40px',
        padding: '8px'
    },
}));

export const  TakeFiveLineItemAnswers: React.FC<Props> = observer((props) => {
  const { answer } = props;
  const classes = useStyles();

  return (
    <Grid direction="column" container spacing={0} >
        <Grid container direction="row" item xs={12}  className={classes.answersContainer}>
        <Divider/>
        <Grid item xs={2}>
                    <Typography variant="subtitle2" gutterBottom>
                        {answer.answer}
                    </Typography>
            </Grid>
            <Grid item xs={6}>
                <Typography variant="subtitle2" gutterBottom>
                    {answer.question}
                </Typography>
            </Grid>
        </Grid>
        <Divider/>
    </Grid>
  );
});

import { inject, observer } from 'mobx-react';
import React, {useEffect} from 'react';
import { IStatusStore } from './store/StatusConfigStore';
import { EstimateStatuses } from './Statuses';
import { STORE_KEYS } from '@core';


interface Props {
  StatusStore: IStatusStore;
}

const StatusComponent: React.FC<Props> = observer((props) => {
  const store = props.StatusStore;
  const { statuses, hasChanges, save, delete: handleDelete } = store;

  useEffect(() => {
    store.fetchData();
    return function cleanup() {
    };
  }, []);

  return (
    <EstimateStatuses EstimateStatusModel={statuses} 
    statuses={statuses.statuses} 
    hasChanges={hasChanges(statuses.statuses)}
    onSave={save}
    onDelete={handleDelete}
    />
  );
});

export const StatusConfig = inject(STORE_KEYS.StatusConfigStore)(StatusComponent);

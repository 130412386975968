import { observer } from 'mobx-react';
import React from 'react';
import { Tabs, Tab } from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core';
import { RequestsSummary }  from './requests/RequestsSummary';
import { EstimatesList } from './estimate/EstimatesList';
import { CommonJobsSummary }  from './common-jobs/CommonJobs';
import { useParams, NavLink } from 'react-router-dom';

enum SetupTabs {
  req = 0,
  estimates = 1,
  commonJobs = 2
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    label: {
        textTransform: 'capitalize'
    },
    link: {
      textDecoration: 'none',
      color: theme.palette.primary.main
    },
}));

interface PathParams {
  tab: string;
}

export const EstimatingTool: React.FC<{}> = observer((props) => {
  const params = useParams<PathParams>();
  const classes = useStyles();

let selectedTab;
switch (params.tab) {
  case 'commonJobs':
    selectedTab = SetupTabs.commonJobs;
    break;
  case 'estimates':
    selectedTab = SetupTabs.estimates;
    break;
  default:
    selectedTab = SetupTabs.req;
}

  return (
    <React.Fragment>
        <Tabs
          value={selectedTab}
          indicatorColor="primary"
        >
          <NavLink to={params.tab ? `req` : `estimatingTool/req`} color="primary" className={classes.link}>
            <Tab label="Requests" className={classes.label} />
          </NavLink>
          <NavLink to={params.tab ? `estimates` : `estimatingTool/estimates`} color="primary" className={classes.link}>
            <Tab label="Estimates" className={classes.label} />
          </NavLink>
          {/* <NavLink to={params.tab ? `commonJobs` : `estimatingTool/commonJobs`} color="primary" className={classes.link}>
            <Tab label="Common Jobs" className={classes.label}/>
          </NavLink> */}
        </Tabs>
      {selectedTab === SetupTabs.req && <RequestsSummary />}
      {selectedTab === SetupTabs.estimates && <EstimatesList />}
      {selectedTab === SetupTabs.commonJobs && <CommonJobsSummary /> }
  </React.Fragment>
  );
});
import { Instance, types, applySnapshot, flow, getEnv } from 'mobx-state-tree';
import { PayrollEnquiryGroup, IPayrollEnquiryGroup } from '../domain/PayrollEnquiryGroup';
import { flatMap, isEmpty, join } from 'lodash';
import { ApiResponse } from 'api';
import { IStoresEnv } from '@core';
import { exportEnquiries, processEnquiries, getPayrollEnquiries } from 'payroll-adjustment-enquiry/endpoints';

export const PayrollAdjustmentEnquiryStore = types
  .model({
    fromDate: types.optional(types.Date, new Date().setMonth(new Date().getMonth() - 1)),
    toDate: types.optional(types.Date, new Date()),
    enquiryGroups: types.optional(types.array(PayrollEnquiryGroup), []),
    teams: types.optional(types.array(types.string), []),
    selectedTeams: types.optional(types.array(types.string), []),
    processed: types.optional(types.boolean, false),
    isLoading: types.optional(types.boolean, false)
  })
  .actions(self => {
    const { api } = getEnv<IStoresEnv>(self);

    return ({
      fetchEnquiries: flow(function* () {
        self.isLoading = true;
        const response: ApiResponse<IPayrollEnquiryGroup[]> = yield getPayrollEnquiries(api, {
          payload: {
            processed: self.processed,
            fromDate: self.fromDate,
            toDate: self.toDate
          },
          errorHandler: 'Failed to fetch payroll enquiries',
        });

        applySnapshot(self.enquiryGroups, response.data);
        self.enquiryGroups.forEach(group => group.setDateRange(self.fromDate, self.toDate));
        self.enquiryGroups.forEach(group => group.processed = self.processed);

        self.isLoading = false;
      }),
      fetchData: flow(function* fetchData() {
        const teams = yield api.get(`/api/teams`);
        applySnapshot(self.teams, teams.data);
        applySnapshot(self.selectedTeams, teams.data);
      }),
    });
  })
  .actions(self => {
    const { api } = getEnv<IStoresEnv>(self);

    return ({
      processEnquiries: flow(function* () {
        const ids = flatMap(self.enquiryGroups, item => item.enquiries.filter(x => x.selected).map(v => v.id));

        const response: ApiResponse<any> =
          yield processEnquiries(
            api,
            {
              payload: { ids },
              errorHandler: 'Failed to process payroll adjustment enquiries.',
            },
          );

        if (!isEmpty(response.data.ids)) {
          //notify user about failed records
          alert('Failed to process some of the records. ' + join(ids, ', '));
        }

        self.fetchEnquiries();

        return response.success;
      }),
      exportEnquiries: flow(function* () {
        const ids = flatMap(self.enquiryGroups, item => item.enquiries.filter(x => x.selected).map(v => v.id));
        self.isLoading = true;

        const response: ApiResponse<BlobPart> =
          yield exportEnquiries(
            api,
            {
              payload: {
                ids
              },
              errorHandler: 'Failed to export payroll adjustment enquiries.',
            }
          );

        if (response.success) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const anchor = document.createElement('a');
          anchor.href = url;
          anchor.download = response.fileName;
          anchor.target = '_blank';
          document.body.appendChild(anchor);
          anchor.click();
          anchor.remove();
        }

        self.isLoading = false;

        return response.success;
      }),
      selectDateRange(from: Date, to: Date) {
        self.fromDate = from;
        self.toDate = to;

        self.fetchEnquiries();
      },
      selectTeams(teams: string[]) {
        self.selectedTeams.replace(teams);
      },
      toggleChecked(value: boolean) {
        self.processed = value;
        if (!value) {
          self.fromDate = new Date(new Date().setMonth(new Date().getMonth() - 1));
          self.toDate = new Date();

        }
        self.fetchEnquiries();
      },
    });
  })
  .views((self) => ({
    get filteredEnquiryGroups() {
      if (!isEmpty(self.selectedTeams)) {
        return self.enquiryGroups.filter((g) => self.selectedTeams.includes(g.team));
      }

      return self.enquiryGroups;
    },
    get amountOfRows() {
      var totalAmount = 0;
      self.enquiryGroups.forEach(g => totalAmount += g.enquiries.length)

      return totalAmount;
    },
    get amountOfSelectedRows() {
      var totalAmount = 0;
      self.enquiryGroups.forEach(g => totalAmount += g.amountOfSelectedItems)

      return totalAmount;
    }
  }));

export type IPayrollAdjustmentEnquiryeStore = Instance<typeof PayrollAdjustmentEnquiryStore>;

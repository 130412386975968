import { DefaultPages } from '../domain/DefaultPages';
import { UserConfiguration } from '../domain/UserConfiguration';
import { UserImport } from '../domain/UserImport';
import { Instance, types, applySnapshot, flow, getEnv } from 'mobx-state-tree';
import { IStoresEnv, INotificationEnv } from '@core';

export const UserSetupStore = types
  .model('userSetupStore', {
    users: types.array(UserConfiguration),
    hasChanges: types.optional(types.boolean, false),
    originalUsers: types.array(UserConfiguration),
    userSerchTerm: types.optional(types.string, ''),
    selectedTeams: types.array(types.string),
    userImport: types.optional(UserImport, {}),
    teams: types.array(types.string),
    isLoading: types.optional(types.boolean, false)
  })
  .actions((self) => {
    const { api } = getEnv<IStoresEnv>(self);
    const { snackMessenger } = getEnv<INotificationEnv>(self);

    const mapAssignedTeam = (team) => ({
      teamName: team.teamName,
      id: team.id,
      userId: team.userId,
      isTimesheetCreator: team.isTimesheetCreator,
      isTimesheetEditor: team.isTimesheetEditor,
      isTimesheetApprover: team.isTimesheetApprover,
      isPayrollEnquiry: team.isPayrollEnquiry,
    });

    const mapUser = (user) => ({
      id: user.id,
      userId: user.userId,
      name: user.name,
      isSettingsAdmin: !!user.isSettingsAdmin,
      isPayrollAdmin: !!user.isPayrollAdmin,
      isTakeFiveReader: !!user.isTakeFiveReader,
      isEstimateEditor: !!user.isEstimateEditor,
      defaultPage: user.defaultPage || DefaultPages.Daily,
      assignedTeams: user.assignedTeams.map((t) => mapAssignedTeam(t))
    });

    const mapImportedUser = (user) => ({
      id: user.id,
      userId: user.userId,
      name: user.userName,
      isSettingsAdmin: !!user.settingsAdmin,
      isPayrollAdmin: !!user.payrollAdmin,
      isTakeFiveReader: !!user.isTakeFiveReader,
      isEstimateEditor: !!user.isEstimateEditor,
      defaultPage: user.defaultPage || DefaultPages.Daily
    });

    const resetStore = () => {
      applySnapshot(self, {});
    };

    return {
      resetStore,
      fetchData: flow(function* fetchData() {
        self.isLoading = true;
        applySnapshot(self.users, []);
        applySnapshot(self.originalUsers, []);
        const teams = yield api.get(`/api/teams`);
        applySnapshot(self.teams, teams.data);
        applySnapshot(self.selectedTeams, teams.data);

        const usersResponse = yield api.get(`/api/UserConfigurations`);
        const users = usersResponse.data.map((u) => mapUser(u));
        applySnapshot(self.users, users);
        applySnapshot(self.originalUsers, users);

        self.isLoading = false;
      }),
      importUser: flow(function* importUser() {
        self.userImport.setIsImporting(true);
        const { email } = self.userImport;
        const response = yield api.post(`/api/UserPermissions`, { payload: email });
        if (response.status === 404 ) {
          self.userImport.setError(`User doesn't exist in Azure Directory`);
        } else if (response.status === 409) {
          self.userImport.setError(`User already exists or has been deleted`);
        } else if (response.status === 200) {
          const importedUser = mapImportedUser(response.data);
          self.users.push(importedUser);
          self.originalUsers.push(importedUser);
          self.userImport.setUserEmail('');
        } else {
          self.userImport.setUserEmail('Unexpected error occurred');
        }

        self.userImport.setIsImporting(false);
      }),
      saveUser: flow(function* saveUser(user) {
        user.setIsSaving(true);
        const response = yield api.post(`/api/UserConfigurations`, { payload: user });
        if (response.status !== 200) {
          snackMessenger.addSnackMessage({
             message: 'Fail to save user, try again.',
             options: { persist: false, variant: 'error'}
          });
          user.setIsSaving(false);
          return;
        }

        const updatedUser = response.data;
        const users = self.users.toJSON();
        const index = users.findIndex((u) => u.id === user.id);
        users[index] = updatedUser;
        self.users.replace(users);

        const originalUsers = self.originalUsers.toJSON();
        originalUsers[index] = updatedUser;
        self.originalUsers.replace(originalUsers);
        user.setIsSaving(false);
      }),
      archiveUser: flow(function* archiveUser(user) {
        self.users.remove(user);
        self.originalUsers.remove(user);
        yield api.delete(`/api/UserConfigurations/${user.userId}`);
      }),
      setUserSerchFilter(term, selectedTeams) {
        self.userSerchTerm = term;
        self.selectedTeams.replace(selectedTeams);
      }
    };
  })
  .views((self) => {
    const hasUserChanges = (user) => {
      const originalUser = self.originalUsers.find((u) => u.userId === user.userId);
      if (!originalUser) {
        return false;
      }

      const isModified = originalUser.equals(user);
      return !isModified;
    };

    return {
      get filteredUsers() {
        return self.users
          .filter((u) => !self.userSerchTerm ||
            (u.name.indexOf(self.userSerchTerm) > -1 || u.userId.indexOf(self.userSerchTerm) > -1))
          .filter((u) => (self.selectedTeams.length === self.teams.length) ||
            (self.selectedTeams.length === 0 && u.assignedTeams.filter((t) => !!t.id).length === 0) ||
            (u.assignedTeams.some((ut) => self.selectedTeams.includes(ut.teamName))));
      },
      hasUserChanges,
      hasAnyChanges() {
        return self.users.some(hasUserChanges);
      }
  };
});

export type IUserSetupStore = Instance<typeof UserSetupStore>;

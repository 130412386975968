import { inject, observer } from 'mobx-react';
import React, {useEffect} from 'react';
import { IPriorityStore } from './store/PriorityConfigStore';
import { EstimatePriorities} from './Priorities';
import { STORE_KEYS } from '@core';


interface Props {
  PriorityStore: IPriorityStore;
}

const PriorityComponent: React.FC<Props> = observer((props) => {
  const store = props.PriorityStore;
  const { priorities, hasChanges, save, delete: handleDelete } = store;

  useEffect(() => {
    store.fetchData();
    return function cleanup() {
    };
  }, []);

  return (
    <EstimatePriorities EstimatePriorityModel={priorities} 
    priorities={priorities.priorities} 
    hasChanges={hasChanges(priorities.priorities)}
    onSave={save}
    onDelete={handleDelete}
    />
  );
});

export const PriorityConfig = inject(STORE_KEYS.PriorityConfigStore)(PriorityComponent);

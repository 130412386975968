import { observer } from 'mobx-react';
import React from 'react';
import { Grid, TextField } from '@material-ui/core';
import { HoursRow } from '../HoursRow';
import { DetailsHeader } from '../DetailsHeader';
import { ServiceCallDetailsPopup } from '../ServiceCallDetailsPopup';
import { GpAdjustmentModel } from 'timesheet-details/domain/gp-adjustments/GpAdjustment';
import { AdjustmentType } from 'timesheet-details/domain/gp-adjustments/AdjustmentType';
import EnumSelector from 'components/EnumSelector';

interface Props {
    disabled?: boolean;
    adjustment: GpAdjustmentModel;
    date: Date;
    onDelete: (allocation: GpAdjustmentModel) => void;
}

const AdjustmentTypes = new Map<string, string>([
    [AdjustmentType.ServiceCall, 'Service Call'],
    [AdjustmentType.Travel, 'Travel']
  ]);

export const GpAdjustmentRow: React.FC<Props> = observer((props) => {
    const { adjustment, onDelete, disabled, date } = props;

    const handleDelete = () => {
        onDelete(adjustment);
    };

    const handleReasonChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        adjustment.setReason(event.target.value);
    };

    const handleServiceCallNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        adjustment.setServiceCallNumber(value, date);
    };

   const { serviceCallMissingError } = adjustment.validate();

    const errors = [serviceCallMissingError]
       .filter((e) => !!e);
    
    return (
    <>
        <DetailsHeader
            canDelete={adjustment.canDelete && !disabled}
            status={adjustment.status}
            errors={errors}
            hasErrors={errors.length > 0}
            onDeleteClick={handleDelete}
        />
        <Grid container direction="row" alignItems="center" >
            <Grid item xs={8}>
                <Grid container spacing={2} direction="row">
                    <Grid item xs={3}>
                        <EnumSelector
                            disabled={adjustment.isReadOnly || disabled}
                            label="Type"
                            value={adjustment.type}
                            options={AdjustmentTypes}
                            onChange={adjustment.setType}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            fullWidth={true}
                            disabled={adjustment.isReadOnly || disabled}
                            label="Service call"
                            margin="dense"
                            variant="outlined"
                            error={!!adjustment.serviceCallError || serviceCallMissingError !== ""}
                            helperText={adjustment.serviceCallError}
                            value={adjustment.serviceCallNumber}
                            onChange={handleServiceCallNumberChange}
                            inputProps={{ maxLength: 9 }}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            fullWidth={true}
                            disabled={adjustment.isReadOnly || disabled}
                            label="Reason"
                            margin="dense"
                            variant="outlined"
                            value={adjustment.reason}
                            onChange={handleReasonChange}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={3}>
                <HoursRow
                    model={adjustment.hours}
                    disabled={adjustment.isReadOnly || disabled}
                    disablePayFields={true}
                    onNormalGPChange={adjustment.setNormalGP}
                    allowNegative={true}
                />
            </Grid>
        </Grid>
        <Grid container alignItems="center">
            <ServiceCallDetailsPopup
                details={adjustment.serviceCallDetails}
                notes={adjustment.notes}
            />
        </Grid>
    </>
  );
});

import { makeStyles, createStyles, Theme } from "@material-ui/core";
import { grey } from "@material-ui/core/colors";

export const useStyles = makeStyles((theme: Theme) =>
createStyles({
  root: {
    backgroundColor: '#fff',
    flexGrow: 1,
    borderRadius: '3px',
    zoom: '75%'
  },
  tab: {
    width: '140px',
    minWidth: '100px'
  },
  expansion: {
    height: '65px',
    paddingTop: '15px',
    minHeight: '65px',
    borderBottom: `1px solid ${grey[300]}`
  },
  '@global': {
    '.MuiBox-root': {
      padding: 0,
    }
  },
  noPadding: {
    height: '685px',
    padding: 0,
    width: '100%',
  },
  customTabPanel: {
    '& > div:first-child': {
      padding: 0,
    },
  }
}));
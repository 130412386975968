import { Instance, types } from 'mobx-state-tree';

export const TravelRecordModel = types
  .model({
    id: types.optional(types.string, ''),
    startLocationLatitude: types.maybeNull(types.number),
    startLocationLongitude: types.maybeNull(types.number),
    startTime: types.maybeNull(types.string),
    technician: types.optional(types.string, ''),
    endLocationLatitude: types.maybeNull(types.number),
    endLocationLongitude: types.maybeNull(types.number),
    endTime: types.maybeNull(types.string),
    appointmentGpId: types.optional(types.string, ''),
  });

export type ITravelRecordModel = Instance<typeof TravelRecordModel>;

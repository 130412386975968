import { flow as _flow, isFunction, isString } from 'lodash';
import { Instance, types, flow, getEnv } from 'mobx-state-tree';

import { ApiCallParams, ErrorHandler } from './ApiCallOptions';
import { ApiResponse } from './ApiResponse';
import { ServerResponse } from './ServerResponse';
import { adalApiFetch, graphApiFetch } from './adalConfig';
import { getFileNameFromDisposition } from './getFileNameFromDisposition';
import { INotificationEnv } from '@core';

export const GraphApiStore = types
  .model('graphApi', {})
  .actions(self => {
    return apiBase(graphApiFetch, self);
  });
export const ApiStore = types
  .model('api', {})
  .actions(self => {
    return apiBase(adalApiFetch, self)
  });

export type IApiStore = Instance<typeof ApiStore>;
export type IGraphApiStore = Instance<typeof GraphApiStore>;

const getURL = (url: string) => `${API_URL}${url}`;

function apiBase(apifetch: (fetch, url: string, options) => Promise<any>, self) {
  const inteSelf = self;
  const {
    snackMessenger
  } = getEnv<INotificationEnv>(inteSelf);
  const processErrorHandlers = (error: Response, handler: ErrorHandler) => {

    if (isString(handler)) {
      console.error(handler)
      return snackMessenger.addSnackMessage({ message: handler as string });
    }

    if (isFunction(handler) && handler as ErrorHandlingFn) {
      return (handler as ErrorHandlingFn)(error);
    }
  };

  const performApiCall = function* (fn: (...args: any[]) =>
    IterableIterator<any>, params?: ApiCallParams, isFile?: boolean) {
    try {
      const serverResponse: ServerResponse = yield* fn();
      const result = serverResponse.response
      if (!result.ok) {
        console.error(result);

        params && params.errorHandler && processErrorHandlers(result, params.errorHandler);
        let error: string = params && isString(params.errorHandler) && params.errorHandler as string || '';
        let message;
        if (result.status === 404) {
          error = `${error} Url not Found: ${result.url}`;
        }
        else if (result.status === 500) {
          error = error || `Oh no! Something bad happened. Please come back later when we have fixed that problem. Thanks.`;
        }
        else if (result.status === 400) {
          error = error || `${result.status} Bad Request`;
        }
        else {
          try {
            const body = yield result.json();
            message = body && body.message || '';
          } catch (ex) {
            console.error(ex);
          }
        }
        return new ApiResponse(null, result.status, undefined,
          error && (error + result.statusText) || message || "Error in fetch data");
      }
      if (result.status === 204) {
        return new ApiResponse(null, result.status);
      }
      if (isFile) {
        const fileName = getFileNameFromDisposition(result.headers);
        const body = yield result.blob();
        return new ApiResponse(body, result.status, fileName);
      }
      const body = yield result.json();
      if (!production) {
        console.log(body);
      }
      return new ApiResponse(body, result.status);

    } catch (e) {
      console.error('error[performApiCall]', e);
      return new ApiResponse(null, 500, e)
    }
  };

  return ({
    get: flow(function* (url: string, params?: ApiCallParams) {
      const call = function* () {
        const requestUrl = params && params.isAbsoluteUrl ? url : getURL(url);
        const response = yield apifetch(fetch, requestUrl, params && params.payload || '');
        return new ServerResponse(response);
      };

      return yield* performApiCall(call, params);
    }),
    getFile: flow(function* (url: string, params: ApiCallParams) {
      const call = function* () {
        const requestUrl = params && params.isAbsoluteUrl ? url : getURL(url);
        const response = yield apifetch(fetch, requestUrl, params && params.payload || '');

        return new ServerResponse(response);
      };

      return yield* performApiCall(call, params, true);
    }),
    getRawFile: flow(function* (url: string, params: ApiCallParams) {
      const call = function* () {
        const requestUrl = params && params.isAbsoluteUrl ? url : getURL(url);

        const response = yield apifetch(fetch, requestUrl, {
          method: 'POST',
          headers: {
            'accept': 'application/octet-stream',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(params && params.payload || ''),
        });

        return new ServerResponse(response);
      };

      return yield* performApiCall(call, params, true);
    }),
    post: flow(function* (url: string, params: ApiCallParams) {
      const call = function* () {
        const o = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(params.payload)
        };
        const response = yield apifetch(fetch, getURL(url), o);

        return new ServerResponse(response);
      };

      return yield* performApiCall(call, params);
    }),

    postFile: flow(function* (url: string, params: ApiCallParams) {
  const call = function* () {
    const o = {
      method: 'POST',
      body: params.payload 
    };

    let response;
    try {
      response = yield apifetch(fetch, getURL(url), o);
    } catch (error) {
      // If the fetch fails, throw the error to be caught in the outer function
      throw error;
    }

    // Check if the response is ok
    if (!response.ok) {
      // If not, throw an error with the status text
      throw new Error(response.statusText);
    }

    return new ServerResponse(response);
  };

  try {
    return yield* performApiCall(call, params);
  } catch (error) {
    // Log the error and re-throw it to be caught in the outer function
    console.error('Error in postFile:', error);
    throw error;
  }
}),

    delete: flow(function* (url: string, params?: ApiCallParams) {
      const call = function* () {
        const o = {
          method: 'DELETE'
        };
        const response = yield apifetch(fetch, getURL(url), o);

        return new ServerResponse(response);
      };

      return yield* performApiCall(call, params);
    }),

    put: flow(function* (url: string, params: ApiCallParams) {
      const call = function* () {

        const o = {
          method: 'PUT',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(params.payload)
        };
        const response = yield apifetch(fetch, getURL(url), o);

        return new ServerResponse(response);
      };

      return yield* performApiCall(call, params);
    }),
    patch: flow(function* (url: string, params: ApiCallParams) {
      const call = function* () {

        const o = {
          method: 'PATCH',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(params.payload)
        };
        const response = yield apifetch(fetch, getURL(url), o);

        return new ServerResponse(response);
      };

      return yield* performApiCall(call, params);
    }),
  });
}
import { Instance, types, applySnapshot, flow, getEnv } from 'mobx-state-tree';

import { EstimateTasks } from '../domain/EstimateTasks';
import { EstimateTaskModel } from '../domain/EstimateTask';
import { uuidv4 } from '@utils';
import { IStoresEnv } from '@core';

export const TaskStore = types
  .model({
    isLoading: types.optional(types.boolean, false),
    originalSettings: types.optional(EstimateTasks, {}),
    tasks: types.optional(EstimateTasks, {}),
    estimateID: types.optional(types.string, '')
  })
  .actions((self) => {
    const { api } = getEnv<IStoresEnv>(self);

    const mapTask = (x) => ({
      id: x.id, 
      task: x.task,
      taskTime: x.taskTime,
      estimateID: x.estimateID
    });
    const mapSettings = (x) => ({
      tasks: x.tasks
    });

    const applyData = (data) => {
        if(data.tasks)
        {
          applySnapshot(self.originalSettings,
            { tasks: data.tasks.map((x) => mapTask(x)) });
          applySnapshot(self.tasks,
            { tasks: data.tasks.map((x) => mapTask(x)) });
        }

        for( let i=1; i<=(10 - self.tasks.tasks.length); i++)
        {
          const task: any = {
              id: uuidv4()
              };
              self.tasks.tasks.push(task)
        }
      }
    
    const resetStore = () => { 
      applySnapshot(self, {});
    };

    return {
      resetStore,
      fetchData: (estimateID: string ) => {
        const quarryData = flow(function* fetch() {
          
          const response = yield api.get(`/api/EstimateTaskList/id?estimateNumber=${estimateID}`);
          if (response.status !== 200) {
            return;
          }
            applyData(response.data);
        });

        quarryData();
      },
      save: flow(function* save() {
        self.isLoading = true;
        const response = yield api.post(`/api/EstimateTaskList/save`,
            { payload: mapSettings(self.tasks) });

        
          applyData(response.data);

        self.isLoading = false;
      }),
      // delete: (task: EstimateTaskModel) => {
      //   const deleteRow = flow(function* fetch() {
      //     self.isLoading = true;

      //     const response = yield api.post(`/api/EstimateTaskList/delete`,
      //         { payload: mapTask(task) });
      //     applyData(response.data);
  
      //     self.isLoading = false;
      //   })

      //   deleteRow()
      // },
      delete: (task: EstimateTaskModel) => {
        const deleteRow = flow(function* fetch() {
          self.isLoading = true;
      
          const response = yield api.post(`/api/EstimateTaskList/delete`,
              { payload: mapTask(task) });
      
              if (response.status === 200) {
                // Remove the deleted task from the store
                self.tasks.tasks.replace(self.tasks.tasks.filter(t => t.id !== task.id));
              }
      
          self.isLoading = false;
        })
      
        deleteRow()
      },

    };
  })
  .views((self) => {
    const hasChanges = (tasks) => {  
      const newRecord = tasks.filter(
        (t) => !self.originalSettings.tasks.some( (x) => t.equals(x))  && t.task != '').length === 0
     
      if(!newRecord) return true

      const isModified = self.originalSettings.equals(tasks);
      return !isModified;
    }; 

    const totalHours = () => {
      return self.tasks.tasks.reduce((total, task) => total + (task.taskTime ?? 0), 0);
    }

    return {
      hasChanges,
      totalHours
    };
  });

export type ITaskStore = Instance<typeof TaskStore>;

import { types, Instance } from 'mobx-state-tree';
import { TimeSheetLineType } from './TimeSheetLineType';
import { TimeSheetHours } from '../TimeSheetHours';
import { ServiceCallDetails, setServiceCall } from '../ServiceCallDetails';
import { TimeSheetStatus } from 'components/TimeSheetStatusViewer';
import { roundToQuarter, toTwoDecimalNumber } from '@utils/decimal';
import { getHoursInTwoDecimalNumberFormat, DATE_FORMAT, getDateTime } from '@utils';
import { format, isValid } from 'date-fns';


const isServiceCallRequired = (type: TimeSheetLineType) => {
  return type === TimeSheetLineType.Travel ||
    type === TimeSheetLineType.ServiceCall ||
    type === TimeSheetLineType.ServiceCallBreakTime ||
    type === TimeSheetLineType.ServiceCallLostTime ||
    type === TimeSheetLineType.FDOWorked;
};

const isLostTypeReasonRequired = (type: TimeSheetLineType) => {
  return type === TimeSheetLineType.ServiceCallLostTime ||
    type === TimeSheetLineType.LostTime;
};

export const TimeSheetLine = types.model({
  id: types.optional(types.string, ''),
  newLine:  types.optional(types.boolean, false),
  status: types.enumeration<TimeSheetStatus>('TimeSheetStatus', Object.values(TimeSheetStatus)),
  type: types.enumeration<TimeSheetLineType>('TimeSheetLineType', Object.values(TimeSheetLineType)),
  serviceCallId:  types.maybeNull(types.string),
  serviceCallNumber: types.optional(types.string, ''),
  lostTimeReason: types.optional(types.string, ''),
  recovered: types.maybeNull(types.boolean),
  startTime: types.string,
  endTime: types.string,
  callOut: types.optional(types.boolean, false),
  hours: TimeSheetHours,
  serviceCallDetails: types.maybeNull(ServiceCallDetails),
  serviceCallError: types.optional(types.string, ''),
  notes: types.optional(types.string, ''),
  lostTimeTypes: types.array(types.string),
  recoverableLostTimeTypes: types.array(types.string),
  startTimeError: types.optional(types.string, ''),
  endTimeError: types.optional(types.string, '')
})
  .actions((self) => {
    const clearServiceCall = () => {
      self.serviceCallId = null;
      self.serviceCallNumber = '';
      self.serviceCallDetails = null;
    };

    const recalculateHours = () => {
      const start = getDateTime(self.startTime);
      const end = getDateTime(self.endTime);
      if (start > end) {
        return;
      }

      if(self.type === TimeSheetLineType.BreakTime || self.type === TimeSheetLineType.ServiceCallBreakTime)
      {
        self.hours.setTotalPay(0);
        self.hours.setTotalGP(0);

        self.hours.setNormalPay(0);
        self.hours.setOvertime(0);
        self.hours.setOvertimeDoubleTime(0);
        self.hours.setDoubleTime(0);

        self.hours.setNormalGP(0);
        self.hours.setOvertimeGP(0);
        self.hours.setOvertimeDoubleTimeGP(0);
        self.hours.setDoubleTimeGP(0);
        return;
      }

      const startHours = getHoursInTwoDecimalNumberFormat(start);
      const endHours = getHoursInTwoDecimalNumberFormat(end);

      const totalHours = toTwoDecimalNumber(endHours - startHours);

      const totalPayHours = toTwoDecimalNumber(totalHours);
      var totalGpHours;

      if(self.type !== TimeSheetLineType.ServiceCall && self.type !== TimeSheetLineType.Travel)
      {
        totalGpHours = toTwoDecimalNumber(totalHours);
      }
      else
      {
        totalGpHours = roundToQuarter(totalHours);
      }
      

      if (self.callOut) {
        self.hours.setTotalPay(totalPayHours > 4 ? totalPayHours : 4);
        self.hours.setTotalGP(totalGpHours > 4 ? totalGpHours : 4);

        self.hours.setNormalPay(0);
        self.hours.setOvertime(0);
        self.hours.setOvertimeDoubleTime(0);
        self.hours.setDoubleTime(self.hours.totalPay);

        self.hours.setNormalGP(0);
        self.hours.setOvertimeGP(0);
        self.hours.setOvertimeDoubleTimeGP(0);
        self.hours.setDoubleTimeGP(self.hours.totalGP);

        return;
      }

      self.hours.totalPay = totalPayHours;
      self.hours.totalGP = totalGpHours;

      self.hours.setOvertimeDoubleTime(0);
      self.hours.setDoubleTime(0);
      self.hours.setOvertimeDoubleTimeGP(0);
      self.hours.setDoubleTimeGP(0);

      if (self.type === TimeSheetLineType.FDOWorked) {
        self.hours.setOvertime(self.hours.totalPay);
        self.hours.setNormalPay(0);
        self.hours.setOvertimeGP(self.hours.totalGP);
        self.hours.setNormalGP(0);
      }
      else {
        self.hours.setOvertime(0);
        self.hours.setOvertimeGP(0);
        self.hours.setNormalPay(self.hours.totalPay);
        self.hours.setNormalGP(self.hours.totalGP);
      }

    };

    const setAllocationTime = (time) => {
      self.hours.setAllocation(time);
      if (!!self.lostTimeReason) {
        self.recovered = self.hours.totalGP === time;
      } else {
        self.recovered = null;
      }
    };

    return {
      setAllocationTime,
      setServiceCallNumber(serviceCallNumber, date) {
        setServiceCall(self, serviceCallNumber, date, 1);
      },
      setType: (type: TimeSheetLineType) => {
        self.type = type;
        if (!isServiceCallRequired(self.type)) {
          clearServiceCall();
        }

        if (!isLostTypeReasonRequired(self.type)) {
          self.lostTimeReason = '';
        }
        recalculateHours()
      },
      setLostTimeReason: (reason: string) => {
        self.lostTimeReason = reason;
        if (!reason) {
          self.recovered = null;
        }
      },
      setStartTime: (startTime: Date) => {
        if(isValid(startTime))
        {
          if(startTime.getMinutes() % 3 == 0)
          {
            self.startTimeError = '';
            self.startTime = format(startTime, DATE_FORMAT);
            recalculateHours();
          }
          else
          {
            self.startTimeError = 'Time must be entered to the nearest 3 minutes'
          }
        }
        else
        {
          self.startTimeError = 'Time entered is invalid';
        }
      },
      setEndTime: (endTime: Date) => {
        if(isValid(endTime))
        {
          if(endTime.getMinutes() % 3 == 0)
          {
            self.endTimeError = '';
            self.endTime = format(endTime, DATE_FORMAT);
            recalculateHours();
          }
          else
          {
            self.endTimeError = 'Time must be entered to the nearest 3 minutes'
          }
        }
        else
        {
          self.endTimeError = 'Time entered is invalid';
        }
      },
      setCallOut: (value: boolean) => {
        self.callOut = value;
        recalculateHours();
      },
      recalculateHours
    };
  })
  .views((self) => {

    const isValidTimeRange = () => {
      return getDateTime(self.startTime) < getDateTime(self.endTime);
    };

    const isValidLostTime = () => {
      return ((self.type === TimeSheetLineType.LostTime || self.type === TimeSheetLineType.ServiceCallLostTime) && self.lostTimeReason) || 
        (self.type !== TimeSheetLineType.LostTime && self.type !== TimeSheetLineType.ServiceCallLostTime)
    }

    const isValidServiceCall = () => {
      return ((self.type === TimeSheetLineType.ServiceCall || self.type === TimeSheetLineType.ServiceCallBreakTime || self.type === TimeSheetLineType.ServiceCallLostTime || self.type === TimeSheetLineType.Travel || self.type === TimeSheetLineType.FDOWorked) && self.serviceCallNumber && !self.serviceCallError) || 
      (self.type !== TimeSheetLineType.ServiceCall && self.type !== TimeSheetLineType.ServiceCallBreakTime && self.type !== TimeSheetLineType.ServiceCallLostTime && self.type !== TimeSheetLineType.Travel && self.type !== TimeSheetLineType.FDOWorked)
    }

    const isValidStartTime = () => {
      return self.startTimeError === ''
    }

    const isValidEndTime = () => {
      return self.endTimeError === ''
    }

    const isValidGPHours = () => {
      if(self.type != TimeSheetLineType.ServiceCallLostTime && self.type != TimeSheetLineType.LostTime)
        return true;

      var countGP = 0;
      
      if(self.hours.normalGP > 0) 
        countGP++;
      if(self.hours.overtimeGP > 0)
        countGP++;
      if(self.hours.doubleTimeGP > 0)
        countGP++;
      if(self.hours.overtimeDoubleTimeGP > 0)
        countGP++;
      
      return countGP == 1;
    }

    const isValidPayHours = () => {
      if(self.type != TimeSheetLineType.ServiceCallLostTime && self.type != TimeSheetLineType.LostTime)
        return true;

      var countPay = 0;
      
      if(self.hours.normalPay > 0)
          countPay++;
      if(self.hours.overtime > 0)
        countPay++;
      if(self.hours.doubleTime > 0)
        countPay++;
      if(self.hours.overtimeDoubleTime > 0)
        countPay++;
      
      return countPay == 1;
    };

    return {
      get isRecoverableLostTime() {
        // we display only recoverable lost time reasons
        return !!self.lostTimeReason && self.recoverableLostTimeTypes.includes(self.lostTimeReason);
      },
      get isServiceCallRequired() {
        return isServiceCallRequired(self.type);
      },
      get isLostTypeReasonRequired() {
        return isLostTypeReasonRequired(self.type);
      },
      get isValid() {
        return isValidTimeRange() && self.hours.isValid && isValidLostTime() && isValidServiceCall() && isValidStartTime() && isValidEndTime() && isValidGPHours() && isValidPayHours();
      },
      get isServiceCallValid() {
        return isValidServiceCall();
      },
      get isLostTimeValid() {
        return isValidLostTime();
      },
      get isOvertimeAllowed() {
        return self.type === TimeSheetLineType.ServiceCall ||
          self.type === TimeSheetLineType.ServiceCallLostTime ||
          self.type === TimeSheetLineType.Travel ||
          self.type === TimeSheetLineType.LostTime ||
          self.type === TimeSheetLineType.Training||
          self.type === TimeSheetLineType.FDOWorked;
      },
      hasOverlap(timesheetLine: Instance<typeof TimeSheetLine>) {
        return getDateTime(self.startTime) < getDateTime(timesheetLine.endTime)
          && getDateTime(self.endTime) > getDateTime(timesheetLine.startTime);
      },
      validate() { 
        const validationResult = self.hours.validate();
        validationResult['startHigherThanEndError'] = !isValidTimeRange() ? 'Start time should be less than End time' : '';
        validationResult['lostTimeReasonMissingError'] = !isValidLostTime() ? 'Lost time must have a reason selected' : '';
        validationResult['serviceCallMissingError'] = !isValidServiceCall() ? self.serviceCallError || 'Valid service call must be entered' : '';
        validationResult['startTimeError'] = self.startTimeError;
        validationResult['endTimeError'] = self.endTimeError; 
        validationResult['gpHoursError'] = !isValidGPHours() ? 'A lost time type of timesheet cannot have more than 1 gp hour type (eg you cannot have normal and T/H hours on the same line). Please create separate entry for each.' : '';
        validationResult['payHoursError'] = !isValidPayHours() ? 'A lost time type of timesheet cannot have more than 1 pay hour type (eg you cannot have normal and T/H hours on the same line). Please create separate entry for each.' : '';
        return validationResult;
      },
      get canDelete() {
        return self.status === TimeSheetStatus.New || self.status === TimeSheetStatus.Rejected;
      },
      get isReadOnly() {
        return self.status === TimeSheetStatus.Submitted || self.status === TimeSheetStatus.Approved ||
          self.status === TimeSheetStatus.Integrated;
      },
      equals(line) {
        return self.id === line.id
          && self.status === line.status
          && self.type === line.type
          && self.serviceCallNumber === line.serviceCallNumber
          && self.lostTimeReason === line.lostTimeReason
          && format(getDateTime(self.startTime), DATE_FORMAT) === format(getDateTime(line.startTime), DATE_FORMAT)
          && format(getDateTime(self.endTime), DATE_FORMAT) === format(getDateTime(line.endTime), DATE_FORMAT)
          && self.callOut === line.callOut
          && self.hours.equals(line.hours)
          && self.notes === line.notes;
      },
      get startDateTime() {
        return getDateTime(self.startTime);
      },
      get endDateTime() {
        return getDateTime(self.endTime);
      }
    };
  });

export type TimeSheetLineModel = Instance<typeof TimeSheetLine>;

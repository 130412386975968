import { observer } from 'mobx-react';
import React from 'react';
import { EstimatePrioritiesModel } from './domain/Priorities';
import { EstimatePriority } from './Priority';
import CloudDownloadOutlinedIcon from '@material-ui/icons/CloudDownloadOutlined';
import { Button, makeStyles, createStyles, Theme } from '@material-ui/core';
import { EstimatePriorityModel } from './domain/Priority';

interface Props {
    EstimatePriorityModel: EstimatePrioritiesModel;
    priorities: EstimatePriorityModel[];
    hasChanges: boolean;
    onSave: (priorities: EstimatePriorityModel[]) => void;
    onDelete: (priority: EstimatePriorityModel) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    priorityWrapper: {
      background: '#fff',
      padding: '2rem'
    },
    label: {
      textTransform: 'capitalize'
    },
    newLineBtn: {
      marginTop: '1rem'
    },
    saveBtn: {
      textAlign: 'right'
    }
}));
export const EstimatePriorities: React.FC<Props> = observer((props) => {

const { EstimatePriorityModel, priorities, hasChanges} = props;
const classes = useStyles();

const onAddNewTimeline = () => {
    return EstimatePriorityModel.addNewLine();
};

const handleSaveClick = () => {
  props.onSave(priorities);
};

const handleDelete = (priority) => {
  props.onDelete(priority);
};

  return (
    <div className={classes.priorityWrapper}>
      <div className={classes.saveBtn}>
      <Button
          color="primary"
          disabled={!hasChanges}
          className={classes.label}
          startIcon= {<CloudDownloadOutlinedIcon/>} //{user.isSaving ? <CircularProgress size={14} /> : <CloudDownloadOutlinedIcon />}
          onClick={handleSaveClick}
      >
          Save
      </Button></div>
      { priorities.map((line, index) =>
        <EstimatePriority priority={line}
        onDeleteClick={handleDelete}/>
      )}
      <Button
        variant="outlined"
        color="primary"
        onClick={onAddNewTimeline}
        className={classes.newLineBtn}
      >
        ADD NEW LINE
      </Button>
    </div>
  );
});
import { Instance, types } from 'mobx-state-tree';

export const DetailsTabData = types
  .model({
    technician: types.optional(types.string, ''),
    scheduledStart: types.maybeNull(types.string),
    actualStart: types.maybeNull(types.string),
    actualEnd: types.maybeNull(types.string),
    estimatedHours: types.optional(types.number, 0),
    unitMake: types.optional(types.string, ''),
    unitModel: types.optional(types.string, ''),
    unitSerial: types.optional(types.string, ''),
    equipmentId: types.optional(types.string, ''),
    problemCode: types.optional(types.string, ''),
    description: types.optional(types.string, ''),
  });

export type IDetailsTabData = Instance<typeof DetailsTabData>;

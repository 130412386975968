import { Instance, SnapshotIn, types } from 'mobx-state-tree';

export const SnackMessageOptions = types.model('SnackMessageOptions', {
  persist: types.boolean,
  variant: types.optional(types.string, 'error'),
});

export const defaultOptions = { persist: false };
export type ISnackMessageOptions = Instance<typeof SnackMessageOptions>;
export type SnackMessageOptionsData = SnapshotIn<ISnackMessageOptions>;

import React, { ChangeEvent, useEffect } from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import { Divider } from '@material-ui/core';
import { observer } from 'mobx-react';

const ALL_TEAMS = 'All Teams';
const NONE = 'No Teams';

interface Props {
  teams: string[];
  selectedTeams: string[];
  onSelectedTeamsChanged: (teams: string[]) => void;
}

function TeamsMultipleSelect(props: Props) {
  const { teams, onSelectedTeamsChanged, selectedTeams } = props;

  const [allTeamsSelected, setAllTeamsSelected] = React.useState<boolean>(
    teams.length !== 0 && teams.length === selectedTeams.length);

  useEffect(() => {
    setAllTeamsSelected(teams.length !== 0 && teams.length === selectedTeams.length);
  });

  const handleSelectAllTeam = (event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    if (checked) {
      setAllTeamsSelected(true);
      onSelectedTeamsChanged(teams);
    } else {
      setAllTeamsSelected(false);
      onSelectedTeamsChanged([]);
    }
  };

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
      const items = event.target.value as string[];
      const selectedValues = items.filter((t) => t !== ALL_TEAMS && t !== NONE);

      if (selectedValues.length === teams.length) {
        setAllTeamsSelected(true);
      } else {
        setAllTeamsSelected(false);
      }

      onSelectedTeamsChanged(selectedValues);
  };

  const getSelectedText = (selected: string[]) => {
      if (selected.includes(NONE)) {
        return NONE;
      }
      if (allTeamsSelected) {
        return ALL_TEAMS;
      }
      return teams.filter((t) => selected.includes(t)).join(', ');
  };

  return (
      <FormControl variant="outlined" fullWidth={true} margin="dense">
        <InputLabel htmlFor="teamid-mutiple-checkbox">Team ID</InputLabel>
        <Select
          id="teamid-mutiple-checkbox"
          multiple
          value={selectedTeams.length > 0 ? selectedTeams : [NONE]}
          onChange={handleChange}
          renderValue={getSelectedText}
          fullWidth={true}
          labelWidth={70}
        >
            <MenuItem key={ALL_TEAMS} value={ALL_TEAMS}>
              <Checkbox color="primary" checked={allTeamsSelected} onChange={handleSelectAllTeam} />
              <ListItemText primary={ALL_TEAMS} />
            </MenuItem>
            <Divider />
            {teams.map((team) => (
                <MenuItem key={team} value={team}>
                  <Checkbox color="primary" checked={selectedTeams.indexOf(team) > -1} />
                  <ListItemText primary={team} />
                </MenuItem>
            ))}
        </Select>
      </FormControl>
  );
}

export default observer(TeamsMultipleSelect);

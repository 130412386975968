import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { Button, makeStyles, createStyles, Theme, Input, InputLabel, FormControl, Grid } from '@material-ui/core';
import { EstimatePartModel } from './domain/EstimatePart';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import ConfirmationDialog from 'components/ConfirmationDialog';
import { IPartStore } from './store/PartStore';
import { IAppStore } from '@shared/store';

interface Props {
  part: EstimatePartModel
  onDeleteClick?: (part: EstimatePartModel) => void;
  PartStore:  IPartStore;
  estimateID: string;
  debtorID: string;
  AppStore: IAppStore;
  isReadOnly: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    partsWrapper: {
      display: 'flex',
      gap: '2rem'
    },
    label: {
      textTransform: 'capitalize'
    }
  }));

export const EstimatePart: React.FC<Props> = observer((props) => {

  const appStore = props.AppStore;
  const { part, onDeleteClick, isReadOnly } = props;
  const classes = useStyles();
  const [confirmationOpen, setConfirmationOpen] = useState(false);

  const handleDeleteClick = async () => {
    await handleSave();
    setConfirmationOpen(true);
  };
  const handleConfirmationClose = async (confirmed: boolean) => {
    setConfirmationOpen(false);
    if (onDeleteClick && confirmed) {
     await onDeleteClick(part);
    }
  };
  const handlePartNrChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    part.setPartNr(props.debtorID, value);
  };
  const handlePartNrCommit = async (event: React.FocusEvent<HTMLInputElement>) => {
    const { value } = event.target;
    await part.setQty(1);
    await part.commitPartNr(props.debtorID, value);
    
  }
  const handleSave = async () => {
    await props.PartStore.save();
  }
  const handlePartDescChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    part.setPartDesc(event.target.value);
  }
  const handleQtyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    part.setQty(Number(event.target.value));
  }
  const handleStdCostChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    part.setStdCost(Number(event.target.value));
  }
  const handleCurrCostChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    part.setCurrCost(Number(event.target.value));
  }
  const handleSellPriceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    part.setSellPrice(Number(event.target.value));
  }
  const handleSupplierChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    part.setSupplier(event.target.value);
  }
  const handleETAChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    part.setETA(event.target.value);
  }
  const handlePartGroupChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    part.setPartGroup(event.target.value);
  }
  const estimateID = props.estimateID;
  part.setEstimateID(estimateID);

  return (
    <>
      <Grid container spacing={1}>
        <Grid item lg={5} md={5} sm={5}>
          <Grid container spacing={1}>
            <Grid item lg={3} md={3} sm={3}>
              <FormControl fullWidth={true}>
                <InputLabel htmlFor="partNr">Part Number</InputLabel>
                <Input
                  margin="dense"
                  value={part.partNr}
                  onChange={handlePartNrChange}
                  onBlur={handlePartNrCommit}
                  inputProps={{ style: { fontSize: 14 } }}
                />
              </FormControl>
            </Grid>
            <Grid item lg={8} md={8} sm={8}>
              <FormControl fullWidth={true}>
                <InputLabel htmlFor="partDesc">Part Description</InputLabel>
                <Input
                  margin="dense"
                  value={part.partDesc}
                  onChange={handlePartDescChange}
                  inputProps={{ style: { fontSize: 14 } }}
                />
              </FormControl>
            </Grid>
            <Grid item lg={1} md={1} sm={1}>
              <FormControl fullWidth={true}>
                <InputLabel htmlFor="qty">Qty</InputLabel>
                <Input
                  margin="dense"
                  value={part.qty}
                  onChange={handleQtyChange}
                  type = 'number'
                  inputProps={{ style: { fontSize: 14 } }}
                />
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Grid item lg={7} md={7} sm={7}>
          <Grid container spacing={1}>
            <Grid item lg={5} md={5} sm={5}>
              <Grid container spacing={1}>

                <Grid item lg={3} md={3} sm={3}>
                  <FormControl fullWidth={true}>
                    <InputLabel htmlFor="stdCost">Std Cost</InputLabel>
                    <Input
                      margin="dense"
                      value={part.stdCost}
                      onChange={handleStdCostChange}
                      inputProps={{ style: { fontSize: 14 } }}
                      disabled
                    />
                  </FormControl>
                </Grid>
                <Grid item lg={3} md={3} sm={3}>
                  <FormControl fullWidth={true}>
                    <InputLabel htmlFor="currCost">Curr Cost</InputLabel>
                    <Input
                      margin="dense"
                      value={part.currCost}
                      onChange={handleCurrCostChange}
                      type = 'number'
                      inputProps={{ style: { fontSize: 14 } }}
                    />
                  </FormControl>
                </Grid>
                <Grid item lg={3} md={3} sm={3}>
                  <FormControl fullWidth={true}>
                    <InputLabel htmlFor="sellPrice">Sell Price</InputLabel>
                    <Input
                      margin="dense"
                      value={part.sellPrice}
                      onChange={handleSellPriceChange}
                      type = 'number'
                      inputProps={{ style: { fontSize: 14 } }}
                    />
                  </FormControl>
                </Grid>
                <Grid item lg={3} md={3} sm={3}>
                  <FormControl fullWidth={true}>
                    <InputLabel htmlFor="qtyOnHand">Qty On Hand</InputLabel>
                    <Input
                      margin="dense"
                      value={part.qtyOnHand}
                      disabled
                      inputProps={{ style: { fontSize: 14 } }}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            <Grid item lg={7} md={7} sm={7}>
              <Grid container spacing={1}>
                <Grid item lg={5} md={5} sm={5}>
                  <FormControl fullWidth={true}>
                    <InputLabel htmlFor="supplier">Supplier</InputLabel>
                    <Input
                      margin="dense"
                      value={part.supplier}
                      onChange={handleSupplierChange}
                      inputProps={{ style: { fontSize: 14 } }}
                    />
                  </FormControl>
                </Grid>
                <Grid item lg={2} md={2} sm={2}>
                  <FormControl fullWidth={true}>
                    <InputLabel htmlFor="eta">ETA</InputLabel>
                    <Input
                      margin="dense"
                      value={part.eta}
                      onChange={handleETAChange}
                      inputProps={{ style: { fontSize: 14 } }}
                    />
                  </FormControl>
                </Grid>
                <Grid item lg={4} md={4} sm={4}>
                  <FormControl fullWidth={true}>
                    <InputLabel htmlFor="group">Group</InputLabel>
                    <Input
                      margin="dense"
                      value={part.partGroup}
                      onChange={handlePartGroupChange}
                      inputProps={{ style: { fontSize: 14 } }}
                    />
                  </FormControl>
                </Grid>
                <Grid item lg={1} md={1} sm={1}>
                  <Button
                    color="primary"
                    className={classes.label}
                    startIcon={<DeleteOutlineIcon />}
                    onClick={handleDeleteClick}
                    disabled={ !appStore.user?.isEstimateEditor === true || isReadOnly}
                  >
                    Delete
                  </Button>
                </Grid>
                <ConfirmationDialog
                  open={confirmationOpen}
                  onClose={handleConfirmationClose}
                  declineButtonTitle="No"
                  confirmButtonTitle="Yes"
                  title="Are you sure you want to delete this item?"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
});
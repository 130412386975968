import { types, Instance } from 'mobx-state-tree';
import { sumBy } from 'lodash';
import { toTwoDecimalNumber } from '@utils';

export const TimeSheetHours = types.model({
  totalPay: types.optional(types.number, 0),                 // Total Pay H
  totalGP: types.optional(types.number, 0),                  // Total GP H
  allocation: types.optional(types.number, 0),               // Alloc Hours
  normalPay: types.optional(types.number, 0),                // Pay T
  overtime: types.optional(types.number, 0),                 // Pay T/H
  doubleTime: types.optional(types.number, 0),               // Pay D
  overtimeDoubleTime: types.optional(types.number, 0),       // Pay D/H
  normalGP: types.optional(types.number, 0),                 // GP T
  overtimeGP: types.optional(types.number, 0),               // GP T/H
  doubleTimeGP: types.optional(types.number, 0),             // GP D
  overtimeDoubleTimeGP: types.optional(types.number, 0)      // GP D/H
})
  .actions((self) => {
    return {
      setTotalPay: (hours: number) => {
        self.totalPay = hours;
      },
      setTotalGP: (hours: number) => {
        self.totalGP = hours;
      },
      setAllocation: (hours: number) => {
        self.allocation = hours;
      },
      setNormalPay: (hours: number) => {
        self.normalPay = hours;
      },
      setOvertime: (hours: number) => {
        self.overtime = hours;
      },
      setDoubleTime: (hours: number) => {
        self.doubleTime = hours;
      },
      setOvertimeDoubleTime: (hours: number) => {
        self.overtimeDoubleTime = hours;
      },
      setNormalGP: (hours: number) => {
        self.normalGP = hours;
      },
      setOvertimeGP: (hours: number) => {
        self.overtimeGP = hours;
      },
      setDoubleTimeGP: (hours: number) => {
        self.doubleTimeGP = hours;
      },
      setOvertimeDoubleTimeGP: (hours: number) => {
        self.overtimeDoubleTimeGP = hours;
      }
    };
  })
  .views((self) => {
    const isTotalPayHValid = () => {
      const totalPay = self.normalPay + self.doubleTime + self.overtime + self.overtimeDoubleTime;
      return totalPay === self.totalPay;
    };

    const isTotalGPValid = () => {
      const totalGP = self.normalGP + self.doubleTimeGP + self.overtimeGP + self.overtimeDoubleTimeGP;
      return totalGP === self.totalGP;
    };

    return {
      get isValid() {
        return isTotalPayHValid() && isTotalGPValid();
      },
      validate() {
        return {
          invalidTotalPayH: !isTotalPayHValid()
            ? 'The total of entered hours should equal to the value in \'Total Pay H\' field'
            : '',
          invalidTotalGP: !isTotalGPValid()
            ? 'The total of entered GP hours should equal to the value in \'Total GP H\' field'
            : ''
        };
      },
      equals(hours) {
        return self.totalPay === hours.totalPay
          && self.totalGP === hours.totalGP
          && self.allocation === hours.allocation
          && self.normalPay === hours.normalPay
          && self.overtime === hours.overtime
          && self.doubleTime === hours.doubleTime
          && self.overtimeDoubleTime === hours.overtimeDoubleTime
          && self.normalGP === hours.normalGP
          && self.overtimeGP === hours.overtimeGP
          && self.doubleTimeGP === hours.doubleTimeGP
          && self.overtimeDoubleTimeGP === hours.overtimeDoubleTimeGP;
      }
    };
  });

export type TimeSheetHoursModel = Instance<typeof TimeSheetHours>;

const emptyHours = () => {
  return {
    totalPay: 0,
    totalGP: 0,
    allocation: 0,
    normalPay: 0,
    overtime: 0,
    doubleTime: 0,
    overtimeDoubleTime: 0,
    normalGP: 0,
    overtimeGP: 0,
    doubleTimeGP: 0,
    overtimeDoubleTimeGP: 0
  } as TimeSheetHoursModel;
};

const sumByProp = (items: TimeSheetHoursModel[], propGetter: (t: TimeSheetHoursModel) => number) => {
  return toTwoDecimalNumber(sumBy(items, (t) => propGetter(t)));
};

export function getTotalHours(items: TimeSheetHoursModel[]) {
  if (!items || items.length === 0) {
    return emptyHours();
  }

  return {
    totalPay: sumByProp(items, (t) => t.totalPay),
    totalGP: sumByProp(items, (t) => t.totalGP),
    allocation: sumByProp(items, (t) => t.allocation),
    normalPay: sumByProp(items, (t) => t.normalPay),
    overtime: sumByProp(items, (t) => t.overtime),
    doubleTime: sumByProp(items, (t) => t.doubleTime),
    overtimeDoubleTime: sumByProp(items, (t) => t.overtimeDoubleTime),
    normalGP: sumByProp(items, (t) => t.normalGP),
    overtimeGP: sumByProp(items, (t) => t.overtimeGP),
    doubleTimeGP: sumByProp(items, (t) => t.doubleTimeGP),
    overtimeDoubleTimeGP: sumByProp(items, (t) => t.overtimeDoubleTimeGP)
  } as TimeSheetHoursModel;
}

import { types, Instance } from 'mobx-state-tree';
import { AdjustmentType } from './AdjustmentType';
import { TimeSheetHours } from '../TimeSheetHours';
import { TimeSheetStatus } from 'components/TimeSheetStatusViewer';

export const PayrollAdjustment = types.model({
  id: types.optional(types.string, ''),
  status: types.enumeration('TimeSheetStatus', [
              TimeSheetStatus.Approved,
              TimeSheetStatus.New,
              TimeSheetStatus.Processed,
              TimeSheetStatus.Rejected,
              TimeSheetStatus.Submitted,
              TimeSheetStatus.Integrated,
              TimeSheetStatus.NoData,
              TimeSheetStatus.Draft,
              TimeSheetStatus.Final
            ]),
  type: types.enumeration('AdjustmentType', [
              AdjustmentType.Time,
              AdjustmentType.OnCallAllowance,
              AdjustmentType.PHOnCallAllowance,
              AdjustmentType.MealAllowance
            ]),
  amount: types.optional(types.number, 0),
  reason: types.optional(types.string, ''),
  hours: TimeSheetHours
})
.actions((self) => {
  const recalculateTotals = () => {
    const totalHours = self.hours.normalPay + self.hours.overtime +
                         self.hours.doubleTime + self.hours.overtimeDoubleTime;

    self.hours.setTotalPay(totalHours);
  };

  return {
    setType: (value: AdjustmentType) => {
      self.type = value;
      if (self.type === AdjustmentType.Time) {
          self.amount = 0;
      } else {
        self.amount = -1;
      }
    },
    setAmount: (value: number) => {
      self.amount = value;
    },
    setReason: (value: string) => {
      self.reason = value;
    },
    setNormalPay: (hours: number) => {
      self.hours.setNormalPay(hours);
      recalculateTotals();
    },
    setOvertime: (hours: number) => {
      self.hours.setOvertime(hours);
      recalculateTotals();
    },
    setDoubleTime: (hours: number) => {
      self.hours.setDoubleTime(hours);
      recalculateTotals();
    },
    setOvertimeDoubleTime: (hours: number) => {
      self.hours.setOvertimeDoubleTime(hours);
      recalculateTotals();
    },
  };
})
.views((self) => {
  
  const isValidAdjustmentReason = () => {
    return (self.status == TimeSheetStatus.New || self.status == TimeSheetStatus.Rejected) ? self.reason != "" : true
  };

  return {
    get isAllowanceType() {
      return self.type === AdjustmentType.OnCallAllowance ||
        self.type === AdjustmentType.PHOnCallAllowance ||
        self.type === AdjustmentType.MealAllowance;
    },
    get isReadOnly() {
      return self.status === TimeSheetStatus.Submitted || self.status === TimeSheetStatus.Approved ||
      self.status === TimeSheetStatus.Processed;
    },
    get canDelete() {
      return self.status === TimeSheetStatus.New ||
        self.status === TimeSheetStatus.Rejected;
    },
    get isAdjustmentReasonValid() {
      return isValidAdjustmentReason();
    },
    validate() {
      return {
        adjustmentReasonMissingError: !isValidAdjustmentReason()
          ? 'Reason is required'
          : '',
      };
    },
    equals(adjustment) {
      return self.id === adjustment.id
      && self.status === adjustment.status
      && self.type === adjustment.type
      && self.amount === adjustment.amount
      && self.reason === adjustment.reason
      && self.hours.equals(adjustment.hours);
    }
  }
});

export type PayrollAdjustmentModel = Instance<typeof PayrollAdjustment>;

import { TableCell, TableRow, Theme, createStyles, makeStyles } from '@material-ui/core';
import { blueGrey, lightBlue } from '@material-ui/core/colors';
import { formatDate_yyyymmdd } from '@utils';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { TakeFiveSummaryModel } from 'take-five-summary/domain/TakeFiveSummary';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      textDecoration: 'none'
    },
    label: {
      textTransform: 'none'
    },
    row: {
      '&:nth-child(even)': {
        backgroundColor: blueGrey[50]
      },
      '&:hover': {
        backgroundColor: lightBlue[100]
      }
    }
  }));

interface Props {
  takeFiveSummary: TakeFiveSummaryModel;
}

export default function TakeFiveSummaryRow(props: Props) {
  const classes = useStyles();
  const { takeFiveSummary } = props;
  const { technicianId, technicianName, totalCompletedTakeFive, startDate, endDate} = takeFiveSummary;

  return (
    <TableRow className={classes.row}>
      <TableCell component="th" scope="row" align="left">
        <NavLink
          className={classes.button}
          to={`take-five-details/${takeFiveSummary.technicianId.trim()}/${formatDate_yyyymmdd(startDate)}/${formatDate_yyyymmdd(endDate)}`}>
        {technicianId}
        </NavLink>
      </TableCell>
      <TableCell component="th" scope="row" align="left">
        {technicianName}
      </TableCell>
      <TableCell align="left">
        {totalCompletedTakeFive}
      </TableCell>
    </TableRow>
  );
}

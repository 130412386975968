import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { Grid, IconButton, Typography, makeStyles, Theme, createStyles, 
    ExpansionPanelDetails, ExpansionPanel, ExpansionPanelSummary } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { PayrollAdjustmentsDetails } from './PayrollAdjustmentsDetails';
import { Totals } from '../Total';
import { PayrollAdjustmentsModel } from 'timesheet-details-v2/domain/payroll-adjustments/PayrollAdjustments';
import { ErrorDetailsIcon } from 'components/ErrorDetailsIcon';

interface Props {
    disabled?: boolean;
    date: Date;
    adjustmentsModel: PayrollAdjustmentsModel;
    validate: () => any;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
        margin: '0 !important'
    },
    summary: {
        '& div:first-child': {
            margin: '0 !important'
        },
        'minHeight': 'auto !important'
    },
    details: {
        padding: '0 !important'
    },
    expandedTotals: {
        paddingRight: 8,
        paddingLeft: '0 !important',
        paddingTop: 30,
        paddingBottom: 15,
    },
    unExpandedTotals: {
        paddingRight: '0 !important',
        paddingLeft: '0 !important',
        paddingTop: theme.spacing(1),
        paddingBottom: 15,
        position: 'absolute',
    },
    headerContainer:{
        position: 'relative',
    },
}));

export const PayrollAdjustments: React.FC<Props> = observer((props) => {
  const [isOpen, setIsOpen] = useState(false);
  const handleExpandCollapseClick = () => {
    setIsOpen(!isOpen);
  };

  const { adjustmentsModel, validate,  disabled, date } = props;

  const classes = useStyles();

  const { adjustmentReasonValidation } = adjustmentsModel.validateReason();
  const errors = [adjustmentReasonValidation].filter((e) => !!e).join('; ');

  const onAddNewAdjustment = () => {
    return adjustmentsModel.addAdjustment(date);
};

  return (
    <ExpansionPanel square expanded={isOpen} className={classes.root}>
        <ExpansionPanelSummary className={classes.summary}>
            <Grid container className={classes.headerContainer}>
                {!isOpen &&
                    <Grid container direction="row" alignItems="center"  className={classes.unExpandedTotals} >
                        <Grid xs={7}/>
                        <Grid xs={1} alignContent='flex-end'>
                            <Typography color="primary" >
                                TOTALS
                            </Typography>
                        </Grid>
                        <Grid  xs={3} >
                            <Totals hours={adjustmentsModel.totalHours} />
                        </Grid>
                    </Grid>
                    }
                <Grid item xs={6}>
                    <Grid container alignItems="center">
                        <Grid item xs={4}>
                            <Typography variant="subtitle1" gutterBottom>
                                {adjustmentsModel.title}
                            </Typography>
                        </Grid>
                        <Grid item xs={1}>
                            <IconButton
                                onClick={handleExpandCollapseClick}
                                aria-expanded={isOpen}
                            >
                                {isOpen ? <ExpandLessIcon/> : <ExpandMoreIcon />}
                            </IconButton>
                        </Grid>
                        <Grid item xs={1}>
                            <ErrorDetailsIcon errors={errors}/>
                        </Grid>
                    </Grid>
                </Grid>
               
            </Grid>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className={classes.details}>
            <Grid container direction="column">
                <PayrollAdjustmentsDetails
                    disabled={disabled}
                    date={date}
                    adjustments={adjustmentsModel.adjustments}
                    onAdd={onAddNewAdjustment}
                    onDelete={adjustmentsModel.deleteAdjustment}
                    validate={validate}
                />
                {isOpen &&
                    <Grid container direction="row" alignItems="center"  className={classes.expandedTotals} >
                        <Grid item={true} xs={7}/>
                        <Grid item={true} xs={1} alignContent='flex-end'>
                            <Typography color="primary" >
                                TOTALS
                            </Typography>
                        </Grid>
                        <Grid item={true} xs={3} >
                            <Totals hours={adjustmentsModel.totalHours} />
                        </Grid>
                    </Grid>
                }
            </Grid>
        </ExpansionPanelDetails>
    </ExpansionPanel>
  );
});

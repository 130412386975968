import { makeStyles, createStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
createStyles({
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '20px 0'
  },
  action: {
    backgroundColor: 'white',
  },
}));

import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { Button, makeStyles, createStyles, Theme, Grid, FormControl, InputLabel, Input } from '@material-ui/core';
import { EstimateTaskModel } from './domain/EstimateTask';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import ConfirmationDialog from 'components/ConfirmationDialog';
import { ITaskStore } from './store/TaskStore';
import { IAppStore } from '@shared/store';

interface Props {
  task: EstimateTaskModel
  onDeleteClick?: (task: EstimateTaskModel) => void;
  TaskStore: ITaskStore;
  estimateID: string;
  AppStore: IAppStore;
  isReadOnly: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tasksWrapper: {
      display: 'flex',
      gap: '2rem'
    },
    label: {
      textTransform: 'capitalize'
    }
  }));

export const EstimateTask: React.FC<Props> = observer((props) => {

  const appStore = props.AppStore;
  const { task, onDeleteClick, isReadOnly } = props;
  const classes = useStyles();
  const [confirmationOpen, setConfirmationOpen] = useState(false);

  const handleDeleteClick = async () => {
    await handleSave();
    setConfirmationOpen(true);
  };
  const handleConfirmationClose = async (confirmed: boolean) => {
    setConfirmationOpen(false);
    if (onDeleteClick && confirmed) {
     await onDeleteClick(task);
    }
  };
  const handleTaskChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    task.setTask(event.target.value);
  };
  const handleTaskTimeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (value === '' || !isNaN(Number(value))) {
      task.setTaskTime(Number(value));
    }
  };
  const handleSave = async () => {
    await props.TaskStore.save();
  }
  const estimateID = props.estimateID;
  task.setEstimateID(estimateID);

  return (
    <form>
      <Grid container spacing={1}>
        <Grid item lg={9} md={9} sm={9}>
          <FormControl fullWidth={true}>
            <InputLabel htmlFor="task">Task Description</InputLabel>
            <Input
              margin="dense"
              value={task.task}
              onChange={handleTaskChange}
            />
          </FormControl>
        </Grid>
        <Grid item lg={2} md={2} sm={2}>
          <FormControl fullWidth={true}>
            <InputLabel htmlFor="time">Time [h]</InputLabel>
            <Input
              margin="dense"
              value={task.taskTime || ''}
              onChange={handleTaskTimeChange}
              type='number'
              required
            />
          </FormControl>
        </Grid>
        <Grid item lg={1} md={1} sm={1}>
          <Button
            color="primary"
            className={classes.label}
            startIcon={<DeleteOutlineIcon />}
            
            onClick={handleDeleteClick}
            disabled={ !appStore.user?.isEstimateEditor === true || isReadOnly}
          >
            Delete
          </Button>
          <ConfirmationDialog
            open={confirmationOpen}
            onClose={handleConfirmationClose}
            declineButtonTitle="No"
            confirmButtonTitle="Yes"
            title="Are you sure you want to delete this item?"
          />
        </Grid>
      </Grid>
    </form >
  );
});
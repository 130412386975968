import React from 'react';
import { sort, SortOrder } from '@utils';
import { IPayrollEnquiryGroup } from 'payroll-adjustment-enquiry/domain/PayrollEnquiryGroup';
import { PayrollAdjustmentEnquiryModel } from 'payroll-adjustment-enquiry/domain/PayrollAdjustmentEnquiry';
import { PayrollEnquiryRow } from './PayrollEnquiryRow';
import { TableBody, TableRow, TableCell } from '@material-ui/core';
import PeopleOutlinedIcon from '@material-ui/icons/PeopleOutlined';
import { IconWithLabel } from '@shared/components/IconWithLabel';
import { observer } from 'mobx-react';
import { useStyles } from './group.style';

interface Props {
  enquiryGroup: IPayrollEnquiryGroup;
  order: SortOrder;
  orderBy: keyof PayrollAdjustmentEnquiryModel;
  showV1Fields: boolean
  showV2Fields: boolean
  showAllFields: boolean
}

export const PayrollEnquiryGroup: React.FC<Props> = observer((props) => {
  const classes = useStyles();
  const { showV1Fields, showV2Fields, showAllFields, enquiryGroup, order, orderBy } = props;

  return (
    <TableBody>
      <TableRow className={classes.row}>
        <TableCell colSpan={15}>
          <IconWithLabel
            className={classes.label} 
            Icon={() => <PeopleOutlinedIcon />}
            text={enquiryGroup.team}
          />
        </TableCell>
      </TableRow>
      {sort<PayrollAdjustmentEnquiryModel>(enquiryGroup.filteredEnquiries, order, orderBy).map((group, index) => (
        <PayrollEnquiryRow
          key={index}
          enquiry={group}
          showV1Fields={showV1Fields}
          showV2Fields={showV2Fields}
          showAllFields={showAllFields}
        />
      ))}
    </TableBody>
  );
});

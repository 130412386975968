import { getEnv, Instance, types, flow } from 'mobx-state-tree';
import { IStoresEnv } from '@core';
import { DataState, DataStateEn } from '@shared/enums';
import { IPayrollAdjustmentEnquiryeStore } from 'payroll-adjustment-enquiry/store/PayrollAdjustmentEnquiryStore';
import { IPayEnquiryStore } from 'pay-enquiry/store/PayEnquiryStore';
import { ICallDetailsStore } from 'call-details/store/CallDetailsStore';

export const APP_INJECTION_KEY = 'AppStore';
export const PAYROLL_INJECTION_KEY = 'payrollStore';
export const PAY_ENQUIRY_KEY = 'payEnquiryStore';
export const CALL_DETAILS_KEY = 'callDetailsStore';

export interface IInjectedAppStore {
  [APP_INJECTION_KEY]: IAppStore;
  [PAYROLL_INJECTION_KEY]: IPayrollAdjustmentEnquiryeStore;
  [PAY_ENQUIRY_KEY]: IPayEnquiryStore;
  [CALL_DETAILS_KEY]: ICallDetailsStore
}

export const AppStore = types
  .model('AppStore', {
    storeState: types.optional(DataStateEn, DataState.notLoaded),
  })
  .views(self => {
    const {
      snackMessenger,
      auth, 
    } = getEnv<IStoresEnv>(self);

    return ({
      get snackMessages () {
        return snackMessenger.snackMessages;
      },
      get userName () {
        return auth.userName;
      },
      get userNameAbbreviation () {
        return auth.userName.split(' ').map(i => i.charAt(0));
      },
      get profilePhoto () {
        return auth.profilePhoto;
      },
      get user () {
        return auth.user;
      },
      get roles () {
        return auth.roles;
      },
      get officeLocation () {
        return auth.officeLocation;
      }
    });
  })
  .actions(self => {
    const { auth, snackMessenger } = getEnv<IStoresEnv>(self);

    return ({
      addSnackMessage: snackMessenger.addSnackMessage,
      removeSnackMessage: snackMessenger.removeSnackMessage,
      loadAppInfo: flow(function* () {
        if(!auth.isAuthenticated && self.storeState === DataState.notLoaded)
        {
          self.storeState = DataState.loading;
          if(!(yield auth.getUserProfile())){
            if(!auth.isAuthenticated){
              auth.logOut();
            }
          }
          
          yield auth.getUserPermissions();
          yield auth.getUserPhoto();
          
          self.storeState = DataState.done;
        }
      })
    });
  });

export type IAppStore = Instance<typeof AppStore>;

import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { Grid, Button, makeStyles, Theme, createStyles } from '@material-ui/core';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import ErrorIcon from '@material-ui/icons/Error';
import red from '@material-ui/core/colors/red';
import TimeSheetStatusViewer, { TimeSheetStatus } from 'components/TimeSheetStatusViewer';
import { ValidationTooltip } from 'components/ValidationTooltip';
import ConfirmationDialog from 'components/ConfirmationDialog';

interface Props {
    canDelete?: boolean;
    status: TimeSheetStatus;
    hasErrors?: boolean;
    errors?: string[];
    onDeleteClick?: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        label: {
            textTransform: 'none'
        },
        deleteIcon: {
            marginRight: theme.spacing(1),
        },
        error: {
            color: red[500]
        }
    }));

export const DetailsHeader: React.FC<Props> = observer((props) => {
    const classes = useStyles();
    const [confirmationOpen, setConfirmationOpen] = useState(false);
    const { canDelete, status, hasErrors, errors, onDeleteClick } = props;

    const handleDeleteClick = () => {
        setConfirmationOpen(true);
    };

    const handleConfirmationClose = (confirmed: boolean) => {
        setConfirmationOpen(false);
        if (onDeleteClick && confirmed) {
            onDeleteClick();
        }
    };

    const errorText = !!errors ? errors.join('; ') : '';

    return (
        <Grid
            container
            item
            direction="row"
            justify="space-between"
            alignItems="center"
        >
            <Grid container spacing={1} item xs={2}>
                <Grid item xs={6}>
                    <TimeSheetStatusViewer status={status} />
                </Grid>
                {hasErrors &&
                    <Grid item>
                        <ValidationTooltip title={errorText || ''} placement="bottom" arrow={true}>
                            <ErrorIcon className={classes.error} />
                        </ValidationTooltip>
                    </Grid>
                }
            </Grid>
            <Grid item>
                <Button
                    disabled={!canDelete}
                    color="primary"
                    onClick={handleDeleteClick}
                    className={classes.label}
                    startIcon={<DeleteOutlinedIcon />}
                >
                    Delete
                </Button>
            </Grid>
            <ConfirmationDialog
                open={confirmationOpen}
                onClose={handleConfirmationClose}
                declineButtonTitle="No"
                confirmButtonTitle="Yes"
                title="Are you sure you want to delete this item?"
            />
        </Grid>
    );
});

import { observer } from 'mobx-react';
import { useStyles } from './tab.style';
import { Grid } from '@material-ui/core';
import React from 'react';
import { TabGridCell } from './TabGridCell';
import { LogListItem } from './LogListItem';
import { ILogsTabData } from './models/LogsTabData';
import { AppointmentStatus } from '@shared/enums/AppointmentStatus';
import { parseISO } from 'date-fns';
import { formatDate, formatTime } from '@utils/dateTime';
import { ITravelRecordModel, IUpdateLogModel } from './models';

interface Props {
  logsData: ILogsTabData;
  showMarker: (lat: number, lng: number) => void;
  calcRoute: (travelRecord: ITravelRecordModel) => void;
}

export const LogsTab: React.FC<Props> = observer((props) => {
  const classes = useStyles();
  const { logsData, showMarker, calcRoute } = props;
  const calcRouteHandle = (travelRecord: ITravelRecordModel) => () => {
    calcRoute(travelRecord)
  }
  const logsClickHandle = (log: IUpdateLogModel) => () => {
    if (log.statusUpdateLocationLatitude !== null && log.statusUpdateLocationLongitude !== null)
      showMarker(log.statusUpdateLocationLatitude, log.statusUpdateLocationLongitude)
  }
  return (
    <>
      <div className={classes.firstTabSection}>
        <div className={classes.tabName}>Travel Log</div>
        <Grid container={true}
          direction="column"
          wrap="nowrap"
        >
          <Grid item={true}>
            {logsData.getTravelRecords.map((record, index) => (
              <Grid
                container={true}
                spacing={2}
                className={classes.gridRow}
                key={index}
                onClick={calcRouteHandle(record)}
              >
                <TabGridCell cellName='Start Time'
                  cellData={record.startTime && `${formatDate(parseISO(record.startTime))} ${formatTime(parseISO(record.startTime))}`}
                  className={classes.gridCellName}
                  title={`lon:${record.startLocationLongitude}\nlat:${record.startLocationLatitude}`}
                />
                <TabGridCell cellName='End Time'
                  cellData={record.endTime && `${formatDate(parseISO(record.endTime))} ${formatTime(parseISO(record.endTime))}`}
                  className={classes.gridCellName}
                  title={`lon:${record.endLocationLongitude}\nlat:${record.endLocationLatitude}`}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </div>
      <div className={classes.bottomTabSection}>
        <div className={classes.tabName}>Update Log</div>
        {logsData.getupdateLogs.map((log, index) => (
          <div
            key={index}
            onClick={logsClickHandle(log)}

            title={`lon:${log.statusUpdateLocationLongitude}\nlat:${log.statusUpdateLocationLatitude}`}
          >
            <LogListItem
              state={AppointmentStatus[log.status]}
              name={log.technician}
              data={(log.statusUpdateTime || '') && `${formatDate(parseISO(log.statusUpdateTime || ''))} ${formatTime(parseISO(log.statusUpdateTime || ''))}`}
              index={index}
            />
          </div>
        ))}
      </div>
      {/* <div className={classes.bottomTabSection} /> */}
    </>
  );
});

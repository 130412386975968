import { observer } from 'mobx-react';
import React from 'react';
import { Grid, makeStyles, Theme, createStyles, TextField, Button, CircularProgress } from '@material-ui/core';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import { UserImportModel } from '../domain/UserImport';

interface Props {
  userImport: UserImportModel;
  onImportUser: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1)
    },
    button: {
      margin: theme.spacing(1)
    }
}));

export const UserImport: React.FC<Props> = observer((props) => {
  const classes = useStyles();

  const { userImport, onImportUser } = props;

  const handleUserEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    userImport.setUserEmail(event.target.value);
  };

  return (
    <Grid container item xs={6} justify="flex-end">
        <Grid item>
          <TextField
              label="User"
              type="search"
              className={classes.textField}
              margin="dense"
              variant="outlined"
              disabled={userImport.isImporting}
              error={!!userImport.error}
              helperText={userImport.error}
              value={userImport.email}
              onChange={handleUserEmailChange}
          />
        </Grid>
        <Grid item xs={4}>
          <Button
              variant="outlined"
              color="primary"
              disabled={userImport.isImporting || !userImport.email}
              className={classes.button}
              onClick={onImportUser}
              startIcon={ userImport.isImporting ? <CircularProgress size={14} /> : <PersonOutlineIcon />}
          >
            ADD NEW USER
          </Button>
        </Grid>
    </Grid>
    );
});

import { IApiStore, EndpointCallParams, ApiResponse } from "api";

type CallDetailsRequest = {
  serviceCallNumber: string;
  appointmentNumber: string;
}
export const getCallDetails = async (
  api: IApiStore,
  params: EndpointCallParams<CallDetailsRequest>,
): Promise<ApiResponse<any>> => {
  var p = params.payload
  const url = `/api/ServiceCall/callDetails?serviceCallNumber=${p.serviceCallNumber}&appointmentNumber=${p.appointmentNumber}`;

  return await api.get(url, params);
};

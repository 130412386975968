export enum TimeSheetLineType {
  Blank = '',
  Travel = 'Travel',
  ServiceCall = 'ServiceCall',
  ServiceCallLostTime = 'ServiceCallLostTime',
  ServiceCallBreakTime = 'ServiceCallBreakTime',
  LostTime = 'LostTime',
  BreakTime = 'BreakTime',
  AnnualLeave = 'AnnualLeave',
  SickLeave = 'SickLeave',
  LongServiceLeave = 'LongServiceLeave',
  CarersLeave = 'CarersLeave',
  WorkersComp = 'WorkersComp',
  LeaveWithoutPay = 'LeaveWithoutPay',
  PublicHoliday = 'PublicHoliday',
  Training = 'Training',
  FDO = 'FDO',
  MDO = 'MDO',
  FDOWorked = 'FDOWorked',
  AnniversaryLeave = 'AnniversaryLeave'
}

import { makeStyles, Theme, createStyles } from "@material-ui/core";
import { lightBlue, blueGrey } from '@material-ui/core/colors';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      textDecoration: 'none'
    },
    label: {
      textTransform: 'none'
    },
    row: {
      '& td': {
        '&:not(:first-child):nth-child(odd)': {
          backgroundColor: blueGrey[50],
        },
      },
      '&:hover': {

        '& td': {
          '&:nth-child(n+3)': {
            backgroundColor: lightBlue[100],
          },
            '&:nth-child(n+3):nth-child(odd)': {
              backgroundColor: lightBlue[100],
            },
        },
      },
    },
    cell: {
      fontSize: '12px',
      borderBottom: 'none',
    },
    technician: {
      marginTop: '5px',

      '& span': {
        fontSize: '12px'
      },
    }
  }));
import { types, Instance } from 'mobx-state-tree';
//import { setDebtorIDAPI } from './CustomerDetails';
//import { setEquipmentIDAPI } from './EquipmentDetails';
//import { setAddressIDAPI } from './AddressDetails';

export const EstimateDetails = types
  .model({   
    ID: types.optional(types.string, ''),
    EstimateID: types.optional(types.number, 0),
    EstimateNr: types.optional(types.string, ''),
    CreatedAt: types.optional(types.string, ''),
    Revision: types.optional(types.number, 0),
    RequestID: types.optional(types.string, ''),
    Priority: types.optional(types.string, ''),
    FleetType: types.optional(types.string, ''),
    Area: types.optional(types.string, ''),
    OriginCallNr: types.optional(types.string, ''),
    DebtorID: types.optional(types.string, ''),
    CustomerName: types.optional(types.string, ''),
    ContactName: types.optional(types.string, ''),
    ContactPhone: types.optional(types.string, ''),
    ContactEmail: types.optional(types.string, ''),
    Location: types.optional(types.string, ''),
    Address: types.optional(types.string, ''),
    Suburb: types.optional(types.string, ''),
    Postcode: types.optional(types.string, ''),
    State: types.optional(types.string, ''),
    EquipmentID: types.optional(types.string, ''),
    Make: types.optional(types.string, ''),
    Model: types.optional(types.string, ''),
    SerialNumber: types.optional(types.string, ''),
    FleetNumber: types.optional(types.string, ''),
    EngineHours: types.optional(types.string, ''),
    JobDescription: types.optional(types.string, ''),
    TimeEst: types.optional(types.string, ''),
    Status: types.optional(types.string, ''),
    Owner: types.optional(types.string, ''),
    Branch: types.optional(types.string, ''),
    EquipmentType: types.optional(types.string, ''),
    SpecialNotes: types.optional(types.string, ''),
    QuotedFor: types.optional(types.string, ''),
    TransportCost: types.optional(types.number, 0),
    TransportSell: types.optional(types.number, 0),
    Freight: types.optional(types.number, 0),
    Enviro: types.optional(types.number, 0),
    Consumables: types.optional(types.number, 0),
    OtherCost: types.optional(types.number, 0),
    AdminFee: types.optional(types.number, 0),
    PriceLevel: types.optional(types.string, ''),
    LabourRate: types.optional(types.number, 0),
    LabourCost: types.optional(types.number, 0),
  })
  .actions((self) => {
    return {
      setDebtorID: (debtorID: string) => {
 //       setDebtorIDAPI(self, debtorID);
      },
      setOriginCallNumber: (originCallNumber: string) => {
        self.OriginCallNr = originCallNumber;
      },
      setAddressID: (debtorID: string, addressID: string)  => {
 //       setAddressIDAPI(self, debtorID, addressID);
      },
      setEquipmentID: (debtorID: string, addressID: string, equipmentID: string)  => {
  //      setEquipmentIDAPI(self, debtorID, addressID, equipmentID);
      },
      setCustomerName: (customerName: string) => {
        self.CustomerName = customerName;
      },
      setAddress: (address: string) => {
        self.Address = address;
      },
      setSuburb: (suburb: string) => {
        self.Suburb = suburb;
      },
      setPostcode: (postcode: string) => {
        self.Postcode = postcode;
      },
      setState: (state: string) => {
        self.State = state;
      },
      setContactName: (contactName: string) => {
        self.ContactName = contactName;
      },
      setContactPhone: (contactPhone: string) => {
        self.ContactPhone = contactPhone;
      },
      setContactEmail: (contactEmail: string) => {
        self.ContactEmail = contactEmail;
      },
      validateContactEmail: (contactEmail: string) => {
        self.ContactEmail = contactEmail;
      },
      setMake: (make: string) => {
        self.Make = make;
      },
      setModel: (model: string) => {
        self.Model = model;
      },
      setSerialNumber: (serialNumber: string) => {
        self.SerialNumber = serialNumber;
      },
      setFleetNumber: (fleetNumber: string) => {
        self.FleetNumber = fleetNumber;
      },
      setEngineHours: (engineHours: string) => {
        self.EngineHours = engineHours;
      },
      setJobDescription: (jobDescription: string) => {
        self.JobDescription = jobDescription;
      },
      setEstimatedLabourHours: (timeEst: string) => {
        self.TimeEst = timeEst;
      },
      setQuotedFor: (quotedFor: string) => {
        self.QuotedFor = quotedFor;
      },
      setSpecialNotes: (specialNotes: string) => {
        self.SpecialNotes = specialNotes;
      },
      setTransportCost: (transportCost: number) => {
        self.TransportCost = transportCost;
      },
      setTransportSell: (transportSell: number) => {
        self.TransportSell = transportSell;
      },
      setFreight: (freight: number) => {
        self.Freight = freight;
      },
      setEnviro: (enviro: number) => {
        self.Enviro = enviro;
      },
      setConsumables: (consumables: number) => {
        self.Consumables = consumables;
      },
      setOtherCost: (otherCost: number) => {
        self.OtherCost = otherCost;
      },
      setAdminFee: (adminFee: number) => {
        self.AdminFee = adminFee;
      },
      setLabourRate: (labourRate: number) => {
        self.LabourRate = labourRate;
      },
    };
  });

export type EstimateDetailsModel = Instance<typeof EstimateDetails>;
import AppBar from '@material-ui/core/AppBar';

import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

import { blueGrey } from '@material-ui/core/colors';
import React from 'react';
import { createMuiTheme, createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { IconButton, Menu, MenuItem, Avatar } from '@material-ui/core';
import { authContext } from 'api';
import { inject, observer } from 'mobx-react';
import { APP_INJECTION_KEY, IAppStore } from '@shared/store';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      }),
      left: theme.spacing(7) + 1,
      width: `calc(100% - ${theme.spacing(7) + 1}px)`,
      backgroundColor: 'white',
      color: blueGrey[600],
      boxShadow: 'none'
    },
    title: {
      textTransform: 'uppercase'
    },
    grow: {
      flexGrow: 1,
    },
    img: {
      width: theme.spacing(15),
      paddingRight: theme.spacing(4),
      backgroundImage: ''
    }
  })
);
interface Props {
  component?: any;
  title?: string;
}

type InjectedProps = {
  AppStore: IAppStore;
};

const Bar: React.FC<Props> = observer((props) => {
  const store = (props as InjectedProps).AppStore;
  const appTheme = createMuiTheme();
  const classes = useStyles(appTheme);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const PageComponent = props.component;

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleMenu = event => {
    setAnchorEl(event.currentTarget);
  };
  return (
    <>
      <AppBar
        position="fixed"
        className={classes.appBar}
      >
        <Toolbar>
          <img className={classes.img} src='https://www.unitedequipment.com.au/wp-content/themes/united_1_1/images/logo.svg'></img>
          <Typography variant="h6" noWrap
            className={classes.title}>
            {props.title}
          </Typography>

          <div className={classes.grow} />
          <div>
            <IconButton
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
            >
              <Avatar src={store.profilePhoto}>{store.userNameAbbreviation}</Avatar>
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={open}
              onClose={handleClose}
            >
              <MenuItem onClick={() => authContext.logOut()}>Logout</MenuItem>
            </Menu>
          </div>
          <Typography>
            {store.userName}
          </Typography>
        </Toolbar>

      </AppBar>
      {PageComponent && <PageComponent />
        || <div />
      }
    </>
  );
})

export default inject(APP_INJECTION_KEY)(Bar);
import { types, Instance } from 'mobx-state-tree';

export const CustomerDetails = types
    .model({
      customerNumber: types.optional(types.string, ''),
      customerName: types.optional(types.string, ''),
      addressCode: types.array(types.string),
    });

export type CustomerDetailsModel = Instance<typeof CustomerDetails>;

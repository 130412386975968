import { Instance, types } from 'mobx-state-tree';

export const CustomerTabData = types
  .model({
    companyName: types.optional(types.string, ''),
    contactName: types.optional(types.string, ''),
    contactPhoneNumber:  types.optional(types.string, ''),
    address: types.maybeNull(types.string),
    status: types.optional(types.number, 0),
    customerAddress:  types.optional(types.string, ''),
  });

export type ICustomerTabData = Instance<typeof CustomerTabData>;

import { IStoresEnv } from "@core";
import { Instance, applySnapshot, flow, getEnv, types } from "mobx-state-tree";
import { TechnicianTeam } from "pay-period-submission/domain";


export const PayPeriodSubmissionStore = types
  .model({
    teams: types.array(TechnicianTeam),
    isLoading: types.optional(types.boolean, false)
  })

  .actions(self => {
    const { api } = getEnv<IStoresEnv>(self);

    return ({
      fetchData: flow(function* fetchData() {
        const teams = yield api.get(`/api/TechnicianTeam`); 
        applySnapshot(self.teams, teams.data);
      }),
      onSubmit: (teamName) => {
        const submitData = flow(function* fetch() {
          self.isLoading = true;

          const response = yield api.post(`/api/Payroll/submit-pay-period?technicianTeam=${teamName}`, {payload: null});

          const { errors, status } = response.data;

          const teams = yield api.get(`/api/TechnicianTeam`); 

          const newTeams = teams.data.map((team, i) => {
            if (team.teamName === teamName) {
              return {
                teamName: teamName,
                numberOfUsers: team.numberOfUsers,
                integratedUpTo: team.integratedUpTo,
                status: status,
                errors: errors

              };
            }  else {
              return self.teams.filter(x=> x.teamName === team.teamName)?.[0];
            }
          });

          applySnapshot(self.teams, newTeams);
          

          self.isLoading = false;
        });

        submitData();
      },
    });
  });

export type IPayPeriodSubmissionStore = Instance<typeof PayPeriodSubmissionStore>;

import { inject, observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { ICallDetailsStore } from './store';
import { CALL_DETAILS_KEY } from '@shared/store/AppStore';
import { useStyles } from './call-details.style';
import { Grid, Paper, LinearProgress, Button, Switch, FormControlLabel } from '@material-ui/core';
import InputTextField from 'components/InputTextField';
import { MapComponent } from './MapComponent';
import { RouteComponentProps, useParams } from 'react-router-dom';


interface PathParams {
  serviceCallNumber: string;
  appointmentNumber: string;
}
interface InjectedProps extends RouteComponentProps<PathParams> {
  callDetailsStore: ICallDetailsStore;
};

const CallDetailsComponent: React.FC<InjectedProps> = observer((props) => {
  const store = (props as InjectedProps).callDetailsStore;
  const classes = useStyles();
  const params = useParams<PathParams>();
  const isFilled = store.isDataFilled;

  useEffect(() => {

    store.resetStore();
    store.setCallDetails(params.serviceCallNumber);
    store.setAppointmentNumber(params.appointmentNumber);
    loadCallDetailsIfDataFilled();
  }, []);

  const handleCallDetailsChange = (value: string) => {
    store.setCallDetails(value)
  };

  const handleAppointmentNumberChange = (value: string) => {
    store.setAppointmentNumber(value);
  };

  const handleShow = () => {
    const reg = new RegExp(/^#\/\w+-\w+/im);
    const result = reg.exec(location.hash);
    if (result) {
      const url = result[0];
      location.hash = `${url}/${store.serviceCallNumber}/${store.appointmentNumber}`;
    } else {
      console.error(`Cant parse URL ${location.hash}`)
    }

    loadCallDetailsIfDataFilled();
  };

  const loadCallDetailsIfDataFilled = () => {
    const isFilled = store.isDataFilled;

    if (isFilled) {
      store.loadCallDetails();
    }

    return isFilled;
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    store.setSatelliteView(event.target.checked)
  };

  return (
    <Grid container={true}
      direction="column"
      wrap="nowrap"
      className={classes.wrapper}
    >
      <Grid item={true}>
        <Paper className={classes.filter}>
          <Grid
            container={true}
            direction="row"
            justify="space-between"
            alignItems="center"
          >
            <Grid item={true}>
              <Grid
                container={true}
                direction="row"
                spacing={2}
                alignItems="center">
                <Grid item={true}>
                  <InputTextField
                    label="Call Details"
                    defaultValue={store.serviceCallNumber}
                    onChanged={handleCallDetailsChange}
                  />
                </Grid>
                <Grid item={true}>
                  <InputTextField
                    label='Appointment Number'
                    defaultValue={store.appointmentNumber}
                    onChanged={handleAppointmentNumberChange}
                  />
                </Grid>

                <Grid item={true}>
                  <Button
                    onClick={handleShow}
                    variant="outlined"
                    color="primary"
                    fullWidth={true}
                  >
                    Show
              </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid item={true}>
              <FormControlLabel
                control={<Switch
                  checked={store.satelliteView}
                  onChange={handleChange}
                  color="primary"
                />}
                label="Satellite view"
              />

            </Grid>
          </Grid>

        </Paper>
      </Grid>
      {store.isLoading && <LinearProgress className={classes.progress} />}
      {!store.isLoading && isFilled && store.callDetails.technician &&
        <MapComponent
          callDetails={store.callDetails}
          onMessage={store.addMessage}
          satelliteView={store.satelliteView}
        />
      }
    </Grid>
  );
});

export const CallDetails = inject(CALL_DETAILS_KEY)(CallDetailsComponent);

import * as React from 'react';

import { BrowserRouter, Switch, Route, Redirect, HashRouter } from 'react-router-dom';
import { Layout } from './Layout';
import { ThemeProvider } from '@material-ui/styles';
import { LinearProgress } from '@material-ui/core';
import { orange } from '@material-ui/core/colors';
import { getAvaliableRoutes } from './Layout/routes';
import Bar from './Layout/Bar';
import { inject, observer } from 'mobx-react';
import { APP_INJECTION_KEY, IAppStore } from '@shared/store';
import { DataState } from '@shared/enums';
import { SnackbarProvider } from 'notistack';
import { snackbarProviderConfig, SnackMessages } from '@shared/components/SnackMessages';
import { createTheme } from '@material-ui/core/styles'
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { IAuthStore } from 'auth';

declare module '@material-ui/core/styles/createTheme' {
  interface Theme {
    status: {
      danger: string;
    };
  }
  // allow configuration using `createMuiTheme`
  interface ThemeOptions {
    status?: {
      danger?: string;
    };
  }
}

interface Props {
}
type InjectedProps = {
  AuthStore: IAuthStore;
  AppStore: IAppStore;
};

const App: React.FC<Props> = observer((props) => {
  const ldClient = useLDClient();
  const store = (props as InjectedProps).AppStore;
  const theme = createTheme({
    status: {
      danger: orange[500],
    },
    palette: {
      type: 'light',
    },
  });

  const routes = getAvaliableRoutes(store.roles)
  const getDefaultPage = () => {
    const defaultPage = store.user && store.user.defaultPage;
    const result = routes.find(t => t.defaultPageName && t.defaultPageName === defaultPage);

    return result && result.path || store.user && routes[0].path || '';
  }

  if (store.storeState === DataState.notLoaded) {
    store.loadAppInfo();
  }
  
  const userId = store.user?.userId
  const ldUser = userId  ? userId.substring(0,userId.indexOf("@")) : "default"
  const ldOfficeLocation = store.officeLocation || "default"

  React.useEffect(() => {
    ldClient?.identify({"kind":"user", "key":ldUser});
    ldClient?.identify({"kind":"officeLocation", "key":ldOfficeLocation});
  },[ldUser, ldOfficeLocation])

  return (
    <div className="app" >

      <SnackbarProvider {...snackbarProviderConfig}>
        <BrowserRouter>
          <HashRouter basename="/">
            <ThemeProvider theme={theme}>
              <Layout>
                {store.storeState == DataState.loading && <LinearProgress />}
                {(store.storeState === DataState.done) &&
                  <Switch >
                    {routes.map((item, index) => {
                      return <Route path={item.path + (item.optionalParams || '')} key={index} exact={false}
                        render={props => (
                          <Bar {...props} title={item.title} component={item.component} />
                        )} />;
                    })}
                    <Route path="*" render={p => (
                      <Redirect to={getDefaultPage()} />)} />
                  </Switch>
                }
                {(store.storeState !== DataState.done) &&
                  <Bar {...props} />
                }
              </Layout>
            </ThemeProvider>
          </HashRouter>
        </BrowserRouter>
        <SnackMessages />
      </SnackbarProvider>
    </div>
  );
})

export default inject(APP_INJECTION_KEY)(App);

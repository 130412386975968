import { Instance, types, applySnapshot, flow, getEnv } from 'mobx-state-tree';
import { IStoresEnv } from '@core';
import { UnallocatedLostTime } from '../domain/UnallocatedLostTime';
import { formatDate_yyyymmdd } from '@utils/dateTime';

const allBranches = 'All';

export const UnallocatedLostTimeStore = types
  .model({
    lostTimeTypes: types.array(types.string),
    selectedLostTimeType: types.optional(types.string, ''),
    branches: types.array(types.string),
    selectedBranch: types.optional(types.string, ''),
    fromDate: types.optional(types.maybeNull(types.Date), new Date().setMonth(new Date().getMonth() - 1)),
    toDate: types.optional(types.maybeNull(types.Date), new Date()),
    unallocatedLostTimeItems: types.array(UnallocatedLostTime),
    isLoading: types.optional(types.boolean, false)
  })
  .actions((self) => {
    const {
      api,
    } = getEnv<IStoresEnv>(self);

    const mapData = (y) => ({
      date: new Date(y.date),
      team: y.team,
      technicianId: y.technicianId,
      technicianName: y.technicianName,
      startDate: new Date(y.startDate),
      endDate: new Date(y.endDate),
      totalGpHours: y.totalGpHours,
      allocatedHours: y.allocatedHours,
      lostTimeType: y.lostTimeType,
      branch: y.branch
    });

    const fetchData = flow(function* dateFetch() {
      if (!self.selectedLostTimeType) {
        applySnapshot(self.unallocatedLostTimeItems, []);
        return;
      }

      self.isLoading = true;

      const apiUrl = `/api/UnallocatedLostTime?lostTimeType=${self.selectedLostTimeType}&branch=${self.selectedBranch === allBranches ? '' : self.selectedBranch}&startDate=${formatDate_yyyymmdd(self.fromDate)}&endDate=${formatDate_yyyymmdd(self.toDate)}`;

      const unallocatedLostTime = yield api.get(apiUrl);
      const result = unallocatedLostTime.data || [];
      applySnapshot(self.unallocatedLostTimeItems, result.map((x) => mapData(x)));

      self.isLoading = false;
    });

    return {
      fetchFilters: flow(function* filtersFetch() {
        self.isLoading = true;

        const lostTimeTypes = yield api.get(`/api/UnallocatedLostTime/lostTimeTypes`);
        applySnapshot(self.lostTimeTypes, lostTimeTypes.data || []);

        const branches = yield api.get(`/api/Branches`);
        applySnapshot(self.branches, branches.data || []);

        self.isLoading = false;
      }),
      selectBranch(branch: string) {
        self.selectedBranch = branch;
        fetchData();
      },
      selectLostType(lostType: string) {
        self.selectedLostTimeType = lostType;
        fetchData();
      },
      selectDateRange(from: Date | null, to: Date | null) {
        self.fromDate = from;
        self.toDate = to;
        fetchData();
      }
    };
  });

export type IUnallocatedLostTimeStore = Instance<typeof UnallocatedLostTimeStore>;

import { types, Instance, getEnv, flow } from "mobx-state-tree";
import { IApiStoreEnv } from "@core";
import { ApiResponse, ApiStore, authContext } from 'api';
import { getUserProfile, getUserConfigurations, getUserPhoto } from "./endpoints/getUserProfile";
import { UserConfiguration } from "setup-page/user-setup/domain/UserConfiguration";
import { UserRoles } from "@shared/enums";

export const AuthStore = types.compose(ApiStore, types
  .model('auth', {
    user: types.maybe(UserConfiguration),
    profilePhoto: types.maybe(types.string),
    userName: types.optional(types.string, ''),
    userId: types.optional(types.string, ''),
    isAuthenticated: types.optional(types.boolean, false),
    officeLocation: types.optional(types.string, ''),
  })
  .views(self => {

    return ({
      get roles() {
        if (self.isAuthenticated && self.user) {
          const teams = self.user.assignedTeams;

          const result: Array<string> = [self.user.isPayrollAdmin && UserRoles.PayrollAdmin || '',
          self.user.isSettingsAdmin && UserRoles.SettingsAdmin || '',
          self.user.isTakeFiveReader && UserRoles.TakeFiveReader || '',
          self.user.isEstimateEditor && UserRoles.EstimateEditor || '',
          teams.some(t => t.isPayrollEnquiry) && UserRoles.PayrollEnquiry || '',
          teams.some(t => t.isTimesheetApprover) && UserRoles.TimesheetApprover || '',
          teams.some(t => t.isTimesheetEditor) && UserRoles.TimesheetEditor || '',
          ].filter(t => t !== '')
          console.log("avaliable roles:", result)
          return result;
        }
        return [];
      },
    });
  })
  .actions(self => {
    const { graphapi, api } = getEnv<IApiStoreEnv>(self);
    return {
      logOut: flow(function* () {
        authContext.logOut();
      }),
      getUserProfile: flow(function* () {
        if (self.isAuthenticated)
          return;
        const response: ApiResponse<{ displayName, mail, officeLocation }> = yield getUserProfile(graphapi, {
          payload: null,
          errorHandler: 'Failed to get user profile',
        });
        if (response.success) {
          self.userName = response.data.displayName;
          self.userId = response.data.mail;
          self.officeLocation = response.data.officeLocation;
          self.isAuthenticated = true;
        }

        return response.success;
      }),
      getUserPermissions: flow(function* () {
        const response: ApiResponse<any> = yield getUserConfigurations(api, {
          payload: null,
          errorHandler: 'Failed to get user configuration',
        },
          self.userId);
        if (response.success) {
          self.user = response.data;
        }

        return response.success;
      }),
      getUserPhoto: flow(function* () {
        const response: ApiResponse<BlobPart> = yield getUserPhoto(graphapi, {
          payload: null
        });
        if (response.success) {
          const url = window.URL || window.webkitURL;
          const blobUrl = url.createObjectURL(response.data);
          self.profilePhoto = blobUrl;
        }
        return response.success
      }),
    };

  }))
export type IAuthStore = Instance<typeof AuthStore>;
import { observer } from 'mobx-react';
import React, { useState } from 'react';
import clsx from 'clsx';
import { Grid, Typography, IconButton, makeStyles, Theme, createStyles,
    ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { TakeFiveLineItemModel } from 'take-five-details/domain/TakeFiveLineItem';
import { TakeFiveLineItemAnswers } from './TakeFiveLineItemAnswers';
import { formatDate_ddmmyyyy } from '@utils';

interface Props {
    index: number;
    details: TakeFiveLineItemModel;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    highlight: {
        backgroundColor: '#f2f5fa'
    },
    root: {
        margin: '0 !important'
    },
    summary: {
        '& div:first-child': {
            margin: '0 !important'
        },
        'minHeight': 'auto !important',
        'padding': '0 !important'
    },
    details: {
        display: 'block',
        padding: '0 !important',
    },
    truncate: {
        'width': '100%',
        'text-overflow': 'ellipsis',
        'overflow': 'hidden'
    },
}));

export const  TakeFiveLineItem: React.FC<Props> = observer((props) => {
  const classes = useStyles();
  const { details } = props;

  const [isOpen, setIsOpen] = useState(false);

  const handleExpandCollapseClick = () => {
    setIsOpen(!isOpen);
  };

  return (
    <ExpansionPanel square expanded={isOpen} className={classes.root}>
        <ExpansionPanelSummary className={clsx({
            [classes.highlight]: props.index % 2 === 1
            }, classes.summary)}
        >
            <Grid container item xs={12}>
                <Grid container direction="row" alignItems="center" item xs={1}>
                    <IconButton
                        onClick={handleExpandCollapseClick}
                        aria-expanded={isOpen}
                    >
                        {isOpen ? <ExpandLessIcon/> : <ExpandMoreIcon />}
                    </IconButton>
                    <Typography variant="subtitle2" gutterBottom>
                        {formatDate_ddmmyyyy(new Date(details.date))}
                    </Typography>
                </Grid>
                <Grid item xs={1} container alignItems="center" >
                    <Typography variant="subtitle2" gutterBottom>
                        {details.time}
                    </Typography>
                </Grid>
                <Grid item xs={3} container alignItems="center" >
                    <Typography variant="subtitle2" className={classes.truncate} gutterBottom>
                        {details.customer}
                    </Typography>
                </Grid>
                <Grid item xs={3} container alignItems="center" >
                    <Typography variant="subtitle2" className={classes.truncate} gutterBottom>
                        {details.address}
                    </Typography>
                </Grid>
                <Grid item xs={1} container alignItems="center">
                    <Typography variant="subtitle2" gutterBottom>
                        {details.serviceCallID}
                    </Typography>
                </Grid>
                <Grid item xs={1} container alignItems="center">
                    <Typography variant="subtitle2" gutterBottom>
                        {details.equipmentID}
                    </Typography>
                </Grid>
                <Grid  item xs={1} container alignItems="center">
                    <Typography variant="subtitle2" gutterBottom>
                        {details.manufacturer}
                    </Typography>
                </Grid>
                <Grid item xs={1} container alignItems="center">
                    <Typography variant="subtitle2" gutterBottom>
                        {details.model}
                    </Typography>
                </Grid>
            </Grid>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className={classes.details}>
        {details.answers.map((answer, index) =>
            <TakeFiveLineItemAnswers
            key={answer.id}
                answer={answer}
            />
        )}
        </ExpansionPanelDetails>
    </ExpansionPanel>
  );
});

import { sumBy, forEach } from 'lodash';
import { types, Instance } from 'mobx-state-tree';
import { TimeSheetHeader, TimeSheetHeaderModel } from './TimeSheetHeader';
import { TimeSheetLines } from './timesheet-lines/TimeSheetLines';
import { GpAdjustments } from 'timesheet-details-v2/domain/gp-adjustments/GpAdjustments';
import { PayrollAdjustments } from 'timesheet-details-v2/domain/payroll-adjustments/PayrollAdjustments';
import { Allocation, AllocationModel } from './../domain/Allocations';
import { TimeSheetStatus } from 'components/TimeSheetStatusViewer';
import { getTotalHours } from './TimeSheetHours';
import { TimeSheetLineModel } from './timesheet-lines/TimeSheetLine';
import { formatISO, format } from 'date-fns';
import { AdjustmentType } from './payroll-adjustments/AdjustmentType';
import { TimelineType } from 'timesheet-details-v2/stores/TimelineType';


const defaultHeader = {
  team: '',
  technicianId: '',
  date: formatISO(new Date()),
  shift: 8,
  mealAllowance: false,
  phOnCall: false,
  onCall: false,
  rejectionReason: '',
  timelineType: TimelineType.Invalid,
  breakTime: 0,
  timesheetDisabled: false,
  status: TimeSheetStatus.New,
  isFdo: false
};

export const TimeSheetDetails = types.model({
  header: types.optional(TimeSheetHeader, defaultHeader),
  timelines: types.optional(TimeSheetLines, { timelines: [] }),
  gpAdjustments: types.optional(GpAdjustments, { adjustments: [] }),
  payrollAdjustments: types.optional(PayrollAdjustments, { adjustments: [] }),
  allocations: types.optional(types.array(Allocation), []),
  maximumCallAdjustmentAllocations: types.optional(types.number, 0),
  errors: types.optional(types.array(types.string), [])
})
  .actions((self) => {
    const deleteAllocation = (allocation: AllocationModel) => {
      self.allocations.remove(allocation);
    };

    return {
      addAllocation: () => {
        self.allocations.push({
          status: TimeSheetStatus.New,
          tempStatus: TimeSheetStatus.Draft
        });
      },
      setTimeSheetHeader(timeSheetHeader: TimeSheetHeaderModel) {
        self.header = timeSheetHeader;
      },
      deleteAllocation,
      deleteTimeLine: (timeline: TimeSheetLineModel) => {
        const allocations = self.allocations.filter((t) => t.timelineId === timeline.id);
        self.timelines.deleteTimeLine(timeline);
        forEach(allocations, (a) => deleteAllocation(a));
        const emptyAllocations = self.allocations.filter((t) => !t.timelineId);
        if (self.timelines.timelines.filter((t) => t.isRecoverableLostTime).length === 0) {
          forEach(emptyAllocations, (a) => deleteAllocation(a));
        }
      },
    };
  })
  .views((self) => {
    const getAllocTime = (timesheetLine: TimeSheetLineModel) => {
      const allocTime = sumBy(self.allocations.filter((a) => a.timelineId === timesheetLine.id),
             (a) => a.allocHours);
      return allocTime;
    };

    const hasChanges = (originalAllocation) => {
      const allocation = self.allocations.find((l) => l.id === originalAllocation.id);
      if (!allocation) {
        return true;
      }

      const isEqual = originalAllocation.equals(allocation);
      return !isEqual;
    };

    const hasChangesInAllocations = (originalAllocations) => {
      return originalAllocations.length !== self.allocations.length
          || originalAllocations.some(hasChanges);
    };

    const isPayrollAdjustmentsValid = () => {
      const { mealAllowance, phOnCall, onCall } = self.header;
      return self.payrollAdjustments.isValid(mealAllowance, phOnCall, onCall) && self.payrollAdjustments.isValidAdjustmentReason() && self.payrollAdjustments.isValidTimeRange() && self.payrollAdjustments.isValidLeaveType();
    };
    
    const isGPAdjustmentsValid = () => {
      return self.gpAdjustments.isValid();
    };
    
    const isTotalPayrollAndGPAdjustmentsValid = (featureFlagEnabled: boolean) => {
      if(!featureFlagEnabled)
        return true;

      return self.gpAdjustments.totalHours.totalGP === self.payrollAdjustments.adjustments.reduce((total, line) =>  {
        if(line.type == AdjustmentType.Time)
        {
          total = total + line.hours.totalPay 
        }

        return total
      }, 0)
    };

    const validateGAdjustments = () => {

      const canAddGPAdjustmentAllocations= self.maximumCallAdjustmentAllocations - (self.gpAdjustments.adjustments.filter(x=> x.tempStatus == TimeSheetStatus.Draft).length + self.allocations.filter(x => x.tempStatus == TimeSheetStatus.Draft).length) > 0;
      const maximumCallAdjustmentAllocations =  canAddGPAdjustmentAllocations ? '' : 'You have reached the maximum number of GP Adjustments/Allocations for the past month. Please contact Central Admin to add more'
      
      const warnings = [maximumCallAdjustmentAllocations];

      return { warnings };
    }

    const validateAllocations = () => {
      const formatTimeline = (timeline) =>
      ( `${format(timeline.startDateTime, 'hh:mm')} - ${format(timeline.endDateTime, 'hh:mm')}`);
      const recoverableTimeLines =
        self.timelines.timelines.filter((t) => t.isRecoverableLostTime);

      const overAllocations = recoverableTimeLines
        .filter((t) => getAllocTime(t) > t.hours.totalGP )
        .map((t) => `TimeLine ${formatTimeline(t)} is overallocated.`);

      const emptyAllocations = recoverableTimeLines
        .filter((t) => getAllocTime(t) <= 0)
        .map((t) => `TimeLine ${formatTimeline(t)} is not allocated, sum of allocations
          must be greater than 0.`);

      const negativeAllocations = recoverableTimeLines
        .filter((t) => getAllocTime(t) < 0)
        .map((t) => `TimeLine ${formatTimeline(t)} is not allocated, sum of allocations
          can not be less than 0.`);

      const canAddGPAdjustmentAllocations= self.maximumCallAdjustmentAllocations - (self.gpAdjustments.adjustments.filter(x=> x.tempStatus == TimeSheetStatus.Draft).length + self.allocations.filter(x=> x.tempStatus == TimeSheetStatus.Draft).length) > 0;
      const maximumCallAdjustmentAllocations =  canAddGPAdjustmentAllocations ? '' : 'You have reached the maximum number of GP Adjustments/Allocations for the past month. Please contact Central Admin to add more.'
      
      const errors = [...overAllocations, ...negativeAllocations];
      if ( self.allocations.length !== 0
        && !self.allocations.every((t) => t.isValid) ) {
          errors.push('Allocations have errors that should be fixed');
      }

      const warnings = [maximumCallAdjustmentAllocations, ...emptyAllocations];

      return { errors, warnings };
    };

    const isAllocationsValid = () => {
      return validateAllocations().errors.length === 0;
    };

    const validatePayrollAdjustments = () => {
      const { mealAllowance, phOnCall, onCall } = self.header;
      return self.payrollAdjustments.validate(mealAllowance, phOnCall, onCall);
    };

    return {
      get recoverableLostTimeLines() {
        return self.timelines.timelines.filter((t) => t.isRecoverableLostTime);
      },
      get totalHours() {
        return getTotalHours([self.timelines.totalHours,
        self.payrollAdjustments.totalHours, self.gpAdjustments.totalHours]);
      },
      getAllocTime,
      hasChanges(originalData) {
        return !self.header.equals(originalData.header) ||
          self.timelines.hasChanges(originalData.timelines) ||
          self.gpAdjustments.hasChanges(originalData.gpAdjustments) ||
          self.payrollAdjustments.hasChanges(originalData.payrollAdjustments) ||
          hasChangesInAllocations(originalData.allocations);
      },
      isValid(skipShiftValidation: boolean, breakTime: number, payrollAndGPHoursValidationFlag: boolean) {
        return self.timelines.isValid(self.header.shift, skipShiftValidation, breakTime)
          && isPayrollAdjustmentsValid()
          && isAllocationsValid() && isGPAdjustmentsValid() && isTotalPayrollAndGPAdjustmentsValid(payrollAndGPHoursValidationFlag);
      },
      totalPayAndGPAdjustmentValidation(featureFlagEnabled: boolean){
        return isTotalPayrollAndGPAdjustmentsValid(featureFlagEnabled) ? '' : 'Total Hours of Payroll Adjustment of type Time and Leave and Total Hours of GP Adjustment must match'
      },
      validatePayrollAdjustments,
      validateAllocations,
      validateGAdjustments,
      hasAnyItemsWithStatus(status: TimeSheetStatus) {
        return self.timelines.timelines.some((x) => x.status === status)
        || self.gpAdjustments.adjustments.some((x) => x.status === status)
        || self.payrollAdjustments.adjustments.some((x) => x.status === status)
        || self.allocations.some((x) => x.status === status);
      },
      hasSubmittedTimesheets() {
        return self.timelines.timelines.some((x) => x.status === TimeSheetStatus.Approved || x.status === TimeSheetStatus.Integrated || x.status === TimeSheetStatus.Submitted || x.status === TimeSheetStatus.Processed)
      },
      get disableAllowances()
      { 
        return self.timelines.timelines.some((t) => t.status === TimeSheetStatus.Submitted || t.status === TimeSheetStatus.Approved || t.status === TimeSheetStatus.Integrated ) 
        || self.gpAdjustments.adjustments.some((t) => t.status === TimeSheetStatus.Submitted || t.status === TimeSheetStatus.Approved || t.status === TimeSheetStatus.Integrated ) 
        || self.payrollAdjustments.adjustments.some((t) => t.status === TimeSheetStatus.Submitted || t.status === TimeSheetStatus.Approved || t.status === TimeSheetStatus.Integrated ) 
        || self.allocations.some((t) => t.status === TimeSheetStatus.Submitted || t.status === TimeSheetStatus.Approved || t.status === TimeSheetStatus.Integrated ) 
      }
    };
});

export type TimeSheetDetailsModel = Instance<typeof TimeSheetDetails>;

import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { Grid, IconButton, Typography, makeStyles, Theme, createStyles,
    ExpansionPanelDetails, ExpansionPanel, ExpansionPanelSummary } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { AllocationsDetails } from './AllocationsDetails';
import { AllocationModel } from 'timesheet-details/domain/Allocations';
import { TimeSheetLineModel } from 'timesheet-details/domain/timesheet-lines/TimeSheetLine';
import { pluralize } from '@utils';
import { ErrorDetailsIcon } from 'components/ErrorDetailsIcon';
import { WarningDetailsIcon } from 'components/WarningDetailsIcon';
import { GpAdjustmentModel } from 'timesheet-details/domain/gp-adjustments/GpAdjustment';

interface Props {
    disabled?: boolean;
    allocations: AllocationModel[];
    adjustments: GpAdjustmentModel[];
    recoverableLostTimeLines: TimeSheetLineModel[];
    date: Date;
    maximumCallAdjustmentAllocations: number;
    onAdd: () => void;
    validate: () => { errors: string[], warnings: string[] };
    onDelete: (allocation: AllocationModel) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
        margin: '0 !important'
    },
    summary: {
        '& div:first-child': {
            margin: '0 !important'
        },
        'minHeight': 'auto !important'
    },
    details: {
        padding: '0 !important'
    }
}));

export const Allocations: React.FC<Props> = observer((props) => {
  const [isOpen, setIsOpen] = useState(false);
  const { allocations, recoverableLostTimeLines, onAdd, onDelete, disabled, date, adjustments, maximumCallAdjustmentAllocations } = props;
  const handleExpandCollapseClick = () => {
    setIsOpen(!isOpen);
  };

  const classes = useStyles();
  const validationResult = props.validate();
  const errors = validationResult.errors.join('; ');
  const warnings = validationResult.warnings.join('; ');

  return (
    <ExpansionPanel square expanded={isOpen} className={classes.root}>
        <ExpansionPanelSummary className={classes.summary}>
            <Grid container>
                <Grid item xs={6}>
                    <Grid container alignItems="center">
                        <Grid item xs={4}>
                            <Typography variant="subtitle1" gutterBottom>
                                {`Allocations ( ${allocations.length}
                                    ${pluralize('event', 's', allocations.length )} )`}
                            </Typography>
                        </Grid>
                        <Grid item xs={1}>
                            <IconButton
                                onClick={handleExpandCollapseClick}
                                aria-expanded={isOpen}
                            >
                                {isOpen ? <ExpandLessIcon/> : <ExpandMoreIcon />}
                            </IconButton>
                        </Grid>
                        <Grid item xs={1}>
                            <ErrorDetailsIcon errors={errors}/>
                            <WarningDetailsIcon warnings={warnings}/>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className={classes.details}>
            <AllocationsDetails
                maximumCallAdjustmentAllocations={maximumCallAdjustmentAllocations}
                disabled={disabled}
                allocations={allocations}
                adjustments={adjustments}
                recoverableLostTimeLines={recoverableLostTimeLines}
                date = {date}
                onAdd={onAdd}
                onDelete={onDelete}
            />
        </ExpansionPanelDetails>
    </ExpansionPanel>
  );
});

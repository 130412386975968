import { Instance, SnapshotIn, types } from 'mobx-state-tree';
import { defaultOptions, SnackMessageOptions } from './SnackMessageOptions';

export const SnackMessage = types.model('SnackMessage', {
  message: types.identifier,
  options: types.optional(SnackMessageOptions, defaultOptions),
});

export type ISnackMessage = Instance<typeof SnackMessage>;
export type SnackMessageData = SnapshotIn<ISnackMessage>;

import { makeStyles, createStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
createStyles({
  map: {
    height: '100%',
  },
  gridContainer: {
    height: '100%',
    flex: 1
  },
  wrapper: {
    height: '100%'
  },
  filter: {
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2)
  },
  progress: {
    width: '100%'
  },
}));
import { types, Instance } from 'mobx-state-tree';
import { PayEnquiry, IPayEnquiry } from './PayEnquiry';
// import { isSameDay, parseISO, isAfter, isBefore } from 'date-fns';
import { round, sumBy } from 'lodash';

export const TechnicianGroup = types
  .model({
    technician: types.optional(types.string, ''),
    technicianId: types.optional(types.string, ''),
    enquiries: types.optional(types.array(PayEnquiry), []),
    selected: types.optional(types.boolean, false),
    fromDate: types.optional(types.Date, 0),
    toDate: types.optional(types.Date, 0),
  })
  .views((self) => ({
    get filteredEnquiries() {
      return self.enquiries
      // .filter((eq) =>eq.processedDate && isSameDay(self.fromDate, parseISO(eq.processedDate)) ||
      // eq.processedDate && isSameDay(self.toDate, parseISO(eq.processedDate)) ||
      // eq.processedDate && isAfter(parseISO(eq.processedDate), self.fromDate) && isBefore(parseISO(eq.processedDate), self.toDate));
    },
  }))
  .actions((self) => {
    return {
      setDateRange (from: Date, to: Date) {
        self.fromDate = from;
        self.toDate = to;
      },
      selectGroup () {
        self.selected = !self.selected;
      },
    };
  })
  .views((self) => {
    return {
      getTotalValue (predicate: AnyFunction) {
        return round(sumBy<IPayEnquiry>(self.filteredEnquiries, predicate), 3);
      },
    };
  });

export type ITechnicianGroup = Instance<typeof TechnicianGroup>;


import Drawer from '@material-ui/core/Drawer';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import blue from '@material-ui/core/colors/blue';
import clsx from 'clsx';
import React from 'react';
import MainMenu from './MainMenu';
import { CssBaseline } from '@material-ui/core';
import {useStyles} from './LayoutStyles'

export function Layout(props) {
  const appTheme = createMuiTheme({
    palette: {
      primary: blue
    }
  });

  const classes = useStyles(appTheme);
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(!open);
  };

  return (
    <ThemeProvider theme={appTheme}>
      <div className={classes.root}>
        <CssBaseline />
        <Drawer
          variant="permanent"
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open
            })
          }}
          open={open}
        >
          <MainMenu onHover={handleDrawerOpen} />
        </Drawer>
        <main className={classes.content}>
          <div className={classes.contentBody}>
            {props.children}
          </div>
        </main>
      </div>
    </ThemeProvider>
  );
}

import { types, Instance } from 'mobx-state-tree';

export const UnallocatedLostTime = types
    .model({
      date: types.Date,
      team: types.string,
      technicianId: types.string,
      technicianName: types.string,
      startDate: types.Date,
      endDate: types.Date,
      totalGpHours: types.optional(types.number, 0),
      allocatedHours: types.optional(types.number, 0),
      lostTimeType: types.string,
      branch: types.string
    });

export type UnallocatedLostTimeModel = Instance<typeof UnallocatedLostTime>;

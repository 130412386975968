import { observer } from 'mobx-react';
import React from 'react';
import {
  Button, Dialog, DialogTitle,
  DialogContent, DialogContentText, DialogActions, TextField
} from '@material-ui/core';

interface Props {
  isOpen: boolean;

  onClose: () => void;
  onConfirmReject: (reason: string) => void;
}

export const RejectDialog: React.FC<Props> = observer((props) => {
  const { onClose, onConfirmReject, isOpen } = props;

  const [reason, setReason] = React.useState('');
  const handleReasonChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setReason(event.target.value);
  };

  const onConfirm = () => {
    onConfirmReject(reason);
  };

  return (
    <>
      <Dialog open={isOpen} onClose={onClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Reject confirmation dialog</DialogTitle>
        <DialogContent>
          <DialogContentText>
            You are about to reject this timesheet, please enter the reason of the rejection.
      </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label="Reason"
            fullWidth={true}
            multiline={true}
            rows="5"
            variant="outlined"
            value={reason}
            onChange={handleReasonChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            Cancel
      </Button>
          <Button onClick={onConfirm} disabled={!reason} color="primary">
            Confirm
      </Button>
        </DialogActions>
      </Dialog>
    </>
  );
});

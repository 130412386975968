import { observer } from 'mobx-react';
import React from 'react';
import { Grid, makeStyles, Theme, createStyles, Typography, Paper } from '@material-ui/core';
import { red, grey } from '@material-ui/core/colors';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

interface Props {
  rejectionReason: string;
  visible?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      padding: theme.spacing(1),
      backgroundColor: red[400],
      color: grey['50'],
    },
    error: {
      paddingLeft: theme.spacing(1)
    },
    title: {
      paddingBottom: theme.spacing(1),
    },
    icon: {
      verticalAlign: 'text-top',
      marginRight: '5px'
    }
  }));

export const RejectionReasonPanel: React.FC<Props> = observer((props) => {
  const classes = useStyles();
  const { rejectionReason } = props;

  if (!rejectionReason) {
    return (<></>);
  }

  return (
    <Paper>
      <Grid
        className={classes.container}
        container={true}
      >
        <Grid item={true} xs={12}>
          <Typography variant="subtitle1" className={classes.title}>
              The Timesheet was rejected with the following reason:
          </Typography>
        </Grid>
        <Grid item={true} xs={12}>
            <Typography noWrap={true} className={classes.error}>
              <FiberManualRecordIcon fontSize="inherit" className={classes.icon} />
              {rejectionReason}
            </Typography>
          </Grid>
      </Grid>
    </Paper>
  );
});

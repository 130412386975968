import { makeStyles, Theme, createStyles } from "@material-ui/core";
import { blueGrey, blue } from '@material-ui/core/colors';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    row: {
      background: blueGrey[50],
    },
    label: {
      padding: 0,
      textTransform: 'none',
      color: blue[500]
    },
  }));

import { types, Instance } from 'mobx-state-tree';
import { formatDate, getDateTime } from '@utils';

export const TimeSheetHeader = types.model({
  id: types.optional(types.string, ''),
  team: types.optional(types.string, ''),
  technicianId: types.optional(types.string, ''),
  date: types.string,
  shift: types.number,
  mealAllowance: types.boolean,
  phOnCall: types.boolean,
  onCall: types.boolean,
  rejectionReason: types.optional(types.string, '')
})
.views((self) => ({
  equals(header) {
    return self.team === header.team
    && self.technicianId === header.technicianId
    && self.date === header.date
    && self.shift === header.shift
    && self.mealAllowance === header.mealAllowance
    && self.phOnCall === header.phOnCall
    && self.onCall === header.onCall;
 },
 get formattedDate() {
   return formatDate(new Date(self.date));
 },
 get getDate() {
  return getDateTime(self.date);
}
}));

export type TimeSheetHeaderModel = Instance<typeof TimeSheetHeader>;

import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import React from 'react';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import ArrowDropDownOutlinedIcon from '@material-ui/icons/ArrowDropDownOutlined';
import { SortOrder, getSortOrder } from '@utils/sort';
import { Checkbox, FormControlLabel } from '@material-ui/core';

export interface HeadCell<T> {
  id: keyof T | 'actions';
  label: string | JSX.Element;
}

type OrderableKey<T> = keyof T | 'actions';

interface Props<T> {
  headCells: Array<HeadCell<T>>;
  onSelectAllClick: () => void;
  numSelected:number;
  rowCount: number;
  order?: SortOrder;
  orderBy?: keyof T;
  cellClassName?: string;
  onSort?: (order: SortOrder, orderBy: keyof T) => void;
}

export default function EnhancedTableHead<T>(props: Props<T>) {
  const { headCells, order, orderBy, onSort, onSelectAllClick, numSelected, rowCount, cellClassName } = props;

  const handleSort = (currentOrder: SortOrder, newOrderBy: OrderableKey<T>, oldOrderBy?: OrderableKey<T>) => {
    if (onSort && newOrderBy !== 'actions') {
      const newSortOrder = newOrderBy === oldOrderBy
        ? getSortOrder(currentOrder)
        : 'desc';
      onSort(newSortOrder, newOrderBy);
    }
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell 
          padding="checkbox" 
          className={cellClassName}
        >
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                indeterminate={numSelected > 0 && numSelected < rowCount}
                checked={numSelected === rowCount}
                onChange={onSelectAllClick}
                inputProps={{ 'aria-label': 'select all desserts' }}
              />}
            label="All"
            labelPlacement="end"
          />
        </TableCell>
        {headCells.map((headCell: HeadCell<T>) => (
          <TableCell
            className={cellClassName}
            key={headCell.id as any}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              style={{fontWeight: 'bold', whiteSpace: 'pre'}}
              active={orderBy === headCell.id && !!order}
              direction={order}
              onClick={() => { handleSort( order, headCell.id, orderBy); }}
              IconComponent={ArrowDropDownOutlinedIcon}
            >
             {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

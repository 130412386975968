import React from 'react';
import { sort, SortOrder } from '@utils';
import { TechnicianGroup } from './TechnicianGroup';
import { TableBody, TableRow, TableCell } from '@material-ui/core';
import PeopleOutlinedIcon from '@material-ui/icons/PeopleOutlined';
import { IconWithLabel } from '@shared/components/IconWithLabel';
import { observer } from 'mobx-react';
import { useStyles } from './group.style';
import { ITechnicianGroup } from 'pay-enquiry/domain/TechnicianGroup';
import { IPayEnquiryGroup } from 'pay-enquiry/domain/PayEnquiryGroup';
import { IPayEnquiry } from 'pay-enquiry/domain/PayEnquiry';

interface Props {
  payEnquiryGroup: IPayEnquiryGroup;
  order: SortOrder;
  orderBy: keyof IPayEnquiry;
  beforeV2: boolean
}

export const PayEnquiryGroup: React.FC<Props> = observer((props) => {
  const classes = useStyles();
  const { payEnquiryGroup, order, orderBy, beforeV2 } = props;

  return (
    <TableBody>
      <TableRow>
        <TableCell colSpan={18} className={classes.cell}>
          <IconWithLabel
            className={classes.label} 
            Icon={() => <PeopleOutlinedIcon />}
            text={payEnquiryGroup.team}
          />
        </TableCell>
      </TableRow>
      {sort<ITechnicianGroup>(payEnquiryGroup.technicianGroups, 'asc', 'technician').map((group, index) => (
        group.filteredEnquiries.length > 0 &&
        <TechnicianGroup
          key={index}
          lastTotalRow={index === payEnquiryGroup.technicianGroups.length - 1}
          order={order}
          orderBy={orderBy}
          technicianGroup={group}
          beforeV2={beforeV2}
        />
      ))}
    </TableBody>
  );
});

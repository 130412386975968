import { Instance, types, applySnapshot, flow, getEnv } from 'mobx-state-tree';
import { IStoresEnv } from '@core';
import { CustomerDetails } from '../domain/CustomerDetails';

export const EstimatingToolStore = types
  .model({
    customerNumber: types.optional(types.string, ''),
    details: types.optional(CustomerDetails, {})
  })
  .actions((self) => { 
    const {
      api,
    } = getEnv<IStoresEnv>(self);

    const fetchData = flow(function* dateFetch() {
      applySnapshot(self.details, {});

      const apiUrl = `/api/ServiceEstimate?customerNumber=${self.customerNumber}`;
      const details = yield api.get(apiUrl);
      console.log(details)
      applySnapshot(self.details, details.data);

    });

    const saveData = flow(function* dateFetch() {
console.log(self.details)
      const response = yield api.post(`/api/ServiceEstimate/save`,
      { payload: self.details });

      console.log(response.data)
     // applySnapshot(self.details, details.data);

    });

    return {
      fetchCustomer(customerNumber: string) {
        self.customerNumber = customerNumber;
        fetchData();
      },
      saveServiceEstimate() {
        saveData();
      },
    };
  });

export type IEstimatingToolStore = Instance<typeof EstimatingToolStore>;

export const getFileNameFromDisposition = (headers: any) => {
  if (!headers) {
    return '';
  }
  const disposition = headers.get('content-disposition');
  if (disposition && disposition.indexOf('attachment') !== -1) {
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = filenameRegex.exec(disposition);
    if (matches != null && matches[1]) {
      return matches[1].replace(/['"]/g, '');
    }
  }

  return "ExelReport.csv";
};

interface SortableItem<K> {
  item: K;
  index: number;
}

function desc<T>(a: T, b: T, sortBy: keyof T) {
  if (b[sortBy] < a[sortBy]) {
    return -1;
  }
  if (b[sortBy] > a[sortBy]) {
    return 1;
  }
  return 0;
}

export type SortOrder = 'asc' | 'desc' | undefined;

export function sort<T>(items: T[], sortOrder: SortOrder, sortBy: keyof T): T[] {
  if (sortOrder === undefined) {
    return items;
  }

  const stabilized: Array<SortableItem<T>> = items.map((item, index) => {
    const result: SortableItem<T> = { item, index };
    return result;
  });

  stabilized.sort((a, b) => {
    const value = sortOrder === 'desc' ? desc(a.item, b.item, sortBy) : desc(a.item, b.item, sortBy) * -1;
    if (value !== 0) {
      return value;
    }

    return a.index - b.index;
  });

  return stabilized.map((el) => el.item);
}

export const getSortOrder = (currentOrder: SortOrder): SortOrder => {
  switch (currentOrder) {
    case 'asc':
      return undefined;
    case 'desc':
      return 'asc';
    case undefined:
      return 'desc';
  }
}

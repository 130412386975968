import { observer } from 'mobx-react';
import React from 'react';
import { Grid, makeStyles, Theme, createStyles, Typography } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import { TimeSheetHoursModel } from 'timesheet-details/domain/TimeSheetHours';

interface Props {
    hours: TimeSheetHoursModel;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        wrapper: {
            padding: '10px, 0px, 10px, 10px'
        },
        label: {
            textTransform: 'capitalize'
        },
        numbers: {
            backgroundColor: grey['300'],
        },
        cell: {
             width: 56,
            backgroundColor: grey['300'],
            textAlign: 'center',
            // paddingLeft: 15,
            // paddingRight: 20,
        }
    }));

export const Totals: React.FC<Props> = observer((props) => {
    const classes = useStyles();
    const { hours } = props;

    return (
        <Grid  item={true} container={true} alignItems="center" spacing={2}>
            {/* <Grid item={true} xs={3}>
                <Typography color="primary" variant="button" display="block">
                    TOTALS
                </Typography>
            </Grid> */}
            
            <Grid item={true} xs={4} />
            <Grid item={true} xs={8}>
                <Grid container direction="row" justify="space-around" alignItems="center">
                    <Typography color="primary" variant="button" display="block" className={classes.cell}>
                        {hours.allocation.toFixed(2)}
                    </Typography>
                    <Typography color="primary" variant="button" display="block" className={classes.cell}>
                        {hours.totalPay.toFixed(2)}
                    </Typography>
                    <Typography color="primary" variant="button" display="block" className={classes.cell}>
                        {hours.totalGP.toFixed(2)}
                    </Typography>
                </Grid>
            </Grid>

            {/* <Typography color="primary" variant="button" display="block" className={classes.cell}>
                        {hours.allocation.toFixed(2)}
                    </Typography>
                    <Typography color="primary" variant="button" display="block" className={classes.cell}>
                        {hours.totalPay.toFixed(2)}
                    </Typography>
                    <Typography color="primary" variant="button" display="block" className={classes.cell}>
                        {hours.totalGP.toFixed(2)}
                    </Typography> */}
                    
            {/* className={classes.numbers} */}
                {/* <Grid  xs={3} > 
                    <Typography color="primary" variant="button" display="block" className={classes.cell}>
                        {hours.allocation.toFixed(2)}
                    </Typography>
                </Grid>
                <Grid  xs={3} >
                    <Typography color="primary" variant="button" display="block" className={classes.cell}>
                        {hours.totalPay.toFixed(2)}
                    </Typography>
                </Grid>
                <Grid  xs={3} >
                    <Typography color="primary" variant="button" display="block" className={classes.cell}>
                        {hours.totalGP.toFixed(2)}
                    </Typography>
                </Grid> */}
                 
                {/*<Grid container className={classes.numbers}  
                    direction="row" justify="space-around">
                    <Typography color="primary" variant="button" display="block" className={classes.cell}>
                        {hours.totalPay.toFixed(2)}
                    </Typography>
                    <Typography color="primary" variant="button" display="block" className={classes.cell}>
                        {hours.totalGP.toFixed(2)}
                    </Typography>
                    <Typography color="primary" variant="button" display="block" className={classes.cell}>
                        {hours.allocation.toFixed(2)}
                    </Typography>
                     <Typography color="primary" variant="button" display="block" className={classes.cell}>
                        {hours.normalPay.toFixed(2)}
                    </Typography>
                    <Typography color="primary" variant="button" display="block" className={classes.cell}>
                        {hours.overtime.toFixed(2)}
                    </Typography>
                    <Typography color="primary" variant="button" display="block" className={classes.cell}>
                        {hours.doubleTime.toFixed(2)}
                    </Typography>
                    <Typography color="primary" variant="button" display="block" className={classes.cell}>
                        {hours.overtimeDoubleTime.toFixed(2)}
                    </Typography>
                    <Typography color="primary" variant="button" display="block" className={classes.cell}>
                        {hours.normalGP.toFixed(2)}
                    </Typography>
                    <Typography color="primary" variant="button" display="block" className={classes.cell}>
                        {hours.overtimeGP.toFixed(2)}
                    </Typography>
                    <Typography color="primary" variant="button" display="block" className={classes.cell}>
                        {hours.doubleTimeGP.toFixed(2)}
                    </Typography>
                    <Typography color="primary" variant="button" display="block" className={classes.cell}>
                        {hours.overtimeDoubleTimeGP.toFixed(2)}
                    </Typography> 
                </Grid>*/}
        </Grid>
    );
});

import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import React from 'react';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import ArrowDropDownOutlinedIcon from '@material-ui/icons/ArrowDropDownOutlined';
import { SortOrder, getSortOrder } from '@utils/sort';

export interface HeadCell<T> {
  id: keyof T | 'actions';
  label: string;
}

type OrderableKey<T> = keyof T | 'actions';

interface Props<T> {
  headCells: Array<HeadCell<T>>;
  order?: SortOrder;
  orderBy?: keyof T;

  onSort?: (order: SortOrder, orderBy: keyof T) => void;
}

export default function SortableTableHead<T>(props: Props<T>) {
  const { headCells, order, orderBy, onSort } = props;

  const handleSort = (currentOrder: SortOrder, newOrderBy: OrderableKey<T>, oldOrderBy?: OrderableKey<T>) => {
    if (onSort && newOrderBy !== 'actions') {
      const newSortOrder = newOrderBy === oldOrderBy
        ? getSortOrder(currentOrder)
        : 'desc';
      onSort(newSortOrder, newOrderBy);
    }
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell: HeadCell<T>) => (
          <TableCell
            key={headCell.id as any}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              style={{fontWeight: 'bold'}}
              active={orderBy === headCell.id && !!order}
              direction={order}
              onClick={() => { handleSort( order, headCell.id, orderBy); }}
              IconComponent={ArrowDropDownOutlinedIcon}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { Grid, makeStyles, Theme, createStyles, Button, InputLabel, Select, FormControl,
    MenuItem } from '@material-ui/core';
import { UserConfigurationModel } from '../domain/UserConfiguration';

interface Props {
    user: UserConfigurationModel;
    teams: string[];
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    row: {
        marginLeft: theme.spacing(6),
        marginBottom: theme.spacing(1)
    },
    formTeamControl: {
        minWidth: 100
    }
}));

export const AddUserTeam: React.FC<Props> = observer((props) => {
    const classes = useStyles();
    const { user, teams } = props;
    const availableTeams = user.availableTeams(teams);

    const [ newTeam, setNewTeam] = useState( 'None' );
    const handleNewTeamChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setNewTeam(event.target.value as string);
    };

    const handleAddNewTeam = () => {
        props.user.addTeam(newTeam);
        setNewTeam('None');
    };

    return (
        <React.Fragment>
            { availableTeams.length > 0 &&
                <Grid container item direction="row" xs={11} className={classes.row}>
                    <Grid item xs={2}>
                        <FormControl variant="outlined" className={classes.formTeamControl}>
                            <InputLabel id="new-team-select-outlined-label">
                                Team
                            </InputLabel>
                            <Select
                                labelId="new-team-select-outlined-label"
                                id="new-team-select-outlined"
                                value={newTeam}
                                margin="dense"
                                onChange={handleNewTeamChange}
                                labelWidth={42}
                            >
                                <MenuItem value="None">
                                    <em>None</em>
                                </MenuItem>
                                { availableTeams.map((team) =>
                                    <MenuItem 
                                        key={`new_team_option${team}_user${user.userId}`}
                                        value={team}
                                    >
                                        {team}
                                    </MenuItem>)
                                }
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={2}>
                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={handleAddNewTeam}
                            disabled={newTeam === 'None'}
                        >
                            ADD NEW
                        </Button>
                    </Grid>
                </Grid>
            }
         </React.Fragment>
  );
});

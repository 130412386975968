import { Instance, types, applySnapshot, flow, getEnv } from 'mobx-state-tree';

import { EstimateParts } from '../domain/EstimateParts';
import { EstimatePartModel } from '../domain/EstimatePart';
import { uuidv4 } from '@utils';
import { IStoresEnv } from '@core';

export const PartStore = types
  .model({
    isLoading: types.optional(types.boolean, false),
    originalSettings: types.optional(EstimateParts, {}),
    parts: types.optional(EstimateParts, {}),
    estimateID: types.optional(types.string, '')
  })
  .actions((self) => {
    const { api } = getEnv<IStoresEnv>(self);

    const mapPart = (x) => ({
      id: x.id || '', 
      partNr: x.partNr || '',
      partDesc: x.partDesc || '',
      estimateID: x.estimateID || '',
      qty: x.qty || 0,
      stdCost: x.stdCost || 0,
      currCost: x.currCost || 0,
      sellPrice: x.sellPrice || 0,
      qtyOnHand: x.qtyOnHand || 0,
      supplier: x.supplier || '',
      eta: x.eta || '',
      partGroup: x.partGroup || ''
    });
    const mapSettings = (x) => ({
      parts: x.parts
    });

    const applyData = (data) => {
        if(data.parts)
        {
          applySnapshot(self.originalSettings,
            { parts: data.parts.map((x) => mapPart(x)) });
          applySnapshot(self.parts,
            { parts: data.parts.map((x) => mapPart(x)) });
        }

        for( let i=1; i<=(10 - self.parts.parts.length); i++)
        {
          const part: any = {
              id: uuidv4()
              };
              self.parts.parts.push(part)
        }
      }
    
    const resetStore = () => {
      applySnapshot(self, {});
    };

    return {
      resetStore,
      fetchData: (estimateID: string) => {
        const quarryData = flow(function* fetch() {
          
          const response = yield api.get(`/api/EstimatePartList/id?estimateNumber=${estimateID}`);
          if (response.status !== 200) {
            return;
          }
            applyData(response.data);
        });

        quarryData();
      },
      save: flow(function* save() {
        self.isLoading = true;
        const response = yield api.post(`/api/EstimatePartList/save`,
            { payload: mapSettings(self.parts) });

        
          applyData(response.data);

        self.isLoading = false;
      }),
      delete: (part: EstimatePartModel) => {
        const deleteRow = flow(function* fetch() {
          self.isLoading = true;

          const response = yield api.post(`/api/EstimatePartList/delete`,
              { payload: mapPart(part) });
              if (response.status === 200) {
                // Remove the deleted task from the store
                self.parts.parts.replace(self.parts.parts.filter(t => t.id !== part.id));
              }
       
          self.isLoading = false;
        })
        deleteRow()
      },
    };
  })
  .views((self) => {
    const hasChanges = (parts) => {  
      const newRecord = parts.filter(
        (t) => !self.originalSettings.parts.some( (x) => t.equals(x))  && t.part != '').length === 0
     
      if(!newRecord) return true

      const isModified = self.originalSettings.equals(parts);
      return !isModified;
    }; 

    const totalCost = () => {
      return self.parts.parts.reduce((total, part) => total + part.qty * part.currCost, 0);
    };

    const totalSellPrice = () => {
      return self.parts.parts.reduce((total, part) => total + part.qty * part.sellPrice, 0);
    }

    return {
      hasChanges,
      totalCost,
      totalSellPrice
    };
  });

export type IPartStore = Instance<typeof PartStore>;

import React from 'react';
import WarningIcon from '@material-ui/icons/Warning';
import amber from '@material-ui/core/colors/amber';
import { WarningTooltip } from 'components/ValidationTooltip';
import { observer } from 'mobx-react';
import { makeStyles, Theme, createStyles } from '@material-ui/core';

interface Props {
  warnings: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    warning: {
    color: amber[500]
  }
}));

export const WarningDetailsIcon: React.FC<Props> = observer((props) => {
  const { warnings } = props;
  const classes = useStyles();

  if (!warnings) {
    return null;
  }

  return (
      <WarningTooltip title={warnings || ''} placement="bottom" arrow={true}>
          <WarningIcon className={classes.warning} />
      </WarningTooltip>
  );
});

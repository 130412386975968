import { inject, observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { STORE_KEYS } from '@core';
import { ILocalFindSetupStore } from './store/LocalFindSetupStore';
import SingleSelect from 'components/SingleSelect';
import { LocalFindTable } from './components/LocalFindTable';
import { debounce } from 'lodash';
import { Grid, LinearProgress, TextField, Paper, makeStyles, createStyles,
   Theme, Button, CircularProgress} from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { LocalFindAddDialog } from './components/LocalFindAddDialog';

interface Props {

}

type InjectedProps = {
  LocalFindSetupStore: ILocalFindSetupStore;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      height: '85%'
    },
    gridContainer: {
      height: 'calc(85% - 115px)',
      display: 'flex',
      flex: 1
    },
    filter: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      padding: theme.spacing(2)
    },
    progress: {
      width: '100%',
      marginTop: theme.spacing(2)
    },
    button: {
      'justify-content': 'flex-start'
    }
  }));

const LocalFindSetupComponent: React.FC<Props> = observer((props) => {
  const store = (props as InjectedProps).LocalFindSetupStore;
  const classes = useStyles();

  useEffect(() => {
    store.fetchBranches();

    return function cleanup() {
      store.resetStore();
    };
  }, []);

  const [displayName, setDisplayName] = useState(store.displayNameText);

  const setDisplayTextSearch = debounce(store.setDisplayText, 400);

  const handleDisplayNameTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setDisplayName(value);
    setDisplayTextSearch(value);
  };

  return (
    <Grid container={true}
      direction="column"
      wrap="nowrap"
      className={classes.wrapper}
    >
      {store.isLoading && <LinearProgress className={classes.progress} />}
      {!store.isLoading &&
        <React.Fragment>
          <Grid item={true}>
            <Paper className={classes.filter}>
              <Grid container direction="column">
                <Grid container={true} spacing={2}>
                  <Grid item={true} lg={2} md={3} xs={6}>
                    <SingleSelect
                      label="Branch"
                      values={store.branches}
                      selectedValue={store.selectedBranch}
                      onChanged={store.selectBranch}
                      error={false}
                    />
                  </Grid>
                  <Grid item={true} lg={2} md={3} xs={6}>
                    <TextField
                      label="Display Name"
                      type="search"
                      margin="dense"
                      variant="outlined"
                      value={displayName}
                      onChange={handleDisplayNameTextChange}
                  />
                  </Grid>
                </Grid>
                <Grid container={true} spacing={2}>
                  <Grid item={true} lg={2} md={3} xs={6}>
                    <Button
                        fullWidth={true}
                        className={classes.button}
                        variant="outlined"
                        color="primary"
                        disabled={false}
                        onClick={store.startAddLocalFind}
                        startIcon={ false ? <CircularProgress size={14} /> : <AddCircleOutlineIcon />}
                    >
                      ADD NEW
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item={true} className={classes.gridContainer}>
            <LocalFindTable
              localFindRecords={store.filteredBranches}
              onDelete={store.deleteLocalFind}
            />
          </Grid>
          <LocalFindAddDialog
            title="Add New Record"
            status={store.createLocalFindStatus}
            branches={store.branches}
            validateLocalFind={store.validateLocalFind}
            open={store.createLocalFindStatus !== 'None'}
            onClose={store.cancelAddLocalFind}
            onOk={store.addLocalFind}
          />
        </React.Fragment>
      }
    </Grid>
  );
});

export const LocalFindSetup = inject(STORE_KEYS.LocalFindSetupStore)(LocalFindSetupComponent);

import { LocalFindModel } from './../domain/LocalFind';
import { Instance, types, applySnapshot, flow, getEnv } from 'mobx-state-tree';
import { LocalFind } from '../domain/LocalFind';
import { IStoresEnv, INotificationEnv } from '@core';

export const LocalFindSetupStore = types
  .model({
    selectedBranch: types.optional(types.string, ''),
    branches: types.array(types.string),
    displayNameText: types.optional(types.string, ''),
    isLoading: types.optional(types.boolean, false),
    createLocalFindStatus: types.optional(types.string, 'None'),
    localFindRecords: types.array(LocalFind),
  })
  .actions((self) => {
    const { api } = getEnv<IStoresEnv>(self);
    const { snackMessenger } = getEnv<INotificationEnv>(self);

    const mapLocalFind = (localFind) => ({
      id: localFind.id,
      branchName: localFind.branchName,
      displayName: localFind.displayName,
      searchText: localFind.searchText || ''
    });

    const resetStore = () => {
      applySnapshot(self, {});
    };

    return {
      resetStore,
      fetchBranches: flow(function* fetchBranches() {
        self.isLoading = true;
        applySnapshot(self.localFindRecords, []);
        const localFindRecordsResponse = yield api.get(`/api/LocalFindRecords`);
        if ( localFindRecordsResponse.status !== 200 ) {
          snackMessenger.addSnackMessage({ message: 'Fail to fetch local find records.' });
          return;
        }
        applySnapshot(self.localFindRecords, localFindRecordsResponse.data.map(mapLocalFind));

        const branchesResponse = yield api.get(`/api/branches`);
        if ( localFindRecordsResponse.status !== 200 ) {
          self.isLoading = false;
          snackMessenger.addSnackMessage({ message: 'Fail to fetch branches.' });
          return;
        }
        applySnapshot(self.branches, branchesResponse.data);

        self.isLoading = false;
      }),
      deleteLocalFind: flow(function* deleteLocalFind(localFind: LocalFindModel) {
        localFind.setIsDeleting(true);
        const response = yield api.delete(`/api/LocalFindRecords/${localFind.id}`);
        if (response.status !== 204) {
          localFind.setIsDeleting(false);
          snackMessenger.addSnackMessage({ message: 'Fail to delete record.' });
          return;
        }
        self.localFindRecords.remove(localFind);
      }),
      addLocalFind: flow(function* addLocalFind(localFind: LocalFindModel) {
        self.createLocalFindStatus = 'Saving';
        const createLocalFindResponse = yield api.post(`/api/LocalFindRecords`, { payload: localFind });
        if (createLocalFindResponse.status !== 200) {
          self.createLocalFindStatus = 'Creating';
          snackMessenger.addSnackMessage({ message: 'Fail to create record.' });
          return;
        }

        self.createLocalFindStatus = 'None';
        self.localFindRecords.push(mapLocalFind(createLocalFindResponse.data));
      }),
      cancelAddLocalFind() {
        self.createLocalFindStatus = 'None';
      },
      startAddLocalFind() {
        self.createLocalFindStatus = 'Creating';
      },
      selectBranch(branchName) {
        self.selectedBranch = branchName;
      },
      setDisplayText(text) {
        self.displayNameText = text;
      }
    };
  })
  .views((self) => ({
    get filteredBranches() {
      return self.localFindRecords
        .filter((b) => !self.displayNameText || b.displayName.indexOf(self.displayNameText) > -1)
        .filter((b) => !self.selectedBranch || b.branchName === self.selectedBranch );
    },
    validateLocalFind(localFind: LocalFindModel) {
      const duplicateIndex = self.localFindRecords
        .findIndex((t) => t.branchName === localFind.branchName && t.displayName === localFind.displayName);

      return {
        duplicateError: duplicateIndex !== -1 ? 'The same local find already exists' : ''
      };
    }
  }));

export type ILocalFindSetupStore = Instance<typeof LocalFindSetupStore>;

import { observer } from 'mobx-react';
import React from 'react';
import { EstimateStatusesModel } from './domain/Statuses';
import { EstimateStatus } from './Status';
import CloudDownloadOutlinedIcon from '@material-ui/icons/CloudDownloadOutlined';
import { Button, makeStyles, createStyles, Theme } from '@material-ui/core';
import { EstimateStatusModel } from './domain/Status';

interface Props {
    EstimateStatusModel: EstimateStatusesModel;
    statuses: EstimateStatusModel[];
    hasChanges: boolean;
    onSave: (statuses: EstimateStatusModel[]) => void;
    onDelete: (status: EstimateStatusModel) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    statusWrapper: {
      background: '#fff',
      padding: '2rem'
    },
    label: {
      textTransform: 'capitalize'
    },
    newLineBtn: {
      marginTop: '1rem'
    },
    saveBtn: {
      textAlign: 'right'
    }
}));
export const EstimateStatuses: React.FC<Props> = observer((props) => {

const { EstimateStatusModel, statuses, hasChanges} = props;
const classes = useStyles();

const onAddNewTimeline = () => {
    return EstimateStatusModel.addNewLine();
};

const handleSaveClick = () => {
  props.onSave(statuses);
};

const handleDelete = (status) => {
  props.onDelete(status);
};

  return (
    <div className={classes.statusWrapper}>
      <div className={classes.saveBtn}>
      <Button
          color="primary"
          disabled={!hasChanges}
          className={classes.label}
          startIcon= {<CloudDownloadOutlinedIcon/>} //{user.isSaving ? <CircularProgress size={14} /> : <CloudDownloadOutlinedIcon />}
          onClick={handleSaveClick}
      >
          Save
      </Button></div>
      { statuses.map((line, index) =>
        <EstimateStatus 
        key={line.id}
        status={line}
        onDeleteClick={handleDelete}/>
      )}
      <Button
        variant="outlined"
        color="primary"
        onClick={onAddNewTimeline}
        className={classes.newLineBtn}
      >
        ADD NEW LINE
      </Button>
    </div>
  );
});
import { observer } from 'mobx-react';
import React from 'react';
import { Grid, Button, createStyles, makeStyles, Theme, Popover, Typography, Divider } from '@material-ui/core';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import { ServiceCallDetailsModel } from '../domain/ServiceCallDetails';
import { ServiceCallDetailsForm } from './ServiceCallDetailsForm';

interface Props {
  details: ServiceCallDetailsModel | null;
  notes: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    label: {
      textTransform: 'none'
    },
    detailsFrom: {
      width: 650,
      height: 450,
      padding: theme.spacing(2),
      display: 'block'
    }
  }));

export const ServiceCallDetailsPopup: React.FC<Props> = observer((props) => {
  const classes = useStyles();
  const { details, notes } = props;

  return (
    <Grid container={true}>
      <Grid item={true} xs={12}>
        <PopupState variant="popover">
          {(popupState: any) => (
            <>
              <Button
                {...bindTrigger(popupState)}
                color="primary"
                className={classes.label}
              >
                More Details
              </Button>
              <Popover
                {...bindPopover(popupState)}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
              >
                <Grid container={true} className={classes.detailsFrom}>
                  <Grid item={true} xs={12}>
                    <Typography variant="subtitle1">
                      DETAILS
                    </Typography>
                    <Divider />
                  </Grid>
                  <Grid item={true} xs={12}>
                    <ServiceCallDetailsForm details={details} notes={notes}/>
                  </Grid>
                </Grid>
              </Popover>
            </>
          )}
        </PopupState>
      </Grid>
    </Grid>
  );
});

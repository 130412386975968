import { flow, getEnv } from 'mobx-state-tree';
import { IStoresEnv } from '@core';



export const setDebtorIDAPI = (model: any, debtorID: string) => {
  const { api } = getEnv<IStoresEnv>(model);
  const fetchData = flow(function* fetch() {
    const debtorIDResponse = yield api.get(
      `/api/ServiceEstimate/DebtorDetails?debtorID=${encodeURIComponent(debtorID)}`);
    if (debtorIDResponse.status === 204) {
      model.CustomerName = '';
      model.AdminFee = 0;
      model.PriceLevel = '';
      model.DebtorIDError = `Debtor doesn't exist`
    } else if (debtorIDResponse.status === 200) {
        model.CustomerName = debtorIDResponse.data.customerName;
        model.AdminFee = debtorIDResponse.data.adminFee;
        model.PriceLevel = debtorIDResponse.data.priceLevel;
        model.AddressIDs = debtorIDResponse.data.addressIDs.map((x) => x.addressID) || [];
        model.DebtorIDError = '';
    } else {
      model.CustomerName = '';
      model.AdminFee = 0;
      model.PriceLevel = '';
      model.DebtorIDError = `Failure retrieving debtor details`;
    }
  });
  fetchData();
};

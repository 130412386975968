import { observer } from 'mobx-react';
import { Grid } from '@material-ui/core';
import React from 'react';
import { NoData } from '@shared/components/NoData/NoData';

interface Props {
  cellName: string;
  cellData: string | null;
  className?: string;
  title?: string;
}

export const TabGridCell: React.FC<Props> = observer((props) => {
  const dataCell = (
    <Grid item xs={6} title={props.title}>
      <div className={props.className}>{props.cellName}</div>
      <div>{props.cellData}</div>
    </Grid>
  );

  const noData = (
    <Grid item xs={6}>
      <div className={props.className}>{props.cellName}</div>
      <NoData />
    </Grid>
  );

  return props.cellData ? dataCell : noData;
});

import { types, Instance } from 'mobx-state-tree';
import { TechnicianGroup } from './TechnicianGroup';

export const PayEnquiryGroup = types
  .model({
    team: types.optional(types.string, ''),
    technicianGroups: types.optional(types.array(TechnicianGroup), []),
    fromDate: types.optional(types.Date, 0),
    toDate: types.optional(types.Date, 0),
    allSelected: types.optional(types.boolean, false),
  })
  .views((self) => ({
    get amountOfSelectedItems() {
      return self.technicianGroups.filter(item => item.selected).length;
    }
  }))
  .actions((self) => {
    return {
      setDateRange(from: Date, to: Date) {
        self.fromDate = from;
        self.toDate = to;
        // self.technicianGroups.forEach(group => group.setDateRange(self.fromDate, self.toDate));
      },
      selectAllItems() {
        self.allSelected = !self.allSelected;
        self.technicianGroups.forEach(item => item.selected = self.allSelected);
      }
    };
  });

export type IPayEnquiryGroup = Instance<typeof PayEnquiryGroup>;

import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import React from 'react';
import { Button } from '@material-ui/core';
import { TimesheetSummaryModel } from 'timesheet-summary/domain/TimesheetSummary';
import RemoveRedEyeOutlinedIcon from '@material-ui/icons/RemoveRedEyeOutlined';
import { blueGrey, lightBlue } from '@material-ui/core/colors';
import { NavLink } from 'react-router-dom';
import TimeSheetStatusViewer from 'components/TimeSheetStatusViewer';
import { formatDate_yyyymmdd } from '@utils/dateTime';
import { useFlags } from 'launchdarkly-react-client-sdk';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      textDecoration: 'none'
    },
    label: {
      textTransform: 'none'
    },
    row: {
      '&:nth-child(even)': {
        backgroundColor: blueGrey[50]
      },
      '&:hover': {
        backgroundColor: lightBlue[100]
      }
    }
  }));

interface Props {
  summary: TimesheetSummaryModel;
}

export default function SummaryRow(props: Props) {
  const classes = useStyles();
  const { summary } = props;
  const flags = useFlags();
  const cutOverDate = flags.smartServicePayrollMetricsCutoverDate

  return (
    <TableRow key={summary.technicianId} className={classes.row}>
      <TableCell component="th" scope="row" align="left">
        {summary.technicianName}
      </TableCell>
      <TableCell align="left">
        <TimeSheetStatusViewer status={summary.status} />
      </TableCell>
      <TableCell align="left">{summary.gpHours}</TableCell>
      <TableCell align="left">{summary.payrollHours}</TableCell>
      <TableCell align="right">
        <NavLink
          className={classes.button}
          to={Date.parse(formatDate_yyyymmdd(new Date(summary.date))) < Date.parse(formatDate_yyyymmdd(new Date(cutOverDate))) ? 
            `/timesheet-details/${summary.technicianId}/${formatDate_yyyymmdd(summary.date)}` 
            : `/timesheet-details-v2/${summary.technicianId}/${formatDate_yyyymmdd(summary.date)}`}>
          <Button
            className={classes.label}
            color="primary"
            startIcon={<RemoveRedEyeOutlinedIcon />}
          >
            View on Timesheet Details
          </Button>
        </NavLink>
      </TableCell>
    </TableRow>
  );
}

import { types, Instance } from 'mobx-state-tree';
import { DefaultPage, DefaultPages } from './DefaultPages';

export const UserTeamConfiguration = types
    .model({
        id: types.optional(types.string, ''),
        teamName: types.string,
        userId: types.string,
        isTimesheetCreator: types.optional(types.boolean, false),
        isTimesheetEditor: types.optional(types.boolean, false),
        isTimesheetApprover: types.optional(types.boolean, false),
        isPayrollEnquiry: types.optional(types.boolean, false)
    })
    .actions((self) => {
        return {
            setTimesheetCreator(isTimesheetCreator) {
                self.isTimesheetCreator = isTimesheetCreator;
            },
            setTimesheetEditor(isTimesheetEditor) {
                self.isTimesheetEditor = isTimesheetEditor;
            },
            setTimesheetApprover(isTimesheetApprover) {
                self.isTimesheetApprover = isTimesheetApprover;
            },
            setPayrollEnquiry(isPayrollEnquiry) {
                self.isPayrollEnquiry = isPayrollEnquiry;
            }
        };
    })
    .views((self) => ({
        equals(team) {
            return self.teamName === team.teamName
                && self.userId === team.userId
                && self.isTimesheetCreator === team.isTimesheetCreator
                && self.isTimesheetEditor === team.isTimesheetEditor
                && self.isTimesheetApprover === team.isTimesheetApprover
                && self.isPayrollEnquiry === team.isPayrollEnquiry;
        },
      }));

export type UserTeamConfigurationModel = Instance<typeof UserTeamConfiguration>;

export const UserConfiguration = types
    .model({
        name: types.string,
        id: types.string,
        userId: types.string,
        isSettingsAdmin: types.optional(types.boolean, false),
        isPayrollAdmin: types.optional(types.boolean, false),
        isTakeFiveReader: types.optional(types.boolean, false),
        isEstimateEditor: types.optional(types.boolean, false),
        defaultPage: types.optional(types.string, DefaultPage),
        assignedTeams: types.array(UserTeamConfiguration),
        isSaving: types.optional(types.boolean, false),
    })
    .actions((self) => {
        const setPayrollAdmin = (isPayrollAdmin) => {
            self.isPayrollAdmin = isPayrollAdmin;
        };

        const setSettingsAdmin = (isSettingsAdmin) => {
            self.isSettingsAdmin = isSettingsAdmin;
        };

        const setTakeFiveReader = (isTakeFiveReader) => {
            self.isTakeFiveReader = isTakeFiveReader;
        };

        const setEstimateEditor = (isEstimateEditor) => {
            self.isEstimateEditor = isEstimateEditor;
        };

        const setDefaultPage = (page) => {
            if (page === DefaultPages.UserSetup) {
                setSettingsAdmin(true);
            }

            if (page === DefaultPages.PayrollAdjustments) {
                setPayrollAdmin(true);
            }
            self.defaultPage = page;
        };
        return {
            deleteTeam(team) {
                self.assignedTeams.remove(team);
            },
            addTeam(teamName) {
                self.assignedTeams.push({
                    teamName,
                    userId: self.id
                });
            },
            setIsSaving(isSaving) {
                self.isSaving = isSaving;
            },
            setDefaultPage,
            setPayrollAdmin,
            setSettingsAdmin,
            setTakeFiveReader,
            setEstimateEditor
        };
    })
    .views((self) => ({
        get teamsCount() {
          return self.assignedTeams.length;
        },
        getTeamNames() {
            return self.assignedTeams.map((t) => t.teamName);
        },
        availableTeams(allTeams: string[]) {
            const teamNames = self.assignedTeams.map((t) => t.teamName);
            return allTeams.filter((t) => teamNames.indexOf(t) < 0);
        },
        equals(user) {
            return self.defaultPage === user.defaultPage
            && self.isSettingsAdmin === user.isSettingsAdmin
            && self.isPayrollAdmin === user.isPayrollAdmin
            && self.isTakeFiveReader === user.isTakeFiveReader
            && self.isEstimateEditor === user.isEstimateEditor
            && self.assignedTeams.length === user.assignedTeams.length
            && self.assignedTeams.filter(
                (t) => !user.assignedTeams.some( (x) => t.equals(x))).length === 0;
        }
      }));

export type UserConfigurationModel = Instance<typeof UserConfiguration>;

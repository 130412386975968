import { STORE_KEYS } from "@core";
import { inject, observer } from "mobx-react";
import React, { useCallback, useEffect } from 'react';
import { TextField, Grid, makeStyles, Theme, createStyles, Paper, LinearProgress, Checkbox, FormControlLabel, debounce } from '@material-ui/core';
import { EstimatesTable } from "./components/EstimatesTable";
import { IEstimatesListStore } from './store/EstimatesListStore';
import SingleSelectWithKey from 'components/SingleSelectWithKey';


interface Props {

}

type InjectedProps = {
  EstimatesListStore: IEstimatesListStore;
};


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      height: '85%'
    },
    gridContainer: {
      height: 'calc(85% - 115px)',
      display: 'flex',
      flex: 1
    },
    filter: {
      display: "flex",
      alignItems: "center", // Vertically centers children
      padding: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    select: {
      marginRight: theme.spacing(2), // Adds some space between the dropdown and the button
    },
    progress: {
      width: '100%',
      marginTop: theme.spacing(2)
    },
    button: {
      'justify-content': 'flex-start'
    },
    link: {
      textDecoration: 'none',
      color: theme.palette.primary.main
    }
  }));



const EstimatesListComponent: React.FC<Props> = observer((props) => {
  const store = (props as InjectedProps).EstimatesListStore;
  const classes = useStyles();


  useEffect(() => {
    store.getStatuses();
    store.getAllEstimates(status);
    store.setInitialCheckboxState();
  }, []);


    const handleCheckboxChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
      store.setChecked(event.target.checked);
      await store.getAllEstimates(store.status);
    };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    store.setSearchPhrase(event.target.value);
    debounceSearchChange(event.target.value);
  }

  const debounceSearchChange = useCallback(
    debounce((value: string) => {
      store.validateSearchPhrase(value);
    }, 1000),
    []
  );


  return (
    <div>
      <Paper className={classes.filter}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={1}>
            <SingleSelectWithKey
              label="Status"
              values={store.statusesList}
              selectedValue={store.status}
              onChanged={store.selectStatus}
              error={false}
            />
          </Grid>
          <Grid item xs={1}>
            <TextField label="Search" name="search" value={store.searchPhrase} onChange={handleSearchChange} fullWidth />
          </Grid>
          <Grid item xs={9}></Grid>
          <Grid item xs={1}>
            <FormControlLabel
              control={
                <Checkbox
                checked={store.isChecked } 
                  onChange={handleCheckboxChange}
                  disabled={!store.isEstimateEditor}
                />
              }
              label="Show my estimates only"
            />
          </Grid>

        </Grid>
      </Paper>
      <Paper className={classes.filter}>
        <Grid item={true} className={classes.gridContainer}>
          {store.isLoading && <LinearProgress className={classes.progress} />}
          {!store.isLoading && <EstimatesTable estimatesList={store.estimatesList} />}
        </Grid>
      </Paper>
    </div>
  );
});

export const EstimatesList = inject(STORE_KEYS.EstimatesListStore)(EstimatesListComponent);

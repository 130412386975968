import { IApiStore, EndpointCallParams, ApiResponse } from "api";

export type PayEnquiryRequest = {
  fromDate: Date
  toDate: Date
}
export const getPayEnquiries = async (
  api: IApiStore,
  params: EndpointCallParams<PayEnquiryRequest>,
): Promise<ApiResponse<any>> => { //TODO: add type
  const p = params.payload
  const url = `/api/payEnquiries?fromDate=${p.fromDate.toDateString()}&toDate=${p.toDate.toDateString()}`;

  return await api.get(url, params);
};

import { types, Instance } from 'mobx-state-tree';
import { TimeSheetStatus } from 'components/TimeSheetStatusViewer';

export const TimesheetSummary = types
    .model({
        technicianId: types.string,
        technicianName: types.string,
        status: types.enumeration('TimeSheetStatus', [TimeSheetStatus.Approved,
            TimeSheetStatus.New, TimeSheetStatus.Processed,
            TimeSheetStatus.Rejected, TimeSheetStatus.Submitted, TimeSheetStatus.NoData]),
        team: types.string,
        gpHours: types.optional(types.number, 0),
        payrollHours: types.optional(types.number, 0),
        date: types.Date
    });

export type TimesheetSummaryModel = Instance<typeof TimesheetSummary>;

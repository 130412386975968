import { Button, Checkbox, TableCell, TableRow, Theme, createStyles, makeStyles } from '@material-ui/core';
import { blueGrey, lightBlue } from '@material-ui/core/colors';
import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import RemoveRedEyeOutlinedIcon from '@material-ui/icons/RemoveRedEyeOutlined';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      textDecoration: 'none'
    },
    label: {
      textTransform: 'none'
    },
    row: {
      '&:nth-child(even)': {
        backgroundColor: blueGrey[50]
      },
      '&:hover': {
        backgroundColor: lightBlue[100]
      }
    }
  }));

export default function CommonJobsSummaryRow() {
  const classes = useStyles();
const [request, setRequest] = useState(false);


  // const handleInputChange = (
  //   e: React.ChangeEvent<{ name?: string; value: unknown }>,
  //   setField: React.Dispatch<React.SetStateAction<string | number | boolean>>
  // ) => {
  //   const value = e.target.value;
  //   if (typeof value === 'boolean') {
  //     setField(value);
  //   } else {
  //     const strValue = value as string;
  //     setField(isNaN(Number(strValue)) ? strValue : Number(strValue));
  //   }
  // };

  const handleCheckboxChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    setField: React.Dispatch<React.SetStateAction<boolean>>
  ) => {
    setField(e.target.checked);
  };

  

  return (
    <>
      <TableRow className={classes.row}>
        <TableCell padding="checkbox">
          <Checkbox 
            name="checkbox"
            checked={request}
             onChange={(e) => handleCheckboxChange(e, setRequest)}
            color="primary"
          />
        </TableCell>
        <TableCell align="left">
          Seat
        </TableCell>
        <TableCell align="left">
          1.5
        </TableCell>
        <TableCell align="left">
          700.00
        </TableCell>
        <TableCell align="right">
          <NavLink  to="/commonJob" 
            className={classes.button}>
            <Button
              className={classes.label}
              color="primary"
              startIcon={<RemoveRedEyeOutlinedIcon />}
            >
              View Details
            </Button>
          </NavLink>
        </TableCell>
      </TableRow>
      <TableRow className={classes.row}>
        <TableCell padding="checkbox">
          <Checkbox
            name="checkbox"
            checked={false}
            // onChange={(e) => handleInputChange(e, request)}
            color="primary"
          />
        </TableCell>
        <TableCell align="left">
          Strobe light
        </TableCell>
        <TableCell align="left">
          0.5
        </TableCell>
        <TableCell align="left">
          $200.00
        </TableCell>
        <TableCell align="right">
        <NavLink  to="/commonJob" 
            className={classes.button}>
            <Button
              className={classes.label}
              color="primary"
              startIcon={<RemoveRedEyeOutlinedIcon />}
            >
              View Details
            </Button>
          </NavLink>
        </TableCell>
      </TableRow>
      <TableRow className={classes.row}>
        <TableCell padding="checkbox">
          <Checkbox
            name="checkbox"
            checked={false}
            // onChange={(e) => handleInputChange(e, request)}
            color="primary"
          />
        </TableCell>
        <TableCell align="left">
          Radiator flush
        </TableCell>
        <TableCell align="left">
          0.5
        </TableCell>
        <TableCell align="left">
          $300.00
        </TableCell>
        <TableCell align="right">
        <NavLink  to="/commonJob" 
            className={classes.button}>
            <Button
              className={classes.label}
              color="primary"
              startIcon={<RemoveRedEyeOutlinedIcon />}
            >
              View Details
            </Button>
          </NavLink>
        </TableCell>
      </TableRow>
    </>
  );
}
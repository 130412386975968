import { flow, getEnv } from 'mobx-state-tree';
import { IStoresEnv } from '@core';



export const setEquipmentIDAPI = (model: any, debtorID: string, addressID: string, equipmentID: string) => {
  const { api } = getEnv<IStoresEnv>(model);
  const fetchData = flow(function* fetch() {
    const equipmentIDResponse = yield api.get(
      `/api/ServiceEstimate/EquipmentDetails?debtorID=${debtorID}&addressID=${addressID}&equipmentID=${equipmentID}`);
    model.DebtorID = debtorID;
    model.Location = addressID;
    model.EquipmentID = equipmentID;
    if (equipmentIDResponse.status === 204) {
      model.Make = '';
      model.Model = '';
      model.SerialNumber = '';
      model.FleetNumber = '';
      model.FleetType = '';
      model.EquipmentIDError = `Equipment doesn't exist`
    } else if (equipmentIDResponse.status === 200) {
        model.Make = equipmentIDResponse.data.make;
        model.Model = equipmentIDResponse.data.model;
        model.SerialNumber = equipmentIDResponse.data.serialNumber;
        model.FleetNumber = equipmentIDResponse.data.fleetNumber;
        if (equipmentIDResponse.data.rentalUnit === true) {
          model.FleetType = 'Rental';
          model.LabourCost = model.DefaultRentalLabourCost;
        } else {
          model.FleetType = 'Retail';
          model.LabourCost = model.DefaultRetailLabourCost;
        }
        model.EquipmentIDError = '';
    } else {
      model.Make = '';
      model.Model = '';
      model.SerialNumber = '';
      model.FleetNumber = '';
      model.FleetType = '';
      model.EquipmentIDError = `Failure retrieving equipment details`;
    }
  });
  fetchData();
};

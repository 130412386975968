import React from 'react';
import { observer } from "mobx-react";
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Paper, Table, TableBody } from '@material-ui/core';
import SortableTableHead, { HeadCell } from 'components/sortable-table/SortableTableHead';
import { SortOrder, sort } from '@utils';
import EstimatesListRow from './EstimatesListRow';
import { EstimateListModel } from '../domain/EstimateList';



const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      overflow: 'auto',
      width: '100%',
      flex: 1
    },
    saveBtn: {
      textAlign: 'right'
    },
    label: {
      textTransform: 'capitalize'
    }
  }));

interface Props {
  estimatesList: EstimateListModel[];
}

const headCells: Array<HeadCell<EstimateListModel>> = [
  { id: 'SubmittedDate', label: 'Submitted Date' },
  { id: 'EstimateNr', label: 'Estimate Nr' },
  { id: 'Revision', label: 'Revision' },
  { id: 'Priority', label: 'Priority' },
  { id: 'FleetType', label: 'Job Type' },
  { id: 'DebtorID', label: 'Debtor Id' },
  { id: 'CustomerName', label: 'Customer' },
  { id: 'Location', label: 'Location' },
  { id: 'Branch', label: 'Branch' },
  { id: 'EquipmentID', label: 'Equipment Id' },
  { id: 'Make', label: 'Make' },
  { id: 'Model', label: 'Model' },
  { id: 'JobDescription', label: 'Description' },
  { id: 'Status', label: 'Status' },
  { id: 'Owner', label: 'Owner' },
  { id: 'Details', label: 'Details' }
];

export const EstimatesTable: React.FC<Props> = observer((props) => {
  const classes = useStyles();
  const { estimatesList } = props;
  const [order, setOrder] = React.useState<SortOrder>('desc');
  const [orderBy, setOrderBy] = React.useState<keyof EstimateListModel>('SubmittedDate');

  const handleRequestSort = (newSortOrder: SortOrder, newOrderBy: keyof EstimateListModel) => {
    setOrder(newSortOrder);
    setOrderBy(newOrderBy);
  };

  return (
    <Paper className={classes.root}>
      <Table stickyHeader={true} size="small">
        <SortableTableHead<EstimateListModel>
          headCells={headCells}
          order={order}
          orderBy={orderBy}
          onSort={handleRequestSort}
        />
        <TableBody>
          {sort<EstimateListModel>(estimatesList, order, orderBy).map((x, index) => ( 
          <EstimatesListRow 
          key={index}
          EstimateList={x}
          />
          ))}
        </TableBody>
      </Table>
    </Paper>
  );
});

import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import Button from '@material-ui/core/Button';
import { observer } from 'mobx-react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    typography: {
      padding: theme.spacing(2)
    },
    label: {
      textTransform: 'none'
    }
  })
);

interface Props {
    content: string;
}

export const DescriptionPopover: React.FC<Props> = observer((props) => {
  const classes = useStyles();
  const { content } = props;

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div>
        <Button
            aria-describedby={id}
            onClick={handleClick}
            color="primary"
            className={classes.label}
            startIcon={<DescriptionOutlinedIcon />}
        >
            Description
        </Button>
        <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
            }}
            transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
            }}
            >
                <Typography className={classes.typography}>{content}</Typography>
         </Popover>
    </div>
  );
});

import { List, ListItem, ListItemIcon, IconButton } from '@material-ui/core';
import { LinkProps, Link, withRouter, RouteComponentProps } from 'react-router-dom';
import React, { forwardRef } from 'react';
import MenuIcon from '@material-ui/icons/Menu';
import { useStyles } from './MainMenuStyle';
import { IAppStore, APP_INJECTION_KEY } from '@shared/store';
import { observer, inject } from 'mobx-react';
import { getAvaliableRoutes } from './routes';

const RouterLink = forwardRef<HTMLAnchorElement, LinkProps>(
  (props, ref) => <Link innerRef={ref} {...props} />
);
interface Props extends RouteComponentProps<any>, StoreProps {
  onHover: () => void;
}

interface StoreProps {
}

type InjectedProps = {
  AppStore: IAppStore;
};

const MainMenu: React.FC<Props> = observer((props) => {
  const p = props as StoreProps;
  const store = (p as InjectedProps).AppStore;
  const routes = getAvaliableRoutes(store.roles)
  const classes = useStyles();
  return (
    <List>
      <ListItem >
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={props.onHover}
          edge="start"
        >
          <MenuIcon />
        </IconButton>
      </ListItem>
      {
        routes.filter(t=>!!t.icon).map((route) => {
          const isSelected = props.location.pathname === route.path;
          return <ListItem
            key={route.title}
            button={true}
            component={RouterLink}
            to={route.path}
            color="p"
            selected={isSelected}
          >
            <ListItemIcon className={isSelected && classes.selected || ''}>{route.icon!}</ListItemIcon>
            <span className={isSelected && classes.selected || ''}>{route.title}</span>
          </ListItem>
        }
        )
      }
    </List >
  );
});

export default inject(APP_INJECTION_KEY)(withRouter(MainMenu));
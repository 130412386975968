import React from "react";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { observer } from "mobx-react";

interface Props {
  disabled?: boolean;
  values: string[];
  selectedValue: string;
  label: string;
  onChanged?: (value: string) => void;
  error: boolean;
}

const labelStyle = {
  backgroundColor: "white",
};

function SingleSelect(props: Props) {
  const { values, onChanged, selectedValue, label, disabled, error } = props;

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    if (onChanged) {
      const selectedValues = event.target.value as string;
      onChanged(selectedValues);
    }
  };

  const inputLabel = React.useRef<HTMLLabelElement>(null);
  const [labelWidth, setLabelWidth] = React.useState(0);
  React.useEffect(() => {
    setLabelWidth(inputLabel.current!.offsetWidth);
  }, []);

  return (
    <FormControl variant="outlined" fullWidth={true} margin="dense">
      <InputLabel ref={inputLabel} shrink={true} style={labelStyle}>
        {label}
      </InputLabel>
      <Select
        disabled={disabled}
        native={true}
        value={selectedValue}
        onChange={handleChange}
        labelWidth={labelWidth}
        error={error}
      >
        <option value="" />
        {values.map((x) => (
          <option key={x} value={x}>
            {x}
          </option>
        ))}
      </Select>
    </FormControl>
  );
}

export default observer(SingleSelect);

import { types, Instance } from 'mobx-state-tree';

export const EstimatePriority = types.model({
  id: types.optional(types.string, ''),
  priority: types.optional(types.string, '')
})
  .actions((self) => {
    return {
      setPriority: (priority: string) => {
          self.priority = priority;
      },

    };
  })
  .views((self) => ({
      equals(priority) {
          return self.priority === priority.priority
      }
    })
  );

export type EstimatePriorityModel = Instance<typeof EstimatePriority>;

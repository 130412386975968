import { types, Instance } from 'mobx-state-tree';

export const TakeFiveQuestion = types.model({
  id: types.optional(types.string, ''),
  enabled: types.optional(types.boolean, false),
  question: types.optional(types.string, '')
})
  .actions((self) => {
    return {
      setQuestion: (question: string) => {
          self.question = question;
      },
      toggleEnabled: (enabled: boolean) => {
          self.enabled = enabled;
      }
    };
  })
  .views((self) => ({
      equals(question) {
          return self.enabled === question.enabled
          && self.question === question.question
      }
    })
  );

export type TakeFiveQuestionModel = Instance<typeof TakeFiveQuestion>;

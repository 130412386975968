import { observer } from 'mobx-react';
import React from 'react';
import { createStyles, makeStyles, Theme, FormControl, FormHelperText } from '@material-ui/core';
import { ValidationTooltip } from 'components/ValidationTooltip';
import NumberFormat, { NumberFormatValues } from 'react-number-format';
import { OutlinedInput } from './OutlinedInput';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    actionWrapper: {
      flexGrow: 1
    },
    action: {
      backgroundColor: 'white',
    },
    smallInputLabel: {
      margin: '0 !important',
      'font-size': '0.6rem',
      textAlign: 'center',
      paddingBottom: 2
    },
    smallInput: {
      '& input:first-child': {
        padding: theme.spacing(0.8),
        'text-align': 'right'
      },
      width: 56,
    }
  }));

interface Props {
  allowNegative?: boolean;
  disabled?: boolean;
  error?: string;
  label: string;
  hours: number;
  onChange?: (hours: number) => void;
}


export const HoursInput: React.FC<Props> = observer((props) => {
  const { label, hours, error, disabled, onChange, allowNegative } = props;
  const classes = useStyles();

  const onValueChange = (values: NumberFormatValues) => {
    if (onChange) {
      onChange(values.floatValue || 0);
    }
  };

  return (
    <ValidationTooltip
      title={error || ''}
      arrow={true}
    >
      <FormControl variant="outlined">
        <FormHelperText error={!!error} className={classes.smallInputLabel}>
          {label}
        </FormHelperText>
        <NumberFormat
          error={!!error}
          allowNegative={!!allowNegative}
          className={classes.smallInput}
          customInput={OutlinedInput}
          disabled={disabled}
          value={hours.toFixed(2)}
          displayType="input"
          decimalScale={2}
          onValueChange={onValueChange}
        />
      </FormControl>
    </ValidationTooltip>
  );
});

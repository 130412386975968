import React from 'react';
import clsx from 'clsx';
import { Grid, createStyles, makeStyles, Theme, Typography, TextField } from '@material-ui/core';
import { LocalFindModel } from '../domain/LocalFind';
import SingleSelect from 'components/SingleSelect';
import red from '@material-ui/core/colors/red';
import ErrorIcon from '@material-ui/icons/Error';

interface Props {
  branches: string[];
  localFind: LocalFindModel;
  duplicateError: string;
  disabled: boolean;
  onChange: (localFind: LocalFindModel) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    label: {
      fontWeight: 'bold'
    },
    error: {
        color: red[500]
    },
    errorContainer: {
      marginBottom: theme.spacing(1)
    }
  }));

export default function LocalFindForm(props: Props) {
  const classes = useStyles();
  const {localFind, onChange, branches, duplicateError, disabled } = props;

  const handleBranchChange = (name: string) => {
    onChange({...localFind, branchName: name});
  };

  const handleSearchTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange({...localFind, searchText: event.target.value});
  };

  const handleDisplayNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange({...localFind, displayName: event.target.value});
  };

  return (
    <Grid direction="column" container>
      { duplicateError &&
        <Grid container alignItems="center" item direction="row" spacing={2} className={classes.errorContainer}>
          <ErrorIcon className={classes.error} />
          <Typography variant="caption" className={clsx(classes.label, classes.error)}>
            {duplicateError}
          </Typography>
        </Grid>
      }
      <Grid container alignItems="center" item direction="row" spacing={2}>
        <Grid item xs={5}>
          <Typography variant="caption" className={classes.label}>
            Branch
          </Typography>
        </Grid>
        <Grid item xs={7}>
            <SingleSelect
              disabled={disabled}
              label="Branch"
              values={branches}
              selectedValue={localFind.branchName}
              onChanged={handleBranchChange}
              error={false}
            />
        </Grid>
      </Grid>
      <Grid container alignItems="center" item direction="row" spacing={2}>
        <Grid item xs={5}>
          <Typography variant="caption" className={classes.label}>
            Display Name
          </Typography>
        </Grid>
        <Grid item xs={7}>
          <TextField
              label="Display Name"
              disabled={disabled}
              margin="dense"
              variant="outlined"
              value={localFind.displayName || ''}
              onChange={handleDisplayNameChange}
          />
        </Grid>
      </Grid>
      <Grid container alignItems="center" item direction="row" spacing={2}>
        <Grid item xs={5}>
          <Typography variant="caption" className={classes.label}>
            Search Text
          </Typography>
        </Grid>
        <Grid item xs={7}>
          <TextField
              disabled={disabled}
              label="Search Text"
              margin="dense"
              variant="outlined"
              value={localFind.searchText || ''}
              onChange={handleSearchTextChange}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}

import React from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { observer } from 'mobx-react';

export interface Props {
  disabled?: boolean;
  label?: string;
  value: number | string;
  options: Map<number | string, any>;
  onChange?: (value: number | string) => void;
}

const emptyComponent: React.ElementType<{}> = () => null;

function EnumSelector(props: Props) {

  const { options, disabled, value, label, onChange } = props;

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    if (onChange) {
      const selectedValues = event.target.value as any;
      onChange(selectedValues);
    }
  };

  const inputLabel = React.useRef<HTMLLabelElement>(null);
  const [labelWidth, setLabelWidth] = React.useState(0);
  React.useEffect(() => {
    setLabelWidth(inputLabel.current!.offsetWidth);
  }, []);

  return (
    <FormControl variant="outlined" fullWidth={true} margin="dense">
      <InputLabel ref={inputLabel}>
        {label}
      </InputLabel>
      <Select
        disabled={disabled}
        native={true}
        value={value}
        onChange={handleChange}
        IconComponent={!disabled && ArrowDropDownIcon || emptyComponent}
        labelWidth={labelWidth}
      >
        {Array.from(options.entries()).map(([key, obj]) => (
          <option key={key} value={key}>{String(obj)}</option>
        ))}
      </Select>
    </FormControl>
  );
}

export default observer(EnumSelector);

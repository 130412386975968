import { Instance, types, applySnapshot, flow, getEnv } from 'mobx-state-tree';

import { TakeFiveQuestions } from '../domain/TakeFiveQuestions';
import {TakeFiveQuestionModel } from '../domain/TakeFiveQuestion';
import { uuidv4 } from '@utils';
import { IStoresEnv } from '@core';

export const TakeFiveStore = types
  .model({
    isLoading: types.optional(types.boolean, false),
    originalSettings: types.optional(TakeFiveQuestions, {}),
    questions: types.optional(TakeFiveQuestions, {})
  })
  .actions((self) => {
    const { api } = getEnv<IStoresEnv>(self);

    const mapQuestion = (x) => ({
      id: x.id, 
      enabled: x.enabled, 
      question: x.question
    });
    const mapSettings = (x) => ({
      questions: x.questions
    });

    const applyData = (data) => {
        if(data.questions)
        {
          applySnapshot(self.originalSettings,
            { questions: data.questions.map((x) => mapQuestion(x)) });
          applySnapshot(self.questions,
            { questions: data.questions.map((x) => mapQuestion(x)) });
        }

        for( let i=1; i<=(4 - self.questions.questions.length); i++)
        {
          const question: any = {
              id: uuidv4()
              };
              self.questions.questions.push(question)
        }
      }
    
    const resetStore = () => {
      applySnapshot(self, {});
    };

    return {
      resetStore,
      fetchData: () => {
        const quarryData = flow(function* fetch() {
          
          const response = yield api.get(`/api/TakeFive`);
          if (response.status !== 200) {
            return;
          }
            applyData(response.data);
        });

        quarryData();
      },
      save: flow(function* save() {
        self.isLoading = true;
        const response = yield api.post(`/api/TakeFive/save`,
            { payload: mapSettings(self.questions) });

        
          applyData(response.data);

        self.isLoading = false;
      }),
      delete: (question: TakeFiveQuestionModel) => {
        const deleteRow = flow(function* fetch() {
          self.isLoading = true;

          const response = yield api.post(`/api/TakeFive/delete`,
              { payload: mapQuestion(question) });
          applyData(response.data);
  
          self.isLoading = false;
        })

        deleteRow()
      },
    };
  })
  .views((self) => {
    const hasChanges = (questions) => {  
      const newRecord = questions.filter(
        (t) => !self.originalSettings.questions.some( (x) => t.equals(x))  && t.question != '').length === 0
     
      if(!newRecord) return true

      const isModified = self.originalSettings.equals(questions);
      return !isModified;
    }; 

    return {
      hasChanges
    };
  });

export type ITakeFiveStore = Instance<typeof TakeFiveStore>;

import { Instance, types } from 'mobx-state-tree';
import { DetailsTabData } from './DetailsTabData';
import { CustomerTabData } from './CustomerTabData';
import { LogsTabData } from './LogsTabData';
import { TakeFiveTabData } from './TakeFiveTabData';

export const ServiceCallDetailModel = types
  .compose(
    DetailsTabData,
    CustomerTabData,
    TakeFiveTabData,
    LogsTabData
  );

export type IServiceCallDetailModel = Instance<typeof ServiceCallDetailModel>;

import { observer } from 'mobx-react';
import React from 'react';
import { Grid, TextField } from '@material-ui/core';
import { HoursRow } from '../HoursRow';
import { DetailsHeader } from '../DetailsHeader';
import { PayrollAdjustmentModel } from 'timesheet-details/domain/payroll-adjustments/PayrollAdjustment';
import { AdjustmentType } from 'timesheet-details/domain/payroll-adjustments/AdjustmentType';
import EnumSelector from 'components/EnumSelector';

interface Props {
    disabled?: boolean;
    adjustment: PayrollAdjustmentModel;
    onDelete: (adjustment: PayrollAdjustmentModel) => void;
}

const AdjustmentTypes = new Map<string, string>([
    [AdjustmentType.Time, 'Time'],
    [AdjustmentType.OnCallAllowance, 'On Call Allowance'],
    [AdjustmentType.PHOnCallAllowance, 'P/H On Call Allowance'],
    [AdjustmentType.MealAllowance, 'Meal Allowance']
  ]);

export const PayrollAdjustmentRow: React.FC<Props> = observer((props) => {

    const { adjustment, onDelete, disabled } = props;

    const handleDelete = () => {
        onDelete(adjustment);
    };

    const handleReasonChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        adjustment.setReason(event.target.value);
    };

    const handleAmountChange = (value: string) => {
        adjustment.setAmount(parseInt(value, 10));
    };

    const getAmountOptions = () => {
        if (adjustment.isAllowanceType) {
            return new Map<number, number>([
                [-1, -1],
                [1, 1]
              ]);
        }

        return new Map<number, number>([ [0, 0] ]);
    };

    const { adjustmentReasonMissingError } = adjustment.validate();

    const errors = [adjustmentReasonMissingError]
        .filter((e) => !!e);

    return (
    <>
        <DetailsHeader
            canDelete={adjustment.canDelete && !disabled}
            status={adjustment.status}
            errors={errors}
            hasErrors={errors.length > 0}
            onDeleteClick={handleDelete}
        />
        <Grid container direction="row" justify="space-between" alignItems="center" >
            <Grid item xs={6}>
                <Grid container spacing={2} direction="row">
                    <Grid item xs={4}>
                        <EnumSelector
                            label="Type"
                            disabled={adjustment.isReadOnly || disabled}
                            value={adjustment.type}
                            options={AdjustmentTypes}
                            onChange={adjustment.setType}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth={true}
                            label="Reason"
                            disabled={adjustment.isReadOnly || disabled}
                            margin="dense"
                            variant="outlined"
                            error={adjustmentReasonMissingError !== ""}
                            value={adjustment.reason}
                            onChange={handleReasonChange}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <EnumSelector
                            disabled={adjustment.isReadOnly || !adjustment.isAllowanceType || disabled}
                            label="Amount"
                            value={adjustment.amount}
                            options={getAmountOptions()}
                            onChange={handleAmountChange}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={6}>
                <HoursRow
                    model={adjustment.hours}
                    disableGPFields={true}
                    disabled={adjustment.isReadOnly || adjustment.isAllowanceType || disabled}
                    onNormalPayChange={adjustment.setNormalPay}
                    onOvertimeChange={adjustment.setOvertime}
                    onDoubleTimeChange={adjustment.setDoubleTime}
                    onOvertimeDoubleTimeChange={adjustment.setOvertimeDoubleTime}
                    allowNegative={true}
                />
            </Grid>
        </Grid>
    </>
  );
});

import { inject, observer } from 'mobx-react';
import React, {useEffect} from 'react';
import { ITakeFiveStore } from './stores/TakeFiveStore';
import { TakeFiveQuestions } from './TakeFiveQuestions';
import { STORE_KEYS } from '../@core';


interface Props {
  TakeFiveStore: ITakeFiveStore;
}

const TakeFiveSettingsComponent: React.FC<Props> = observer((props) => {
  const store = props.TakeFiveStore;
  const { questions, hasChanges, save, delete: handleDelete } = store;

  useEffect(() => {
    store.fetchData();
    return function cleanup() {
    };
  }, []);

  return (
    <TakeFiveQuestions takeFiveModel={questions} 
    questions={questions.questions} 
    hasChanges={hasChanges(questions.questions)}
    onSave={save}
    onDelete={handleDelete}
    />
  );
});

export const TakeFiveSettings = inject(STORE_KEYS.TakeFiveStore)(TakeFiveSettingsComponent);

import clsx from 'clsx';
import React from 'react';

import { useStyles } from './IconWithLabel.style';

// Extend if needed
export interface IconWithLabelProps {
  Icon: () => JSX.Element;
  text: string;
  className?: string;
}

export const IconWithLabel: React.FC<IconWithLabelProps> = ({ className, Icon, text }) => {
  const s = useStyles();

  const buttonClassName = clsx(
    s.container,
    className,
  );

  return (
    <>
      <div className={buttonClassName}>
        <span className={s.icon}>
          <Icon />
        </span>
        {text}
      </div>
    </>
  );
};

IconWithLabel.displayName = 'IconWithLabel';

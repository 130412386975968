import { Instance, applySnapshot, flow, getEnv, types } from 'mobx-state-tree';

import { IStoresEnv } from '@core';
import { formatDate_yyyymmdd, uuidv4 } from '@utils';
import { TakeFiveLineItem } from '../domain/TakeFiveLineItem';

export const TakeFiveDetailsStore = types
  .model({
    isLoading: types.optional(types.boolean, false),
    technicianId:  types.optional(types.string, ''),
    startDate:  types.optional(types.Date, 0),
    endDate:  types.optional(types.Date, 0),
    details: types.array(TakeFiveLineItem)
  })
  .actions((self) => {
    const {
      api,  
    } = getEnv<IStoresEnv>(self);

    const mapAnswers = (y) => ({
      id: uuidv4(),
      question: y.question,
      answer: y.answer ? 'Yes' : 'No',
    });

    const mapData = (y) => ({
      id: uuidv4(),
      date: y.date,
      time: y.time,
      customer: y.customer,
      address: y.address,
      serviceCallID: y.serviceCallId,
      equipmentID: y.equipmentId,
      manufacturer: y.manufacturer, 
      model: y.model, 
      answers: y.answers.map((x) => mapAnswers(x))
    });

    const fetchData = flow(function* dataFetch() {
      self.isLoading = true;
      const apiUrl = `/api/TakeFiveSummary/details?technicianId=${self.technicianId}&startDate=${formatDate_yyyymmdd(self.startDate)}&endDate=${formatDate_yyyymmdd(self.endDate)}`

      const result = yield api.get(apiUrl);
      const data = result.data || [];
      
      applySnapshot(self.details, data.map((x) => mapData(x)));

      self.isLoading = false; 
    });

    return {
      fetchData(technicianId: string, startDate: Date, endDate: Date) {
        self.technicianId = technicianId;
        self.startDate = startDate;
        self.endDate = endDate;
        fetchData();
      }
    };
 });

export type ITakeFiveDetailsStore = Instance<typeof TakeFiveDetailsStore>;

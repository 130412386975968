import { Instance, types, applySnapshot, flow, getEnv } from 'mobx-state-tree';

import { EstimatePriorities } from '../domain/Priorities';
import { EstimatePriorityModel } from '../domain/Priority';
import { uuidv4 } from '@utils';
import { IStoresEnv } from '@core';

export const PriorityConfigStore = types
  .model({
    isLoading: types.optional(types.boolean, false),
    originalSettings: types.optional(EstimatePriorities, {}),
    priorities: types.optional(EstimatePriorities, {})
  })
  .actions((self) => {
    const { api } = getEnv<IStoresEnv>(self);

    const mapPriority = (x) => ({
      id: x.id, 
      priority: x.priority
    });
    const mapSettings = (x) => ({
      priorities: x.priorities
    });

    const applyData = (data) => {
        if(data.priorities)
        {
          applySnapshot(self.originalSettings,
            { priorities: data.priorities.map((x) => mapPriority(x)) });
          applySnapshot(self.priorities,
            { priorities: data.priorities.map((x) => mapPriority(x)) });
        }

        for( let i=1; i<=(4 - self.priorities.priorities.length); i++)
        {
          const priority: any = {
              id: uuidv4()
              };
              self.priorities.priorities.push(priority)
        }
      }
    
    const resetStore = () => {
      applySnapshot(self, {});
    };

    return {
      resetStore,
      fetchData: () => {
        const quarryData = flow(function* fetch() {
          
          const response = yield api.get(`/api/EstimatePriorityConfig`);
          if (response.status !== 200) {
            return;
          }
            applyData(response.data);
        });

        quarryData();
      },
      save: flow(function* save() {
        self.isLoading = true;
        const response = yield api.post(`/api/EstimatePriorityConfig/save`,
            { payload: mapSettings(self.priorities) });

        
          applyData(response.data);

        self.isLoading = false;
      }),
      delete: (priority: EstimatePriorityModel) => {
        const deleteRow = flow(function* fetch() {
          self.isLoading = true;

          const response = yield api.post(`/api/EstimatePriorityConfig/delete`,
              { payload: mapPriority(priority) });
          applyData(response.data);
  
          self.isLoading = false;
        })

        deleteRow()
      },
    };
  })
  .views((self) => {
    const hasChanges = (priorities) => {  
      const newRecord = priorities.filter(
        (t) => !self.originalSettings.priorities.some( (x) => t.equals(x))  && t.priority != '').length === 0
     
      if(!newRecord) return true

      const isModified = self.originalSettings.equals(priorities);
      return !isModified;
    }; 

    return {
      hasChanges
    };
  });

export type IPriorityStore = Instance<typeof PriorityConfigStore>;

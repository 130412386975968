export function toTwoDecimalNumber(num) {
    return Math.round(num * 100) / 100;
}

export const roundToQuarter = (x) => {

    const result = Math.ceil(x / 0.25) * 0.25;

    return result;
};

import { observer } from 'mobx-react';
import React from 'react';
import { EstimateAreasModel } from './domain/Areas';
import { EstimateArea } from './Area';
import CloudDownloadOutlinedIcon from '@material-ui/icons/CloudDownloadOutlined';
import { Button, makeStyles, createStyles, Theme } from '@material-ui/core';
import { EstimateAreaModel } from './domain/Area';

interface Props {
    EstimateAreaModel: EstimateAreasModel;
    areas: EstimateAreaModel[];
    hasChanges: boolean;
    onSave: (areas: EstimateAreaModel[]) => void;
    onDelete: (area: EstimateAreaModel) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    areaWrapper: {
      background: '#fff',
      padding: '2rem'
    },
    label: {
      textTransform: 'capitalize'
    },
    newLineBtn: {
      marginTop: '1rem'
    },
    saveBtn: {
      textAlign: 'right'
    }
}));
export const EstimateAreas: React.FC<Props> = observer((props) => {

const { EstimateAreaModel, areas, hasChanges} = props;
const classes = useStyles();

const onAddNewTimeline = () => {
    return EstimateAreaModel.addNewLine();
};

const handleSaveClick = () => {
  props.onSave(areas);
};

const handleDelete = (area) => {
  props.onDelete(area);
};

  return (
    <div className={classes.areaWrapper}>
      <div className={classes.saveBtn}>
      <Button
          color="primary"
          disabled={!hasChanges}
          className={classes.label}
          startIcon= {<CloudDownloadOutlinedIcon/>} //{user.isSaving ? <CircularProgress size={14} /> : <CloudDownloadOutlinedIcon />}
          onClick={handleSaveClick}
      >
          Save
      </Button></div>
      { areas.map((line, index) =>
        <EstimateArea area={line}
        onDeleteClick={handleDelete}/>
      )}
      <Button
        variant="outlined"
        color="primary"
        onClick={onAddNewTimeline}
        className={classes.newLineBtn}
      >
        ADD NEW LINE
      </Button>
    </div>
  );
});
import { Instance, types, applySnapshot, flow, getEnv } from 'mobx-state-tree';

import { EstimateBranches } from '../domain/Branches';
import { EstimateBranchModel } from '../domain/Branch';
import { uuidv4 } from '@utils';
import { IStoresEnv } from '@core';

export const BranchConfigStore = types
  .model({
    isLoading: types.optional(types.boolean, false),
    originalSettings: types.optional(EstimateBranches, {}),
    branches: types.optional(EstimateBranches, {})
  })
  .actions((self) => {
    const { api } = getEnv<IStoresEnv>(self);

    const mapBranch = (x) => ({
      id: x.id, 
      branch: x.branch,
      officeLocation: x.officeLocation,
      viewAll: x.viewAll,
      threshold: x.threshold,
      retailSendTo: x.retailSendTo,
      rentalAccessSendTo: x.rentalAccessSendTo,
      rentalForkliftSendTo: x.rentalForkliftSendTo,
      sentFrom: x.sentFrom
    });
    const mapSettings = (x) => ({
      branches: x.branches
    });

    const applyData = (data) => {
        if(data.branches)
        {
          applySnapshot(self.originalSettings,
            { branches: data.branches.map((x) => mapBranch(x)) });
          applySnapshot(self.branches,
            { branches: data.branches.map((x) => mapBranch(x)) });
        }

        for( let i=1; i<=(4 - self.branches.branches.length); i++)
        {
          const branch: any = {
              id: uuidv4()
              };
              self.branches.branches.push(branch)
        }
      }
    
    const resetStore = () => {
      applySnapshot(self, {});
    };

    return {
      resetStore,
      fetchData: () => {
        const quarryData = flow(function* fetch() {
          
          const response = yield api.get(`/api/EstimateBranchConfig`);
          if (response.status !== 200) {
            return;
          }
            applyData(response.data);
        });

        quarryData();
      },
      save: flow(function* save() {
        self.isLoading = true;
        const response = yield api.post(`/api/EstimateBranchConfig/save`,
            { payload: mapSettings(self.branches) });

        
          applyData(response.data);

        self.isLoading = false;
      }),
      delete: (branch: EstimateBranchModel) => {
        const deleteRow = flow(function* fetch() {
          self.isLoading = true;

          const response = yield api.post(`/api/EstimateBranchConfig/delete`,
              { payload: mapBranch(branch) });
          applyData(response.data);
  
          self.isLoading = false;
        })

        deleteRow()
      },
    };
  })
  .views((self) => {
    const hasChanges = (branches) => {  
      const newRecord = branches.filter(
        (t) => !self.originalSettings.branches.some( (x) => t.equals(x))  && t.branch != '').length === 0
     
      if(!newRecord) return true

      const isModified = self.originalSettings.equals(branches);
      return !isModified;
    }; 

    return {
      hasChanges
    };
  });

export type IBranchStore = Instance<typeof BranchConfigStore>;

import { withStyles, Tooltip } from '@material-ui/core';
import { red, grey, amber } from '@material-ui/core/colors';

export const ValidationTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: red[500],
    color: grey[50],
    maxWidth: 400,
    fontSize: 12,
    border: '1px solid',
    borderColor: red[800]
  },
}))(Tooltip);

export const WarningTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: amber[500],
    color: grey[50],
    maxWidth: 400,
    fontSize: 12,
    border: '1px solid',
    borderColor: amber[800]
  },
}))(Tooltip);

import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { Grid, makeStyles, Theme, createStyles, TextField, Button } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import TeamsMultipleSelect from 'components/TeamsMultipleSelect';

interface Props {
    userSearchTerm: string;
    teams: string[];
    selectedTeams: string[];
    onSearchClick: (userSearchTerm: string, selectedTeams: string[]) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1)
    },
    button: {
      margin: theme.spacing(1)
    }
}));

export const UsersFilter: React.FC<Props> = observer((props) => {
  const { teams } = props;

  const [selectedTeams, setSelectedTeams] = useState(teams);
  const [userSearchTerm, setUserSearchTerm] = useState(props.userSearchTerm);

  const classes = useStyles();

  const handleSearchClick = () => {
      props.onSearchClick(userSearchTerm, selectedTeams);
  };

  const handleUserSearchTermChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUserSearchTerm(event.target.value);
  };
  const handleTeamsSelect = (items: string[]) => {
    setSelectedTeams(items);
  };

  return (
    <Grid container item xs={6} direction="row">
      <Grid item xs={4}>
        <TeamsMultipleSelect
          teams={teams}
          selectedTeams={selectedTeams}
          onSelectedTeamsChanged={handleTeamsSelect}
          />
      </Grid>
      <Grid item>
        <TextField
          label="User Name"
          type="search"
          className={classes.textField}
          margin="dense"
          variant="outlined"
          value={userSearchTerm}
          onChange={handleUserSearchTermChange}
        />
      </Grid>
      <Grid item>
        <Button
          variant="outlined"
          color="primary"
          className={classes.button}
          startIcon={<SearchIcon />}
          onClick={handleSearchClick}
        >
          SEARCH
        </Button>
      </Grid>
    </Grid>
  );
});

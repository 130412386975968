import { observer } from 'mobx-react';
import React from 'react';
import { Grid } from '@material-ui/core';
import { TimeSheetHoursModel } from '../domain/TimeSheetHours';
import { HoursInput } from './HoursInput';

interface Props {
    disabled?: boolean;
    disableGPFields?: boolean;
    disablePayFields?: boolean;
    allowNegative?: boolean;
    model: TimeSheetHoursModel;

    onNormalPayChange?: (hours: number) => void;
    onNormalGPChange?: (hours: number) => void;
}

export const HoursRow: React.FC<Props> = observer((props) => {
    const { model, disabled, disableGPFields, disablePayFields } = props;
    const { onNormalPayChange, onNormalGPChange, allowNegative} = props;

    const { invalidTotalPayH, invalidTotalGP } = model.validate();

    return (
        <Grid container={true} direction="row" alignItems="center" spacing={2}>
            <Grid item={true} xs={4} />
            <Grid item={true} xs={8}>
                <Grid container direction="row" justify="space-around" alignItems="center">
                    <HoursInput
                        label="Alloc Hours"
                        disabled={true}
                        hours={model.allocation}
                        allowNegative={allowNegative}
                    />
                    <HoursInput
                        label="Pay"
                        disabled={disabled || disablePayFields}
                        hours={model.normalPay}
                        onChange={onNormalPayChange || model.setNormalPay}
                        error={invalidTotalPayH}
                        allowNegative={allowNegative}
                    />
                    <HoursInput
                        label="GP"
                        disabled={disabled || disableGPFields}
                        hours={model.normalGP}
                        onChange={onNormalGPChange || model.setNormalGP}
                        error={invalidTotalGP}
                        allowNegative={allowNegative}
                    />
                </Grid>
            </Grid>
        </Grid>
    );
});

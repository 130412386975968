import { types, Instance } from 'mobx-state-tree';
import { TakeFiveQuestion } from './TakeFiveQuestion';

import { uuidv4 } from '@utils';


export const TakeFiveQuestions = types.model({
  questions: types.optional(types.array(TakeFiveQuestion), [])
})
  .actions((self) => {
    return {
      addNewLine: () => {
        const question: any = {
            id: uuidv4()
        };
        
        self.questions.push(question);
      }
    };
  })
  .views((self) => ({
    equals(questions) {
        return self.questions.filter(
          (t) => !questions.some( (x) => t.equals(x))).length === 0;
    }
  }));

export type TakeFiveQuestionsModel = Instance<typeof TakeFiveQuestions>;

import { flow, getEnv } from 'mobx-state-tree';
import { IStoresEnv } from '@core';



export const setAddressIDAPI = (model: any, debtorID: string, addressID: string) => {
  const { api } = getEnv<IStoresEnv>(model);
  const fetchData = flow(function* fetch() {
    const addressIDResponse = yield api.get(
      `/api/ServiceEstimate/LocationDetails?debtorID=${debtorID}&addressID=${addressID}`);
    model.DebtorID = debtorID;
    model.Location = addressID;
    if (addressIDResponse.status === 204) {
      model.Address = '';
      model.Suburb = '';
      model.Postcode = '';
      model.State = '';
      model.LabourRate = 0;
      model.DefaultRetailLabourCost = 0;
      model.DefaultRentalLabourCost = 0;
    } else if (addressIDResponse.status === 200) {
        model.Address = addressIDResponse.data.address || '';
        model.Suburb = addressIDResponse.data.suburb || '';
        model.Postcode = addressIDResponse.data.postCode || '';
        model.State = addressIDResponse.data.state || '';
        model.LabourRate = addressIDResponse.data.labourRate || 0;
        model.DefaultRetailLabourCost = addressIDResponse.data.defaultRetailLabourCost || 0;
        model.DefaultRentalLabourCost = addressIDResponse.data.defaultRentalLabourCost || 0;
    } else {
      model.Address = '';
      model.Suburb = '';
      model.Postcode = '';
      model.State = '';
      model.LabourRate = 0;
      model.DefaultRetailLabourCost = 0;
      model.DefaultRentalLabourCost = 0;
    }
  });
  fetchData();
};

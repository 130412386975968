import { Provider } from 'mobx-react';
import React from 'react';
import { render } from 'react-dom';
import App from './components/App';
import { createStores } from './@core/createStores';
import { addMiddleware } from 'mobx-state-tree';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';


(async () => {
  const stores = createStores();
  Object.keys(stores).map(t=>
    addMiddleware(stores[t], (call, next)=>{
      return next(call);
    })
    )
  const LDProvider = await asyncWithLDProvider({
    clientSideID: LAUNCH_DARKLY_CLIENT_SIDE_ID,
    deferInitialization: true,
    options: {
      streaming: true,
    },
    flags: {
      "timesheet-creation-security": false,
      "estimates-enabled": false,
      "smart-service-payroll-metrics-cutover-date": '9999-01-01',
      "payroll-and-gp-adjustments-total-hours-must-match": false,
    }
  });
  
  const rootEl = document.getElementById('root');

  const app = (
    <LDProvider>
    <Provider {...stores}>
      <App />
    </Provider>
    </LDProvider>
  );

  render(
    app,
    rootEl
  );
})();

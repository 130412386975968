import { Instance, types, applySnapshot, flow, getEnv } from 'mobx-state-tree';
import { EstimateEquipmentTypes } from '../domain/EquipmentTypes';
import { EstimateEquipmentTypeModel } from '../domain/EquipmentType';
import { uuidv4 } from '@utils';
import { IStoresEnv } from '@core';

export const EquipmentTypeConfigStore = types
  .model({
    isLoading: types.optional(types.boolean, false),
    originalSettings: types.optional(EstimateEquipmentTypes, {}),
    equipmentTypes: types.optional(EstimateEquipmentTypes, {})
  })
  .actions((self) => {
    const { api } = getEnv<IStoresEnv>(self);

    const mapEquipmentType = (x) => ({
      id: x.id, 
      equipmentType: x.equipmentType
    });
    const mapSettings = (x) => ({
      equipmentTypes: x.equipmentTypes
    });

    const applyData = (data) => {
        if(data.equipmentTypes)
        {
          applySnapshot(self.originalSettings,
            { equipmentTypes: data.equipmentTypes.map((x) => mapEquipmentType(x)) });
          applySnapshot(self.equipmentTypes,
            { equipmentTypes: data.equipmentTypes.map((x) => mapEquipmentType(x)) });
        }

        for( let i=1; i<=(4 - self.equipmentTypes.equipmentTypes.length); i++)
        {
          const equipmentType: any = {
              id: uuidv4()
              };
              self.equipmentTypes.equipmentTypes.push(equipmentType)
        }
      }
    
    const resetStore = () => {
      applySnapshot(self, {});
    };

    return {
      resetStore,
      fetchData: () => {
        const quarryData = flow(function* fetch() {
          
          const response = yield api.get(`/api/EstimateEquipmentTypeConfig`);
          if (response.status !== 200) {
            return;
          }
            applyData(response.data);
        });

        quarryData();
      },
      save: flow(function* save() {
        self.isLoading = true;
        const response = yield api.post(`/api/EstimateEquipmentTypeConfig/save`,
            { payload: mapSettings(self.equipmentTypes) });

        
          applyData(response.data);

        self.isLoading = false;
      }),
      delete: (equipmentType: EstimateEquipmentTypeModel) => {
        const deleteRow = flow(function* fetch() {
          self.isLoading = true;

          const response = yield api.post(`/api/EstimateEquipmentTypeConfig/delete`,
              { payload: mapEquipmentType(equipmentType) });
          applyData(response.data);
  
          self.isLoading = false;
        })

        deleteRow()
      },
    };
  })
  .views((self) => {
    const hasChanges = (equipmentTypes) => {  
      const newRecord = equipmentTypes.filter(
        (t) => !self.originalSettings.equipmentTypes.some( (x) => t.equals(x))  && t.equipmentType != '').length === 0
     
      if(!newRecord) return true

      const isModified = self.originalSettings.equals(equipmentTypes);
      return !isModified;
    }; 

    return {
      hasChanges
    };
  });

export type IEquipmentTypeStore = Instance<typeof EquipmentTypeConfigStore>;

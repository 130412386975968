import { makeStyles, createStyles, Theme } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';

export const useStyles = makeStyles((theme: Theme) =>
createStyles({
  noData: {
    color: grey[500],
    fontStyle: 'italic',
  },
}));

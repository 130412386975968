import { inject, observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { STORE_KEYS } from '@core';
import { Grid, makeStyles, Theme, createStyles, Paper, Divider, LinearProgress } from '@material-ui/core';
import { IUserSetupStore } from './store/UserSetupStore';
import { UserConfigurationListItem } from './components/UserConfigurationListItem';
import { UsersFilter } from './components/UsersFilter';
import { UserImport } from './components/UserImport';
import { UsersListHeader } from './components/UsersListHeader';
import { SortOrder, sort } from '@utils/sort';
import { UserConfigurationModel } from './domain/UserConfiguration';
import { Prompt } from 'react-router-dom';

interface Props {
}

type InjectedProps = {
  UserSetupStore: IUserSetupStore;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2)
    },
    filter: {
      padding: theme.spacing(2)
    },
    table: {
      'flex-wrap': 'nowrap !important'
    }
}));

const UserSetupComponent: React.FC<Props> = observer((props) => {
  const store = (props as (InjectedProps)).UserSetupStore;
  useEffect(() => {
    store.fetchData();
    return function cleanup() {
      store.resetStore();
    };
  },        []);

  const [order, setOrder] = React.useState();
  const [orderBy, setOrderBy] = React.useState();

  const handleRequestSort = (newSortOrder: SortOrder, newOrderBy: keyof UserConfigurationModel) => {
    setOrder(newSortOrder);
    setOrderBy(newOrderBy);
  };

  const hasChanges = store.hasAnyChanges();

  const classes = useStyles();
  return (
    <React.Fragment>
      <Prompt
        when={hasChanges}
        message="You have unsaved changes, are you sure you want to leave?"
      />
      <Paper className={classes.root}>
        <Grid container direction="column" justify="flex-start">
            {store.isLoading && <LinearProgress />}
            {!store.isLoading &&
            <React.Fragment>
              <Grid container direction="row" className={classes.filter}>
                <UsersFilter
                  selectedTeams={store.selectedTeams}
                  teams={store.teams}
                  userSearchTerm={store.userSerchTerm}
                  onSearchClick={store.setUserSerchFilter}
                />
                <UserImport
                  userImport={store.userImport}
                  onImportUser={store.importUser}
              />
              </Grid>
              <Grid direction="column" container className={classes.table}>
                <UsersListHeader
                    onSort={handleRequestSort}
                    order={order}
                    orderBy={orderBy}
              />
                <Divider />
                { sort<UserConfigurationModel>(store.filteredUsers, order, orderBy).map((t, index) =>
                    <UserConfigurationListItem
                      key={`UserConfiguration${t.userId}`}
                      index={index}
                      teams={store.teams}
                      user={t}
                      hasChanges={store.hasUserChanges(t)}
                      onArchiveUser={store.archiveUser}
                      onSaveUser={store.saveUser}
                    />)
                }
              </Grid>
            </React.Fragment>
            }
        </Grid>
      </Paper>
    </React.Fragment>
  );
});

export const UserSetup = inject(STORE_KEYS.UserSetupStore)(UserSetupComponent);

import { Button, TableCell, TableRow, Theme, createStyles, makeStyles } from '@material-ui/core';
import { blueGrey, lightBlue } from '@material-ui/core/colors';
import React from 'react';
import { NavLink } from 'react-router-dom';
import RemoveRedEyeOutlinedIcon from '@material-ui/icons/RemoveRedEyeOutlined';
import { EstimateListModel } from '../domain/EstimateList';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      textDecoration: 'none'
    },
    label: {
      textTransform: 'none'
    },
    row: {
      '&:nth-child(even)': {
        backgroundColor: blueGrey[50]
      },
      '&:hover': {
        backgroundColor: lightBlue[100]
      }
    }
  }));

  interface Props {
    EstimateList: EstimateListModel;
  }

export default function EstimatesListRow(props: Props) {
  const classes = useStyles();
  const { EstimateList } = props;

  return (
    <>
      <TableRow className={classes.row}>
      <TableCell component="th" scope="row" align="left">
          {new Date(EstimateList.SubmittedDate).toLocaleString()}
        </TableCell>
        <TableCell component="th" scope="row" align="left">
          {EstimateList.EstimateNr}
        </TableCell>
        <TableCell component="th" scope="row" align="left">
          {EstimateList.Revision}
        </TableCell>
        <TableCell component="th" scope="row" align="left">
          {EstimateList.Priority}
        </TableCell>
        <TableCell component="th" scope="row" align="left">
          {EstimateList.FleetType}
        </TableCell>
        <TableCell component="th" scope="row" align="left">
          {EstimateList.DebtorID}
        </TableCell>
        <TableCell component="th" scope="row" align="left">
          {EstimateList.CustomerName}
        </TableCell>
        <TableCell align="left">
          {EstimateList.Location}
        </TableCell>
        <TableCell align="left">
          {EstimateList.Branch}
        </TableCell>
        <TableCell align="left">
          {EstimateList.EquipmentID}
        </TableCell>
        <TableCell align="left">
          {EstimateList.Make}
        </TableCell>
        <TableCell align="left">
          {EstimateList.Model}
        </TableCell>
        <TableCell align="left">
          {EstimateList.JobDescription.substring(0, 100)}
        </TableCell>
        <TableCell align="left">
          {EstimateList.Status}
        </TableCell>
        <TableCell align="left">
          {EstimateList.Owner}
        </TableCell>
        <TableCell align="left">
          <NavLink  
          className={classes.button}
          to={`/estimates/${EstimateList.ID}`}>
            <Button
              className={classes.label}
              color="primary"
              startIcon={<RemoveRedEyeOutlinedIcon />}
            >
              View Details
            </Button>
          </NavLink>
        </TableCell>
      </TableRow>



    </>
  );
}
import { types, Instance } from 'mobx-state-tree';

export const PayEnquiry = types
    .model({
      technician: types.maybeNull(types.string),
      processedDate: types.maybeNull(types.string),
      norm: types.optional(types.number, 0),
      th: types.optional(types.number, 0), 
      doub: types.optional(types.number, 0),
      dh: types.optional(types.number, 0), 
      sl: types.optional(types.number, 0),
      al: types.optional(types.number, 0),
      lsl: types.optional(types.number, 0),
      publicHolidays: types.optional(types.number, 0),
      carersLeave: types.optional(types.number, 0),
      leaveWoPay: types.optional(types.number, 0),
      workersComp: types.optional(types.number, 0),
      mealAllowance: types.optional(types.number, 0),
      callOutAllowance: types.optional(types.number, 0),
      phCallOutAllowance: types.optional(types.number, 0),
      fdo: types.optional(types.number, 0),
      mdo: types.optional(types.number, 0),
      anniversaryLeave: types.optional(types.number, 0),
      extendedLWOP: types.optional(types.number, 0),
      parentalLeave: types.optional(types.number, 0),
      juryDuty: types.optional(types.number, 0),
      armyReserves: types.optional(types.number, 0),
      compassionateLeave: types.optional(types.number, 0),
      total: types.optional(types.number, 0),
    })
    .views((self) => ({
      get totalAmount () {
        var sum = 0;

        for (var property in self ) {
          if(self.hasOwnProperty(property) &&
            property !== 'technician' &&
            property !== 'processedDate' &&
            property !== 'total') {
            sum += parseFloat(self[property] );
          }
        }

        return sum;
      }
    }));

        
export type IPayEnquiry = Instance<typeof PayEnquiry>;

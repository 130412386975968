import React from 'react';

import { useStyles } from './NoData.style';

export const NoData = () => {
  const s = useStyles();

  return (
    <div className={s.noData}>No Data</div>
  );
};

NoData.displayName = 'NoData';

export enum LeaveType {
  Empty = 'Empty',
  ExtendedLWOP = 'ExtendedLWOP',
  ParentalLeave = 'ParentalLeave',
  JuryDuty = 'JuryDuty',
  ArmyReserves = 'ArmyReserves',
  CompassionateLeave = 'CompassionateLeave',
  AnnualLeave = 'AnnualLeave',
  SickLeave = 'SickLeave',
  LongServiceLeave = 'LongServiceLeave',
  CarersLeave = "CarersLeave",
  WorkersComp = "WorkersComp",
  LeaveWithoutPay = 'LeaveWithoutPay',
  PublicHoliday = 'PublicHoliday',
  FDO = 'FDO',
  MDO = 'MDO',
  AnniversaryLeave = 'AnniversaryLeave'
}
import { observer } from 'mobx-react';
import React from 'react';
import { createStyles, makeStyles, Theme, FormControl, TextField, TextFieldProps } from '@material-ui/core';
import { KeyboardTimePicker } from '@material-ui/pickers';
import { ValidationTooltip } from 'components/ValidationTooltip';
import InputLabel from '@material-ui/core/InputLabel';
import {  isValid } from 'date-fns';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    action: {
      backgroundColor: 'white',
    },
    smallInputLabel: {
      margin: '0 !important',
      'font-size': '0.6rem',
      textAlign: 'center',
      paddingBottom: 2
    },
    smallInput: {
      '& input:first-child': {
        padding: '11px 2px 10.5px 6px'
      },
      width: '100%'
    }
  }));

interface Props {
  disabled?: boolean;
  error?: string;
  label: string;
  dateTime: Date;
  onChange?: (dateTime: Date) => void;
}

function InputTextField(props: TextFieldProps) {
  return (
    <ValidationTooltip
      title={props.helperText || ''}
      arrow={true}
    >
      <TextField {...props} helperText="" />
    </ValidationTooltip>
  );
}

function RevertDateToHeaderDate(existingDate: Date, newDate: Date)
{
  if(isValid(newDate))
  {
    newDate.setDate(existingDate.getDate());
    newDate.setMonth(existingDate.getMonth());
    newDate.setFullYear(existingDate.getFullYear());
  }
  return newDate;
};
const labelStyle = 
{
  backgroundColor: 'white'
}

export const TimeSheetTimePicker: React.FC<Props> = observer((props) => {
  const { label, dateTime, error, onChange, disabled } = props;
  const classes = useStyles();

  const onChangeHandler = (date: Date) => {
    if (onChange) {
      onChange(RevertDateToHeaderDate(dateTime, date));//Rever date portion back to header date.
    }
  };

  const inputLabel = React.useRef<HTMLLabelElement>(null);

  return (
    <FormControl variant="outlined" fullWidth={true} margin="dense">
      <InputLabel ref={inputLabel} shrink={true} style={labelStyle}>
        {label}
      </InputLabel>
      <KeyboardTimePicker
        helperText={error}
        error={!!error}
        disabled={disabled}
        fullWidth={true}
        ampm={false}
        inputVariant="outlined"
        value={dateTime}
        onChange={onChangeHandler}
        TextFieldComponent={InputTextField}
        className={classes.smallInput}
        minutesStep={3}
      />
    </FormControl>
  );
});

import { IApiStore, EndpointCallParams, ApiResponse } from "api";

export const exportEnquiries = async (
  api: IApiStore,
  params: EndpointCallParams<any>,
): Promise<ApiResponse> => { //TODO: add type
  const url = `/api/payrollAdjustments/report`;

  return await api.getRawFile(url, params);
};

import { types, Instance } from 'mobx-state-tree';
import { EstimateArea } from './Area';

import { uuidv4 } from '@utils';


export const EstimateAreas = types.model({
  areas: types.optional(types.array(EstimateArea), [])
})
  .actions((self) => {
    return {
      addNewLine: () => {
        const area: any = {
            id: uuidv4()
        };
        
        self.areas.push(area);
      }
    };
  })
  .views((self) => ({
    equals(areas) {
        return self.areas.filter(
          (t) => !areas.some( (x) => t.equals(x))).length === 0;
    }
  }));

export type EstimateAreasModel = Instance<typeof EstimateAreas>;

import { STORE_KEYS } from "@core";
import { inject, observer } from "mobx-react";
import React, { useEffect } from 'react';
import { Button, Grid, makeStyles, Theme, createStyles, Paper, LinearProgress, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import { RequestsSummaryTable } from "./components/RequestsSummaryTable";
import { NavLink } from 'react-router-dom';
import { IRequestStore } from './store/RequestStore';
import { APP_INJECTION_KEY, IAppStore } from '@shared/store';


interface Props {

}

type InjectedProps = {
  RequestStore : IRequestStore;
  AppStore: IAppStore;
};


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      height: '85%'
    },
    gridContainer: {
      height: 'calc(85% - 115px)',
      display: 'flex',
      flex: 1
    },
    filter: {
      display: "flex",
      alignItems: "center", // Vertically centers children
      padding: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    select: {
      marginRight: theme.spacing(2), // Adds some space between the dropdown and the button
    },
    progress: {
      width: '100%',
      marginTop: theme.spacing(2)
    },
    button: {
      'justify-content': 'flex-start'
    },
    link: {
      textDecoration: 'none',
      color: theme.palette.primary.main
    }
  }));

const RequestsSummaryComponent: React.FC<Props> = observer((props) => {
  const { RequestStore: store } = props as InjectedProps; 
  const { AppStore: appStore } = props as InjectedProps;
  const classes = useStyles();
  const [status, setStatus] = React.useState("");

  useEffect(() => {
    store.getAllRequests(status);
}, []);


  const handleChange = (event) => {
    setStatus(event.target.value);
    store.getAllRequests(event.target.value);
  };

  return (
    <div>
      <Paper className={classes.filter}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={1}>
            <FormControl variant="outlined" className={classes.select} fullWidth>
              <InputLabel>Status</InputLabel>
              <Select
                value={status || "All"}
                onChange={handleChange}
                label="Status"
              >
                <MenuItem value="All">All</MenuItem>
                <MenuItem value="Draft">Draft</MenuItem>
                <MenuItem value="Submitted">Submitted</MenuItem>
              </Select>
            </FormControl>
            </Grid>
          <Grid item xs={11} container justifyContent="flex-end">
            <NavLink to="/requests/new" className={classes.link}>
              <Button variant="contained" color="primary">
                Create New Request
              </Button>
            </NavLink>
          </Grid>
        </Grid>
      </Paper>
      <Paper className={classes.filter}>
        <Grid item={true} className={classes.gridContainer}>
          {store.isLoading && <LinearProgress className={classes.progress} />}
          {!store.isLoading && <RequestsSummaryTable requestsStore={store}  appStore={appStore} />}
        </Grid>
      </Paper>
    </div>
  );
});

export const RequestsSummary = inject(STORE_KEYS.RequestStore, APP_INJECTION_KEY)(RequestsSummaryComponent);

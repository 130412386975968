import { Theme, createStyles, makeStyles, Grid, Divider, Paper, TextField, LinearProgress } from "@material-ui/core"; 
import { observer } from "mobx-react";
 import { STORE_KEYS } from '@core';
import { inject } from 'mobx-react';
import React, { useEffect } from 'react';
import { RouteComponentProps, useParams } from "react-router-dom";
import { ITakeFiveDetailsStore } from "./store/TakeFiveDetailsStore";
import { formatDate } from "@utils";
import { TakeFiveHeader } from "./components/TakeFiveHeader";
import { TakeFiveLineItem } from "./components/TakeFiveLineItem";

interface Props {

}

interface PathParams {
    technicianId: string;
    startDate: string;
    endDate: string;
}

interface InjectedProps extends RouteComponentProps<PathParams> {
    TakeFiveDetailsStore: ITakeFiveDetailsStore;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      padding: theme.spacing(2)
    },
    detailsContainer: {
      paddingTop: theme.spacing(2)
    },
    progress: {
      width: '100%'
    }
}));

const TakeFiveDetailsComponent: React.FC<Props> = observer((props) => {
  const classes = useStyles();

  const store = (props as InjectedProps).TakeFiveDetailsStore;

  const params = useParams<PathParams>();
  const technicianId = params.technicianId;
  const startDate = new Date(params.startDate);
  const endDate = new Date(params.endDate);
    
  useEffect(() => {
    store.fetchData(technicianId, startDate, endDate);
  }, []);

  return (  
    <Grid container={true} spacing={2}>
        <Grid item={true} xs={12}>  
            <Paper className={classes.container}>
                <Grid item={true} xs={12}>
                    <Grid container={true} spacing={1}>
                        <Grid item={true} xs={3}>
                            <TextField
                            disabled={true}
                            label="Technician ID"
                            value={store.technicianId}
                            />
                        </Grid>
                        <Grid item={true} xs={3}>
                            <TextField
                            disabled={true}
                            label="From Date:"
                            value={formatDate(new Date(store.startDate))}
                            />
                        </Grid>
                        <Grid item={true} xs={3}>
                            <TextField
                            disabled={true}
                            label="To Date"
                            value={formatDate(new Date(store.endDate))}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        </Grid>   
        <Grid item={true} xs={12}>
            <Paper className={classes.detailsContainer}>
                <TakeFiveHeader/>
                <Divider />
                    {store.isLoading && <LinearProgress className={classes.progress} />}
                    {!store.isLoading && store.details.map((detail, index) => (
                        <TakeFiveLineItem 
                        key={detail.id}
                        index={index}
                        details={detail}/>
                    ))}
            </Paper>
        </Grid>
    </Grid>
  );
});


export const TakeFiveDetails = inject(STORE_KEYS.TakeFiveDetailsStore)(TakeFiveDetailsComponent);

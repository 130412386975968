import { OutstandingApprovals } from '../domain/OutstandingApprovals';
import { Instance, types, applySnapshot, flow, getEnv } from 'mobx-state-tree';
import { IStoresEnv } from '@core';

export const OutstandingApprovalsStore = types
  .model({
    teams: types.array(types.string),
    selectedTeam: types.optional(types.string, ''),
    outstandingApprovals: types.array(OutstandingApprovals),
    isLoading: types.optional(types.boolean, false)
  })
  .actions((self) => {
    const {
      api,
    } = getEnv<IStoresEnv>(self);

    const mapOutstandingApprovals = (y) => ({
      technicianId: y.technicianId,
      technicianName: y.technicianName,
      team: y.team,
      date: new Date(y.date),
      gpHours: y.gpHours,
      payrollHours: y.payrollHours
    });

    const queryData = flow(function* fetchData() {
      if (!self.selectedTeam) {
        applySnapshot(self.outstandingApprovals, []);
        return;
      }

      self.isLoading = true;

      const outstandingApprovals = yield api.get(`/api/OutstandingApprovals?team=${self.selectedTeam}`);
      const result = outstandingApprovals.data || [];
      applySnapshot(self.outstandingApprovals, result.map((x) => mapOutstandingApprovals(x)));

      self.isLoading = false;
    });

    return {
      fetchTeam: flow(function* fetchData() {
        self.isLoading = true;
        const teams = yield api.get(`/api/teams/timesheet-approver`);
        applySnapshot(self.teams, teams.data || []);

        self.isLoading = false;
      }),
      selectTeam(team: string) {
        self.selectedTeam = team;

        queryData();
      },
      fetchData() {
        if(!!self.selectedTeam)
        {
          queryData();
        }
      }
    };
  })
  .views((self) => ({
    get filteredOutstandingApprovals() {
      if (!!self.selectedTeam) {
        return self.outstandingApprovals.filter((t) => !!self.selectedTeam && self.selectedTeam === t.team);
      }

      return self.outstandingApprovals;
    }
  }));

export type IOutstandingApprovalsStore = Instance<typeof OutstandingApprovalsStore>;

import { Instance, types } from 'mobx-state-tree';

import { SnackMessage, SnackMessageData } from './SnackMessage';

export const SnackMessagesStore = types
  .model({
    snackMessages: types.map(SnackMessage),
  })
  .actions(self => ({
    removeSnackMessage: (snack: SnackMessageData) => {
      self.snackMessages.delete(snack.message);
    },
    addSnackMessage: (snack: SnackMessageData) => {
      self.snackMessages.has(snack.message)
        ? self.snackMessages.replace(snack)
        : self.snackMessages.put(SnackMessage.create(snack));
    },
  }));

export const snackMessenger = SnackMessagesStore.create();

export type ISnackMessagesStore = Instance<typeof SnackMessagesStore>;

import { observer } from 'mobx-react';
import React from 'react';
import { Grid, Button, createStyles, makeStyles, Theme } from '@material-ui/core';

interface Props {
  disableSave?: boolean;
  disableSubmit?: boolean;
  disableApprove?: boolean;
  disableReject?: boolean;

  isTimeSheetApprover: boolean;
  isTimeSheetEditor: boolean;

  onSave: () => void;
  onSubmit: () => void;
  onApprove: () => void;
  onReject: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    actionWrapper: {
      minWidth: 150,
    },
    action: {
      backgroundColor: 'white',
    }
  }));

export const Actions: React.FC<Props> = observer((props) => {
  const { disableSave, disableApprove, disableReject, disableSubmit } = props;
  const { isTimeSheetApprover, isTimeSheetEditor } = props;
  const { onSave, onApprove, onReject, onSubmit } = props;
  const classes = useStyles();

  return (
    <Grid container direction="row" spacing={3} justify="flex-end">
      {
        isTimeSheetEditor &&
        <Grid item className={classes.actionWrapper}>
          <Button
            fullWidth={true}
            className={classes.action}
            disabled={disableSave}
            variant="outlined"
            color="primary"
            onClick={onSave}
          >
            SAVE
          </Button>
        </Grid>
      }
      {
        isTimeSheetEditor &&
        <Grid item className={classes.actionWrapper}>
          <Button
            fullWidth={true}
            className={classes.action}
            disabled={disableSubmit}
            variant="outlined"
            color="primary"
            onClick={onSubmit}
          >
            SUBMIT
          </Button>
        </Grid>
      }
      {
        isTimeSheetApprover &&
        <Grid item className={classes.actionWrapper}>
          <Button
            fullWidth={true}
            className={classes.action}
            disabled={disableApprove}
            variant="outlined"
            color="primary"
            onClick={onApprove}
          >
            APPROVE
          </Button>
        </Grid>
      }
      {
        isTimeSheetApprover &&
        <Grid item className={classes.actionWrapper}>
          <Button
            fullWidth={true}
            className={classes.action}
            disabled={disableReject}
            variant="outlined"
            color="primary"
            onClick={onReject}
          >
            REJECT
          </Button>
        </Grid>
      }
    </Grid>
  );
});

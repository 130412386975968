import { observer } from 'mobx-react';
import React from 'react';
import { Grid, Divider, Button, createStyles, makeStyles, Theme } from '@material-ui/core';
import { PayrollAdjustmentRow } from './PayrollAdjustmentRow';
import ControlPointOutlinedIcon from '@material-ui/icons/ControlPointOutlined';
import { blueGrey, red } from '@material-ui/core/colors';
import clsx from 'clsx';
import { PayrollAdjustmentModel } from 'timesheet-details/domain/payroll-adjustments/PayrollAdjustment';
import { ValidationTooltip } from 'components/ValidationTooltip';
import ErrorIcon from '@material-ui/icons/Error';

interface Props {
    disabled?: boolean;
    adjustments: PayrollAdjustmentModel[];
    onAdd: () => void;
    onDelete: (adjustment: PayrollAdjustmentModel) => void;
    validate: () => any;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        newRow: {
            paddingLeft: theme.spacing(1),
            paddingTop: theme.spacing(1),
        },
        row: {
            padding: theme.spacing(1)
        },
        even: {
            backgroundColor: blueGrey[50]
        },
        error: {
            color: red[500],
            marginLeft: 10
        }
    }));

export const PayrollAdjustmentsDetails: React.FC<Props> = observer((props) => {
    const classes = useStyles();
    const { adjustments, onAdd, onDelete, validate, disabled } = props;

    const { totalsValidationResult } = validate();

    return (
        <Grid container={true}>
            <Grid item={true} xs={12}>
                <Divider />
            </Grid>
            <Grid item={true} xs={12} className={classes.newRow}>
                <Button
                    disabled={disabled}
                    variant="outlined"
                    color="primary"
                    startIcon={<ControlPointOutlinedIcon />}
                    onClick={onAdd}
                >
                    ADD NEW LINE
                </Button>
                {!!totalsValidationResult &&
                    <ValidationTooltip title={totalsValidationResult || ''} placement="bottom" arrow={true}>
                        <ErrorIcon className={classes.error} />
                    </ValidationTooltip>
                }
            </Grid>
            { adjustments.map((adj, index) =>
                <Grid item={true} xs={12} className={clsx(index % 2 === 1 && classes.even, classes.row)}
                    key={`timeline${index}`}
                >
                    <PayrollAdjustmentRow
                        disabled={disabled}
                        adjustment={adj}
                        onDelete={onDelete}
                    />
                </Grid>)
            }
        </Grid>
    );
});

import { observer } from 'mobx-react';
import React from 'react';
import { Grid, createStyles, makeStyles, Theme, Typography, TextField } from '@material-ui/core';
import { ServiceCallDetailsModel } from '../domain/ServiceCallDetails';

interface Props {
  details: ServiceCallDetailsModel | null;
  notes: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    label: {
      fontWeight: 'bold'
    },
    value: {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'pre'
    },
    container: {
      paddingTop: theme.spacing(2)
    }
  }));

export const ServiceCallDetailsForm: React.FC<Props> = observer((props) => {
  const classes = useStyles();
  const { details, notes } = props;

  return (
    <Grid container={true} spacing={2} className={classes.container}>
      <Grid item={true} xs={12}>
        <Grid container={true}>
          <Grid item={true} xs={3}>
            <Typography variant="caption" className={classes.label}>
              Make
            </Typography>
            <Typography className={classes.value} title={details?.unitMake}>
              {details?.unitMake}
            </Typography>
          </Grid>
          <Grid item={true} xs={4}>
            <Typography variant="caption" className={classes.label}>
              Model
            </Typography>
            <Typography className={classes.value} title={details?.unitModel}>
              {details?.unitModel}
            </Typography>
          </Grid>
          <Grid item={true} xs={5}>
            <Typography variant="caption" className={classes.label}>
              Customer Name
            </Typography>
            <Typography className={classes.value} title={details?.companyName}>
              {details?.companyName}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item={true} xs={12}>
        <Grid container={true}>
          <Grid item={true} xs={3}>
            <Typography variant="caption" className={classes.label}>
              Equip ID
            </Typography>
            <Typography className={classes.value} title={details?.equipmentId}>
              {details?.equipmentId}
            </Typography>
          </Grid>
          <Grid item={true} xs={4}>
            <Typography variant="caption" className={classes.label}>
              Division
            </Typography>
            <Typography className={classes.value} title={details?.divisions}>
              {details?.divisions}
            </Typography>
          </Grid>
          <Grid item={true} xs={5}>
            <Typography variant="caption" className={classes.label}>
              Customer Location
            </Typography>
            <Typography className={classes.value} title={details?.customerAddress}>
              {details?.customerAddress}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item={true} xs={12}>
        <Typography variant="caption" className={classes.label}>
          Description
        </Typography>
        <TextField
          disabled={true}
          fullWidth={true}
          variant="outlined"
          value={details?.description}
          multiline={true}
          rows="3"
        />
      </Grid>
      <Grid item={true} xs={12}>
        <Typography variant="caption" className={classes.label}>
          Notes
        </Typography>
        <TextField
          disabled={true}
          fullWidth={true}
          variant="outlined"
          value={notes}
          multiline={true}
          rows="3"
        />
      </Grid>
    </Grid>
  );
});

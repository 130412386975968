import { observer } from 'mobx-react';
import React, {useState} from 'react';
import { TextField, Button, makeStyles, createStyles, Theme } from '@material-ui/core';
import { EstimateEquipmentTypeModel } from './domain/EquipmentType';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import ConfirmationDialog from 'components/ConfirmationDialog';

interface Props {
    equipmentType: EstimateEquipmentTypeModel
    onDeleteClick?: (equipmentType: EstimateEquipmentTypeModel) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    equipmentTypeWrapper: {
        display: 'flex',
        gap: '2rem'
    },
    label: {
      textTransform: 'capitalize'
    }
}));

export const EstimateEquipmentType: React.FC<Props> = observer((props) => {

const { equipmentType, onDeleteClick } = props;
const classes = useStyles();
const [confirmationOpen, setConfirmationOpen] = useState(false);


const handleDeleteClick = () => {
  setConfirmationOpen(true);
};

const handleConfirmationClose = (confirmed: boolean) => {
  setConfirmationOpen(false);
  if (onDeleteClick && confirmed) {
      onDeleteClick(equipmentType);
  }
};

const handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  equipmentType.setEquipmentType(event.target.value);
};

  return (
        <div className={classes.equipmentTypeWrapper}>
          <TextField
            fullWidth={true}
            label="Equipment Type"
            margin="dense"
            variant="outlined"
            value = {equipmentType.equipmentType}
            onChange={handleTextChange}
          />
        
          <Button
            color="primary"
            className={classes.label}
            startIcon={<DeleteOutlineIcon />}
            onClick={handleDeleteClick}
          >
            Delete
          </Button>
          <ConfirmationDialog
              open={confirmationOpen}
              onClose={handleConfirmationClose}
              declineButtonTitle="No"
              confirmButtonTitle="Yes"
              title="Are you sure you want to delete this item?"
          />
        </div>
  );
});
import { types, Instance } from 'mobx-state-tree';

export const OutstandingApprovals = types
    .model({
        technicianId: types.string,
        technicianName: types.string,
        team: types.string,
        date: types.Date,
        gpHours: types.optional(types.number, 0),
        payrollHours: types.optional(types.number, 0)
    });

export type OutstandingApprovalsModel = Instance<typeof OutstandingApprovals>;

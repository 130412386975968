import { observer } from 'mobx-react';
import { useStyles } from './tab.style';
import React from 'react';
import { Grid } from '@material-ui/core';

interface Props {
  state: string;
  name: string;
  data: string;
  index: number;
}

export const LogListItem: React.FC<Props> = observer((props) => {
  const classes = useStyles();
  const { index, state, name, data } = props;

  return (
    <>
      <Grid item={true} className={classes.pointer}>
        <Grid 
          container={true} 
          spacing={2} 
          className={classes.gridRow}
        >
          <Grid item xs={1}>
            <div className={classes.tabName}>{index + 1}</div>
          </Grid>
          <Grid item xs={3}>
            <div className={classes.gridCellName}>{state}</div>
          </Grid>
          <Grid item xs={3}>
            <div>{name}</div>          
          </Grid>
          <Grid item xs={5}>
            <div>{data}</div>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
});

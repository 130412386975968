
export class ApiResponse<T = any> {
  constructor (
    public data: T,
    public status: number,
    public fileName: string = '',
    public error?: any,
  ) {}

  public get success () {
    return !this.error;
  }
}

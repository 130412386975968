import { makeStyles, Theme, createStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
createStyles({
  root: {
    overflowX: 'auto',
    width: '100%',
    flex: 1,
  },
  customCell: {
    '& span': {
      fontSize: '12px',
    },

    fontSize: '12px',
    padding: '6px 5px',
  }
}));
import { inject, observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { STORE_KEYS } from '@core';
import { ITimesheetSummaryStore } from './store/TimesheetSummaryStore';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import SingleSelect from 'components/SingleSelect';
import { SummaryTable } from './components/SummaryTable';
import { Grid, LinearProgress, Paper, makeStyles, createStyles, Theme } from '@material-ui/core';

interface Props  {

}

type InjectedProps = {
  TimesheetSummaryStore: ITimesheetSummaryStore;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      height: '100%'
    },
    gridContainer: {
      height: 'calc(100% - 115px)',
      display: 'flex',
      flex: 1
    },
    filter: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      padding: theme.spacing(2)
    },
    progress: {
      width: '100%'
    }
  }));

const TimesheetSummaryComponent: React.FC<Props> = observer((props) => {
  const store = (props as InjectedProps).TimesheetSummaryStore;
  const classes = useStyles();

  useEffect(() => {
    store.fetchFilters();
    store.reloadData();
  }, []);
  

  return (
    <Grid container={true}
      direction="column"
      wrap="nowrap"
      className={classes.wrapper}
    >
      <Grid item={true}>
        <Paper className={classes.filter}>
          <Grid container={true} spacing={2}>
            <Grid item={true} lg={2} md={3} xs={6}>
              <SingleSelect
                label="Team ID"
                values={store.teams}
                selectedValue={store.selectedTeam}
                onChanged={store.selectTeam}
                error={false}
              />
            </Grid>
            <Grid item={true} lg={2} md={3} xs={6}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  fullWidth={true}
                  disableToolbar={true}
                  format="EEEE dd MMM yyyy"
                  margin="dense"
                  label="Date"
                  value={store.selectedDate}
                  onChange={store.selectDate}
                  inputVariant="outlined"
                />
              </MuiPickersUtilsProvider>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Grid item={true} className={classes.gridContainer}>
        {store.isLoading && <LinearProgress className={classes.progress} />}
        {!store.isLoading && <SummaryTable summaries={store.timesheetSummary} />}
      </Grid>
    </Grid>
  );
});

export const TimesheetSummary = inject(STORE_KEYS.TimesheetSummaryStore)(TimesheetSummaryComponent);

import { types, Instance } from 'mobx-state-tree';
import { EstimateTask} from './EstimateTask';

import { uuidv4 } from '@utils';


export const EstimateTasks = types.model({
    tasks: types.optional(types.array(EstimateTask), [])
})
  .actions((self) => {
    return {
      addNewLine: () => {
        const task: any = {
            id: uuidv4()
        };
        
        self.tasks.push(task);
      }
    };
  })
  .views((self) => ({
    equals(tasks) {
        return self.tasks.filter(
          (t) => !tasks.some( (x) => t.equals(x))).length === 0;
    }
  }));

export type EstimateTasksModel = Instance<typeof EstimateTasks>;

import { runWithAdal } from 'react-adal';
import { ApplicationInsights } from '@microsoft/applicationinsights-web'
import { authContext } from 'api';
 
const DO_NOT_LOGIN = false;

const appInsights = new ApplicationInsights({ config: {
  instrumentationKey: APP_INSIGHTS_INSTRUMENTATION_KEY
} });
appInsights.loadAppInsights();
appInsights.trackPageView();

console.log(`production: ${production}`);
runWithAdal(authContext, () => {
  require('./index.tsx');
 
},DO_NOT_LOGIN);
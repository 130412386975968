import { observer } from 'mobx-react';
import React, {useState} from 'react';
import { TextField, Button, makeStyles, createStyles, Theme,Switch, FormControlLabel } from '@material-ui/core';
import { TakeFiveQuestionModel } from './domain/TakeFiveQuestion';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import ConfirmationDialog from 'components/ConfirmationDialog';

interface Props {
    question: TakeFiveQuestionModel
    onDeleteClick?: (question: TakeFiveQuestionModel) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    questionsWrapper: {
        display: 'flex',
        gap: '2rem'
    },
    label: {
      textTransform: 'capitalize'
    }
}));

export const TakeFiveQuestion: React.FC<Props> = observer((props) => {

const { question, onDeleteClick } = props;
const classes = useStyles();
const [confirmationOpen, setConfirmationOpen] = useState(false);

const toggleChecked = event => {
  question.toggleEnabled(event.target.checked);
};

const handleDeleteClick = () => {
  setConfirmationOpen(true);
};

const handleConfirmationClose = (confirmed: boolean) => {
  setConfirmationOpen(false);
  if (onDeleteClick && confirmed) {
      onDeleteClick(question);
  }
};

const handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  question.setQuestion(event.target.value);
};

  return (
        <div className={classes.questionsWrapper}>
          <TextField
            fullWidth={true}
            label="Question"
            margin="dense"
            variant="outlined"
            value = {question.question}
            onChange={handleTextChange}
          />
          <FormControlLabel
          control={
            <Switch
              checked={question.enabled}
              onChange={toggleChecked}
              color="primary"
            />
          }
          label={question.enabled ? "Enabled" : "Disabled"}
        />
          <Button
            color="primary"
            className={classes.label}
            startIcon={<DeleteOutlineIcon />}
            onClick={handleDeleteClick}
          >
            Delete
          </Button>
          <ConfirmationDialog
              open={confirmationOpen}
              onClose={handleConfirmationClose}
              declineButtonTitle="No"
              confirmButtonTitle="Yes"
              title="Are you sure you want to delete this item?"
          />
        </div>
  );
});
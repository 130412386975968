import { observer } from 'mobx-react';
import React from 'react';
import { Grid, makeStyles, Theme, createStyles, Typography, Paper } from '@material-ui/core';
import red from '@material-ui/core/colors/red';
import FiberManualRecordOutlinedIcon from '@material-ui/icons/FiberManualRecordOutlined';

interface Props {
  errors?: string[];
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      padding: theme.spacing(1)
    },
    error: {
      color: red[700],
      paddingLeft: theme.spacing(1)
    },
    title: {
      paddingBottom: theme.spacing(1),
      color: red[700],
    },
    icon: {
      verticalAlign: 'text-top',
      marginRight: '5px'
    }
  }));

export const ErrorList: React.FC<Props> = observer((props) => {
  const classes = useStyles();
  const { errors } = props;

  if (!errors || errors.length === 0) {
    return (<></>);
  }

  return (
    <Paper>
      <Grid
        className={classes.container}
        container={true}
      >
        <Grid item={true} xs={12}>
          <Typography variant="subtitle1" className={classes.title}>
              The operation was not completed due to the following issues:
          </Typography>
        </Grid>
        {errors.map((x, i) => (
          <Grid item={true} xs={12} key={i}>
            <Typography noWrap={true} className={classes.error}>
              <FiberManualRecordOutlinedIcon fontSize="inherit" className={classes.icon} />
              {x}
            </Typography>
          </Grid>
        ))}
      </Grid>
    </Paper>
  );
});

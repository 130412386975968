import React, { useCallback, useEffect, useState } from 'react';
import { Accordion, AccordionDetails, Button, Grid, IconButton, Paper, TextField, Typography, debounce } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { RouteComponentProps, useHistory, useParams } from 'react-router-dom';
import { IEstimateDetailsStore } from './store/EstimateDetailsStore';
import { inject, observer } from 'mobx-react';
import { STORE_KEYS } from '@core';
import SingleSelectWithKey from 'components/SingleSelectWithKey';
import { ITaskStore } from './components/tasks/store/TaskStore';
import { EstimateTasks } from './components/tasks/EstimateTasks';
import { IPartStore } from './components/parts/store/PartStore';
import { EstimateParts } from './components/parts/EstimateParts';
import { APP_INJECTION_KEY, IAppStore } from '@shared/store';
import { FileUploader } from "react-drag-drop-files";



interface PathParams {
  estimateNumber: string;
}

interface Props extends RouteComponentProps<PathParams> {
  EstimateDetailsStore: IEstimateDetailsStore;
  TaskStore: ITaskStore;
  PartStore: IPartStore;
  AppStore: IAppStore;
}

const EstimateFormComponent: React.FC<Props> = observer((props) => {

  const history = useHistory();
  const store = props.EstimateDetailsStore;
  const taskstore = props.TaskStore;
  const partstore = props.PartStore;
  const appStore = props.AppStore;
  const params = useParams<PathParams>();
  const estimateNumber = params.estimateNumber;

  useEffect(() => {
    const fetchData = async () => {
      store.selectEstimateNumber(estimateNumber);
      await store.fetchData();
      await taskstore.fetchData(store.ID);
      await partstore.fetchData(store.ID);
    };

    fetchData();
  }, []);

  useEffect(() => {
        const errorMessage = props.EstimateDetailsStore.uploadErrorMessage;
        if (errorMessage) {
            alert(errorMessage); // Or trigger a modal popup
            // Optionally, clear the error message after displaying it
            props.EstimateDetailsStore.clearUploadError();
        }
    }, [props.EstimateDetailsStore.uploadErrorMessage]);

  const handleExpandCollapseGIClick = () => {
    setIsGIOpen(!isGIOpen);
  };


  const handleExpandCollapsePartsClick = () => {
    setIsPartsOpen(!isPartsOpen);
  };
  const handleExpandCollapseTasksClick = () => {
    setIsTasksOpen(!isTasksOpen);
  };
  const handleExpandCollapseNotesClick = () => {
    setIsNotesOpen(!isNotesOpen);
  };
  const handleExpandCollapseSummaryClick = () => {
    setIsSummaryOpen(!isSummaryOpen);
  };

  const validateEmail = async (email: string) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (re.test(String(email).toLowerCase())) {
      if (await store.emailValidate(email)) {
        return true;
      }
      else {
        return false;
      }
    }
    return false;
  }
  const validatePhoneNumber = (phoneNumber: string) => {
    const re = /^(?:\(04\)|04|\+614|0011614|\(02\)|02|\+612|0011612|\(03\)|03|\+613|0011613|\(07\)|07|\+617|001167|0011617|\(08\)|08|\+618|0011618|\(02\) |02 |\+612 |0011612 |\(03\) |03 |\+613 |0011613 |\(07\) |07 |\+617 |0011617 |\(08\) |08 |\+618 |0011618 |)(?:\d{8}|(\d{4}\s\d{3}\s\d{3})|(\d{2}\s\d{4}\s\d{4}))$/;
    return re.test(phoneNumber);
  }

  const [customerNameError, setCustomerNameError] = useState('');
  const [AddressError, setAddressError] = useState('');
  const [SuburbError, setSuburbError] = useState('');
  const [PostcodeError, setPostcodeError] = useState('');
  const [stateError, setStateError] = useState('');
  const [contactNameError, setContactNameError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [phoneError, setPhoneError] = useState('');
  const [areaError, setAreaError] = useState('');
  const [equipmentTypeError, setEquipmentTypeError] = useState('');
  const [makeError, setMakeError] = useState('');
  const [modelError, setModelError] = useState('');
  const [serialNumberError, setSerialNumberError] = useState('');
  const [estimateStatusError, setEstimateStatusError] = useState('');
  const [quotedForError, setQuotedForError] = useState('');



  const handleCustomerNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    if (!value) {
      setCustomerNameError('Customer Name is required');
    } else {
      setCustomerNameError('');
    }
    store.estimateDetails.setCustomerName(value);
  }

  const handleAddressChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    if (!value) {
      setAddressError('Address is required');
    } else {
      setAddressError('');
    }
    store.estimateDetails.setAddress(value);
  }

  const handleSuburbChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    if (!value) {
      setSuburbError('Suburb is required');
    } else {
      setSuburbError('');
    }
    store.estimateDetails.setSuburb(value);
  }

  const handlePostcodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    if (!value) {
      setPostcodeError('Postcode is required');
    } else {
      setPostcodeError('');
    }
    store.estimateDetails.setPostcode(value);
  }

  const handleStateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    if (!value) {
      setStateError('State is required');
    } else {
      setStateError('');
    }
    store.estimateDetails.setState(value);
  }

  const handleMakeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    if (!value) {
      setMakeError('Make is required');
    } else {
      setMakeError('');
    }
    store.estimateDetails.setMake(value);
  }

  const handleModelChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    if (!value) {
      setModelError('Model is required');
    } else {
      setModelError('');
    }
    store.estimateDetails.setModel(value);
  }

  const handleSerialNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    if (!value) {
      setSerialNumberError('Serial Number is required');
    } else {
      setSerialNumberError('');
    }
    store.estimateDetails.setSerialNumber(value);
  }

  const handleFleetNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    store.estimateDetails.setFleetNumber(value);
  }

  const handleContactNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    if (!value) {
      setContactNameError('Contact Name is required');
    } else {
      setContactNameError('');
    }
    store.estimateDetails.setContactName(value);
  }

  const handleContactPhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    if (!validatePhoneNumber(value)) {
      setPhoneError('Invalid phone number');
    } else {
      setPhoneError('');
    }
    store.estimateDetails.setContactPhone(value);
  }

  const debouncedHandleContactEmailChange = useCallback(
    debounce(async (ContactEmail: string) => {
      if (!(await validateEmail(ContactEmail))) {
        setEmailError('Invalid email address');
      } else {
        setEmailError('');
      }
      store.estimateDetails.setContactEmail(ContactEmail);
    }, 1000),
    [store]
  );


  const handleContactEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    store.estimateDetails.validateContactEmail(value);
    debouncedHandleContactEmailChange(value);
  }

  const handleEngineHoursChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    store.estimateDetails.setEngineHours(value);
  }

  const handleOriginCallNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    store.estimateDetails.setOriginCallNumber(value);
  }

  const handleQuotedForChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    if (!value) {
      setQuotedForError('Quoted For is required');
    } else {
      setQuotedForError('');
    }
    store.estimateDetails.setQuotedFor(value);
  }
  const handleSpecialNotesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    store.estimateDetails.setSpecialNotes(value);
  }

  const handleTransportCostChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = Number(event.target.value);
    store.estimateDetails.setTransportCost(value);
    store.estimateDetails.setTransportSell(value * 1.3);
  }

  const handleTransportSellChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = Number(event.target.value);
    store.estimateDetails.setTransportSell(value);
  }

  const handleFreightChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = Number(event.target.value);
    store.estimateDetails.setFreight(value);
  }

  const handleEnviroChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = Number(event.target.value);
    store.estimateDetails.setEnviro(value);
  }

  const handleConsumablesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = Number(event.target.value);
    store.estimateDetails.setConsumables(value);
  }

  const handleAdminFeeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = Number(event.target.value);
    store.estimateDetails.setAdminFee(value);
  }

  const handleLabourRateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = Number(event.target.value);
    store.estimateDetails.setLabourRate(value);
  }

  const handleStatusChange = async (status: string) => {
    let isValid = validate();
    if (isValid) {
      await store.selectEstimateStatus(status);
      await store.save();
    }
  }

  const [isGIOpen, setIsGIOpen] = useState(true);
  const [isPartsOpen, setIsPartsOpen] = useState(true);
  const [isTasksOpen, setIsTasksOpen] = useState(true);
  const [isNotesOpen, setIsNotesOpen] = useState(false);
  const [isSummaryOpen, setIsSummaryOpen] = useState(false);

  const openSharePoint = () => {
    const url = `${ESTIMATE_SHAREPOINT_PREFIX_URL}${store.estimateDetails.RequestID}${ESTIMATE_SHAREPOINT_SUFFIX_URL}`;
    window.open(url, '_blank');
  }

  const openSSRSReport = () => {
    const url = `${ESTIMATE_SSRS_REPORT_URL}${store.estimateDetails.ID}&ApprovalSiteURL=${ESTIMATE_APPROVAL_URL}`;
    window.open(url, '_blank');
  }

  const validate = () => {
    let isValid = true;

    if (!store.estimateDetails.Area) {
      setAreaError('Area is required');
      isValid = false;
    } else {
      setAreaError('');
    }

    if (!store.estimateDetails.EquipmentType) {
      setEquipmentTypeError('Equipment type is required');
      isValid = false;
    } else {
      setEquipmentTypeError('');
    }

    if (!store.estimateDetails.Status) {
      setEstimateStatusError('Estimate Status is required');
      isValid = false;
    } else {
      setEstimateStatusError('');
    }

    if (!store.estimateDetails.ContactName) {
      setContactNameError('Contact Name is required');
      isValid = false;
    } else {
      setContactNameError('');
    }

    if (!store.estimateDetails.ContactPhone) {
      setPhoneError('Contact Phone Number is required');
      isValid = false;
    } else if (!validatePhoneNumber(store.estimateDetails.ContactPhone)) {
      setPhoneError('Invalid phone number');
      isValid = false;
    } else {
      setPhoneError('');
    }

    if (!store.estimateDetails.ContactEmail) {
      setEmailError('Contact Email Address is required');
      isValid = false;
    } else if (!validateEmail(store.estimateDetails.ContactEmail)) {
      setEmailError('Invalid email address');
      isValid = false;
    } else {
      setEmailError('');
    }

    if (!store.estimateDetails.QuotedFor) {
      setQuotedForError('Quoted For Name is required');
      isValid = false;
    } else {
      setQuotedForError('');
    }
    if (!isValid) {
      alert('Please ensure all required fields are completed before proceeding.');
    }
    return isValid;
  }




  const handleSave = async () => {
    let isValid = validate();
    if (isValid) {
      await taskstore.save();
      await partstore.save();
      await store.save();
    }
  };

  const deletePart = async (part: any) => {
    partstore.delete(part);
  };


  const fileTypes = ["JPG", "JPEG", "PNG", "GIF", "BMP", "TIFF", "PDF", "DOC", "DOCX", "MSG", "XLS", "XLSX", "PPT", "PPTX", "TXT", "RTF", "CSV", "ODT", "ODS", "ODP", "SVG", "PSD", "EML"];

  const handleFileUploadChange = async (files: FileList) => {
    const uploadPromises = Array.from(files).map(async (file) => {
      const request = {
        RequestId: store.estimateDetails.RequestID || "",
        EstimateNumber: store.estimateDetails.EstimateNr || "",
        EquipmentID: store.estimateDetails.EquipmentID || "",
        Make: store.estimateDetails.Make || "",
        Model: store.estimateDetails.Model || "",
        SerialNumber: store.estimateDetails.SerialNumber || "",
        DebtorID: store.estimateDetails.DebtorID || "",
        CustomerName: store.estimateDetails.CustomerName || ""
      };
      await store.uploadFileToSharePoint(file, request);
    });

    await Promise.all(uploadPromises);
  };

  const handleSubmit = async () => {
    let isValid = validate();
    if (isValid) {
      await store.getStatusID("Estimate Sent");
      await handleSave();
      //  store.submit();
    }
  };

  const handleEdit = async () => {
    await store.createRevision();
    await store.save();
    await taskstore.save();
    await partstore.save();
    history.push(`/estimates/${store.estimateDetails.ID}`);
  };

  const handleApproveEstimate = async () => {
    await store.getStatusID("Estimate Accepted");
    await store.acceptEstimate();
  }

  return (
    <form>
      <Accordion expanded={isGIOpen}>
        <Grid container item xs={12}>
          <Grid container direction="row" alignItems="center" item xs={3}>
            <IconButton
              onClick={handleExpandCollapseGIClick}
              aria-expanded={isGIOpen}
            >
              {isGIOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
            <Typography variant="h6" gutterBottom>
              General Information
            </Typography>
          </Grid>
        </Grid>
        <Paper>
          <AccordionDetails>
            <Grid container spacing={10}>
              <Grid item lg={12} md={12} xs={12}>
                <Grid container spacing={1}>
                  <Grid item lg={9} md={9} xs={9}>
                    <TextField
                      fullWidth={true}
                      label="Request Summary"
                      variant='outlined'
                      multiline minRows={10}
                      margin="dense"
                      value={`${store.estimateDetails.JobDescription}\n\nEstimated Labour: ${store.estimateDetails.TimeEst}`}
                      disabled />
                  </Grid>
                  <Grid item lg={1} md={1} xs={1}>
                  </Grid>
                  <Grid item lg={2} md={2} xs={2}>
                    <Grid container spacing={1}>

                      <TextField
                        label="Estimate Number"
                        margin="dense"
                        value={store.estimateDetails.EstimateNr}
                        disabled />
                      <TextField
                        label="Revision"
                        margin="dense"
                        value={store.estimateDetails.Revision}
                        disabled />
                      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <SingleSelectWithKey
                          label="Set Estimate Status"
                          values={store.estimateStatusList}
                          selectedValue={store.estimateDetails.Status}
                          onChanged={handleStatusChange}
                          error={!!estimateStatusError}
                          disabled={store.isReadOnly} />
                        <TextField
                          fullWidth={true}
                          label="Estimate Status"
                          margin="dense"
                          value={store.statusName}
                          disabled />
                        <Button
                          style={{ marginTop: '50px' }}
                          variant="contained"
                          color="primary"
                          onClick={openSharePoint}
                          fullWidth>
                          View Additional Documents
                        </Button>
                        <div style={{ marginTop: '20px' }}>
                             <FileUploader
                              multiple={true}
                              handleChange={handleFileUploadChange}
                              name="file"
                              types={fileTypes}
                            />
                        </div>
                        <Button
                          style={{ marginTop: '20px' }}
                          variant="contained"
                          color="primary"
                          onClick={openSSRSReport}
                          fullWidth>
                          View Estimate
                        </Button>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item lg={3} md={3} xs={3}>
                < Grid container spacing={1}>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth={true}
                      label="Customer Name"
                      margin="dense"
                      onChange={handleCustomerNameChange}
                      error={!!customerNameError}
                      helperText={customerNameError}
                      required
                      value={store.estimateDetails.CustomerName}
                      disabled={store.isReadOnly} />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth={true}
                      label="Street Address"
                      margin="dense"
                      onChange={handleAddressChange}
                      error={!!AddressError}
                      helperText={AddressError}
                      required
                      value={store.estimateDetails.Address}
                      disabled={store.isReadOnly} />
                  </Grid>
                  <Grid item xs={8}>
                    <TextField
                      fullWidth={true}
                      label="Suburb"
                      margin="dense"
                      onChange={handleSuburbChange}
                      error={!!SuburbError}
                      helperText={SuburbError}
                      value={store.estimateDetails.Suburb}
                      disabled={store.isReadOnly} />
                  </Grid>
                  <Grid item xs={2}>
                    <TextField
                      fullWidth={true}
                      label="Postcode"
                      margin="dense"
                      onChange={handlePostcodeChange}
                      error={!!PostcodeError}
                      helperText={PostcodeError}
                      required
                      value={store.estimateDetails.Postcode}
                      disabled={store.isReadOnly} />
                  </Grid>
                  <Grid item xs={2}>
                    <TextField
                      fullWidth={true}
                      label="State"
                      margin="dense"
                      onChange={handleStateChange}
                      error={!!stateError}
                      helperText={stateError}
                      value={store.estimateDetails.State}
                      disabled={store.isReadOnly} />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth={true}
                      label="Contact Name"
                      margin="dense"
                      value={store.estimateDetails.ContactName}
                      onChange={handleContactNameChange}
                      error={!!contactNameError}
                      helperText={contactNameError}
                      required
                      disabled={store.isReadOnly} />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth={true}
                      label="Contact Phone Number"
                      margin="dense"
                      value={store.estimateDetails.ContactPhone}
                      onChange={handleContactPhoneChange}
                      error={!!phoneError}
                      helperText={phoneError}
                      required
                      disabled={store.isReadOnly} />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth={true}
                      label="Contact Email Address"
                      margin="dense"
                      value={store.estimateDetails.ContactEmail}
                      onChange={handleContactEmailChange}
                      error={!!emailError}
                      helperText={emailError}
                      required
                      disabled={store.isReadOnly} />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item lg={3} md={3} xs={3}>
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth={true}
                      label="Equipment ID"
                      margin="dense"
                      value={store.estimateDetails.EquipmentID}
                      disabled />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth={true}
                      label="Job Type"
                      margin="dense"
                      value={store.estimateDetails.FleetType}
                      disabled />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth={true}
                      label="Make"
                      margin="dense"
                      onChange={handleMakeChange}
                      error={!!makeError}
                      helperText={makeError}
                      required
                      value={store.estimateDetails.Make}
                      disabled={store.isReadOnly} />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth={true}
                      label="Model"
                      margin="dense"
                      onChange={handleModelChange}
                      error={!!modelError}
                      helperText={modelError}
                      required
                      value={store.estimateDetails.Model}
                      disabled={store.isReadOnly} />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth={true}
                      label="Serial Number"
                      margin="dense"
                      onChange={handleSerialNumberChange}
                      error={!!serialNumberError}
                      helperText={serialNumberError}
                      required
                      value={store.estimateDetails.SerialNumber}
                      disabled={store.isReadOnly} />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth={true}
                      label="Fleet Number"
                      margin="dense"
                      onChange={handleFleetNumberChange}
                      value={store.estimateDetails.FleetNumber}
                      disabled={store.isReadOnly} />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth={true}
                      label="Engine Hours"
                      margin="dense"
                      value={store.estimateDetails.EngineHours}
                      onChange={handleEngineHoursChange}
                      disabled={store.isReadOnly} />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth={true}
                      label="Originating Call Number"
                      margin="dense"
                      value={store.estimateDetails.OriginCallNr}
                      onChange={handleOriginCallNumberChange}
                      disabled={store.isReadOnly} />
                  </Grid>
                  <Grid item xs={12}>
                    <SingleSelectWithKey
                      label="Select Area"
                      values={store.areaList}
                      selectedValue={store.estimateDetails.Area}
                      onChanged={store.selectArea}
                      error={!!areaError}
                      disabled={store.isReadOnly} />
                  </Grid>
                  <Grid item xs={12}>
                    <SingleSelectWithKey
                      label="Select Equipment Type"
                      values={store.equipmentTypeList}
                      selectedValue={store.estimateDetails.EquipmentType}
                      onChanged={store.selectEquipmentType}
                      error={!!equipmentTypeError}
                      disabled={store.isReadOnly} />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item lg={3} md={3} xs={3}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth={true}
                      label="Quoted For"
                      margin="dense"
                      value={store.estimateDetails.QuotedFor}
                      onChange={handleQuotedForChange}
                      error={!!quotedForError}
                      helperText={quotedForError}
                      required
                      disabled={store.isReadOnly} />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth={true}
                      label="Transport Cost"
                      value={store.estimateDetails.TransportCost || ''}
                      onChange={handleTransportCostChange}
                      type="number"
                      disabled={store.isReadOnly} />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth={true}
                      label="Transport Sell"
                      value={store.estimateDetails.TransportSell || ''}
                      onChange={handleTransportSellChange}
                      type="number"
                      disabled={store.isReadOnly} />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth={true}
                      label="Freight"
                      value={store.estimateDetails.Freight || ''}
                      onChange={handleFreightChange}
                      type="number"
                      disabled={store.isReadOnly} />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth={true}
                      label="Enviro Cost"
                      value={store.estimateDetails.Enviro || ''}
                      onChange={handleEnviroChange}
                      type="number"
                      disabled={store.isReadOnly} />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth={true}
                      label="Consumables"
                      value={store.estimateDetails.Consumables || ''}
                      onChange={handleConsumablesChange}
                      type="number"
                      disabled={store.isReadOnly} />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth={true}
                      label="Admin Fee"
                      value={store.estimateDetails.AdminFee || ''}
                      onChange={handleAdminFeeChange}
                      type="number"
                      disabled={store.isReadOnly} />
                  </Grid>
                </Grid>

              </Grid>
              <Grid item lg={3} md={3} xs={3}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth={true}
                    label="Price Level"
                    value={store.estimateDetails.PriceLevel}
                    disabled />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth={true}
                    label="Labour Rate"
                    value={store.estimateDetails.LabourRate || ''}
                    onChange={handleLabourRateChange}
                    type="number"
                    disabled={store.isReadOnly} />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth={true}
                    label="Labour Cost"
                    value={store.estimateDetails.LabourCost}
                    type="number"
                    disabled />
                </Grid>

              </Grid>

            </Grid>
          </AccordionDetails>
        </Paper>
      </Accordion>

      <Accordion expanded={isPartsOpen}>
        <Grid container item xs={9}>
          <Grid container direction="row" alignItems="center" item xs={3}>
            <IconButton
              onClick={handleExpandCollapsePartsClick}
              aria-expanded={isPartsOpen}
            >
              {isPartsOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
            <Typography variant="h6" gutterBottom>
              Parts
            </Typography>
          </Grid>
        </Grid>
        <Paper>
          <AccordionDetails>
            <Grid item lg={9} md={9} xs={9}>
              < Grid container spacing={1}>
                <Grid item xs={12}>
                  <EstimateParts EstimatePartModel={partstore.parts}
                    parts={partstore.parts.parts}
                    onDelete={deletePart}
                    PartStore={partstore}
                    estimateID={store.estimateDetails.ID}
                    debtorID={store.estimateDetails.DebtorID}
                    appStore={appStore}
                    isReadOnly={store.isReadOnly}
                  />
                </Grid>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Paper>
      </Accordion>


      <Accordion expanded={isTasksOpen}>
        <Grid container item xs={9}>
          <Grid container direction="row" alignItems="center" item xs={3}>
            <IconButton
              onClick={handleExpandCollapseTasksClick}
              aria-expanded={isTasksOpen}
            >
              {isTasksOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
            <Typography variant="h6" gutterBottom>
              Tasks
            </Typography>
          </Grid>
        </Grid>
        <Paper>
          <AccordionDetails>
            <Grid item lg={9} md={9} xs={9}>
              < Grid container spacing={1}>
                <Grid item xs={12}>
                  <EstimateTasks EstimateTaskModel={taskstore.tasks}
                    tasks={taskstore.tasks.tasks}
                    TaskStore={taskstore}
                    onSave={taskstore.save}
                    onDelete={taskstore.delete}
                    estimateID={store.estimateDetails.ID}
                    appStore={appStore}
                    isReadOnly={store.isReadOnly}
                  />
                </Grid>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Paper>
      </Accordion>

      <Accordion expanded={isNotesOpen}>
        <Grid container item xs={9}>
          <Grid container direction="row" alignItems="center" item xs={3}>
            <IconButton
              onClick={handleExpandCollapseNotesClick}
              aria-expanded={isNotesOpen}
            >
              {isNotesOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
            <Typography variant="h6" gutterBottom>
              Additional Notes
            </Typography>
          </Grid>
        </Grid>
        <Paper>
          <AccordionDetails>
            <Grid container spacing={10}>
              <Grid item lg={9} md={9} xs={9}>
                < Grid container spacing={1}>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth={true}
                      variant="outlined"
                      label="Special Notes"
                      margin="dense"
                      multiline minRows={10}
                      value={store.estimateDetails.SpecialNotes}
                      onChange={handleSpecialNotesChange}
                      disabled={store.isReadOnly} />
                  </Grid>
                </Grid>
              </Grid>


            </Grid>
          </AccordionDetails>
        </Paper>
      </Accordion>


      <Accordion expanded={isSummaryOpen}>
        <Grid container item xs={9}>
          <Grid container direction="row" alignItems="center" item xs={3}>
            <IconButton
              onClick={handleExpandCollapseSummaryClick}
              aria-expanded={isSummaryOpen}
            >
              {isSummaryOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
            <Typography variant="h6" gutterBottom>
              Summary
            </Typography>
          </Grid>
        </Grid>
        <Paper>
          <AccordionDetails>
            <Grid container spacing={10}>
              <Grid item lg={9} md={9} xs={9}>
                < Grid container spacing={1}>
                  <Grid item xs={2}>
                    <TextField
                      label="Total Cost Price (+GST)"
                      name="totalCost"
                      value={(partstore.totalCost() + taskstore.totalHours() * store.estimateDetails.LabourCost).toLocaleString('en-AU', { style: 'currency', currency: 'AUD' })}
                      disabled />
                    <TextField
                      label="Parts GP [%]"
                      name="partsGp"
                      value={`${((partstore.totalSellPrice() - partstore.totalCost()) / partstore.totalSellPrice() * 100).toFixed(2)}%`}
                      disabled />
                    <TextField
                      label="Labour GP [%]"
                      name="labourGp"
                      value={`${((taskstore.totalHours() * store.estimateDetails.LabourRate - taskstore.totalHours() * store.estimateDetails.LabourCost) / (taskstore.totalHours() * store.estimateDetails.LabourRate) * 100).toFixed(2)}%`} disabled />
                    <TextField
                      label="Job GP [%]"
                      name="jobGpMargin"
                      value={((((partstore.totalSellPrice() +
                        taskstore.totalHours() * store.estimateDetails.LabourRate +
                        store.estimateDetails.TransportSell +
                        store.estimateDetails.Freight +
                        store.estimateDetails.Enviro +
                        store.estimateDetails.Consumables +
                        store.estimateDetails.AdminFee) -
                        (partstore.totalCost() + taskstore.totalHours() * store.estimateDetails.LabourCost + store.estimateDetails.TransportCost)) /
                        (partstore.totalSellPrice() +
                          taskstore.totalHours() * store.estimateDetails.LabourRate +
                          store.estimateDetails.TransportSell +
                          store.estimateDetails.Freight +
                          store.estimateDetails.Enviro +
                          store.estimateDetails.Consumables +
                          store.estimateDetails.AdminFee)) * 100).toFixed(2) + '%'}
                      disabled />
                    <TextField
                      label="Job GP [$]"
                      name="jobGpValue"
                      value={((partstore.totalSellPrice() +
                        taskstore.totalHours() * store.estimateDetails.LabourRate +
                        store.estimateDetails.TransportSell +
                        store.estimateDetails.Freight +
                        store.estimateDetails.Enviro +
                        store.estimateDetails.Consumables +
                        store.estimateDetails.AdminFee) -
                        (partstore.totalCost() + taskstore.totalHours() * store.estimateDetails.LabourCost + store.estimateDetails.TransportCost)).toLocaleString('en-AU', { style: 'currency', currency: 'AUD' })}
                      disabled />
                    <TextField
                      label='Total Sell Price'
                      name="totalSellPrice"
                      value={(partstore.totalSellPrice() +
                        taskstore.totalHours() * store.estimateDetails.LabourRate +
                        store.estimateDetails.TransportSell +
                        store.estimateDetails.Freight +
                        store.estimateDetails.Enviro +
                        store.estimateDetails.Consumables +
                        store.estimateDetails.AdminFee).toLocaleString('en-AU', { style: 'currency', currency: 'AUD' })}
                      disabled
                      InputProps={{
                        style: { fontWeight: 'bold', fontSize: '1.2em' }
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>


            </Grid>
          </AccordionDetails>
        </Paper>
      </Accordion>

      <Grid container spacing={1}>
        <Grid item xs={12}></Grid>
        <Grid item xs={1}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleEdit}
            disabled={!appStore.user?.isEstimateEditor || (appStore.user?.isEstimateEditor && !store.isEstimateSent)}
            fullWidth>
            Edit
          </Button>
        </Grid>
        <Grid item xs={9}>

        </Grid>
        <Grid item xs={1}>
          <Button variant="contained" color="primary" onClick={handleSave}
            disabled={!appStore.user?.isEstimateEditor === true || store.isReadOnly}
            fullWidth >Save</Button>
        </Grid>
        <Grid item xs={1}>
          <Button variant="contained" color="primary" onClick={handleSubmit}
            disabled={store.isReadOnly || !store.isEstimateReady}
            fullWidth >Submit</Button>
        </Grid>
        <Grid item xs={10}></Grid>
        <Grid item xs={2}>
          <Button variant="contained" color="primary" onClick={handleApproveEstimate}
            disabled={!store.isEstimateSent}
            fullWidth >Accept Estimate</Button>
        </Grid>
      </Grid>

    </form>
  );
});

export const EstimateForm = inject(STORE_KEYS.EstimateDetailsStore, STORE_KEYS.TaskStore, STORE_KEYS.PartStore, APP_INJECTION_KEY)(EstimateFormComponent);
import { AuthenticationContext, adalFetch, withAdalLogin, AdalConfig } from 'react-adal';

export const adalConfig: AdalConfig = {
  tenant: TENANT,
  redirectUri: REDIRECT_URI,
  clientId: CLIENT_ID,
  endpoints: {
    api: CLIENT_ID,
    graph: 'https://graph.microsoft.com',
  },
  cacheLocation: 'localStorage',
};


export const authContext = new AuthenticationContext(adalConfig);

export const adalApiFetch = (fetch, url: string, options): Promise<any> =>
  adalFetch(authContext, adalConfig.endpoints!.api, fetch, url, options);

export const graphApiFetch = (fetch, url: string, options): Promise<any> =>
  adalFetch(authContext, adalConfig.endpoints!.graph, fetch, url, options);

export const withAdalLoginApi = withAdalLogin(authContext, adalConfig.endpoints!.api);
export const withAdalLoginGraph = withAdalLogin(authContext, adalConfig.endpoints!.graph);
export const getToken = () => authContext.getCachedToken(adalConfig.clientId);

import { types, Instance } from 'mobx-state-tree';
import { setPartAPI } from './PartDetails';

export const EstimatePart = types.model({
  id: types.optional(types.string, ''),
  partNr: types.optional(types.string, ''),
  partDesc: types.optional(types.string, ''),
  estimateID: types.optional(types.string, ''),
  qty: types.optional(types.number, 0),
  stdCost: types.optional(types.number, 0),
  currCost: types.optional(types.number, 0),
  sellPrice: types.optional(types.number, 0),
  qtyOnHand: types.optional(types.number, 0),
  supplier: types.optional(types.string, ''),
  eta: types.optional(types.string, ''),
  partGroup: types.optional(types.string, ''),
})
  .actions((self) => {
    return {
      setPartNr: (debtorID: string, partNr: string) => {
        //setPartAPI(self, debtorID, partNr);
        self.partNr = partNr;
      },
      commitPartNr: (debtorID: string, partNr: string) => {
        setPartAPI(self, debtorID, partNr);
        //self.partNr = partNr;
      },
      setPartDesc: (partDesc: string) => {
        self.partDesc = partDesc;
      },
      setEstimateID: (estimateID: string) => {
        self.estimateID = estimateID;
      },
      setQty: (qty: number) => {
        self.qty = qty;
      },
      setStdCost: (stdCost: number) => {
        self.stdCost = stdCost;
      },
      setCurrCost: (currCost: number) => {
        self.currCost = currCost;
      },
      setSellPrice: (sellPrice: number) => {
        self.sellPrice = sellPrice;
      },
      setQtyOnHand: (qtyOnHand: number) => {
        self.qtyOnHand = qtyOnHand;
      },
      setSupplier: (supplier: string) => {
        self.supplier = supplier;
      },
      setETA: (eta: string) => {
        self.eta = eta;
      },
      setPartGroup: (partGroup: string) => {
        self.partGroup = partGroup;
      }

    };
  })
  .views((self) => ({
    equals(part) {
        return self.partNr === part.part
    }
  })
);

export type EstimatePartModel = Instance<typeof EstimatePart>;

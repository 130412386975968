import { types, Instance } from 'mobx-state-tree';

export const EstimateBranch = types.model({
  id: types.optional(types.string, ''),
  branch: types.optional(types.string, ''),
  officeLocation: types.optional(types.string, ''),
  viewAll: types.optional(types.boolean, false),
  threshold: types.optional(types.number, 0),
  retailSendTo: types.optional(types.string, ''),
  rentalAccessSendTo: types.optional(types.string, ''),
  rentalForkliftSendTo: types.optional(types.string, ''),
  sentFrom: types.optional(types.string, ''),
})
  .actions((self) => {
    return {
      setBranch: (branch: string) => {
          self.branch = branch;
      },
      setOffice: (office: string) => {
        self.officeLocation = office;
      },
      setViewAll: (viewAll: boolean) => {
        self.viewAll = viewAll;
      },
      setThreshold: (threshold: number) => {
        self.threshold = threshold;
      },
      setRetailSendTo: (retailSendTo: string) => {
        self.retailSendTo = retailSendTo;
      },
      setRentalAccessSendTo: (rentalAccessSendTo: string) => {
        self.rentalAccessSendTo = rentalAccessSendTo;
      },
      setRentalForkliftSendTo: (rentalForkliftSendTo: string) => {
        self.rentalForkliftSendTo = rentalForkliftSendTo;
      },
      setSentFrom: (sentFrom: string) => {
        self.sentFrom = sentFrom;
      },


    };
  })
  .views((self) => ({
      equals(branch) {
          return self.branch === branch.branch
              && self.officeLocation === branch.officeLocation
              && self.viewAll === branch.viewAll
              && self.threshold === branch.threshold
              && self.retailSendTo === branch.retailSendTo
              && self.rentalAccessSendTo === branch.rentalAccessSendTo
              && self.rentalForkliftSendTo === branch.rentalForkliftSendTo
              && self.sentFrom === branch.sentFrom;
      }
    })
  );

export type EstimateBranchModel = Instance<typeof EstimateBranch>;

import { observer } from 'mobx-react';
import { useStyles } from './tab.style';
import { Grid } from '@material-ui/core';
import React from 'react';
import { TabGridCell } from './TabGridCell';
import { ICustomerTabData } from './models/CustomerTabData';

interface Props {
  customerData : ICustomerTabData;
}

export const CustomerTab: React.FC<Props> = observer((props) => {
  const classes = useStyles();
  const { customerData } = props;

  return (
    <>
      <div  className={classes.firstTabSection}>
        <Grid container={true}
          direction="column"
          wrap="nowrap"
        >
          <Grid item={true}>
            <Grid 
              container={true} 
              spacing={2} 
              className={classes.gridRow}
            >
              <TabGridCell cellName='Company' cellData={customerData.companyName} className={classes.gridCellName} />
              <TabGridCell cellName='Contact Person' cellData={customerData.contactName} className={classes.gridCellName} />
            </Grid>
          </Grid>
          <Grid item={true}>
            <Grid 
              container={true} 
              spacing={2} 
              className={classes.gridRow}
            >
              <TabGridCell cellName='Phone Number' cellData={customerData.contactPhoneNumber} className={classes.gridCellName} />
              <TabGridCell cellName='Address' cellData={customerData.customerAddress} className={classes.gridCellName} />
            </Grid>
          </Grid>
        </Grid>
      </div>
      <div className={classes.middleTabSection}>
        <Grid container={true}
          direction="column"
          wrap="nowrap"
        >
          <Grid item={true}>
            <Grid 
              container={true} 
              spacing={2} 
              className={classes.gridRow}
            >
              <TabGridCell cellName='Service Call Status' cellData={customerData.status.toString()} className={classes.gridCellName} />
            </Grid>
          </Grid>
        </Grid>
      </div>
      <div className={classes.bottomTabSection}>
      </div>
    </>
  );
});

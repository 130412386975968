import { TimeSheetStatus } from './../../components/TimeSheetStatusViewer';
import { types, Instance } from 'mobx-state-tree';
import { ServiceCallDetails, SERVICE_CALL_NUMBER_LENGTH, setServiceCall } from './ServiceCallDetails';

export const Allocation = types.model({
  id: types.optional(types.string, ''),
  timelineId: types.optional(types.string, ''),
  serviceCallId: types.optional(types.string, ''),
  serviceCallNumber: types.optional(types.string, ''),
  serviceCallDetails: types.maybeNull(ServiceCallDetails),
  hours: types.optional(types.number, 0),
  status: types.enumeration('TimeSheetStatus', [
                TimeSheetStatus.Approved,
                TimeSheetStatus.New,
                TimeSheetStatus.Processed,
                TimeSheetStatus.Rejected,
                TimeSheetStatus.Submitted,
                TimeSheetStatus.Integrated,
                TimeSheetStatus.NoData,
                TimeSheetStatus.Draft,
                TimeSheetStatus.Final
              ]),
  tempStatus: types.enumeration('TimeSheetStatus', [
                TimeSheetStatus.Draft,
                TimeSheetStatus.Final
              ]),
  serviceCallError: types.optional(types.string, ''),
  isValid: types.optional(types.boolean, false)
})
.actions((self) => {
    const setServiceCallNumber = (serviceCallId, date) => {
      setServiceCall(self, serviceCallId, date, 4);
    };

    const setTimelineId = (timelineId) => {
      self.timelineId = timelineId;
    };

    const setHours = (hours) => {
      self.hours = hours;
    };

    const setIsValid = (isValid) => {
      self.isValid = isValid;
    };

    return {
      setHours,
      setIsValid,
      setServiceCallNumber,
      setTimelineId
    };
})
.views((self) => {
    return {
    get canDelete() {
      return self.status === TimeSheetStatus.New;
    },
    get allocHours() {
      const hours = Number(self.hours);
      return isNaN(hours) ? 0 : hours;
    },
    validate() {
       const result = {
        hoursError: self.hours === 0 ? 'Hours should not be a zero' : '',
        timelineIdError: !self.timelineId ? 'Value can not be empty' : '',
        serviceCallError: !self.serviceCallNumber || self.serviceCallNumber.length !== SERVICE_CALL_NUMBER_LENGTH
          ? 'Invalid service call' : self.serviceCallError
       };

       self.setIsValid(!result.hoursError && !result.timelineIdError && !result.serviceCallError);
       return result;
    },
    get isReadOnly() {
      return self.status === TimeSheetStatus.Submitted || self.status === TimeSheetStatus.Approved ||
      self.status === TimeSheetStatus.Integrated;
    },
    equals(allocation) {
      return self.id === allocation.id
        && self.status === allocation.status
        && self.timelineId === allocation.timelineId
        && self.serviceCallNumber === allocation.serviceCallNumber
        && self.serviceCallId === allocation.serviceCallId
        && self.hours === allocation.hours;
    },
  };
});

export type AllocationModel = Instance<typeof Allocation>;

import { observer } from 'mobx-react';
import { useStyles } from './tab.style';
import { Grid, TextareaAutosize } from '@material-ui/core';
import React from 'react';
import { TabGridCell } from './TabGridCell';
import { IDetailsTabData } from './models/DetailsTabData';
import { parseISO } from 'date-fns';
import { formatDate, formatTime } from '@utils/dateTime';

interface Props {
  detailsData: IDetailsTabData;
}

export const DetailsTab: React.FC<Props> = observer((props) => {
  const classes = useStyles();
  const { detailsData } = props;

  return (
    <>
      <div className={classes.firstTabSection}>
        <div className={classes.tabName}>Technician</div>
        <div>{detailsData.technician}</div>
      </div>
      <div className={classes.middleTabSection}>
        <div className={classes.tabName}>Times</div>
        <Grid container={true}
          direction="column"
          wrap="nowrap"
        >
          <Grid item={true}>
            <Grid
              container={true}
              spacing={2}
              className={classes.gridRow}
            >
              <TabGridCell cellName='Scheduled Start'
                cellData={detailsData.scheduledStart && `${formatDate(parseISO(detailsData.scheduledStart))} ${formatTime(parseISO(detailsData.scheduledStart))}`}
                className={classes.gridCellName} />
              <TabGridCell cellName='Actual Start'
                cellData={detailsData.actualStart && `${formatDate(parseISO(detailsData.actualStart))} ${formatTime(parseISO(detailsData.actualStart))}`}
                className={classes.gridCellName} />
            </Grid>
          </Grid>
          <Grid item={true}>
            <Grid
              container={true}
              spacing={2}
              className={classes.gridRow}
            >
              <TabGridCell cellName='Estimated Hours' cellData={detailsData.estimatedHours.toString()} className={classes.gridCellName} />
              <TabGridCell cellName='Actual End' cellData={detailsData.actualEnd && `${formatDate(parseISO(detailsData.actualEnd))} ${formatTime(parseISO(detailsData.actualEnd))}`} className={classes.gridCellName} />
            </Grid>
          </Grid>
        </Grid>
      </div>
      <div className={classes.bottomTabSection}>
        <div className={classes.tabName}>Equipment</div>
        <Grid container={true}
          direction="column"
          wrap="nowrap"
        >
          <Grid item={true}>
            <Grid
              container={true}
              spacing={2}
              className={classes.gridRow}
            >
              <TabGridCell cellName='Manufacturer' cellData={detailsData.unitMake} className={classes.gridCellName} />
              <TabGridCell cellName='Model' cellData={detailsData.unitModel} className={classes.gridCellName} />
            </Grid>
          </Grid>
          <Grid item={true}>
            <Grid
              container={true}
              spacing={2}
              className={classes.gridRow}
            >
              <TabGridCell cellName='Serial No.' cellData={detailsData.unitSerial} className={classes.gridCellName} />
              <TabGridCell cellName='Equipment ID' cellData={detailsData.equipmentId} className={classes.gridCellName} />
            </Grid>
          </Grid>
          <Grid item={true}>
            <Grid
              container={true}
              spacing={2}
              className={classes.gridRow}
            >
              <TabGridCell cellName='Problem Code' cellData={detailsData.problemCode} className={classes.gridCellName} />
            </Grid>
          </Grid>
          <Grid item={true}>
            <Grid
              container={true}
              spacing={2}
              className={classes.gridRow}
            >
              <Grid item xs={12}>
                <div className={classes.gridCellName}>Description</div>
              </Grid>
              <TextareaAutosize
                className={classes.textArea}
                rowsMin={4}
                rowsMax={5}
                aria-label="minimum height"
                placeholder='Description'
                value={detailsData.description}
              />
            </Grid>
          </Grid>
        </Grid>
      </div>
    </>
  );
});

import React from 'react';

import { useStyles } from './ActionButtons.style';
import { Grid, Button } from '@material-ui/core';

// Extend if needed
export interface ActionButtonsProps {
  leftBtnTest: string;
  rightBtnTest?: string;
  leftAction: AnyFunction;
  rigthAction?: AnyFunction;
  hideRightBtn: boolean;
  disabled?: boolean;
}

export const ActionButtons: React.FC<ActionButtonsProps> = ({ leftBtnTest, rightBtnTest, leftAction, rigthAction, hideRightBtn, disabled }) => {
  const s = useStyles();

  return (
    <>
      <Grid
        container={true}
        spacing={2}
        className={s.actions}
      >
        <Grid item xs={1}>
          <Button
            fullWidth={true}
            className={s.action}
            variant="outlined"
            color="primary"
            onClick={leftAction}
            disabled={disabled}
          >
            {leftBtnTest}
          </Button>
        </Grid>
        {!hideRightBtn &&
          <Grid item xs={1}>
            <Button
              fullWidth={true}
              className={s.action}
              variant="outlined"
              color="primary"
              onClick={rigthAction}
              disabled={disabled}
            >
              {rightBtnTest}
            </Button>
          </Grid>
        }
      </Grid>
    </>
  );
};

ActionButtons.displayName = 'ActionButtons';

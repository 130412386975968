import { observer } from 'mobx-react';
import React from 'react';
import { Grid, Divider, Button, createStyles, makeStyles, Theme} from '@material-ui/core';
import { AllocationRow } from './AllocationRow';
import ControlPointOutlinedIcon from '@material-ui/icons/ControlPointOutlined';
import { blueGrey } from '@material-ui/core/colors';
import clsx from 'clsx';
import { AllocationModel } from 'timesheet-details/domain/Allocations';
import { TimeSheetLineModel } from 'timesheet-details/domain/timesheet-lines/TimeSheetLine';
import { GpAdjustmentModel } from 'timesheet-details/domain/gp-adjustments/GpAdjustment';
import { TimeSheetStatus } from 'components/TimeSheetStatusViewer';

interface Props {
    disabled?: boolean;
    allocations: AllocationModel[];
    adjustments: GpAdjustmentModel[];
    recoverableLostTimeLines: TimeSheetLineModel[];
    date: Date;
    maximumCallAdjustmentAllocations: number;
    onAdd: () => void;
    onDelete: (allocation: AllocationModel) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
        marginBottom: theme.spacing(1)
    },
    newRow: {
        paddingLeft: theme.spacing(1),
        paddingTop: theme.spacing(1),
    },
    row: {
        padding: theme.spacing(1)
    },
    even: {
        backgroundColor: blueGrey[50]
    }
}));

export const AllocationsDetails: React.FC<Props> = observer((props) => {
    const classes = useStyles();
    const { allocations, recoverableLostTimeLines, onAdd, onDelete, disabled, date, adjustments, maximumCallAdjustmentAllocations} = props;

    const canAddNewAllocation = !!recoverableLostTimeLines &&
        recoverableLostTimeLines.length > 0;
        
    const canAddAllocations= maximumCallAdjustmentAllocations - (adjustments.filter(x=> x.tempStatus == TimeSheetStatus.Draft).length + allocations.filter(x=> x.tempStatus == TimeSheetStatus.Draft).length) > 0;

    const handleAddAllocation = () => {
        onAdd();
    };

    return (
    <Grid container direction="column">
         <Divider />
         <Grid className={clsx(allocations.length === 0 && classes.row, classes.newRow)}
             container direction="row" alignItems="center"
         >
            <Button
                variant="outlined"
                color="primary"
                disabled={!canAddNewAllocation || disabled || !canAddAllocations }
                onClick={handleAddAllocation}
                startIcon={<ControlPointOutlinedIcon />}
            >
                ADD NEW LINE
            </Button>
        </Grid>
        { allocations.map((allocation, index) =>
            <Grid className={clsx(index % 2 === 1 && classes.even, classes.row)}
                key={`allocation_${index}`}
            >
                <AllocationRow
                    date ={date}
                    recoverableLostTimeLines={recoverableLostTimeLines}
                    disabled={disabled}
                    allocation={allocation}
                    onDelete={onDelete}
                />
            </Grid>)
        }
    </Grid>
  );
});

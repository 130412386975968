import { IApiStore, EndpointCallParams, ApiResponse } from "api";

export type PayrollAdjusmentRequest = {
  processed: boolean;
  fromDate: Date
  toDate: Date
}

export const getPayrollEnquiries = async (
  api: IApiStore,
  params: EndpointCallParams<PayrollAdjusmentRequest>,
): Promise<ApiResponse<any>> => { //TODO: add type
  const p = params.payload;
  const url = `/api/payrollAdjustments?processed=${p.processed}&fromDate=${p.fromDate.toDateString()}&toDate=${p.toDate.toDateString()}`;

  return await api.get(url, params);
};

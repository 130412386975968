import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import React, { useEffect, useState } from 'react';
import { PayrollAdjustmentEnquiryModel } from '../domain/PayrollAdjustmentEnquiry';
import EnhancedTableHead, { HeadCell } from '../../components/enhanced-table/EnhancedTableHead';
import { observer } from 'mobx-react-lite';
import { SortOrder, formatDate_yyyymmdd, sort } from '@utils';
import { useStyles } from './table.style';
import { IPayrollEnquiryGroup } from 'payroll-adjustment-enquiry/domain/PayrollEnquiryGroup';
import { PayrollEnquiryGroup } from './PayrollEnquiryGroup';
import { isEmpty } from 'lodash';
import { useFlags } from 'launchdarkly-react-client-sdk';

interface Props {
  enquiryGroups: IPayrollEnquiryGroup[];
  amountOfRows: number;
  amountOfSelectedRows: number;
}

const headCellsEnum: Array<HeadCell<PayrollAdjustmentEnquiryModel>> = [
  { id: 'technicianId', label: 'Technician' },
  { id: 'processDate', label: 'Date' },
  { id: 'startTime', label: 'Start Time' },
  { id: 'endTime', label: 'End time' },
  { id: 'type', label: 'Type' },
  { id: 'leaveType', label: 'Leave Type' },
  { id: 'reason', label: 'Reason' },
  { id: 'amount', label: 'Amount' },
  { id: 'callout', label: 'Call Out' },
  { id: 'totalPayHoursSum', label: 'Total Pay Hours' },
  { id: 'payT', label: 'Pay T' },
  { id: 'payTH', label: 'Pay T/H' },
  { id: 'payD', label: 'Pay D' },
  { id: 'payDH', label: 'Pay D/H' },
];

const headCellsV1Enum: Array<HeadCell<PayrollAdjustmentEnquiryModel>> = [
  { id: 'technicianId', label: 'Technician' },
  { id: 'processDate', label: 'Date' },
  { id: 'type', label: 'Type' },
  { id: 'reason', label: 'Reason' },
  { id: 'amount', label: 'Amount' },
  { id: 'totalPayHoursSum', label: 'Total Pay Hours' },
  { id: 'payT', label: 'Pay T' },
  { id: 'payTH', label: 'Pay T/H' },
  { id: 'payD', label: 'Pay D' },
  { id: 'payDH', label: 'Pay D/H' },
];

const headCellsV2Enum: Array<HeadCell<PayrollAdjustmentEnquiryModel>> = [
  { id: 'technicianId', label: 'Technician' },
  { id: 'processDate', label: 'Date' },
  { id: 'startTime', label: 'Start Time' },
  { id: 'endTime', label: 'End time' },
  { id: 'type', label: 'Type' },
  { id: 'leaveType', label: 'Leave Type' },
  { id: 'reason', label: 'Reason' },
  { id: 'amount', label: 'Amount' },
  { id: 'callout', label: 'Call Out' },
  { id: 'payT', label: 'Pay T' },
];

export const PayrollEnquiryTable: React.FC<Props> = observer((props) => {
  const flags = useFlags();
  const cutOffDate = flags.smartServicePayrollMetricsCutoverDate

  const classes = useStyles();
  const { enquiryGroups, amountOfRows, amountOfSelectedRows } = props;
  
  const [order, setOrder] = useState();
  const [orderBy, setOrderBy] = useState();
  const [headCells, setHeadCells] = useState(headCellsEnum)
  const [showV1Fields, setShowV1Fields] = useState(false);
  const [showV2Fields, setShowV2Fields] = useState(false);
  const [showAllFields, setShowAllFields] = useState(false);

  const handleRequestSort = (newSortOrder: SortOrder, newOrderBy: keyof PayrollAdjustmentEnquiryModel) => {
    setOrder(newSortOrder);
    setOrderBy(newOrderBy);
  };
  
  const handleSelectAllClick = () => {
    enquiryGroups.forEach(group => group.selectAllItems());
  };

  useEffect(() => {
    const hasV1Data = enquiryGroups.filter(x => x.filteredEnquiries.filter(y => Date.parse(formatDate_yyyymmdd(new Date(y.date || '9999-01-01'))) < Date.parse(formatDate_yyyymmdd(new Date(cutOffDate)))).length > 0).length > 0;
    const hasV2Data = enquiryGroups.filter(x => x.filteredEnquiries.filter(y => Date.parse(formatDate_yyyymmdd(new Date(y.date || '9999-01-01'))) >= Date.parse(formatDate_yyyymmdd(new Date(cutOffDate)))).length > 0).length > 0;

    if(hasV1Data && hasV2Data)
    {
      setShowAllFields(show => !show);
    }

    if(hasV1Data && !hasV2Data){
      setShowV1Fields(true);
      setHeadCells(headCellsV1Enum);
    }

    if(!hasV1Data && hasV2Data){
      setShowV2Fields(true);
      setHeadCells(headCellsV2Enum);
    }
  }, []);

  return (
    <Paper className={classes.root}>
      <Table stickyHeader={true} size="small">
        <EnhancedTableHead<PayrollAdjustmentEnquiryModel>
          headCells={headCells}
          numSelected={amountOfSelectedRows}
          rowCount={amountOfRows}
          onSelectAllClick={handleSelectAllClick}
          order={order}
          orderBy={orderBy}
          onSort={handleRequestSort}
        />
        {sort<IPayrollEnquiryGroup>(enquiryGroups, 'asc', 'team').map((group, index) => (
          !isEmpty(group.filteredEnquiries) &&
          <PayrollEnquiryGroup
            key={index}
            order={order}
            orderBy={orderBy}
            enquiryGroup={group}
            showV1Fields={showV1Fields}
            showV2Fields={showV2Fields}
            showAllFields={showAllFields}
          />
        ))}
      </Table>
    </Paper>
  );
});

import { observer } from 'mobx-react';
import React from 'react';
import { Grid } from '@material-ui/core';
import { TimeSheetHoursModel } from '../domain/TimeSheetHours';
import { HoursInput } from './HoursInput';

interface Props {
    disabled?: boolean;
    disableGPFields?: boolean;
    disablePayFields?: boolean;
    disableOvertimeFields?: boolean;
    allowNegative?: boolean;
    model: TimeSheetHoursModel;

    onNormalPayChange?: (hours: number) => void;
    onOvertimeChange?: (hours: number) => void;
    onDoubleTimeChange?: (hours: number) => void;
    onOvertimeDoubleTimeChange?: (hours: number) => void;
    onNormalGPChange?: (hours: number) => void;
    onOvertimeGPChange?: (hours: number) => void;
    onDoubleTimeGPChange?: (hours: number) => void;
    onOvertimeDoubleTimeGPChange?: (hours: number) => void;
}

export const HoursRow: React.FC<Props> = observer((props) => {
    const { model, disabled, disableGPFields, disablePayFields } = props;
    const { onNormalPayChange, onOvertimeChange, onDoubleTimeChange, onOvertimeDoubleTimeChange,
        onNormalGPChange, onOvertimeGPChange, onDoubleTimeGPChange, onOvertimeDoubleTimeGPChange,
        allowNegative, disableOvertimeFields} = props;

    const { invalidTotalPayH, invalidTotalGP } = model.validate();

    return (
        <Grid container={true} direction="row" alignItems="center" spacing={2}>
            <Grid item={true} xs={1} />
            <Grid item={true} xs={11}>
                <Grid container direction="row" justify="space-around" alignItems="center">
                    <HoursInput
                        label="Total Pay H"
                        disabled={true}
                        hours={model.totalPay}
                        allowNegative={allowNegative}
                    />
                    <HoursInput
                        label="Total GP H"
                        disabled={true}
                        hours={model.totalGP}
                        allowNegative={allowNegative}
                    />
                    <HoursInput
                        label="Alloc Hours"
                        disabled={true}
                        hours={model.allocation}
                        allowNegative={allowNegative}
                    />
                    <HoursInput
                        label="Pay T"
                        disabled={disabled || disablePayFields}
                        hours={model.normalPay}
                        onChange={onNormalPayChange || model.setNormalPay}
                        error={invalidTotalPayH}
                        allowNegative={allowNegative}
                    />
                    <HoursInput
                        label="Pay T/H"
                        disabled={disabled || disablePayFields || disableOvertimeFields}
                        hours={model.overtime}
                        onChange={onOvertimeChange || model.setOvertime}
                        error={invalidTotalPayH}
                        allowNegative={allowNegative}
                    />
                    <HoursInput
                        label="Pay D"
                        disabled={disabled || disablePayFields || disableOvertimeFields}
                        hours={model.doubleTime}
                        onChange={onDoubleTimeChange || model.setDoubleTime}
                        error={invalidTotalPayH}
                        allowNegative={allowNegative}
                    />
                    <HoursInput
                        label="Pay D/H"
                        disabled={disabled || disablePayFields || disableOvertimeFields}
                        hours={model.overtimeDoubleTime}
                        onChange={onOvertimeDoubleTimeChange || model.setOvertimeDoubleTime}
                        error={invalidTotalPayH}
                        allowNegative={allowNegative}
                    />
                    <HoursInput
                        label="GP T"
                        disabled={disabled || disableGPFields}
                        hours={model.normalGP}
                        onChange={onNormalGPChange || model.setNormalGP}
                        error={invalidTotalGP}
                        allowNegative={allowNegative}
                    />
                    <HoursInput
                        label="GP T/H"
                        disabled={disabled || disableGPFields || disableOvertimeFields}
                        hours={model.overtimeGP}
                        onChange={onOvertimeGPChange || model.setOvertimeGP}
                        error={invalidTotalGP}
                        allowNegative={allowNegative}
                    />
                    <HoursInput
                        label="GP D"
                        disabled={disabled || disableGPFields || disableOvertimeFields}
                        hours={model.doubleTimeGP}
                        onChange={onDoubleTimeGPChange || model.setDoubleTimeGP}
                        error={invalidTotalGP}
                        allowNegative={allowNegative}
                    />
                    <HoursInput
                        label="GP D/H"
                        disabled={disabled || disableGPFields || disableOvertimeFields}
                        hours={model.overtimeDoubleTimeGP}
                        onChange={onOvertimeDoubleTimeGPChange || model.setOvertimeDoubleTimeGP}
                        error={invalidTotalGP}
                        allowNegative={allowNegative}
                    />
                </Grid>
            </Grid>
        </Grid>
    );
});

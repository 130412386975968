import { Instance, types, applySnapshot, flow, getEnv } from 'mobx-state-tree';
import { IStoresEnv } from '@core';
import { TimesheetSummary } from '../domain/TimesheetSummary';
import { formatDate_yyyymmdd } from '@utils';

export const TimesheetSummaryStore = types
  .model({
    teams: types.array(types.string),
    selectedTeam: types.optional(types.string, ''),
    selectedDate: types.optional(types.maybeNull(types.Date), new Date()),
    timesheetSummary: types.array(TimesheetSummary),
    isLoading: types.optional(types.boolean, false)
  })
  .actions((self) => { 
    const {
      api, auth
    } = getEnv<IStoresEnv>(self);

    const mapData = (y) => ({
      team: y.team,
      technicianId: y.technicianId,
      technicianName: y.technicianName,
      gpHours: y.gpHours,
      payrollHours: y.payrollHours,
      status: y.status,
      date: new Date(y.date)
    });

    const fetchData = flow(function* dateFetch() {
      if (!self.selectedDate || !self.selectedTeam) {
        applySnapshot(self.timesheetSummary, []);
        return;
      }

      self.isLoading = true;

      const apiUrl = `/api/DailyTimesheetSummary?team=${self.selectedTeam}&date=${formatDate_yyyymmdd(self.selectedDate)}`;
      const timesheetSummary = yield api.get(apiUrl);
      const result = timesheetSummary.data || [];
      applySnapshot(self.timesheetSummary, result.map((x) => mapData(x)));

      self.isLoading = false;
    });

    return {
      fetchFilters: flow(function* filtersFetch() {
        self.isLoading = true;
        let teams;
        if (auth.user?.isPayrollAdmin) {
          teams = yield api.get(`/api/teams`);
        } else {
          teams = yield api.get(`/api/teams/permitted`);
        }
        applySnapshot(self.teams, teams.data || []);

        self.isLoading = false;
      }),
      selectTeam(team: string) {
        self.selectedTeam = team;
        fetchData();
      },
      selectDate(date: Date | null) {
        self.selectedDate = date;
        fetchData();
      },
      selectState(team: string, date: Date | null) {
        self.selectedDate = date;
        self.selectedTeam = team;
        fetchData();
      },
      reloadData() {
        if(!!self.selectedDate && !! self.selectedTeam)
        {
          fetchData();
        }
      }
    };
  });

export type ITimesheetSummaryStore = Instance<typeof TimesheetSummaryStore>;

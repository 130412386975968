import { types, Instance } from 'mobx-state-tree';

export const LocalFind = types
    .model({
        id: types.optional(types.string, ''),
        branchName: types.optional(types.string, ''),
        displayName: types.optional(types.string, ''),
        searchText: types.optional(types.string, ''),
        isDeleting: types.optional(types.boolean, false)
    }).actions((self) => ({
        setIsDeleting: (isDeleting: boolean) => {
            self.isDeleting = isDeleting;
        }
    }));

export type LocalFindModel = Instance<typeof LocalFind>;

import { types, Instance } from 'mobx-state-tree';

export const TakeFiveSummary = types
    .model({
      technicianId: types.string,
      technicianName: types.string,
      totalCompletedTakeFive: types.optional(types.number, 0),
      startDate: types.Date,
      endDate: types.Date
    });

export type TakeFiveSummaryModel = Instance<typeof TakeFiveSummary>;

import { observer } from 'mobx-react';
import React from 'react';
import { EstimateTasksModel } from './domain/EstimateTasks';
import { EstimateTask } from './EstimateTask';
import { Button, makeStyles, createStyles, Theme } from '@material-ui/core';
import { EstimateTaskModel } from './domain/EstimateTask';
import { ITaskStore } from './store/TaskStore';
import { IAppStore } from '@shared/store';

interface Props {
    EstimateTaskModel: EstimateTasksModel;
    tasks: EstimateTaskModel[];
    onSave: (tasks: EstimateTaskModel[]) => void;
    onDelete: (task: EstimateTaskModel) => void;
    TaskStore: ITaskStore;
    estimateID: string;
    appStore: IAppStore;
    isReadOnly: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    taskWrapper: {
      background: '#fff',
      padding: '2rem'
    },
    label: {
      textTransform: 'capitalize'
    },
    newLineBtn: {
      marginTop: '1rem'
    },
    saveBtn: {
      textAlign: 'right'
    }
}));
export const EstimateTasks: React.FC<Props> = observer((props) => {

const { EstimateTaskModel, tasks, isReadOnly} = props;
const classes = useStyles();

const onAddNewTimeline = () => {
    return EstimateTaskModel.addNewLine();
};

const handleDelete = async (task) => {
  await props.onDelete(task);
};

  return (
    <form>
      { tasks.map((line, index) =>
        <EstimateTask
       // key={line.id} 
        task={line}
        estimateID={props.estimateID}
        TaskStore={props.TaskStore}
        AppStore={props.appStore}
        isReadOnly={isReadOnly}
        onDeleteClick={handleDelete}/>
      )}
      <Button
        variant="outlined"
        color="primary"
        onClick={onAddNewTimeline}
        className={classes.newLineBtn}
      >
        ADD NEW LINE
      </Button>
    </form>
  );
});
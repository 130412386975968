import { types, Instance } from 'mobx-state-tree';
import { EstimatePart} from './EstimatePart';

import { uuidv4 } from '@utils';


export const EstimateParts = types.model({
    parts: types.optional(types.array(EstimatePart), [])
})
  .actions((self) => {
    return {
      addNewLine: () => {
        const part: any = {
            id: uuidv4()
        };
        
        self.parts.push(part);
      }
    };
  })
  .views((self) => ({
    equals(parts) {
        return self.parts.filter(
          (t) => !parts.some( (x) => t.equals(x))).length === 0;
    }
  }));

export type EstimatePartsModel = Instance<typeof EstimateParts>;

import React from 'react';
import { observer } from "mobx-react";
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { CommonJobsModel } from 'estimating-tool/common-jobs/domain/CommonJobsSummary';
import {  Paper, Table, TableBody } from '@material-ui/core';
import SortableTableHead, { HeadCell } from 'components/sortable-table/SortableTableHead';
import { SortOrder } from '@utils';
import CommonJobsSummaryRow from './CommonJobsSummaryRow';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      overflow: 'auto',
      width: '100%',
      flex: 1
    },
    saveBtn: {
      textAlign: 'right'
    },
    label: {
      textTransform: 'capitalize'
    }
  }));

interface Props {
  CommonJobsSummary: CommonJobsModel[];
}

const headCells: Array<HeadCell<CommonJobsModel>> = [
  { id: 'selected', label: '' },
  { id: 'description', label: 'Description' },
  { id: 'labour', label: 'Labour allowance' },
  { id: 'price', label: 'Price' },

];

export const CommonJobsSummaryTable: React.FC<Props> = observer((props) => {
  const classes = useStyles();
  const [order, setOrder] = React.useState<SortOrder>('asc');
  const [orderBy, setOrderBy] = React.useState<keyof CommonJobsModel>('description');
 
  const handleCommonJobsSort = (newSortOrder: SortOrder, newOrderBy: keyof CommonJobsModel) => {
    setOrder(newSortOrder);
    setOrderBy(newOrderBy);
  };

  return (
    <Paper className={classes.root}>
      <Table stickyHeader={true} size="small">
        <SortableTableHead<CommonJobsModel>
          headCells={headCells}
          order={order}
          orderBy={orderBy}
          onSort={handleCommonJobsSort}
        />
        <TableBody>
          <CommonJobsSummaryRow />
        </TableBody>
      </Table>
    </Paper>
  );
});

import { types, Instance } from 'mobx-state-tree';
import { TimeSheetLine, TimeSheetLineModel } from './TimeSheetLine';
import { TimeSheetStatus } from 'components/TimeSheetStatusViewer';
import { TimeSheetLineType } from './TimeSheetLineType';
import { uuidv4, pluralize, DATE_FORMAT } from '@utils';
import { getTotalHours } from '../TimeSheetHours';
import { format } from 'date-fns';

interface TimeLineTotals {
  payrollHours: number;
  gPHours: number;
}

export const TimeSheetLines = types.model({
  timelines: types.optional(types.array(TimeSheetLine), [])
})
  .actions((self) => {
    const tuneDateForTimeLine = (date: Date, hours: number) => {
      date.setHours(hours);
      date.setMinutes(0);

      return format(date, DATE_FORMAT);
    };

    return {
      addTimeline: (date: Date, lostTimeTypes: string[], recoverableLostTimeTypes: string[], canAddNonLeave: boolean, timesheetCreationSecurityFlag: boolean ) => {
        const timeline: TimeSheetLineModel = {
          id: uuidv4(),
          newLine: true,
          status: TimeSheetStatus.New,
          startTime: tuneDateForTimeLine(date, 8),
          endTime: tuneDateForTimeLine(date, 16),
          hours: {},
          serviceCallDetails: {},
          lostTimeTypes: lostTimeTypes,
          recoverableLostTimeTypes: recoverableLostTimeTypes
        };

        if(timesheetCreationSecurityFlag) {
          timeline.type = canAddNonLeave ? TimeSheetLineType.ServiceCall : TimeSheetLineType.AnnualLeave 
        }
        else{
          timeline.type = TimeSheetLineType.ServiceCall
        }

        self.timelines.push(timeline);
        self.timelines[self.timelines.length - 1].recalculateHours();
      },
      deleteTimeLine: (timeline: TimeSheetLineModel) => {
        self.timelines.remove(timeline);
      },
    };
  })
  .views((self) => {
    const hasChangesInternal = (originalLine) => {
      const line = self.timelines.find((l) => l.id === originalLine.id);
      if (!line) {
        return true;
      }

      const isEqual = originalLine.equals(line);
      return !isEqual;
    };

    const hasOverlapWith = (timesheetLine: TimeSheetLineModel) => {
      return self.timelines.findIndex((t) => t !== timesheetLine && t.hasOverlap(timesheetLine)) !== -1;
    };

    const getNotBreaksHours = () => {
      return self.timelines.reduce<TimeLineTotals>((accumulator, line) => {
        if (line.type !== TimeSheetLineType.BreakTime && line.type !== TimeSheetLineType.ServiceCallBreakTime && line.type != TimeSheetLineType.FDOWorked) {
          accumulator.payrollHours += line.hours.normalPay;
          accumulator.gPHours += line.hours.normalGP;
        }
        else if (line.type == TimeSheetLineType.FDOWorked) {
          accumulator.payrollHours += line.hours.normalPay + line.hours.doubleTime + line.hours.overtime + line.hours.overtimeDoubleTime;
          accumulator.gPHours += line.hours.normalGP+ line.hours.doubleTimeGP + line.hours.overtimeGP + line.hours.overtimeDoubleTimeGP;
        }

        return accumulator;
      }, { payrollHours: 0, gPHours: 0 });
    };

    const isPayrollTotalsValid = (shift: number) => {
      const totals = getNotBreaksHours();
      return self.timelines.length === 0 ||
        totals.payrollHours >= shift;
    };

    const isGPTotalsValid = (shift: number) => {
      const totals = getNotBreaksHours();
      return self.timelines.length === 0 ||
        totals.gPHours >= shift;
    };

    const isServiceCallsValid = () => {
      return self.timelines.length === 0 || self.timelines.every((t) => {
        return t.isServiceCallValid
      });
    };

    const isLostTimeReasonsValid = () => {
      return self.timelines.length === 0 || self.timelines.every((t) => {
        return t.isLostTimeValid
      });
    }

    const validateTotals = (shift: number) => {
      return {
        payrollTotalsValidation: isPayrollTotalsValid(shift) ? '' : 'Total payroll hours should not be less than technician\'s shift',
        gPTotalsValidation: isGPTotalsValid(shift) ? '' : 'Total GP hours should not be less than technician\'s shift',
        serviceCallsValidation: isServiceCallsValid() ? '' : 'Some service call lines are missing a valid service call',
        lostTimeReasonValidation: isLostTimeReasonsValid() ? '' : 'Some lost time lines are missing a lost time reason'
      };
    };

    const validateOvertimeHours = (shift: number) => {
      const validationResult = {
        payrollOvertimeValidation: '',
        gpOvertimeValidation: ''
      };

      if (self.timelines.length === 0) {
        return validationResult;
      }

      const totals = getNotBreaksHours();
      if(!self.timelines.some((x) => x.status === TimeSheetStatus.Approved || x.status === TimeSheetStatus.Integrated || x.status === TimeSheetStatus.Processed || x.status === TimeSheetStatus.Submitted))
      {
        validationResult.payrollOvertimeValidation = totals.payrollHours > shift
            ? 'The normal payroll hours exceeds technician\'s shift, should the extra hours be entered as overtime'
            : '';

        validationResult.gpOvertimeValidation = totals.gPHours > shift
            ? 'The normal GP hours exceeds technician\'s shift, should this be entered as overtime'
            : '';
      }

      return validationResult;
    };

    return {
      get title() {
        const lines = self.timelines.length;
        return `Timesheet Lines ( ${lines} ${pluralize('event', 's', lines)} )`;
      },
      isValid(shift: number, skipShiftValidation: boolean) {
        const timelineValidation = !self.timelines.some( (t) => hasOverlapWith(t) || !t.isValid);
        if (skipShiftValidation) {
          return timelineValidation;
        }

        return timelineValidation &&
          isPayrollTotalsValid(shift) &&
          isGPTotalsValid(shift);
      },
      validateTotals,
      validate(timesheetLine: TimeSheetLineModel) {
        return {
          overlapError: hasOverlapWith(timesheetLine) ? 'This item overalps with other timelines' : ''
        };
      },
      get totalHours() {
        return getTotalHours(self.timelines.map((t) => t.hours));
      },
      get canAddTimeLine() {
        return !self.timelines.some((x) => x.status === TimeSheetStatus.Approved || x.status === TimeSheetStatus.Integrated);
      },
      hasChanges(originalData) {
        return originalData.timelines.length !== self.timelines.length
          || originalData.timelines.some(hasChangesInternal);
      },
      validateOvertimeHours
    };
  });

export type TimeSheetLinesModel = Instance<typeof TimeSheetLines>;

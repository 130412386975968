import { observer } from 'mobx-react';
import React from 'react';
import { TextField, TextFieldProps } from '@material-ui/core';


export const OutlinedInput: React.FC<TextFieldProps> = observer((props) => {
  return (
    <TextField {...props} variant="outlined" />
  );
});

import { observer } from 'mobx-react';
import React from 'react';
import { TakeFiveQuestionsModel } from './domain/TakeFiveQuestions';
import { TakeFiveQuestion } from './TakeFiveQuestion';
import CloudDownloadOutlinedIcon from '@material-ui/icons/CloudDownloadOutlined';
import { Button, makeStyles, createStyles, Theme } from '@material-ui/core';
import { TakeFiveQuestionModel } from './domain/TakeFiveQuestion';

interface Props {
    takeFiveModel: TakeFiveQuestionsModel;
    questions: TakeFiveQuestionModel[];
    hasChanges: boolean;
    onSave: (questions: TakeFiveQuestionModel[]) => void;
    onDelete: (question: TakeFiveQuestionModel) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    takeFiveWrapper: {
      background: '#fff',
      padding: '2rem'
    },
    label: {
      textTransform: 'capitalize'
    },
    newLineBtn: {
      marginTop: '1rem'
    },
    saveBtn: {
      textAlign: 'right'
    }
}));
export const TakeFiveQuestions: React.FC<Props> = observer((props) => {

const { takeFiveModel, questions, hasChanges} = props;
const classes = useStyles();

const onAddNewTimeline = () => {
    return takeFiveModel.addNewLine();
};

const handleSaveClick = () => {
  props.onSave(questions);
};

const handleDelete = (question) => {
  props.onDelete(question);
};

  return (
    <div className={classes.takeFiveWrapper}>
      <div className={classes.saveBtn}>
      <Button
          color="primary"
          disabled={!hasChanges}
          className={classes.label}
          startIcon= {<CloudDownloadOutlinedIcon/>} //{user.isSaving ? <CircularProgress size={14} /> : <CloudDownloadOutlinedIcon />}
          onClick={handleSaveClick}
      >
          Save
      </Button></div>
      { questions.map((line, index) =>
        <TakeFiveQuestion question={line}
        onDeleteClick={handleDelete}/>
      )}
      <Button
        variant="outlined"
        color="primary"
        onClick={onAddNewTimeline}
        className={classes.newLineBtn}
      >
        ADD NEW LINE
      </Button>
    </div>
  );
});
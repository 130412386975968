import { Instance, types, applySnapshot, flow, getEnv } from 'mobx-state-tree';

import { EstimateAreas } from '../domain/Areas';
import { EstimateAreaModel } from '../domain/Area';
import { uuidv4 } from '@utils';
import { IStoresEnv } from '@core';

export const AreaConfigStore = types
  .model({
    isLoading: types.optional(types.boolean, false),
    originalSettings: types.optional(EstimateAreas, {}),
    areas: types.optional(EstimateAreas, {})
  })
  .actions((self) => {
    const { api } = getEnv<IStoresEnv>(self);

    const mapArea = (x) => ({
      id: x.id, 
      area: x.area
    });
    const mapSettings = (x) => ({
      areas: x.areas
    });

    const applyData = (data) => {
        if(data.areas)
        {
          applySnapshot(self.originalSettings,
            { areas: data.areas.map((x) => mapArea(x)) });
          applySnapshot(self.areas,
            { areas: data.areas.map((x) => mapArea(x)) });
        }

        for( let i=1; i<=(4 - self.areas.areas.length); i++)
        {
          const area: any = {
              id: uuidv4()
              };
              self.areas.areas.push(area)
        }
      }
    
    const resetStore = () => {
      applySnapshot(self, {});
    };

    return {
      resetStore,
      fetchData: () => {
        const quarryData = flow(function* fetch() {
          
          const response = yield api.get(`/api/EstimateAreaConfig`);
          if (response.status !== 200) {
            return;
          }
            applyData(response.data);
        });

        quarryData();
      },
      save: flow(function* save() {
        self.isLoading = true;
        const response = yield api.post(`/api/EstimateAreaConfig/save`,
            { payload: mapSettings(self.areas) });

        
          applyData(response.data);

        self.isLoading = false;
      }),
      delete: (area: EstimateAreaModel) => {
        const deleteRow = flow(function* fetch() {
          self.isLoading = true;

          const response = yield api.post(`/api/EstimateAreaConfig/delete`,
              { payload: mapArea(area) });
          applyData(response.data);
  
          self.isLoading = false;
        })

        deleteRow()
      },
    };
  })
  .views((self) => {
    const hasChanges = (areas) => {  
      const newRecord = areas.filter(
        (t) => !self.originalSettings.areas.some( (x) => t.equals(x))  && t.area != '').length === 0
     
      if(!newRecord) return true

      const isModified = self.originalSettings.equals(areas);
      return !isModified;
    }; 

    return {
      hasChanges
    };
  });

export type IAreaStore = Instance<typeof AreaConfigStore>;

import { types, Instance, flow, getEnv } from 'mobx-state-tree';
import { IStoresEnv } from '@core';
import { formatDate_yyyymmdd } from '@utils';

export const ServiceCallDetails = types.model({
  unitMake: types.optional(types.string, ''),
  unitModel: types.optional(types.string, ''),
  companyName: types.optional(types.string, ''),
  customerAddress: types.optional(types.string, ''),
  equipmentId: types.optional(types.string, ''),
  divisions: types.optional(types.string, ''),
  description: types.optional(types.string, ''),
  notes: types.optional(types.string, '')
});

export const SERVICE_CALL_NUMBER_LENGTH = 9;

export type ServiceCallDetailsModel = Instance<typeof ServiceCallDetails>;

export const setServiceCall = (model: any, callNumber: string, date: Date, type: number) => {
  const { api } = getEnv<IStoresEnv>(model);
  const { timeSheetDetails: { header: { technicianId } } } = model.$treenode.root.value;
  const fetchData = flow(function* fetch() {
    const serviceCallResponse = yield api.get(
      `/api/serviceCall?callNumber=${callNumber}&technicianId=${technicianId}&date=${formatDate_yyyymmdd(date)}&recordType=${type}`);
    if (serviceCallResponse.status === 204) {
      model.serviceCallDetails = {} as ServiceCallDetailsModel;
      model.serviceCallError = `Call doesn't exist`
    } else if (serviceCallResponse.status === 200) {
      if(serviceCallResponse.data.error){
        model.serviceCallDetails = {} as ServiceCallDetailsModel;
        model.serviceCallError = serviceCallResponse.data.error;
      }
      else{
        model.serviceCallDetails = serviceCallResponse.data;
        model.serviceCallId = serviceCallResponse.data.callNumber;
        model.serviceCallError = '';
      }
    } else {
      model.serviceCallDetails = {} as ServiceCallDetailsModel;
      model.serviceCallError = `Failure retrieving service call details`;
    }
  });

  model.serviceCallId = '';
  model.serviceCallError = 'Validation pending...';
  model.serviceCallNumber = callNumber;
  model.serviceCallDetails = {} as ServiceCallDetailsModel;
  if (callNumber.length === SERVICE_CALL_NUMBER_LENGTH) {
    fetchData();
  }
  else
  {
    model.serviceCallError = 'Service Call ID is not long enough';
  }
};

import React from 'react';
import { observer } from "mobx-react";
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { TakeFiveSummaryModel } from 'take-five-summary/domain/TakeFiveSummary';
import { Paper, Table, TableBody } from '@material-ui/core';
import SortableTableHead, { HeadCell } from 'components/sortable-table/SortableTableHead';
import { SortOrder, sort } from '@utils';
import TakeFiveSummaryRow from './TakeFiveSummaryRow';

const useStyles = makeStyles((theme: Theme) =>
createStyles({
    root: {
      overflow: 'auto',
      width: '100%',
      flex: 1
    }
  }));

interface Props {
    takeFiveSummary: TakeFiveSummaryModel[];
  }

  const headCells: Array<HeadCell<TakeFiveSummaryModel>> = [
    { id: 'technicianId', label: 'Technician Id' },
    { id: 'technicianName', label: 'Technician Name' },
    { id: 'totalCompletedTakeFive', label: 'Total Completed Take Five' },
  ];

export const TakeFiveSummaryTable: React.FC<Props> = observer((props) => {
    const classes = useStyles();
    const { takeFiveSummary } = props;
    const [order, setOrder] = React.useState();
    const [orderBy, setOrderBy] = React.useState();
  
    const handleRequestSort = (newSortOrder: SortOrder, newOrderBy: keyof TakeFiveSummaryModel) => {
      setOrder(newSortOrder);
      setOrderBy(newOrderBy);
    };
  
    return (
      <Paper className={classes.root}>
        <Table stickyHeader={true} size="small">
          <SortableTableHead<TakeFiveSummaryModel>
            headCells={headCells}
            order={order}
            orderBy={orderBy}
            onSort={handleRequestSort}
          />
          <TableBody>
            {sort<TakeFiveSummaryModel>(takeFiveSummary, order, orderBy).map((x, index) => (
              <TakeFiveSummaryRow
                key={index}
                takeFiveSummary={x}
              />
            ))}
          </TableBody>
        </Table>
      </Paper>
    );
  });
  
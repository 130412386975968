import { observer } from 'mobx-react';
import { useStyles } from './tab.style';
import { Grid } from '@material-ui/core';
import React from 'react';
import { formatDate } from '@utils/dateTime';
import { ITakeFiveTabData } from './models/TakeFiveTabData';

interface Props {
  takeFiveData: ITakeFiveTabData
}

export const TakeFiveTab: React.FC<Props> = observer((props) => {
  const classes = useStyles();
  const { takeFiveData } = props;

  return (
      <div className={classes.tabSection}>
        <Grid container={true}
          direction="column"
          wrap="nowrap"
        >
          {takeFiveData.takeFives.map((takeFive) => (
            <Grid item={true}>
              <div className={classes.gridCellName}>{`${formatDate(new Date(takeFive.date))} ${takeFive.time}`}</div>
                <Grid container={true}
                  direction="column"
                  wrap="nowrap"
                >
                {takeFive.answers.map((detail) => {

console.log(detail)
                  return (
                    <Grid 
                    container={true} 
                    spacing={2} 
                    className={classes.gridRow}
                  >
                    <Grid item xs={2}>
                      <div>{detail.answer ? 'Yes' : 'No'}</div>          
                    </Grid>
                    <Grid item xs={10}>
                      <div>{detail.question}</div>
                    </Grid>
                  </Grid>
          )})}
                </Grid>
            </Grid>
          ))}
        </Grid>
      </div>
  );
});

import { types, Instance } from 'mobx-state-tree';
import { round, sum } from 'lodash';

export const PayrollAdjustmentEnquiry = types
    .model({
      id: types.optional(types.string, ''),
      technicianId: types.optional(types.string, ''),
      technician: types.optional(types.string, ''),
      processDate: types.maybeNull(types.string),
      date: types.maybeNull(types.string),
      startTime: types.maybeNull(types.string),
      endTime: types.maybeNull(types.string),
      type: types.optional(types.string, ''),
      leaveType: types.maybeNull(types.string),
      reason: types.maybeNull(types.string),
      amount: types.optional(types.number, 0),
      callout: types.optional(types.number, 0),
      payT: types.optional(types.number, 0),
      payTH: types.optional(types.number, 0),
      payD: types.optional(types.number, 0),
      payDH: types.optional(types.number, 0),
      processed: types.optional(types.boolean, false),
      selected: types.optional(types.boolean, false),
    })
    .views((self) => ({
      get totalPayHoursSum () {
        return round(sum([self.payT,self.payTH, self.payD, self.payDH]), 3);
      }
    }))
    .actions((self) => ({
      selectItem () {
        self.selected = !self.selected;
      },
    }));

export type PayrollAdjustmentEnquiryModel = Instance<typeof PayrollAdjustmentEnquiry>;

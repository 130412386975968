import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { Grid, IconButton, Typography, makeStyles, Theme, createStyles, 
    ExpansionPanelDetails, ExpansionPanel, ExpansionPanelSummary } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { PayrollAdjustmentsDetails } from './PayrollAdjustmentsDetails';
import { Totals } from '../Total';
import { PayrollAdjustmentsModel } from 'timesheet-details/domain/payroll-adjustments/PayrollAdjustments';
import { ErrorDetailsIcon } from 'components/ErrorDetailsIcon';

interface Props {
    disabled?: boolean;
    adjustmentsModel: PayrollAdjustmentsModel;
    validate: () => any;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
        margin: '0 !important'
    },
    summary: {
        '& div:first-child': {
            margin: '0 !important'
        },
        'minHeight': 'auto !important'
    },
    details: {
        padding: '0 !important'
    },
    totals: {
        padding: theme.spacing(1.5)
    }
}));

export const PayrollAdjustments: React.FC<Props> = observer((props) => {
  const [isOpen, setIsOpen] = useState(false);
  const handleExpandCollapseClick = () => {
    setIsOpen(!isOpen);
  };

  const { adjustmentsModel, validate, disabled } = props;

  const classes = useStyles();

  const { adjustmentReasonValidation } = adjustmentsModel.validateReason();
  const errors = [adjustmentReasonValidation].filter((e) => !!e).join('; ');


  return (
    <ExpansionPanel square expanded={isOpen} className={classes.root}>
        <ExpansionPanelSummary className={classes.summary}>
            <Grid container>
                <Grid item xs={6}>
                    <Grid container alignItems="center">
                        <Grid item xs={4}>
                            <Typography variant="subtitle1" gutterBottom>
                                {adjustmentsModel.title}
                            </Typography>
                        </Grid>
                        <Grid item xs={1}>
                            <IconButton
                                onClick={handleExpandCollapseClick}
                                aria-expanded={isOpen}
                            >
                                {isOpen ? <ExpandLessIcon/> : <ExpandMoreIcon />}
                            </IconButton>
                        </Grid>
                        <Grid item xs={1}>
                            <ErrorDetailsIcon errors={errors}/>
                        </Grid>
                    </Grid>
                </Grid>
                {!isOpen &&
                    <Grid item xs={6}>
                        <Totals hours={adjustmentsModel.totalHours}/>
                    </Grid>
                }
            </Grid>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className={classes.details}>
            <Grid container direction="column">
                <PayrollAdjustmentsDetails
                    disabled={disabled}
                    adjustments={adjustmentsModel.adjustments}
                    onAdd={adjustmentsModel.addAdjustment}
                    onDelete={adjustmentsModel.deleteAdjustment}
                    validate={validate}
                />
                {isOpen &&
                    <Grid container direction="row" justify="flex-end">
                        <Grid item xs={6} className={classes.totals}>
                            <Totals hours={adjustmentsModel.totalHours} />
                        </Grid>
                    </Grid>
                }
            </Grid>
        </ExpansionPanelDetails>
    </ExpansionPanel>
  );
});

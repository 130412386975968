import React from 'react';
import { observer } from 'mobx-react';
import { Grid, makeStyles, Theme, createStyles, Typography } from '@material-ui/core';


interface Props {
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
      },
    userLabel: {
        paddingLeft: theme.spacing(5)
    }
}));

export const TakeFiveHeader: React.FC<Props> = observer((props) => {
  const classes = useStyles();

  return (
    <Grid container item xs={12} className={classes.root}>
        <Grid item xs={1} className={classes.userLabel} container alignItems="center">
            <Typography  style={{fontWeight: 'bold'}} variant="body2" display="block" gutterBottom>
                Date
            </Typography>
        </Grid>
        <Grid item xs={1} container alignItems="center">
            <Typography  style={{fontWeight: 'bold'}} variant="body2" display="block" gutterBottom>
                Time
            </Typography>
        </Grid>
        <Grid item xs={3} container alignItems="center">
            <Typography  style={{fontWeight: 'bold'}} variant="body2" display="block" gutterBottom>
               Customer
            </Typography>
        </Grid>
        <Grid item xs={3} container alignItems="center">
            <Typography  style={{fontWeight: 'bold'}} variant="body2" display="block" gutterBottom>
               Address
            </Typography>
        </Grid>
        <Grid item xs={1} container alignItems="center">
            <Typography  style={{fontWeight: 'bold'}} variant="body2" display="block" gutterBottom>
               Service Call Id
            </Typography>
        </Grid>
        <Grid item xs={1} container alignItems="center">
            <Typography  style={{fontWeight: 'bold'}} variant="body2" display="block" gutterBottom>
               Equipment Id
            </Typography>
        </Grid>
        <Grid item xs={1} container alignItems="center">
            <Typography  style={{fontWeight: 'bold'}} variant="body2" display="block" gutterBottom>
               Manufacurer
            </Typography>
        </Grid>
        <Grid item xs={1} container alignItems="center">
            <Typography  style={{fontWeight: 'bold'}} variant="body2" display="block" gutterBottom>
               Model
            </Typography>
        </Grid>
     </Grid>
  );
});

import { types, Instance } from 'mobx-state-tree';
import { EstimateEquipmentType} from './EquipmentType';

import { uuidv4 } from '@utils';


export const EstimateEquipmentTypes = types.model({
  equipmentTypes: types.optional(types.array(EstimateEquipmentType), [])
})
  .actions((self) => {
    return {
      addNewLine: () => {
        const equipmentType: any = {
            id: uuidv4()
        };
        
        self.equipmentTypes.push(equipmentType);
      }
    };
  })
  .views((self) => ({
    equals(equipmentTypes) {
        return self.equipmentTypes.filter(
          (t) => !equipmentTypes.some( (x) => t.equals(x))).length === 0;
    }
  }));

export type EstimateEquipmentTypesModel = Instance<typeof EstimateEquipmentTypes>;

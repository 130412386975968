import { observer } from 'mobx-react';
import React from 'react';
import { Grid, TextField, Select, FormControl, InputLabel, MenuItem } from '@material-ui/core';
import { DetailsHeader } from '../DetailsHeader';
import { AllocationModel } from 'timesheet-details/domain/Allocations';
import { DescriptionPopover } from '../DescriptionPopover';
import { TimeSheetLineModel } from 'timesheet-details/domain/timesheet-lines/TimeSheetLine';
import { format } from 'date-fns';
import { ValidationTooltip } from 'components/ValidationTooltip';
import { OutlinedInput } from '../OutlinedInput';
import NumberFormat, { NumberFormatValues } from 'react-number-format';

interface Props {
    disabled?: boolean;
    allocation: AllocationModel;
    recoverableLostTimeLines: TimeSheetLineModel[];
    date: Date;
    onDelete: (allocation: AllocationModel) => void;
}

const emptyIfNotDefined = (value) => {
    return value === null || value === undefined ? '' : value;
};

export const AllocationRow: React.FC<Props> = observer((props) => {
    const { allocation,
        recoverableLostTimeLines, onDelete, disabled, date } = props;
    const { serviceCallDetails } = allocation;

    const { hoursError, serviceCallError, timelineIdError } =
        allocation.validate();

    const emptyRecoverableTimelinesError = recoverableLostTimeLines.length === 0 ?
        'There is no recoverable timelines' : '';

    const handleDeleteClick = () => {
        onDelete(allocation);
    };

    const handleAllocationTimeChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        allocation.setTimelineId(event.target.value as string);
    };

    const handleHoursChange = (values: NumberFormatValues) => {
        allocation.setHours(values.floatValue || 0);
    };

    const handleServiceCallNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        allocation.setServiceCallNumber(value, date);
    };

    const formatAllocationTimeOption = (timeLineId: string) => {
        const timeLine = recoverableLostTimeLines.find((t) => t.id === timeLineId);
        if (!timeLine) {
            allocation.setTimelineId('');
            return;
        }
        const startTime = format(timeLine.startDateTime, 'hh:mm');
        const endTime = format(timeLine.endDateTime, 'hh:mm');
        return `${startTime} - ${endTime}`;
    };

    return (
    <Grid container direction="column">
        <DetailsHeader
            canDelete={allocation.canDelete && !disabled}
            onDeleteClick={handleDeleteClick}
            status={allocation.status}
            hasErrors={!allocation.isValid}
            errors={['Allocation has errors that should be fixed']}
         />
        <Grid container direction="row" justify="space-between" alignItems="center" >
            <Grid item xs={10}>
                <Grid container direction="row" spacing={2}>
                    <Grid item xs={2}>
                        <ValidationTooltip
                                title={emptyRecoverableTimelinesError || timelineIdError}
                                placement="bottom"
                                arrow={true}
                        >
                            <FormControl
                                margin="dense"
                                variant="outlined"
                                fullWidth={true}
                                error={!!timelineIdError}
                            >
                                <InputLabel id="allocation-time-select-label">
                                    Allocation Time
                                </InputLabel>
                                <Select
                                    labelId="allocation-time-select-label"
                                    disabled={allocation.isReadOnly || disabled || !!emptyRecoverableTimelinesError}
                                    value={allocation.timelineId}
                                    renderValue={formatAllocationTimeOption}
                                    onChange={handleAllocationTimeChange}
                                    labelWidth={110}
                                >
                                    { recoverableLostTimeLines.map((line, index) =>
                                        <MenuItem
                                            key={`alloc_option${line.id}_${index}`}
                                            value={line.id}
                                        >
                                            {formatAllocationTimeOption(line.id)}
                                        </MenuItem>)
                                    }
                                </Select>
                            </FormControl>
                        </ValidationTooltip>
                    </Grid>
                    <Grid item xs={2}>
                        <ValidationTooltip
                            title={serviceCallError || allocation.serviceCallError || ''}
                            placement="bottom"
                            arrow={true}
                        >
                            <TextField
                                error={!!serviceCallError || !!allocation.serviceCallError}
                                label="Service Call"
                                margin="dense"
                                variant="outlined"
                                disabled={allocation.isReadOnly || disabled}
                                value={allocation.serviceCallNumber}
                                onChange={handleServiceCallNumberChange}
                                inputProps={{ maxLength: 9 }}
                            />
                        </ValidationTooltip>
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            label="Customer Name"
                            margin="dense"
                            variant="outlined"
                            disabled
                            value={emptyIfNotDefined(serviceCallDetails?.companyName)}
                        />
                    </Grid>
                    <Grid item xs={1}>
                        <TextField
                            label="Make"
                            margin="dense"
                            disabled
                            variant="outlined"
                            value={emptyIfNotDefined(serviceCallDetails?.unitMake)}
                        />
                    </Grid>
                    <Grid item xs={1}>
                        <TextField
                            label="Model"
                            margin="dense"
                            disabled
                            variant="outlined"
                            value={emptyIfNotDefined(serviceCallDetails?.unitModel)}
                        />
                    </Grid>
                    <Grid item xs={1}>
                        <TextField
                            disabled
                            label="Equip ID"
                            margin="dense"
                            variant="outlined"
                            value={emptyIfNotDefined(serviceCallDetails?.equipmentId)}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            disabled
                            label="Division"
                            margin="dense"
                            variant="outlined"
                            value={emptyIfNotDefined(serviceCallDetails?.divisions)}
                        />
                    </Grid>
                    <Grid item xs={1}>
                        <ValidationTooltip
                                title={hoursError}
                                placement="bottom"
                                arrow={true}
                        >
                                <NumberFormat
                                    disabled={allocation.isReadOnly || disabled}
                                    label="Hours"
                                    margin="dense"
                                    variant="outlined"
                                    error={!!hoursError}
                                    allowNegative={true}
                                    customInput={OutlinedInput}
                                    value={allocation.hours}
                                    displayType="input"
                                    decimalScale={2}
                                    onValueChange={handleHoursChange}
                                />
                        </ValidationTooltip>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={1}>
                { !!serviceCallDetails?.description &&
                    <Grid container direction="row" justify="flex-end">
                        <DescriptionPopover content={serviceCallDetails?.description} />
                    </Grid>
                }
            </Grid>
        </Grid>
    </Grid>
  );
});

import { types, Instance } from 'mobx-state-tree';

export const CommonJobsSummary = types
    .model({
      id: types.boolean,
      description: types.string,
        labour: types.integer,
        price: types.integer,
      selected: types.optional(types.boolean, false),
    })
    .actions((self) => ({
      selectItem () {
        self.selected = !self.selected;
      },
    }));

export type CommonJobsModel = Instance<typeof CommonJobsSummary>;



import React from 'react';
import { ITechnicianGroup } from '../domain/TechnicianGroup';
import { observer } from 'mobx-react';
import { IPayEnquiry } from 'pay-enquiry/domain/PayEnquiry';
import { EnquiryRowItem } from './EnquiryRowItem';
import { sort, SortOrder } from '@utils/sort';
import { TotalRow } from './TotalRow';

interface Props {
  technicianGroup: ITechnicianGroup;
  order: SortOrder;
  orderBy: keyof IPayEnquiry;
  lastTotalRow: boolean;
  beforeV2: boolean
}

export const TechnicianGroup: React.FC<Props> = observer((props) => {
  const { technicianGroup, order, orderBy, lastTotalRow, beforeV2 } = props;

  const handleGroupSelect = () => {
    technicianGroup.selectGroup();
  };

  return (
    <>
      {sort<IPayEnquiry>(technicianGroup.filteredEnquiries, order, orderBy).map((group, index) => (
        <EnquiryRowItem
          key={index}
          index={index}
          selected={technicianGroup.selected}
          onSelect={handleGroupSelect}
          enquiry={group}
          technician={technicianGroup.technician}
          technicianId={technicianGroup.technicianId}
          beforeV2={beforeV2}
        />
      ))}
      <TotalRow
        lastTotalRow={lastTotalRow}
        technicianGroup={technicianGroup}
        beforeV2={beforeV2}
      />
    </>
  );
});

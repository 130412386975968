import React from 'react';
import ErrorIcon from '@material-ui/icons/Error';
import red from '@material-ui/core/colors/red';
import { ValidationTooltip } from 'components/ValidationTooltip';
import { observer } from 'mobx-react';
import { makeStyles, Theme, createStyles } from '@material-ui/core';

interface Props {
  errors: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
  error: {
    color: red[500]
  }
}));

export const ErrorDetailsIcon: React.FC<Props> = observer((props) => {
  const { errors } = props;
  const classes = useStyles();

  if (!errors) {
    return null;
  }

  return (
      <ValidationTooltip title={errors || ''} placement="bottom" arrow={true}>
          <ErrorIcon className={classes.error} />
      </ValidationTooltip>
  );
});

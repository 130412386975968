import { observer } from 'mobx-react';
import React from 'react';
import { EstimatePartsModel } from './domain/EstimateParts';
import { EstimatePart } from './EstimatePart';
import { Button, makeStyles, createStyles, Theme } from '@material-ui/core';
import { EstimatePartModel } from './domain/EstimatePart';
import { IPartStore } from './store/PartStore';
import { IAppStore } from '@shared/store';

interface Props {
    EstimatePartModel: EstimatePartsModel;
    parts: EstimatePartModel[];
    onDelete: (part: EstimatePartModel) => void;
    estimateID: string;
    debtorID: string;
    PartStore: IPartStore;
    appStore: IAppStore;
    isReadOnly: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    partWrapper: {
      background: '#fff',
      padding: '2rem'
    },
    label: {
      textTransform: 'capitalize'
    },
    newLineBtn: {
      marginTop: '1rem'
    },
    saveBtn: {
      textAlign: 'right'
    }
}));
export const EstimateParts: React.FC<Props> = observer((props) => {

const { EstimatePartModel, parts, isReadOnly} = props;
const classes = useStyles();

const onAddNewTimeline = () => {
    return EstimatePartModel.addNewLine();
};

const handleDelete = (part) => {
  props.onDelete(part);
};

  return (
    <form>
      { parts.map((line, index) =>
        <EstimatePart 
        key={line.id} 
        part={line}
        estimateID={props.estimateID}
        debtorID={props.debtorID}
        PartStore={props.PartStore}
        AppStore={props.appStore}
        isReadOnly={isReadOnly}
        onDeleteClick={handleDelete}/>
      )}
      <Button
        variant="outlined"
        color="primary"
        onClick={onAddNewTimeline}
        className={classes.newLineBtn}
      >
        ADD NEW LINE
      </Button>
    </form>
  );
});


import { types, Instance } from 'mobx-state-tree';

export const EstimateList = types
  .model({   
    ID: types.optional(types.string, ''),
    SubmittedDate: types.optional(types.string, ''),
    EstimateNr: types.optional(types.string, ''),
    Revision: types.optional(types.integer, 0),
    Priority: types.optional(types.string, ''),
    FleetType: types.optional(types.string, ''),
    DebtorID: types.optional(types.string, ''),
    CustomerName: types.optional(types.string, ''),
    Location: types.optional(types.string, ''),
    EquipmentID: types.optional(types.string, ''),
    Make: types.optional(types.string, ''),
    Model: types.optional(types.string, ''),
    JobDescription: types.optional(types.string, ''),
    Status: types.optional(types.string, ''),
    Owner: types.optional(types.string, ''),
    Branch: types.optional(types.string, ''),
    Details: types.optional(types.string, ''),
  })
  .actions((self) => {
    return {




    };
  });

export type EstimateListModel = Instance<typeof EstimateList>;
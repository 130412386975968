import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

interface Props {
    title: string;
    description?: string;
    declineButtonTitle?: string;
    confirmButtonTitle?: string;
    open: boolean;
    onClose: (confirmed: boolean) => void;
}

export default function ConfirmationDialog(props: Props) {
  const { open, onClose, title, description, confirmButtonTitle, declineButtonTitle } = props;

  const handleCancelClick = () => {
    onClose(false);
  };

  const handleOkClick = () => {
    onClose(true);
  };

  return (
      <Dialog
        open={open}
        onClose={handleCancelClick}
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {description}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelClick} color="primary">
            {declineButtonTitle || 'Cancel'}
          </Button>
          <Button onClick={handleOkClick} color="primary" autoFocus>
            {confirmButtonTitle || 'Ok'}
          </Button>
        </DialogActions>
      </Dialog>
  );
}

import { makeStyles, Theme, createStyles } from "@material-ui/core";
import { blue, grey } from '@material-ui/core/colors';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cell: {
      borderTop: `1px solid ${grey[300]}`,
      borderBottom: `1px solid ${grey[300]}`
    },
    label: {
      padding: 0,
      textTransform: 'none',
      color: blue[500]
    },
  }));

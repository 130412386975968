import { makeStyles, Theme, createStyles } from "@material-ui/core";
import { lightBlue } from '@material-ui/core/colors';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      textDecoration: 'none'
    },
    label: {
      textTransform: 'none'
    },
    row: {
      '&:hover': {
        backgroundColor: lightBlue[100]
      },
    }
  }));
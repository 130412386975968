import { types, Instance } from 'mobx-state-tree';

export const TechnicianTeam = types
  .model({
    teamName: types.string,
    numberOfUsers: types.number,
    integratedUpTo: types.maybeNull(types.string),
    status: types.optional(types.string, ''),
    errors: types.optional(types.array(types.string), []),
  });

export type ITechnicianTeam = Instance<typeof TechnicianTeam>;

import { observer } from 'mobx-react';
import React from 'react';
import { Tabs, Tab } from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core';
import { UserSetup } from './user-setup/UserSetup';
import { LocalFindSetup } from './local-find-setup/LocaFindSetup';
import { useParams, NavLink } from 'react-router-dom';

enum SetupTabs {
  user = 0,
  localFind = 1
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    label: {
        textTransform: 'capitalize'
    },
    link: {
      textDecoration: 'none',
      color: theme.palette.primary.main
    },
}));

interface PathParams {
  tab: string;
}

export const SetupPage: React.FC<{}> = observer((props) => {
  const params = useParams<PathParams>();
  const classes = useStyles();

  const selectedTab = params.tab === 'localFind' ? SetupTabs.localFind : SetupTabs.user;

  return (
    <React.Fragment>
        <Tabs
          value={selectedTab}
          indicatorColor="primary"
        >
          <NavLink to={params.tab ? `user` : `setup/user`} color="primary" className={classes.link}>
            <Tab label="User Setup" className={classes.label} />
          </NavLink>
          <NavLink to={params.tab ? `localFind` : `setup/localFind`} color="primary" className={classes.link}>
            <Tab label="Local Find Setup" className={classes.label}/>
          </NavLink>
        </Tabs>
      {selectedTab === SetupTabs.user && <UserSetup />}
      {selectedTab === SetupTabs.localFind && <LocalFindSetup /> }
  </React.Fragment>
  );
});

import { observer } from 'mobx-react';
import React from 'react';
import { Checkbox, FormControlLabel, Grid, TextField, makeStyles } from '@material-ui/core';
import { HoursRow } from '../HoursRow';
import { DetailsHeader } from '../DetailsHeader';
import { PayrollAdjustmentModel } from 'timesheet-details-v2/domain/payroll-adjustments/PayrollAdjustment';
import { AdjustmentType } from 'timesheet-details-v2/domain/payroll-adjustments/AdjustmentType';
import EnumSelector from 'components/EnumSelector';
import { TimeSheetTimePicker } from '../TimeSheetTimePicker';
import { LeaveType } from 'timesheet-details-v2/domain/payroll-adjustments/LeaveType';

interface Props {
    disabled?: boolean;
    date: Date,
    adjustment: PayrollAdjustmentModel;
    validate: () => any;
    onDelete: (adjustment: PayrollAdjustmentModel) => void;
}

const AdjustmentTypes = new Map<string, string>([
    [AdjustmentType.Time, 'Time'],
    [AdjustmentType.Leave, 'Leave'],
    [AdjustmentType.OnCallAllowance, 'On Call Allowance'],
    [AdjustmentType.PHOnCallAllowance, 'P/H On Call Allowance'],
    [AdjustmentType.MealAllowance, 'Meal Allowance']
  ]);

  
const LeaveTypes = new Map<string, string>([
    [LeaveType.Empty, ''],
    [LeaveType.ExtendedLWOP, 'Extended LWOP'],
    [LeaveType.ParentalLeave, 'Parental Leave'],
    [LeaveType.JuryDuty, 'Jury Duty'],
    [LeaveType.ArmyReserves, 'Army Reserves'],
    [LeaveType.CompassionateLeave, 'Compassionate Leave'],
    [LeaveType.AnnualLeave, 'Annual Leave'],
    [LeaveType.SickLeave, 'Sick Leave'],
    [LeaveType.LongServiceLeave, 'Long Service Leave'],
    [LeaveType.CarersLeave, "Carer's Leave"],
    [LeaveType.WorkersComp, "Worker's Comp"],
    [LeaveType.LeaveWithoutPay, 'Leave Without Pay'],
    [LeaveType.PublicHoliday, 'Public Holiday'],
    [LeaveType.AnniversaryLeave, 'Anniversary Leave'],
  ]);


export const PayrollAdjustmentRow: React.FC<Props> = observer((props) => {

    const useStyles = makeStyles({
        root: {
            padding: '8px 0px 0px 0px'
        },
      });
    
    const classes = useStyles();
    const { adjustment,  onDelete, disabled } = props;

    const disabledTime = adjustment.type == AdjustmentType.MealAllowance || adjustment.type == AdjustmentType.OnCallAllowance || adjustment.type == AdjustmentType.PHOnCallAllowance
    
    const handleDelete = () => {
        onDelete(adjustment);
    };

    const handleReasonChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        adjustment.setReason(event.target.value);
    };

    const handleAmountChange = (value: string) => {
        adjustment.setAmount(parseInt(value, 10));
    };

    const onCallOutChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        adjustment.setCallOut(checked);
    };

    const onNegativeChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        adjustment.setNegative(checked);
    };

    const getAmountOptions = () => {
        if (adjustment.isAllowanceType) {
            return new Map<number, number>([
                [-1, -1],
                [1, 1]
              ]);
        }

        return new Map<number, number>([ [0, 0] ]);
    };

    const { adjustmentReasonMissingError, startTimeError, endTimeError, timeRangeError, leaveTypeMissingError} = adjustment.validate();

    const errors = [adjustmentReasonMissingError, startTimeError, endTimeError,  timeRangeError, leaveTypeMissingError]
        .filter((e) => !!e);

    return (
    <>
        <DetailsHeader
            canDelete={adjustment.canDelete && !disabled}
            status={adjustment.status}
            errors={errors}
            hasErrors={errors.length > 0}
            onDeleteClick={handleDelete}
        />
        <Grid container direction="row" alignItems="center" >
            <Grid item xs={8}>
                <Grid container spacing={4} direction="row">
                    <Grid item xs={7}>
                        <Grid container spacing={1} direction="row">
                            <Grid item xs={2}>
                                <EnumSelector
                                    label="Type"
                                    disabled={adjustment.isReadOnly || disabled}
                                    value={adjustment.type}
                                    options={AdjustmentTypes}
                                    onChange={adjustment.setType}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <EnumSelector
                                    label="Leave Type"
                                    disabled={!adjustment.isLeaveRequired || adjustment.isReadOnly || disabled}
                                    options={LeaveTypes}
                                    value={adjustment.leaveType}
                                    onChange={adjustment.setLeaveType}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    fullWidth={true}
                                    label="Reason"
                                    disabled={adjustment.isReadOnly || disabled}
                                    margin="dense"
                                    variant="outlined"
                                    error={adjustmentReasonMissingError !== ""}
                                    value={adjustment.reason}
                                    onChange={handleReasonChange}
                                />
                            </Grid>
                            <Grid item xs={1}>
                                <EnumSelector
                                    disabled={adjustment.isReadOnly || !adjustment.isAllowanceType || disabled}
                                    label="Amount"
                                    value={adjustment.amount}
                                    options={getAmountOptions()}
                                    onChange={handleAmountChange}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={5} >
                        <Grid container spacing={1} direction="row">
                            <Grid item xs={6}>
                                <Grid container spacing={1} direction="row">
                                    <Grid item xs={6}>
                                        <TimeSheetTimePicker
                                            label="Start"
                                            dateTime={adjustment.startDateTime}
                                            onChange={adjustment.setStartTime}
                                            error={startTimeError}
                                            disabled={disabledTime}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TimeSheetTimePicker
                                            label="End"
                                            dateTime={adjustment.endDateTime}
                                            onChange={adjustment.setEndTime}
                                            error={endTimeError}
                                            disabled={disabledTime}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={6}>
                                <Grid container spacing={1} direction="row">
                                    <Grid item xs={8}>
                                        <FormControlLabel
                                            control={
                                            <Checkbox
                                                disabled={adjustment.isReadOnly || disabled}
                                                checked={adjustment.callOut}
                                                value="callOut"
                                                color="primary"
                                            />
                                            }
                                            label="Call Out"
                                            onChange={onCallOutChange}
                                            classes={classes}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <FormControlLabel
                                            control={
                                            <Checkbox
                                                disabled={adjustment.isReadOnly || disabled}
                                                checked={adjustment.negative}
                                                value="negative"
                                                color="primary"
                                            />
                                            }
                                            label="Negative Hours"
                                            onChange={onNegativeChange}
                                            classes={classes}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={3}>
                <HoursRow
                    model={adjustment.hours}
                    disableGPFields={true}
                    disabled={true}
                    onNormalPayChange={adjustment.setNormalPay}
                    allowNegative={true}
                />
            </Grid>
        </Grid>
    </>
  );
});

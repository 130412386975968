import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import { NavLink } from 'react-router-dom';
import { Theme, makeStyles, createStyles } from '@material-ui/core';

interface Props {
    title: string;
    open: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      textDecoration: 'none'
    }
  }));

export default function TimesheetPromt(props: Props) {
  const { open, title } = props;
  const classes = useStyles();

  return (
      <Dialog
        open={open}
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogActions>
            <NavLink to={'/daily-timesheet-summary'} className={classes.button}>
                <Button color="primary" autoFocus>
                    {'Ok'}
                </Button>
            </NavLink>
        </DialogActions>
      </Dialog>
  );
}

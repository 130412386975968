import { Instance, types } from 'mobx-state-tree';
import { UpdateLogModel, IUpdateLogModel } from './UpdateLogModel';
import { TravelRecordModel, ITravelRecordModel } from './TravelRecordModel';
import { sort } from '@utils';

export const LogsTabData = types
  .model({
    statusUpdateLocationLatitude: types.maybeNull(types.number),
    statusUpdateLocationLongitude: types.maybeNull(types.number),
    updateLogs: types.optional(types.array(UpdateLogModel), []),
    travelRecords: types.optional(types.array(TravelRecordModel), []),
  })
  .views((self) => ({
    get getupdateLogs() {
      return sort<IUpdateLogModel>(self.updateLogs, 'asc', 'statusUpdateTime'); 
    },
    get getTravelRecords() {
      return sort<ITravelRecordModel>(self.travelRecords, 'asc', 'startTime'); 
    },
  }))

export type ILogsTabData = Instance<typeof LogsTabData>;

import Paper from '@material-ui/core/Paper';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import React from 'react';
import { OutstandingApprovalsModel } from '../domain/OutstandingApprovals';
import ApprovalRow from './ApprovalRow';
import SortableTableHead, { HeadCell } from 'components/sortable-table/SortableTableHead';
import { SortOrder, sort } from '@utils';
import { observer } from 'mobx-react-lite';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      overflowX: 'auto',
      width: '100%',
      flex: 1
    }
  }));

interface Props {
  approvals: OutstandingApprovalsModel[];
}

const headCells: Array<HeadCell<OutstandingApprovalsModel>> = [
  { id: 'technicianName', label: 'Technician ID' },
  { id: 'date', label: 'Date' },
  { id: 'gpHours', label: 'GP Hours' },
  { id: 'payrollHours', label: 'Payroll Hours' },
  { id: 'actions', label: '' }
];

export const ApprovalsTable: React.FC<Props> = observer((props) => {
  const classes = useStyles();
  const { approvals } = props;

  const [order, setOrder] = React.useState();
  const [orderBy, setOrderBy] = React.useState();

  const handleRequestSort = (newSortOrder: SortOrder, newOrderBy: keyof OutstandingApprovalsModel) => {
    setOrder(newSortOrder);
    setOrderBy(newOrderBy);
  };

  return (
    <Paper className={classes.root}>
      <Table stickyHeader={true} size="small">
        <SortableTableHead<OutstandingApprovalsModel>
          headCells={headCells}
          order={order}
          orderBy={orderBy}
          onSort={handleRequestSort}
        />
        <TableBody>
          {sort<OutstandingApprovalsModel>(approvals, order, orderBy).map((ap) => (
            <ApprovalRow
              key={ap.technicianId}
              approval={ap}
            />
          ))}
        </TableBody>
      </Table>
    </Paper>
  );
});

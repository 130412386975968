export enum AppointmentStatus {
  Created = 0,
  Dispatched = 10,
  Received = 20,
  Reviewed = 30,
  Accepted = 40,
  Started = 50,
  Paused = 60,
  Completed = 70,
  Rejected = 80,
  Cancel = 90,
  Cancelled = 100,
  Unassigned = 110,
}
  
import { types, Instance } from 'mobx-state-tree';

export const EstimateArea = types.model({
  id: types.optional(types.string, ''),
  area: types.optional(types.string, '')
})
  .actions((self) => {
    return {
      setArea: (area: string) => {
          self.area = area;
      },

    };
  })
  .views((self) => ({
      equals(area) {
          return self.area === area.area
      }
    })
  );

export type EstimateAreaModel = Instance<typeof EstimateArea>;

import { pluralize } from '@utils';
import { types, Instance } from 'mobx-state-tree';
import { AdjustmentType } from './AdjustmentType';
import { TimeSheetStatus } from 'components/TimeSheetStatusViewer';
import { GpAdjustment, GpAdjustmentModel } from './GpAdjustment';
import { getTotalHours } from '../TimeSheetHours';

export const GpAdjustments = types.model({
  adjustments: types.optional(types.array(GpAdjustment), [])
})
  .actions((self) => {
    return {
      addAdjustment: () => {
        self.adjustments.push({
          status: TimeSheetStatus.New,
          tempStatus: TimeSheetStatus.Draft,
          type: AdjustmentType.Travel,
          hours: {},
          serviceCallDetails: {}
        });
      },
      deleteAdjustment: (adj: GpAdjustmentModel) => {
        self.adjustments.remove(adj);
      },
    };
  })
  .views((self) => {
    const hasChangesInternal = (originalAdjustment) => {
      const adjustment = self.adjustments.find((l) => l.id === originalAdjustment.id);
      if (!adjustment) {
        return true;
      }

      const isEqual = originalAdjustment.equals(adjustment);
      return !isEqual;
    };
    const isServiceCallsValid = () => {
      return self.adjustments.length === 0 || self.adjustments.every((t) => {
        return t.isServiceCallValid
      });
    };

    return {
      get title() {
        const adjustmentCount = self.adjustments.length;
        return `GP Adjustments ( ${adjustmentCount} ${pluralize('event', 's', adjustmentCount)} )`;
      },
      get totalHours() {
        return getTotalHours(self.adjustments.map((t) => t.hours));
      },
      hasChanges(originalData) {
        return originalData.adjustments.length !== self.adjustments.length
          || originalData.adjustments.some(hasChangesInternal);
      },
      isValid() {
        return isServiceCallsValid();
      }
    };
  });

export type GpAdjustmentsModel = Instance<typeof GpAdjustments>;

import { observer } from 'mobx-react';
import React, {useEffect} from 'react';
import { Grid, makeStyles, Theme, createStyles, Button,
     FormControlLabel, Checkbox, Divider } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { UserTeamConfigurationModel } from '../domain/UserConfiguration';
import { useLDClient } from 'launchdarkly-react-client-sdk';

interface Props {
    team: UserTeamConfigurationModel;
    onDelete: (team: UserTeamConfigurationModel) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    row: {
        marginLeft: theme.spacing(6)
    },
    teamLabel: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    },
    label: {
        textTransform: 'capitalize'
    }
}));

export const UserTeamDetails: React.FC<Props> = observer((props) => {
    const ldClient = useLDClient();
    const classes = useStyles();
    const { team } = props;
    let [dispayTimesheetCreator, setDispayTimesheetCreator]  = React.useState(false)

    const handleTimesheetApproverChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        team.setTimesheetApprover(event.target.checked);
      };

    const handleTimesheetCreatorChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        team.setTimesheetCreator(event.target.checked);
    };

    const handleTimesheetEditorChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        team.setTimesheetEditor(event.target.checked);
    };

    const handlePayrollEnquiryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        team.setPayrollEnquiry(event.target.checked);
    };

    const handleDeleteClick = () => {
        props.onDelete(team);
    };

    useEffect(() => {
        setDispayTimesheetCreator(ldClient?.variation('timesheet-creation-security', false))
      }, []);

    return (
    <Grid direction="column" container spacing={0}>
        <Grid container item direction="row">
            <Grid container item direction="row" xs={11}>
                <Grid item xs={2} className={classes.row}>
                    <Box className={classes.teamLabel} fontWeight="fontWeightBold" m={1}>
                        {team.teamName}
                    </Box>
                </Grid>
                {dispayTimesheetCreator ?
                    <Grid item xs={3}>
                        <FormControlLabel
                            control={
                            <Checkbox
                                checked={team.isTimesheetCreator}
                                onChange={handleTimesheetCreatorChange}
                                value="checkedTimesheetCreator"
                                color="primary"
                            />
                            }
                            label="Non-Leave Timesheet Creator"
                        />
                    </Grid>
                : ""}
                <Grid item xs={2}>
                    <FormControlLabel
                        control={
                        <Checkbox
                            checked={team.isTimesheetEditor}
                            onChange={handleTimesheetEditorChange}
                            value="checkedTimesheetEditor"
                            color="primary"
                        />
                        }
                        label="Timesheet Editor"
                    />
                </Grid>
                <Grid item xs={2}>
                    <FormControlLabel
                        control={
                        <Checkbox
                            checked={team.isTimesheetApprover}
                            onChange={handleTimesheetApproverChange}
                            value="checkedTimesheetApprover"
                            color="primary"
                        />
                        }
                        label="Timesheet Approver"
                    />
                </Grid>
                <Grid item xs={2}>
                    <FormControlLabel
                        control={
                        <Checkbox
                            checked={team.isPayrollEnquiry}
                            onChange={handlePayrollEnquiryChange}
                            value="checkedPayroll Enquiry"
                            color="primary"
                        />
                        }
                        label="Payroll Enquiry"
                    />
                </Grid>
            </Grid>
            <Grid container
                item
                xs={1}
                justify="flex-end"
                alignItems="flex-end"
            >
                <Grid item xs={12}>
                    <Button
                        color="primary"
                        className={classes.label}
                        startIcon={<DeleteOutlineIcon />}
                        onClick={handleDeleteClick}
                    >
                        Delete
                    </Button>
                </Grid>
            </Grid>
        </Grid>
        <Divider />
    </Grid>
  );
});
